import {
	Message
} from 'element-ui'
import config from '@/config/index';
import Vue from 'vue';

export default {

  /**
   * 提交信息不完整或者有错误提示 页面定位至相应位置
   * @param {string} message 提示语句
   * @param {string} className is-error：适用于form表单；is-error-button：除form表单的其他必选  
   */
  completeFormInformation: function (message,className) {
      setTimeout(() => {
        if(className=='is-error'){
          if (document.getElementsByClassName('el-form-item__error').length > 0) {
            let isError = document.getElementsByClassName(className);
            isError[0].scrollIntoView({block: "center"});
          }
        } else if(className=='is-error-button'){
          let isError = document.getElementsByClassName(className);
          isError[0].scrollIntoView({block: "center"});
        } else {
          let isError = document.getElementsByClassName(className);
          isError[0].scrollIntoView({block: "center"});
        }    
        Message({
          showClose: true,
          message: message,
          type: 'error'
        })     
      },100);                                            
  },

  /**
   * 新窗口打开页面
   * @param {string} pageUrl
   */
  openNewWindow: function (pageUrl) {
    let newWin = window.open('')
    let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
    let url = config.GO_URL + headsUrls + pageUrl
    newWin.location.href = url
  }

}

Vue.prototype.formatNextDate = function (dateStr){
	
	if (!dateStr) return '';
	
	var date = dateStr.split('-');
	var year = date[0];
	var month = +date[1] - 1;
	var day = +date[2];
	
	return getTimeStr(new Date(year, month,day+1))
}
Vue.prototype.formatPrevDate = function (dateStr){
	
	if (!dateStr) return '';
	
	var date = dateStr.split('-');
	var year = date[0];
	var month = +date[1] - 1;
	var day = +date[2];
	
	return getTimeStr(new Date(year, month,day-1))
}

//将时间转换为字符串类型
function getTimeStr(date) {
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();

	return [
		year,
		month > 9 ? month : ('0' + month),
		day > 9 ? day : ('0' + day),
	].join('-')
}