import axios from 'axios'
import {
	Message,
	MessageBox
} from 'element-ui'
import store from '@/store/index'
import {
	getToken
} from '@/utils/auth'
import config from '@/config/index'
import router from '@/router/index'

// create an axios instance
const service = axios.create({
	withCredentials: true,
	baseURL: config.BASE_URL, // api的base_url
	// timeout: 10000 // request timeout
})

// var URL = window.location.href;
// let h = URL.split(".")[0];
// let domain = h.split("//")[1];
// var secondParam = ''
// if (process.env.NODE_ENV == 'development') {
// 	// secondParam = 'bofenfen'//推手模式
// 	secondParam = 'xcs'//推手模式
// 	// secondParam = 'nyhzp'// secondParam ='dke'
// 	// secondParam ='diankeyi3'
// 	// secondParam = '1'//推手模式
// 	// secondParam = 'zz'//免费版
// 	// secondParam = 'lxy'//代理模式
// } else {
// 	secondParam = domain
// }




// request interceptor
service.interceptors.request.use(config => {
	// Do something before request is sent
	if (getToken()){
		 config.headers['Authorization'] = getToken();
	}
	if (store.getters.token) {
		// config.headers['X-Token'] = getToken() // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
	}
	return config
}, error => {
	// Do something with request error
	console.log(error) // for debug
	Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(response => {

	var data = {};
	try{
		
		//如果是登录接口， 提前清空message, 
		//解决第一次输入错误密码，并且错误信息还没消失的时候， 进入首页后错误信息还继续显示的问题
		if (response.config.url.indexOf('auth/pclogin')>-1){
			Message.closeAll();
		}
		
		data = JSON.parse(response.config.data);
	}catch(e){}
			// console.log(data,666)
	if (response.data.ErrorCode) {
		//标记了noError的接口， 不走reject, 直接返回正常数据
		if (data.noError){
			// console.log(response.data,222)
			return response.data;
		}else if(response.data.ErrorCode === 20000007){
			Message({
				showClose: true,
				message: response.data.Message,
				type: 'error',
				duration: 3000
			})
			router.push({
				path: '/forget'
			})
			return Promise.reject(response.data.Message)
		}else if(response.data.ErrorCode === 20000010){
			MessageBox.confirm('企店系统登录功能已更新：登录帐号和登录密码一对一匹配。为此请重新设置您的登录密码（如果您的登录帐号关联多个商城，登录成功后即可切换进入关联商城后台）', '提示', {confirmButtonText:'前往设置'}).then(res=>{
				router.push({
					path: '/forget'
				})
			});
			return Promise.reject(response.data.Message)
		}else if(response.data.ErrorCode === 20000008){
			router.push({
				path: '/selectMall'
			})
			return;
		}else if(response.data.ErrorCode === 20000009){
			MessageBox.alert('你已切换到其它商户后台，请刷新页面继续操作', '提示').then(res=>{
				location.reload();
			})
			return;
		}else if(response.data.ErrorCode === 401){
			MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
				confirmButtonText: '重新登录',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				store.dispatch('FedLogOut').then(() => {
					location.href = location.origin + '/index.html#/login';
					
				})
			})
			return;
		}else if (response.data.ErrorCode === 20000012){
			MessageBox.confirm(response.data.Message, '提示', {
				confirmButtonText: '去开通',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				router.push({
					path: '/versionOrder'
				})
			})
			return;
		}
		//限时折扣 如果有商品冲突 需要显示冲突商品,所以还是要把数据返回回去 然后在页面里判断
		if (response.data.Result && response.data.Result.length) {
			return response.data
		} else {
			
			Message({
				showClose: true,
				message: response.data.Message,
				// dangerouslyUseHTMLString:true,
				// message: `<p style="white-space:pre-wrap;line-height:25px">${response.data.Message}</p>`,
				type: 'error',
				duration: 5 * 1000,
				// duration: 5 * 1000
			})
			return Promise.reject(response.data.Message)
		}

	} else {
		return response.data;
	}
}, error => {
	if (error.response.status == '401') {
		MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
			confirmButtonText: '重新登录',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			// 先注释着
			// router.push({
			// 	path: '/login'
			// })

			store.dispatch('FedLogOut').then(() => {
				location.href = location.origin + '/index.html#/login';
			})
		})
	} else {
		console.log('error:' + error.message) // for debug
		Message({
			showClose: true,
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
	}
	return Promise.reject(error)
})


export default async (url = '', data = {}, type = 'post') => {
	for (let key in data) {
		if (typeof(data[key]) == 'string') {
			data[key] = data[key].replace(/(^\s*)|(\s*$)/g, "")
		} else if (typeof(data[key]) == 'object' && (!Array.isArray(data[key]))) {
			let chiildData = data[key]
			for (let keyWords in chiildData) {
				if (typeof(chiildData[keyWords]) == 'string') {
					chiildData[keyWords] = chiildData[keyWords].replace(/(^\s*)|(\s*$)/g, "")
				}
			}
		}
	}
	
	var secondParam = '';
	var val = localStorage.getItem('mlmzMallKey');
	if (val){
		secondParam = val;
	}

	// if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE !== 'stage' && url.indexOf('/pc/') === 0){
	// 	url = '/pc' + url;
	// }
	
	if (type === 'get') {
		return service({
			url: url + '?MallKey=' + secondParam,
			method: type,
			params: data
		})
	} else if (type === 'post') {
		return service({
			url: url + '?MallKey=' + secondParam,
			method: type,
			data: data
		})
	}
}
