<template>
	 <!-- v-show="muneShow" -->
	 <div class="left-scroll-bar">
		 <el-scrollbar wrap-class="scrollbar-wrapper">
		 	<el-menu :default-active="$route.path" mode="vertical" text-color="#333333"
		 	 active-text-color="#409EFF">
		 		<!-- <el-menu-item index="-1">
		 			<div style="color:#fff;font-size:26px;margin:40px 0 ;text-align:center"></div>
		 		</el-menu-item> -->
		 		<sidebar-item :item="routerObject" :slectRouter="slectRouter" :base-path="routerObject.path" />
		 	</el-menu>
		 </el-scrollbar>
		 <!-- <div class="tools">
			 <CustomerServicePub></CustomerServicePub>
		 </div> -->
	 </div>
	
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import SidebarItem from './SidebarItem'
	// import CustomerServicePub from '@/components/CustomerServicePub.vue'
	
	export default {
		props: {
			mylyshopList: {
				type: Object
			},
			mySelectMenu: {
				type: String,
				default: ''
			}
		},
		components: {
			SidebarItem,
			// CustomerServicePub
		},
		data() {
			return {
				muneShow: true,
				routerObject: {},
				slectRouter: ''
			}
		},
		created () {
			// console.log(this.$route.path)
		},
		computed: {
			...mapGetters([
				'permission_routers',
				'sidebar'
			]),
			isCollapse() {
				return !this.sidebar.opened
			}
		},
		watch: {
			mylyshopList(value) {
				// console.log(value)
				this.routerObject = value;
				if (value.children.length < 2) {
					this.muneShow = false;
				} else {
					this.muneShow = true
				}
				this.$emit('getIshowmenu',this.muneShow)
			},
			mySelectMenu(value) {
				this.slectRouter = value;
			}
		},
	}
</script>

<style lang="less" scoped>
	.left-scroll-bar{
		overflow: hidden;
		
		display: flex;
		flex-direction: column;
		// border: 1px solid black;
		.el-scrollbar{
			flex: 1 1 auto;
			overflow: hidden;
		}
		.tools{
			flex: 0 0 auto;
		}
	}
</style>
