<template>
	<div class="tags-view-container">
		<div class="view-container-childs max-zindex">
			<el-row>
				<el-col :span="3">
					<div style="background: #1a2533;height:61px;line-height:60px;text-align: left;padding-left: 40px;">
						<img :src="localPageLogo||'https://cdn.dkycn.cn/melyshop/image/20210722-9726909c-ec6e-450d-b45b-43dcbaea5e8d.png'" alt=""
							style="width: 116px;padding-top: 10px;margin-right: 30px;height: 50px;object-fit: contain;">
					</div>
				</el-col>
				<el-col :span="16">
					<el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#1a2533"
						text-color="#fff" active-text-color="#409EFF" :default-active="activeIndex">
						<el-menu-item :index="item.path" v-for="(item,index) in routerList"
							style="display:flex;justify-content: center;" :disabled='index!=0 && freeMallNoSet'
							:key="index" @click="parentsRouter(item)" class="hahah"
							:class="{'isNoShowzj':(item.path=='/myNeed' || item.path=='/SystemNews'||item.path=='/smsaccount'||item.path=='/myrecommended'
							||item.path=='/myAccountparent')}">
							<span v-if="item.meta&&item.meta.title"
								slot="title">{{ generateTitle(item.meta.title) }}</span>
						</el-menu-item>
					</el-menu>
				</el-col>
				<el-col :span="5" style="background:#1a2533;position:relative;">
					<div class="right-menu-people">
						<el-popover placement="bottom" trigger="hover" content="" v-if="!IsXiTai">
							<div class="kefu-pop">
								<div class="tip1">添加专属客服</div>
								<div class="tip2">一对一为您答疑解惑</div>
								<img src="@/assets/img/20210719171848.jpg" style="width:150px;height:150px" alt="">
							</div>
							
							<div slot="reference" class="custom-wraper" style="">
								<i class="el-icon-chat-dot-square" style="padding-right: 3px;"></i>联系客服
							</div>
						</el-popover>
						<el-dropdown class="avatar-container right-menu-item" trigger="click" :title="'欢迎用户'+name"
							@command="handleCommand">
							<div class="avatar-wrapper">
								<img v-if="localPageWxurl!=''" :src="localPageWxurl" class="user-avatar">
								<img v-else src="http://cdn.dkycn.cn/images/CJYP/static/img/icons/avatardefault.png"
									class="user-avatar">
								<div class="mall-message" style="margin-left:10px;color:#fff">
									<div style="line-height:20px;">{{localPageName}}</div>
									<div style="line-height:20px;">{{localPagePhone}}</div>
								</div>
								<i class="el-icon-arrow-right"
									style="color:#fff;vertical-align:10px;margin-left:10px;" />
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="5" v-if="!IsXiTai">
									<span>帮助中心</span>
								</el-dropdown-item>
								<el-dropdown-item command="1">
									<!-- @click="changePasswaord" -->
									<span>修改密码</span>
								</el-dropdown-item>
								<el-dropdown-item command="6" v-if="!IsXiTai">
									<!-- @click="changePasswaord" -->
									<span>切换商城</span>
								</el-dropdown-item>
								<el-dropdown-item command="3" v-if="!IsXiTai">
									<span style="display:block;">我的需求</span>
								</el-dropdown-item>
								<!-- <el-dropdown-item command="4">
									<span style="display:block;">更新日志</span>
								</el-dropdown-item> -->
								<el-dropdown-item command="2">
									<!-- @click="logout" -->
									<span style="display:block;">{{ $t('navbar.logOut') }}</span>
								</el-dropdown-item>


							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</el-col>
			</el-row>
			<scroll-pane ref="scrollPane" class="tags-view-wrapper">
				<router-link v-for="(tag,indexr) in Array.from(visitedViews)" ref="tag"
					:class="isActive(tag)?'active':''" :to="tag" :key="indexr" class="tags-view-item"
					@contextmenu.prevent.native="openMenu(tag,$event)">
					{{ (tag.title=='homeTitle'&&IsXiTai)?'首页':generateTitle(tag.title)}}
					<span class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
				</router-link>
			</scroll-pane>
			<div class="mfl-right-buttons">
				<el-dropdown>
					<span class="el-dropdown-link">
						<el-button type="primary" size="mini" style="width:100px;height:30px;line-height: 10px;">标签选项
						</el-button>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="clearAll" @click.native="handleTagsClose">关闭所有</el-dropdown-item>
						<el-dropdown-item command="clearOthers" @click.native="handleCloseother">关闭其他</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!--  -->
		<el-dialog title="修改密码" :close-on-press-escape='false' :visible.sync="SendDialogVisible" width="850px"
			:close-on-click-modal='IsShowClose?true:false' :showClose='IsShowClose?true:false' :loading="loading"
			class="editpassards" style="z-index: 2016!important;">
			<div v-if="!IsShowClose" style="font-size: 16px;color: #333;margin-bottom:20px;line-height: 30px;">
				系统检测到您的密码过于简单，为了充分确保您个人密码在系统内的安全使用，请您设置一个高强度、不易猜测的密码作为您的登录密码</div>
			<el-form :model="sendForm" ref="sendForm" :rules="rules" style="width:47%;margin-left: 10%;">
				<el-form-item label="旧密码" label-width="150px" prop="oldPs">
					<el-input v-model="sendForm.oldPs" placeholder="请输入旧密码" :type="psoldType" class="inputBox"
						auto-complete="new-password" />
					<span class="show-pwd" @click="showoldPwd">
						<svg-icon icon-class="eye" />
					</span>
				</el-form-item>
				<el-form-item label="新密码" label-width="150px" prop="newPs">
					<el-input v-model="sendForm.newPs" placeholder="请输入新密码" :type="psnewType" class="inputBox"
						auto-complete="new-password" />
					<span class="show-pwd" @click="shownewPwd">
						<svg-icon icon-class="eye" />
					</span>
				</el-form-item>
				<el-form-item label="确认密码" label-width="150px" prop="surePs">
					<el-input v-model="sendForm.surePs" placeholder="请输入确认密码" :type="psureType" class="inputBox"
						auto-complete="new-password" />
					<span class="show-pwd" @click="showsurePwd">
						<svg-icon icon-class="eye" />
					</span>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="layOut" style="margin-right: 25px;" v-if='isNeedEditPass == 1'>退出登录</el-button>
				<el-button type="primary" @click="SubmitSend">确认修改</el-button>
			</span>
		</el-dialog>


	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import {
		generateTitle
	} from '@/utils/i18n'
	import ScrollPane from '@/components/ScrollPane'
	import config from "@/config"
	export default {
		props: {
			mySelectMenu: {
				type: String,
				default: ''
			},
			// IsgetIshowmenu:{
			// 	type: Boolean,
			// 	default:true
			// }
		},
		components: {
			ScrollPane
		},
		data() {
			return {
				IsShowClose: false,
				visible: false,
				top: 0,
				left: 0,
				selectedTag: {},
				activeIndex: '',
				SendDialogVisible: false,
				loading: false,
				psoldType: 'password',
				psnewType: 'password',
				psureType: 'password',
				rules: {
					oldPs: [{
						required: true,
						message: '请输入旧密码',
						trigger: 'blur'
					}, ],
					newPs: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					surePs: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}],
				},
				sendForm: {
					oldPs: '',
					newPs: '',
					surePs: '',
				},
				routerList: [],
				localPageLogo: '',
				localPageName: '',
				localPagePhone: '',
				localPageWxurl: '',
				isHeadens: false,
				ComponentId: null,
				showStoreTags: false,

				freeMallNoSet: false,
			}
		},
		computed: {
			...mapGetters([
				'name',
				'MallName',
				'mallPhone',
				'MallAvatar',
				'permission_routers',
				'isNeedEditPass',
				'IsXiTai'
			]),
			visitedViews() {
				return this.$store.state.tagsView.visitedViews
			},
		},
		created() {
			//20240806 新加config.IsOfficial 产品说 测试上的判断拿掉 正式不用拿 没有这种户了
			this.freeMallNoSet = window.localStorage.getItem('mlmzFreePayState') != 2 && window.localStorage.getItem(
				'mlmzIsFree') == 'true' && config.IsOfficial
			this.SendDialogVisible = this.isNeedEditPass == 1
			this.showStoreTags = this.$store.getters.haveStoreRouter
			this.ComponentId = localStorage.getItem('mlmzpageComponentId')
		},
		watch: {
			isNeedEditPass(v) {
				this.SendDialogVisible = v == 1
			},
			IsgetIshowmenu() {

			},
			activeIndex(n, o) {
			},

			$route() {
				// if (this.$route.name == 'NeedPay') return;
				this.addViewTags()
				this.moveToCurrentTag()
				// console.log(this.$route.name)
			},
			visible(value) {
				if (value) {
					document.body.addEventListener('click', this.closeMenu)
				} else {
					document.body.removeEventListener('click', this.closeMenu)
				}

			},
			mySelectMenu(value) {
				// console.log(this.$route, 'mySelectMenu')
				// let menueLength = value.split("/")
				// let newRouter = '/' + (menueLength[1] ? menueLength[1] : '')
				// this.activeIndex = (newRouter == '/home') ? '/' : newRouter;
				
				//如果是进入了付费页 则不需要改菜单选中状态
				// if (this.$route.name == 'NeedPay' && this.navChange) return;
				// this.navChange = true;
				
				this.activeIndex = this.$router.currentRoute.matched[0].path ? this.$router.currentRoute.matched[0].path : '/';
			}

		},
		mounted() {
			this.localPageLogo = localStorage.getItem('mlmzpageLogo')
			// console.log(localStorage.getItem('mlmzpageLogo'))
			this.localPageName = localStorage.getItem('mlmzpageName')
			this.localPagePhone = localStorage.getItem('mlmzpagePhone')
			this.localPageWxurl = localStorage.getItem('mlmzpageWxurl');
			this.mod = localStorage.getItem('mlmzDistributionModel');

			this.$store.dispatch('delAllViews')
			// this.$router.push('/')
			this.routerList = []
			// let routerAlldata = this.$router.options.routes.concat(this.permission_routers)
			// console.log(this.permission_routers)
			this.permission_routers.map(record => {
				
				if (record.meta && record.meta.title) {
					// if ((this.mod === '0' && record.meta.title == 'agent') || (this.mod === '1' && record.meta.title == 'distribution')){
					// 	console.log(record.meta.title)
					// }else{
						if (record.children && record.children.length){
							var list = [];
							record.children.map(item=>{
								if (item.children){
									var array =item.children.filter(item1=>{
										return !item1.hidden
									})
									if (array.length){
										list.push(item)
									}
								}else if (!item.hidden){
									list.push(item)
								}
							})
							
							if (list.length){
								this.routerList.push(record)
							}
						}else if (!record.hidden){
							this.routerList.push(record)
						}
					// }
				}
				
				
			})

			this.activeIndex = this.$router.currentRoute.matched[0].path ? this.$router.currentRoute.matched[0].path : '/';
			this.routerList.map(item => {
				if (item.path == this.activeIndex) {
					this.$emit('getRourterList', item)
				}
			})
		// console.log(this.routerList)
			this.addViewTags()
		},
		methods: {
			layOut() {
				this.logout()
			},
			// 下拉框的方法
			handleCommand(command) {
				if (command == 1) {
					// this.IsShowClose = true
					// this.changePasswaord()
					this.$router.push({
						path: '/forget'
					})
				} else if (command == 2) {
					this.logout()
				} else if (command == 3) {
					this.$router.push({
						path: "/myNeed/needPage"
					})
				} else if (command == 4) {
					window.open('https://shimo.im/docs/pckCcDRgyGQ3Q6q3/')
				} else if (command == 5) {
					// 链接地址换企店官网中心
					window.open('https://www.qidian.shop/help')
				}else if (command == 6) {
					this.$router.push({
						path: '/selectMall'
					})
				setTimeout(()=>{
						location.reload(false)
					}, 10)
				}
			},

			// 点击父级菜单
			parentsRouter(item) {
				
				var list = [];
				item.children.map(item=>{
					if (item.children){
						var array =item.children.filter(item1=>{
							return !item1.hidden
						})
						if (array.length){
							list.push(item)
						}
					}else if (!item.hidden){
						list.push(item)
					}
				})
				
				// item.children = item.children.filter(t => !t.hidden)
				let children0 = list[0];//item.children[0]
				// 免费商户且没有申请结束，且点击不是首页
				if (this.freeMallNoSet && children0.path != "/home") {
					this.$message({
						type: 'error',
						message: '你还未绑定商城小程序和支付，不可操作',
						showClose: true
					})
					return
				}
				let pathpage = ''
				if (children0.children && children0.children.length) {
					pathpage = children0.children[0].path
				} else {
					pathpage = (children0.path == "home") ? '/' : children0.path
				}
				this.$router.push({
					path: pathpage
				});
			},

			// 客服
			goCustmer() {
				window.open('http://lastonepcapitest.xiaojiankeji.com/swagger-ui/#!/auth/Authenticate_0')
			},
			openStorePC() {
				// 获取是否有店铺系统的权限
				this.$store.dispatch('GenerateRoutes').then(() => {
					const haveStoreRouter = this.$store.getters.haveStoreRouter
					if (haveStoreRouter) {
						if (process.env.NODE_ENV == 'production') {
							window.open('/store')
						} else {
							window.open('http://localhost:8098/#/')
						}
					} else {
						this.$message({
							type: 'error',
							message: '店铺系统未设置权限，跳转失败',
							showClose: true
						})
					}
				})

			},

			//确认
			SubmitSend() {
				this.$refs.sendForm.validate(valid => {
					if (valid) {
						if (this.sendForm.newPs != this.sendForm.surePs) {
							this.$message({
								showClose: true,
								message: '密码不一致',
								type: 'error'
							});
							return false
						}
						if (this.sendForm.newPs == this.sendForm.surePs && this.sendForm.surePs == 123456) {
							this.$message({
								showClose: true,
								message: '密码过于简单，请设置安全性更高的登录密码',
								type: 'error'
							});
							this.sendForm.oldPs = '';
							this.sendForm.newPs = '';
							this.sendForm.surePs = '';
							return false
						}
						this.loading = true
						this.$store.dispatch('ChangePass', this.sendForm).then(() => {
							this.$message({
								showClose: true,
								type: 'success',
								message: '修改成功，请重新登录'
							})
							this.$store.commit('SET_ISNEEDEDITPASS', 0)
							this.loading = false
							this.logout()
						}).catch(() => {
							this.loading = false
						})
					} else {
						// console.log('error submit!!')
						return false
					}
				})
			},

			//显示旧密码
			showoldPwd() {
				if (this.psoldType === 'password') {
					this.psoldType = ''
				} else {
					this.psoldType = 'password'
				}
			},
			shownewPwd() {
				if (this.psnewType === 'password') {
					this.psnewType = ''
				} else {
					this.psnewType = 'password'
				}
			},
			showsurePwd() {
				if (this.psureType === 'password') {
					this.psureType = ''
				} else {
					this.psureType = 'password'
				}
			},

			logout() {
				this.$store.dispatch('LogOut').then(() => {
					location.reload() // In order to re-instantiate the vue-router object to avoid bugs
				})
			},

			//修改密码
			changePasswaord() {
				this.SendDialogVisible = true;
				this.sendForm.oldPs = '';
				this.sendForm.newPs = '';
				this.sendForm.surePs = '';
			},


			handleSelect(key) {
				// 免费商户且没有设置申请，且点击不是首页
				if (this.freeMallNoSet && key != '/') {
					return
				}
				this.routerList.map(items => {
					if (items.path == key) {
						this.$emit('getRourterList', items)
					}
				})
			},
			generateTitle, // generateTitle by vue-i18n
			generateRoute() {

				if (this.$route.name) {
					return this.$route
				}
				return false
			},
			isActive(route) {
				let matched = this.$route.matched[0].path == '' ? '/' : this.$route.matched[0].path
				this.routerList.map(items => {
					if (items.path == matched) {
						this.$emit('getRourterList', items)
					}

				})

				this.$emit('selctMenu', this.$route.path)
				return route.path === this.$route.path
			},
			addViewTags() {
				const route = this.generateRoute()

				if (!route) {
					return false
				}
				this.$store.dispatch('addVisitedViews', route)
			},
			moveToCurrentTag() {
				const tags = this.$refs.tag
				this.$nextTick(() => {
					for (const tag of tags) {
						if (tag.to.path === this.$route.path) {
							this.$refs.scrollPane.moveToTarget(tag.$el)
							break
						}
					}
				})
			},
			closeSelectedTag(view) {
				this.$store.dispatch('delVisitedViews', view).then((views) => {
					if (this.isActive(view)) {
						const latestView = views.slice(-1)[0]
						if (latestView) {
							this.$router.push(latestView)
						} else {
							this.$router.push('/')
						}
					}
				})
			},
			closeOthersTags() {
				this.$router.push(this.selectedTag)
				this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
					this.moveToCurrentTag()
				})
			},
			closeAllTags() {
				this.$store.dispatch('delAllViews')
				this.$router.push('/')
			},
			openMenu(tag, e) {

				this.visible = true
				this.selectedTag = tag
				const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
				this.left = e.clientX - offsetLeft + 15 // 15: margin right
				this.top = e.clientY
			},
			closeMenu() {
				this.visible = false
			},
			// 关闭所有
			handleTagsClose() {
				this.$store.state.tagsView.visitedViews = []
				this.$router.push({
					name: 'home'
				})

			},

			// 关闭其他
			handleCloseother() {
				this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(item => {
					return item.path == this.$route.path
				})
			}

		}
	}
</script>

<style lang="less" scoped>
	.max-zindex{
		// z-index: 2010;
	}
	.custom-wraper{
		font-size: 14px;
		color: #fff;
		margin-right: 1px;
		cursor: pointer;
		margin-top: 2px;
		
		display: inline-flex;
		align-items: center;
		
		&:after{
			content: '';
			display: block;
			width: 1px;
			background-color: #fff;
			height: 10px;
			margin-left: 6px;
		}
	}
	
	.show-pwd {
		position: absolute;
		right: 20px;
	}

	.right-menu-people {
		float: right;
		height: 100%;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		background: #1a2533;
		line-height: 60px;
		height: 60px;
		color: #fff;

		.addBtn {
			span {
				cursor: pointer;
				font-size: 14px;
				margin-right: 30px;
			}
		}


		&:focus {
			outline: none;
		}

		.right-menu-item {
			display: inline-block;
			margin: 0 8px;
		}

		.screenfull {
			height: 20px;
		}

		.international {
			vertical-align: top;
		}

		.theme-switch {
			vertical-align: 15px;
		}

		.avatar-container {
			height: 50px;

			.avatar-wrapper {
				cursor: pointer;
				margin-top: 10px;
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;

				.mall-message {
					// div{
					// 	cursor: pointer;
					font-size: 14px;
				}

				.user-avatar {
					width: 24px;
					height: 24px;
					border-radius: 100%;
				}

				// .el-icon-arrow-right
				.el-icon-caret-bottom {
					position: absolute;
					right: -20px;
					top: 10px;
					font-size: 12px;
				}
			}
		}
	}


	.login-passworld {
		background: #304156;
		height: 60px;
		line-height: 60px;
		color: #fff;
		padding-right: 60px;
	}


	.tags-view-container {
		width: 100%;

		.view-container-childs {
			position: fixed;
			width: 100%;
			left: 0;
			top: 0;
			z-index: 2000;

			.el-menu-demo {
				display: flex;
				flex-direction: row;

				.new-menu-item {
					color: #fff;
					background-color: rgb(26, 37, 51);
					display: flex;
					justify-content: center;

					height: 60px;
					line-height: 60px;
					margin: 0;

					font-size: 14px;
					padding: 0 20px;
					cursor: pointer;
				}

				.free-no-set-item {
					opacity: 0.25;
					cursor: not-allowed;
				}
			}
		}

		.tags-view-wrapper {
			background: #fff;
			height: 36px;
			border-bottom: 1px solid #d8dce5;
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);
			margin-left: 222px;
			transition: all .3s;

			.tags-view-item {
				display: inline-block;
				position: relative;
				height: 26px;
				line-height: 26px;
				border: 1px solid #d8dce5;
				color: #495060;
				background: #fff;
				padding: 0 8px;
				font-size: 12px;
				margin-left: 5px;
				margin-top: 5px;

				&:first-of-type {
					margin-left: 15px;
				}

				&.active {
					background-color: #2d8cf0;
					color: #fff;
					border-color: #2d8cf0;

					&::before {
						content: '';
						background: #fff;
						display: inline-block;
						width: 8px;
						height: 8px;
						border-radius: 50%;
						position: relative;
						margin-right: 2px;
					}
				}
			}
		}

		// .tags-view-wrapperishow{
		// 	margin-left: 180px;
		// }


		.contextmenu {
			margin: 0;
			background: #fff;
			z-index: 100;
			position: absolute;
			list-style-type: none;
			padding: 5px 0;
			border-radius: 4px;
			font-size: 12px;
			font-weight: 400;
			color: #333;
			box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);

			li {
				margin: 0;
				padding: 7px 16px;
				cursor: pointer;

				&:hover {
					background: #eee;
				}
			}
		}
	}
	
	@media screen and (max-width: 1280px) {
		.tags-view-container{
			.tags-view-wrapper{
				margin-left: 140px;
			}
		}
	}
	
	.kefu-pop{
		text-align: center;
		font-size: 14px;
		color: #333;
		.tip1{
			font-weight: bold;
			margin-bottom: 3px;
		}
		.tip2{
			color: #999;
			margin-bottom: 5px;
		}
	}
	
</style>

<style lang="less" scoped>
	//reset element css of el-icon-close
	.tags-view-wrapper {
		.tags-view-item {
			.el-icon-close {
				width: 16px;
				height: 16px;
				vertical-align: 2px;
				border-radius: 50%;
				text-align: center;
				transition: all .3s cubic-bezier(.645, .045, .355, 1);
				transform-origin: 100% 50%;

				&:before {
					transform: scale(.6);
					display: inline-block;
					vertical-align: -3px;
				}

				&:hover {
					background-color: #b4bccc;
					color: #fff;
				}
			}
		}

	}

	// .el-row {
	// 	 display: flex;
	// 	 flex-wrap: nowrap;
	// }

	.el-menu--horizontal>.el-menu-item {
		// flex: 1!important;
		transition: all .3s;
	}
	
	@media screen and (max-width: 1400px) {
		.el-menu--horizontal>.el-menu-item {
			padding: 0 10px;
		}
	}

	// .el-col-17 {
	//   width: 65%;
	// }

	.isNoShowzj {
		width: 0 !important;
		padding: 0 !important;
		opacity: 0;
		position: relative;
		z-index: -1;
		display: none !important;
	}

	.mfl-right-buttons {
		position: absolute;
		right: 10px;
		top: 63px;
	}
</style>
