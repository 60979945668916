import request from '@/utils/request'

// 登录
export const login = (LoginPhone, LoginPassword,MallKey) => request('/pc/auth/pclogin', {LoginPhone, LoginPassword,MallKey});
//验证码登录
export const pcloginbysmscode = (params) => request('/pc/auth/pcloginbysmscode', params);
// 登出
export const logout = () => request('/pc/auth/pcloginout');
// 获取用户信息
export const getUserInfo = () => request('/pc/appstart/employeeinfo', {});

export const surePassword = (OldPassword,NewPassWord) => request('/pc/auth/pcChangePassWord', {OldPassword,NewPassWord});

//修改密码
export const pcChangePassWord = (params) => request('/pc/auth/pcChangePassWord', params);

//商户注册
export const Mallapplyfreeversion = (data)=> request('/admin/mall/applyfreeversion',data);
//商户注册-获取验证码
export const applyfreeversionsendcode = (data)=> request('/admin/mall/sms/applyfreeversionsendcode',data);
//商户注册-获取验证码
export const applyfreeversionsmsimgcode = (data)=> request('/admin/mall/sms/applyfreeversionsmsimgcode',data);

//  获取后台商户名称 
export const getPcMallName = (data) => request('/pc/auth/getPcMallName', data);
//进入客服系统获取登录参数 
export const XJChatGetLoginCert = (params) => request('/xj-chat/get-login-cert', params, 'post');
//获取配置面板信息商户小程序-支付配置情况
export const  mallWxAppAndPayInfo = (data) => request('/pc/mall/mallWxAppAndPayInfo',data);
//短信密码
export const smsendsmscode = (data) => request('/pc/auth/sms/sendsmscode', data);
//图片验证码
export const smsimgcode = (data) => request('/pc/auth/sms/smsimgcode', data);
//重置登录密码
export const resetloginpassword = (data) => request('/pc/auth/resetloginpassword', data);
//获取商户列表
export const employeemalllist = (data) => request('/pc/auth/employeemalllist', data);
//选择商城
export const employeechosemall = (data) => request('/pc/auth/employeechosemall', data);

//获取菜单付费权限
export const paymentrulevalitemenu= (data) => request('/pc/paymentrule/valitemenu',data);


//获取版本列表
export const paymentRulemallVersionList= (data) => request('/pc/paymentRule/mallVersionList',data);
//购买版本
export const paymentRulemallVersionSubmitOrder= (data) => request('/pc/paymentRule/mallVersionSubmitOrder',data);
//获取新的支付二维码
export const paymentRulemallVersionRefreshQrcode= (data) => request('/pc/paymentRule/mallVersionRefreshQrcode',data);


