import {
	login,
	pcloginbysmscode,
	logout,
	getUserInfo,
	surePassword,
	getPcMallName,
	mallWxAppAndPayInfo,
	paymentrulevalitemenu,
	employeechosemall,
} from '@/api/login'
import {
	employeeloginEmployeeInfo
} from '@/api/api'
import {
	getToken,
	setToken,
	removeToken
} from '@/utils/auth'
import {
	pcBaseInfo
} from '@/api/TurnTomySelf';
import {
	addShopValite
} from '@/api/common'
import router from '@/router/index'
import staticImage from '@/assets/staticImages';
import config  from '@/config';
const user = {
	state: {
		displayName: '',
		sessionId: '',
		userId: '',
		userName: '',
		// user: '',
		// status: '',
		// code: '',
		token: getToken(),
		Mallname: '',
		mallPhone: '',
		MallAvatar: '',
		UserId: '',
		IsOpenCommunity: false, //是否开启社群活动
		loginState: false,
		moduleList: [{
			title: '商品展示',
			list: [{
					name: '搜索',
					icon: staticImage.menu15,
					moduleType: 16
				},
				{
					name: '图片导航',
					icon: staticImage.menu8,
					moduleType: 9
				},
				{
					name: '商品',
					icon: staticImage.menu1,
					moduleType: 2
				},
				{
					name: '热区连接',
					icon: staticImage.menu11,
					moduleType: 12
				},
				{
					name: '积分商城',
					icon: staticImage.menu5,
					moduleType: 6
				},
				{
					name: '优惠卷',
					icon: staticImage.menu17,
					moduleType: 18
				},
			]
		}],
		ShopName: '',
		ShopLogo: '',
		ShopmallName: '',
		ShopthirdName: '',
		ComponentId: '',
		isNeedEditPass: 0,
		IsMoreShop: true,
		mallInfo: {},
		isopen498pay: false,
		pcImg:'',
		isShowUpdateTips: false,
		pagemsg:{},
		pagename:'',
		noviceGuidanceCurrent: { //记录新手引导选中状态
			first: 0,
			second: -1,
			third: -1
		},
		RecruitmentType:0,
		DistributorLockFansType:0,
		IsAdmin:false,
		ShopProductStockModel:0,
		IsOpenLookUserPrivacyAuthority:false,
		IsOpenTaoKeDistributorModel:false,//淘客分销
		IsDockingRoutuan:false,//对接肉团
		IsXiTai:false,
	},



	mutations: {
		SET_PAGENAME: (state, name) => {
			state.pagename = name
		},
		SET_LOGINSTATE: (state, loginState) => {
			state.loginState = loginState
		},
		SET_DISPLAYNAME: (state, displayName) => {
			state.displayName = displayName
		},
		SET_SESSIONId: (state, sessionId) => {
			state.sessionId = sessionId
		},
		SET_USERID: (state, userId) => {
			state.userId = userId
		},
		SET_USERNAME: (state, userName) => {
			state.userName = userName
		},
		SET_MALLNAME: (state, Mallname) => {
			state.Mallname = Mallname
		},
		SET_MALLPHONE: (state, mallPhone) => {

			state.mallPhone = mallPhone
		},
		SET_MALLAVATAR: (state, MallAvatar) => {
			state.MallAvatar = MallAvatar
		},

		SET_MALLUSERID: (state, UserId) => {
			state.UserId = UserId

		},

		// SET_CODE: (state, code) => {
		//   state.code = code
		// },
		SET_TOKEN: (state, token) => {
			state.token = token
		},

		SET_SHOPNAME: (state, ShopName) => {
			state.ShopName = ShopName
		},
		SET_SHOPLOGO: (state, ShopLogo) => {
			state.ShopLogo = ShopLogo
		},
		SET_SHOPMALLNAME: (state, ShopmallName) => {
			state.ShopmallName = ShopmallName
		},
		SET_THIRDNAME: (state, ShopthirdName) => {
			state.ShopthirdName = ShopthirdName
		},
		SET_COMPONENT: (state, ComponentId) => {
			state.ComponentId = ComponentId
		},
		SET_ISOPENCOMMUNITY: (state, bol) => {
			state.IsOpenCommunity = bol
		},
		SET_ISNEEDEDITPASS: (state, bol) => {
			state.isNeedEditPass = bol
		},
		SET_ISMORESHOP: (state, bol) => {
			state.IsMoreShop = bol
		},
		SET_MALLINFO: (state, obj) => {
			state.mallInfo = obj
		},
		SET_ISOPEN498PAY: (state, obj) => {
			state.isopen498pay = obj
		},
		SET_PCIMG:(state, obj)=>{
			state.pcImg = obj
		},
		SET_IsShowUpdateTips:(state, obj)=>{
			state.isShowUpdateTips = obj
		},
		SET_PAGEMSG:(state, obj)=>{
			state.pagemsg = obj
		},
		SET_RecruitmentType:(state, obj)=>{
			state.RecruitmentType = obj
		},
		SET_DistributorLockFansType:(state, obj)=>{
			state.DistributorLockFansType = obj
		},
		set_noviceGuidanceCurrent(state, obj){
			if (obj.first != undefined){
				state.noviceGuidanceCurrent.first = obj.first;
			}
			if (obj.second != undefined){
				state.noviceGuidanceCurrent.second = obj.second;
			}
			if (obj.third != undefined){
				state.noviceGuidanceCurrent.third = obj.third;
			}
		},
		SET_IsAdmin(state, obj){
			state.IsAdmin = obj
		},
		SET_ShopProductStockModel(state, obj){
			state.ShopProductStockModel = obj
		},
		SET_IsOpenLookUserPrivacyAuthority(state, obj){
			state.IsOpenLookUserPrivacyAuthority = obj
		},
		
		SET_TAOKEFENXIAO: (state, bol) => {
			state.IsOpenTaoKeDistributorModel = bol
		},
		SET_DOCKINGROUTUAN: (state, bol) => {
			state.IsDockingRoutuan = bol
		},
		SET_ISXITAI: (state, bol) => {
			state.IsXiTai = bol
		},
	},

	actions: {
		// 用户名登录
		Login({
			commit
		 }, userInfo) {
			const username = userInfo.username.trim()
			return new Promise((resolve, reject) => {
				let secondParam = '';// window.localStorage.getItem('mlmzMallKey')
				login(username, userInfo.password, secondParam).then(response => {
					const data = response
					if (data.IsSuccess) {
						// console.log(data,'1111')
						// 先加着看效果
						commit('SET_ISGET', false)
						commit('SET_LOGINSTATE', true)
						commit('SET_TOKEN', data.Result.SessionId)
						commit('SET_MALLNAME', data.Result.EmployeeName)
						commit('SET_MALLPHONE', data.Result.Phone)
						commit('SET_MALLAVATAR', data.Result.WxHeadUrl)
						commit('SET_COMPONENT', data.Result.ComponentId)
						commit('SET_ISNEEDEDITPASS', data.Result.IsNeedChangePassWord ? 1 : 0)
						
						
						//是否多门店
						// commit('SET_ISMORESHOP', true)
						setToken(data.Result.SessionId)
						//无效 window.localStorage.setItem('IsOpenWithdrawalsToAlipay',data.Result.IsOpenWithdrawalsToAlipay);
						//无效 window.localStorage.setItem('IsOpenWithdrawalsToBankCard', data.Result.IsOpenWithdrawalsToBankCard);
						window.localStorage.setItem('mlmzpageName', data.Result.EmployeeName)
						window.localStorage.setItem('mlmzpagePhone', data.Result.Phone)
						window.localStorage.setItem('mlmzpageWxurl', data.Result.WxHeadUrl)
						window.localStorage.setItem('mlmzpageComponentId', data.Result.ComponentId)
						window.localStorage.setItem('mlmzDistributionModel', data.Result.DistributionModel)
						window.localStorage.setItem('mlmzMallKey', data.Result.MallKey || '')
						// 是否是免费版本商户
						window.localStorage.setItem('mlmzIsFree', data.Result.IsFree)
						resolve()
					}

				}).catch(error => {
					reject(error)
				})
			})
		},
		codeLogin({
			commit
		 }, params) {
			return new Promise((resolve, reject) => {
				pcloginbysmscode(params).then(response => {
					const data = response
					if (data.IsSuccess) {
						
						commit('SET_ISGET', false)
						commit('SET_LOGINSTATE', true)
						commit('SET_TOKEN', data.Result.SessionId)
						commit('SET_MALLNAME', data.Result.EmployeeName)
						commit('SET_MALLPHONE', data.Result.Phone)
						commit('SET_MALLAVATAR', data.Result.WxHeadUrl)
						commit('SET_COMPONENT', data.Result.ComponentId)
						commit('SET_ISNEEDEDITPASS', data.Result.IsNeedChangePassWord ? 1 : 0)
						
						setToken(data.Result.SessionId)
						
						window.localStorage.setItem('mlmzpageName', data.Result.EmployeeName)
						window.localStorage.setItem('mlmzpagePhone', data.Result.Phone)
						window.localStorage.setItem('mlmzpageWxurl', data.Result.WxHeadUrl)
						window.localStorage.setItem('mlmzpageComponentId', data.Result.ComponentId)
						window.localStorage.setItem('mlmzDistributionModel', data.Result.DistributionModel)
						window.localStorage.setItem('mlmzMallKey', data.Result.MallKey || '')
						// 是否是免费版本商户
						window.localStorage.setItem('mlmzIsFree', data.Result.IsFree)
						resolve()
					}
					
				}).catch(error => {
					reject(error)
				})
			})
		},
		selectMall({
			commit
		 }, params) {
			return new Promise((resolve, reject) => {
				
				employeechosemall(params).then(response => {
					const data = response
					if (data.IsSuccess) {
						
						commit('SET_ISGET', false)
						commit('SET_LOGINSTATE', true)
						commit('SET_TOKEN', data.Result.SessionId)
						commit('SET_MALLNAME', data.Result.EmployeeName)
						commit('SET_MALLPHONE', data.Result.Phone)
						commit('SET_MALLAVATAR', data.Result.WxHeadUrl)
						commit('SET_COMPONENT', data.Result.ComponentId)
						commit('SET_ISNEEDEDITPASS', data.Result.IsNeedChangePassWord ? 1 : 0)
						
			
						setToken(data.Result.SessionId)
						
						window.localStorage.setItem('mlmzpageName', data.Result.EmployeeName)
						window.localStorage.setItem('mlmzpagePhone', data.Result.Phone)
						window.localStorage.setItem('mlmzpageWxurl', data.Result.WxHeadUrl)
						window.localStorage.setItem('mlmzpageComponentId', data.Result.ComponentId)
						window.localStorage.setItem('mlmzDistributionModel', data.Result.DistributionModel)
						window.localStorage.setItem('mlmzMallKey', data.Result.MallKey || '')
						// 是否是免费版本商户
						window.localStorage.setItem('mlmzIsFree', data.Result.IsFree)
						
						resolve()
					}
		
		
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 商店名称和商店logo
		GetPcMallName({
			commit
		 }, info) {
			const domain = info
			return new Promise((resolve, reject) => {
				getPcMallName(domain).then(response => {
					const data = response
					// console.log(data.Result,'123')
					if (data.IsSuccess) {
						//门店库存模式
						// commit('SET_ShopProductStockModel', data.Result.ShopProductStockModel)
						window.localStorage.setItem('ShopProductStockModel', data.Result.ShopProductStockModel)
						//招募机制
						commit('SET_RecruitmentType', data.Result.RecruitmentType)
						//锁粉方式
						commit('SET_DistributorLockFansType', data.Result.DistributorLockFansType)
						// console.log(data.Result.DistributorLockFansType,'DistributorLockFansType')
						// 先注释着
						commit('SET_SHOPLOGO', data.Result.PcMainUrl)
						commit('SET_SHOPNAME', data.Result.PcMallName)
						commit('SET_ISOPENCOMMUNITY', data.Result.IsOpenCommunity)
						commit('SET_TAOKEFENXIAO',data.Result.IsOpenTaoKeDistributorModel)
						commit('SET_DOCKINGROUTUAN',data.Result.IsDockingRoutuan)
						commit('SET_COMPONENT', data.Result.ComponentId)
						commit('SET_PCIMG',data.Result.PcMainUrl)//pc主图
						//多门店及使用企业微信
						commit('SET_ISMORESHOP', true)
		
						if (process.env.VUE_APP_CURRENTMODE == 'stage'){
							data.Result.IsUseWxPay = true;
						}
						commit('SET_MALLINFO', data.Result)
						let ISXITAI = (config.IsOfficial&&[196,746].includes(data.Result.MallId))||(!config.IsOfficial&&[166].includes(data.Result.MallId))
						console.log(config.IsOfficial,data.Result.MallId)
						commit('SET_ISXITAI', ISXITAI)
						// commit('SET_ISOPENCOMMUNITY', true)
						window.localStorage.setItem('mlmzpageTitle', data.Result.PcMallName)
						window.localStorage.setItem('mlmzpageLogo', data.Result.PcMainUrl)
						window.localStorage.setItem('IsOpenSupplierProduct', data.Result.IsOpenSupplierProduct)
						// console.log(data.Result.MallId,'66')
						window.localStorage.setItem('PCMallId', data.Result.MallId)
						window.localStorage.setItem('MallId', data.Result.MallId)
						//是否开启余额充值功能
						window.localStorage.setItem('IsOpenMallTopUp', data.Result.IsOpenMallTopUp)
						//是否开启B2B2C模式
						window.localStorage.setItem('IsOpenB2B2C', data.Result.IsOpenB2B2C)
						//是否开启企业微信配置
						window.localStorage.setItem('IsUseQyWeixin', data.Result.IsUseQyWeixin)
						// 是否是免费版本商户
						window.localStorage.setItem('mlmzIsFree', data.Result.IsFree)
						
						
						resolve()
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		//获取商城名称和第三方平台名称（商城信息）
		GetPcShopInfo({
			commit
		 }) {
			// let info = {}
			// const domain = info
			return new Promise((resolve, reject) => {
				pcBaseInfo().then(response => {
					const data = response
					if (data.IsSuccess) {
						// console.log(data.Result.IsAdmin ,'963')
						commit('SET_IsAdmin', data.Result.IsAdmin)
						//招募机制
						commit('SET_RecruitmentType', data.Result.RecruitmentType)
						//锁粉方式
						commit('SET_DistributorLockFansType', data.Result.DistributorLockFansType)
						// window.localStorage.setItem('RecruitmentType',data.Result.RecruitmentType)
						//商户是否开启498支付
						commit('SET_ISOPEN498PAY', data.Result.IsOpen498Pay)
						// ThirdMallname


						// 先注释着
						commit('SET_SHOPMALLNAME', data.Result.MallName)
						commit('SET_THIRDNAME', data.Result.ComponentName)
						// console.log('data.Result.IsShowUpdateTips', data.Result.IsShowUpdateTips)
						commit('SET_IsShowUpdateTips', data.Result.IsShowUpdateTips)
						window.localStorage.setItem('shopnameTitle', data.Result.MallName)
						window.localStorage.setItem('shopthirdName', data.Result.ComponentName)
						//是否开启余额充值功能
						// window.localStorage.setItem('IsOpenMallTopUp', data.Result.IsOpenMallTopUp)
						// setToken(data.Result.SessionId)
						
						document.title = data.Result.MallName;
						resolve(data)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},

		GetFreeMallWxAppAndPayInfo({
			commit
		 }, info) {
			return new Promise((resolve, reject) => {
				mallWxAppAndPayInfo().then(response => {
					const data = response
						// console.log(response)
					if (data.IsSuccess) {
						// 0:未绑定小程序-->3:未配置小程序秘钥-->1:未通过支付-->5:支付申请完成；--> 4:未发布小程序；-->2：免费版配置流程结束；
						window.localStorage.setItem('mlmzFreePayState', data.Result.State)
						resolve()
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		//判断页面是否需要付费使用
		getpaymentrulevalitemenu({
			commit
		},name){
			return new Promise((res,rej)=>{
				paymentrulevalitemenu(name).then((result)=>{
						// console.log(result.Result,123)
						commit('SET_PAGEMSG', result.Result)

						res(result)
				}).catch((error)=>{
					rej(error)
				})
			})
		},
		getloginEmployeeInfo({commit},name){
			return new Promise((res,rej)=>{
				employeeloginEmployeeInfo(name).then((result)=>{
						//用户隐私查看权限
						commit('SET_IsOpenLookUserPrivacyAuthority', result.Result.IsOpenLookUserPrivacyAuthority)
						// console.log(result.Result.IsOpenLookUserPrivacyAuthority,123)
						res(result)
				}).catch((error)=>{
					rej(error)
				})
			})
		},
		getroutename({
			commit
		},name){
			commit('SET_PAGENAME', name)
		},

		//检查当前版本是否正确
		checkVersion({
			commit
		}) {
			return new Promise((resolve, reject) => {
				addShopValite({noError:true}).then(res => {
					let obj = res.Result;
					if (res.IsSuccess) {
						resolve()
					}else{
						reject()
					}
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 获取用户信息

		// GetUserInfo({
		// 	commit,
		// 	state
		// }) {
		// 	return new Promise((resolve, reject) => {
		// 		getUserInfo(state.token).then(response => {
		// 			const data = response.Result
		// 			console.log('data获取用户信息---', data)
		// 			// commit('SET_MALLNAME', data.MallName)
		// 			// commit('SET_MALLPHONE', data.Phone)
		// 			// commit('SET_MALLUSERID', data.MallId)
		// 			resolve(response)
		// 		}).catch(error => {
		// 			reject(error)
		// 		})
		// 	})
		// },

		// GetUserInfo({
		// 	commit,
		// 	state
		// }) {
		// 	return new Promise((resolve, reject) => {
		// 		getUserInfo(state.token).then(response => {
		// 			const data = response.Result
		// 			console.log('data获取用户信息',data)
		// 			// commit('SET_MALLNAME', data.MallName)
		// 			// commit('SET_MALLPHONE', data.Phone)
		// 			// commit('SET_MALLUSERID', data.MallId)
		// 			resolve(response)
		// 		}).catch(error => {
		// 			reject(error)
		// 		})
		// 	})
		// },


		//改变密码
		ChangePass({
			commit
		}, userpassInfo) {
			const newps = userpassInfo.newPs
			const oldps = userpassInfo.oldPs
			return new Promise((resolve, reject) => {
				surePassword(oldps, newps).then(response => {
					const data = response

					// commit('SET_TOKEN', data.SessionId)
					// commit('SET_DISPLAYNAME', data.DisplayName)
					// commit('SET_USERID', data.UserId)
					// commit('SET_USERNAME', data.UserName)
					// setToken(data.SessionId)


					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 登出
		LogOut({
			commit
		}) {
			return new Promise((resolve, reject) => {
				logout().then(() => {
					// 先注释着
					commit('SET_ISGET', false)

					commit('SET_TOKEN', '')

					removeToken()
					// resolve()
					// this.$store.dispatch('delVisitedViews', view).then((views) => {
					// 	if (this.isActive(view)) {
					// 		const latestView = views.slice(-1)[0]
					// 		if (latestView) {
					// 			this.$router.push(latestView)
					// 		} else {
					// 			this.$router.push('/')
					// 		}
					// 	}
					// })

					// router.push({
					// 	path: '/login'
					// })
					//先注释着
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 前端 登出
		FedLogOut({
			commit
		}) {
			return new Promise(resolve => {

				commit('SET_TOKEN', '')

				removeToken()

				// 先注释的
				// router.push({
				// 	path: '/login'
				// })

				resolve()
			})
		},

		// 动态修改权限
		// ChangeRoles({ commit }, role) {
		//   return new Promise(resolve => {
		//     commit('SET_TOKEN', role)
		//     setToken(role)
		//     getUserInfo(role).then(response => {
		//       const data = response.data
		//       commit('SET_ROLES', data.roles)
		//       commit('SET_NAME', data.name)
		//       commit('SET_AVATAR', data.avatar)
		//       commit('SET_INTRODUCTION', data.introduction)
		//       resolve()
		//     })
		//   })
		// }
	}
}

export default user