export default {
	BASE_URL: process.env.NODE_ENV == 'production' ? (process.env.VUE_APP_CURRENTMODE == 'stage' ?
		'/' : '/') : 'http://localhost:8099',
	HOST: process.env.NODE_ENV == 'production' ? (process.env.VUE_APP_CURRENTMODE == 'stage' ?
		'/' : '/') : 'http://localhost:8099',
	IMG_BASE: 'https://cdn.dkycn.cn/melyshop',
	EXPORT_URL: process.env.NODE_ENV == 'production' ? (process.env.VUE_APP_CURRENTMODE == 'stage' ?
		'' : '') : 'http://localhost:8099',
	GO_URL:process.env.NODE_ENV == 'production' ? (process.env.VUE_APP_CURRENTMODE == 'stage' ?
		'' : '') : 'http://localhost:8099/#',
	UPLOAD_IMG: 'https://file.qidian.shop/api/image/upload?PlatForm=melyshop&Path=image',
	UPLOAD_VIDEO: 'https://file.qidian.shop/api/image/upload?PlatForm=melyshop&Path=video',
	UPLOAD_FILE: 'https://file.qidian.shop/api/image/upload?PlatForm=melyshop&Path=File',
	UPLOAD_ZIP:'https://file.qidian.shop/api/file/upload?PlatForm=melyshop&Path=File',
  DEFAULT_HEADER:'https://cdn.dkycn.cn/images/melyshop/pcDefaultHeader.png',
	IsOfficial:process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE != 'stage'
}
