<template>
	<div class="step-wraper" v-if="show">
		<div class="step step-0" v-show="step===0">
			<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/step/step0.png">
			<div class="btn" @click="stepNext"></div>
		</div>
		<div class="step step-1" v-show="step===1">
			<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/step/step1.png">
			<div class="btn" @click="stepNext"></div>
		</div>
		<div class="step step-2" v-show="step===2">
			<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/step/step2.png">
			<div class="btn" @click="stepNext"></div>
		</div>
		<div class="step step-3" v-show="step===3">
			<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/step/step3.png">
			<div class="btn" @click="stepNext"></div>
		</div>
		<div class="step step-4" v-show="step===4">
			<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/step/step4.png">
			<div class="btn" @click="stepNext"></div>
		</div>
		<div class="step step-5" v-show="step===5">
			<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/step/step5.png">
			<div class="btn" @click="stepNext"></div>
		</div>
		<div class="step step-6" v-show="step===6">
			<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/step/step6.png">
			<div class="btn" @click="stepNext"></div>
		</div>
		<div class="step step-7" v-show="step===7">
			<img class="bg" src="http://cdn.dkycn.cn/images/melyshop/step/step7.png">
			<div class="btn" @click="stepFinished"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	
	import {
		isfinishupdatetips
	} from '@/api/sv1.0.0.js'

	export default {
		props: {
		},
		components: {
		},
		data() {
			return {
				show: false,
				step: 0
			}
		},
		computed: {
			...mapGetters([
				'isShowUpdateTips',
			]),
		},
		created() {
			this.show = this.isShowUpdateTips
		},
		watch: {
			isShowUpdateTips(){
				// console.log(this.isShowUpdateTips)
				this.show = this.isShowUpdateTips
			}
		},
		mounted() {
			
		},
		methods: {
			stepNext(){
				this.step ++;
			},
			async stepFinished(){
				this.show = false;
				this.step = 0;
				
				try{
					const res = await isfinishupdatetips();
					this.$store.dispatch('GetPcShopInfo');
				}catch(e){
					//TODO handle the exception
				}
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.step-wraper{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 100000;
		overflow: hidden;
		
		.step{
			position: relative;
			width: 100%;
			
			.bg{
				display: block;
				width: 100%;
			}
			.btn{
				position: absolute;
				// background-color: red;
				cursor: pointer;
			}
		}
		.step-0{
			.btn{
				width: 11%;
				height: 3%;
				left: 55%;
				top: 30%;
			}
		}
		.step-1{
			.btn{
				width: 5%;
				height: 2%;
				left: 38%;
				top: 12.8%;
			}
		}
		.step-2{
			.btn{
				width: 5%;
				height: 2%;
				left: 47.5%;
				top: 7.5%;
			}
		}
		.step-3{
			.btn{
				width: 6%;
				height: 2%;
				left: 72%;
				top: 16%;
			}
		}
		.step-4{
			.btn{
				width: 5%;
				height: 2%;
				left: 41.5%;
				top: 16%;
			}
		}
		.step-5{
			.btn{
				width: 5%;
				height: 2%;
				left: 25.5%;
				top: 10.5%;
			}
		}
		.step-6{
			.btn{
				width: 5%;
				height: 2%;
				left: 67.5%;
				top: 9.6%;
			}
		}
		.step-7{
			.btn{
				width: 13%;
				height: 2%;
				left: 62.3%;
				top: 35%;
			}
		}
	}
</style>
