<template>
	<div class="novice-guidance" v-if="(showModel || showHelp) && !IsXiTai">
		<div class="title-wraper">
			<div class="tabs">
				<div class="tab" v-if="showModel" @click="tabChange(0)" :class="{tabCurrent: tabCurrent === 0}">
					新手指引
					<span v-if="!showHelp" style="font-size: 14px;color: #666;padding-left: 3px;">·5步实现交易破冰</span>
				</div>
				<div class="tab" v-if="showHelp" @click="tabChange(1)" :class="{tabCurrent: tabCurrent === 1}">常见问题
				</div>
			</div>

			<div class="right" @click="handleExpand(!show)">
				<span v-if="show">收起</span>
				<span v-if="!show">展开</span>
			</div>
		</div>
		<div class="content" :style="{height: (show && tabCurrent===0)?'270px':'0px'}">
			<!-- 一级菜单 -->
			<div class="menu">
				<div class="menu-item" :class="{menuItemCurrent: noviceGuidanceCurrent.first === index}"
					v-for="(item, index) in list" :key="index" @click="menuChange(index)">
					<span>{{item.no}}</span> {{item.name}}
				</div>
			</div>


			<div class="desc-wraper">
				<!-- 二级菜单 -->
				<div class="desc-box">
					<img :src="currentData.icon" class="icon"></img>
					<div class="info">
						<div class="name">{{currentData.name}}</div>
						<div class="desc">
							<div v-for="(desc, i) in currentData.desc" :key="i">{{desc}}</div>
						</div>
						<div class="step">
							<div v-for="(step, j) in currentData.step" :key="j">{{step}}</div>
						</div>
						<div class="btn" @click="jump">{{currentData.btn}}</div>
					</div>
				</div>
				<!-- 三级级菜单 -->
				<div class="detail-box" :class="{detailBoxShow: showChild}">

					<!-- 三级级菜单标题 -->
					<div class="detail-left">
						<div class="nav" @click="showChild=false"> &lt; 返回{{currentData.name}}</div>

						<div class="detail-nav">
							<div class="detail-nav-item"
								:class="{detailItemCurrent: k == noviceGuidanceCurrent.second}"
								v-for="(child, k) in currentData.children" :key="k" @click="childChange(k, child)">
								{{child.name}}
							</div>
						</div>
					</div>

					<!-- 三级级菜单内容 -->
					<div class="detail-right" v-if="childData">
						<div class="desc">
							<div v-if="childData.path !== 'supplyMarket'" v-for="(desc, i) in childData.desc"
								:key="i">
								<span v-html="desc"></span>
								<el-link type="primary" style="margin-left: 10px;" v-if="childData.descBtn"
									@click="showPop">
									{{childData.descBtn}}
								</el-link>
							</div>
							<div v-else>
								<span>我们从企店供货市场为您挑选了{{productList.length}}款热卖单品，您可以点击一键添加到自己商城进行销售，丰富商品品类，快速完成商城铺货</span>
								<el-link type="primary" style="margin-left: 10px;" @click="batchAddProduct"
									v-if="showBatchBtn">一键添加</el-link>
								<span v-else
									style="color: #999;display: inline-block;margin-left: 20px;">商城已添加热卖单品</span>
							</div>
						</div>
						<div class="step" v-if="childData.path !== 'orderMange'">
							<div v-for="(step, j) in childData.step" :key="j">
								<div v-if="step.type == 'text'" v-html="step.value"></div>
								<img :src="step.value" v-else></img>
							</div>
						</div>
						<div class="step" v-else>
							<div>
								<div>·准备好发圈文案，<el-link type="primary" @click="downloadIamge(homeUrl, '商城小程序码')">
										点击下载商城小程序码</el-link>
									，下载好的小程序码，您可以直接分至朋友圈或者微信群聊，引导客户扫码进入您的商城；如果您有设计能力或者设计人员，您可以设计一些精美的海报图和文案，更有利于商城推广
								</div>
								<div>
									·如果您有线下门店，您还可以在门店张贴成为会员的小程序码，通过线下门店的流量，为线上商城吸引新客。商城创建初期，您可以降低成为会员的门槛，例如：0元入会并赠送优惠券等
									<el-link type="primary"
										href="https://jusnn6k8al.feishu.cn/docs/doccnwle3ADt2ZbInZwrcbXhVwd"
										target="_blank">（查看如何设置）</el-link>。<el-link type="primary"
										@click="downloadIamge(vipUrl, '成为会员小程序码')">点击下载成为会员小程序码</el-link>
								</div>
								<div>·您还可以发动您的员工，为您推广商城。您可以给员工开启锁粉提成，给员工一些小小的奖励，从而提高员工的积极性。<el-link type="primary"
										href="https://jusnn6k8al.feishu.cn/docs/doccnNltIgVYz9DbnnVIuzGFBdf"
										target="_blank">查看如何开启员工锁粉提成</el-link>
								</div>
							</div>
						</div>
						<div class="btn" @click="showPop" v-if="childData.btn">{{childData.btn}}</div>

						<!-- 如果是一键添加热卖商品， 则固定展示商品 -->
						<div class="product-list" v-if="childData.path == 'supplyMarket' && productList.length">
							<div class="product-item" v-for="(item, index) in productList" v-if="index<4"
								:key="item.Id">
								<img :src="item.ImgUrlComplete" /></img>
							</div>
							<div class="product-tip" @click="visibleProduct=true">查看全部热卖></div>
						</div>
					</div>
				</div>
			</div>

			<!-- 示例弹框 -->
			<el-dialog :visible.sync="visible" class="pop-data" v-if="tabCurrent === 0 && childData && childData.popTitle"
				:title="childData.popTitle" width="1000px">
				<el-tabs type="border-card" v-model="popStep" v-if="childData.popList.length>1">
					<el-tab-pane :label="item.title" :name="index+''" v-for="(item, index) in childData.popList"
						:key="index">
						<div style="max-height: 60vh;overflow: auto;margin-top: -15px;">
							<div v-for="(obj, i) in item.data" :key="i">
								<div v-if="obj.type=='text'" style="margin-top: 20px;" v-html="obj.value"></div>
								<img :src="obj.value" v-else style="width: 100%;margin-top: 10px;"></img>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>

				<div style="max-height: 60vh;overflow: auto;padding-top: 5px;margin-top: -15px;"
					v-else-if="childData.popList.length">
					<div v-for="(obj, i) in childData.popList[0].data" :key="i">
						<div v-if="obj.type=='text'" style="margin-top: 20px;" v-html="obj.value"></div>
						<img :src="obj.value" v-else style="width: 100%;;margin-top: 10px;"></img>
					</div>
				</div>

				<div slot="footer" style="text-align: center;" v-if="childData.popList.length>1">
					<el-button @click="visible = false">关闭</el-button>
					<el-button type="primary" @click="popPrev" v-if="popStep > 0">上一步</el-button>
					<el-button type="primary" @click="popNext" v-if="popStep<childData.popList.length-1">下一步
					</el-button>
					<el-button type="primary" @click="popIKnow" v-if="popStep==childData.popList.length-1">我知道了
					</el-button>
				</div>
				<div slot="footer" style="text-align: center;" v-else>
					<el-button type="primary" @click="popIKnow">我知道了</el-button>
				</div>

			</el-dialog>


			<!-- 全部热卖商品 -->
			<el-dialog :visible.sync="visibleProduct" class="remai" v-if="tabCurrent === 0" title="一键添加热卖单品" width="890px">
				<div class="pop-title">我们从企店供货市场为您挑选了{{productList.length}}款热卖单品，您可以点击一键添加到自己商城进行销售，快速完成商城铺货</div>

				<div class="pop-list">
					<div class="pop-item" v-for="(item, index) in productList" :key="item.Id">
						<div class="img">
							<img :src="item.ImgUrlComplete"></img>
						</div>
						<div class="sale">利润{{item.ProfitRate}}%</div>
						<div class="name">
							<div>{{item.Name}}</div>
						</div>
					</div>
				</div>

				<div slot="footer" style="text-align: center;">
					<el-button @click="visibleProduct = false">暂不添加</el-button>
					<el-button type="primary" @click="batchAddProduct" v-if="showBatchBtn">一键添加</el-button>
					<el-button type="text" v-else style="color: #999;">商城已添加热卖单品</el-button>
				</div>

			</el-dialog>

		</div>

		<div class="content help-content" :style="{height: (show && tabCurrent===1)? (helpHeight + 'px'):'0px', marginBottom: show? '20px':'0px'}">
			<div class="help-item" v-for="(item, index) in helpData" :key="index" v-html="item"></div>
		</div>
		
		
		<div class="content hidden-help-content" style="position: absolute;left: -999999px;height: auto;width: 100%;display: block;">
			<div class="help-item" v-for="(item, index) in helpData" :key="index" v-html="item"></div>
		</div>
	</div>
</template>

<script>
	import {
		templateList
	} from './data.js';
	import {
		helpList
	} from './help.js';

	import apiList from '@/api/other';
	import {
		triggeredmallguidsave,
		triggeredmallguidlist
	} from '@/api/api.js';
	import {
		mallFreeVersionConfigInfo
	} from "@/api/goods"

	import config from '@/config/index'

	import {
		mapGetters
	} from 'vuex';

	var routeNameList = ['homeTitle', 'goodsList', 'goodsExport', 'supplyMarket', 'coupon', 'limitedDiscount',
		'discountActivity', 'mallPagemange', 'wxAppManage', 'settingPay', 'orderMange', 'editSetting'
	]

	export default {
		name: 'novice-guidance',
		props: {
			isPop: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				showModel: routeNameList.indexOf(this.$route.name) > -1,
				
				showHelp: false,
				helpData: [],
				helpHeight: 0,
				
				list: templateList,

				current: 0,
				childCurrent: 0,
				showChild: false,
				show: this.isPop ? true : false,

				visible: false,
				popStep: '0',

				productList: [], //供货商品
				visibleProduct: false,
				showBatchBtn: false,

				homeUrl: '',
				vipUrl: '',

				ErrorCode: 0,

				tabCurrent: 0
			}
		},
		computed: {
			...mapGetters([
				'noviceGuidanceCurrent',
				'IsXiTai'
			]),
			currentData() {
				return this.list[this.noviceGuidanceCurrent.first];
			},
			childData() {
				if (this.noviceGuidanceCurrent.first < 0 || this.noviceGuidanceCurrent.second < 0) return null;
				return this.list[this.noviceGuidanceCurrent.first].children[this.noviceGuidanceCurrent.second]
			}
		},
		watch: {
			'$route'() {

				this.showModel = routeNameList.indexOf(this.$route.name) > -1;
				// console.log(this.showModel, 'watch $route');
				// this.initCurrent();
				this.checkShowHelp();

				// this.getTriggeredmallguidlist();
				// if (this.$route.name == 'supplyMarket'){
				// 	this.getProduct();
				// }
			},
		},
		mounted() {
			if(this.IsXiTai) return 
			this.ErrorCode = 0;

			this.initCurrent();

			this.getTriggeredmallguidlist();

			if (this.$route.name == 'supplyMarket') {
				this.getProduct();
			}

			if (this.$route.name == 'orderMange') {
				this.getQrCode('pages/index/index', res => {
					this.homeUrl = this.imgUrl + res;
				})
				this.getQrCode('pages/memberActivation/memberActivationpage', res => {
					this.vipUrl = this.imgUrl + res;
				})
			}

		},
		methods: {
			async getQrCode(url, callback) {
				try {
					const res = await apiList.mallGetQrCode({
						Page: url,
						Scene: "1",
						IsLimited: false,
						ProgramType: 0,
						noError: true,
					})

					this.ErrorCode = res.ErrorCode;

					if (res.IsSuccess) {
						var url = JSON.parse(res.Result)[0]
						callback && callback(url)
					}

				} catch (e) {
					//TODO handle the exception
				}
			},
			downloadIamge(url, name) {

				if (this.ErrorCode == 1000063) { //未绑定商城小程序
					this.$store.dispatch('GetFreeMallWxAppAndPayInfo').then(() => {
						this.state = window.localStorage.getItem('mlmzFreePayState')
						if (this.state == 0) {
							this.$confirm('请先绑定商城小程序', '提示', {
								distinguishCancelAndClose: true,
								confirmButtonText: '去绑定',
								cancelButtonText: '关闭'
							}).then(() => {
								this.getMallConfig();
							}).catch(action => {});
							return
						}
					}).catch(() => {});
					return;
				} else if (this.ErrorCode == 85074) { //小程序未发布
					this.$confirm('商城小程序还未发布上线，推广码生成失败', '', {
						confirmButtonText: '去发布',
						cancelButtonText: '关闭',
					}).then(() => {
						let url = location.origin + '/index.html#/setting/wxAppManage'
						window.open(url)
					}).catch(() => {});
					return;
				} else if (this.ErrorCode == 41030) { //page页面不存在
					this.$confirm('获取新页面推广码，请先将小程序版本更新成最新版本', '', {
						confirmButtonText: '去更新',
						cancelButtonText: '关闭',
					}).then(() => {
						let url = location.origin + '/index.html#/setting/wxAppManage'
						window.open(url)
					}).catch(() => {});
					return;
				}


				var image = new Image()
				// 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height

					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png')

					// 生成一个a元素
					var a = document.createElement('a')
					// 创建一个单击事件
					var event = new MouseEvent('click')

					// 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
					a.download = name || '下载图片名称'
					// 将生成的URL设置为a.href属性
					a.href = url
					// 触发a的单击事件
					a.dispatchEvent(event)
				}

				image.src = url;

			},
			async getMallConfig() {
				let res = await mallFreeVersionConfigInfo()
				this.mallConfigInfo = res.Result
				window.open(this.mallConfigInfo.WxAppAuthUrl)
			},
			async getTriggeredmallguidlist() {
				try {
					const res = await triggeredmallguidlist();

					//找出模板数据里， 跟页面相匹配的数据
					var recordType = -1;
					this.list.map(item => {
						item.children.map(record => {
							if (record.path == this.$route.name) {
								recordType = record.RecordType || -1;
							}
						})
					})

					//查询当前页面 是否在已触发过弹框数据里
					var list = res.Result.filter(item => {
						return item.RecordType === recordType
					})
					
					//如果不在， 则弹框， 并且标记为已弹框
					if (!list.length) {
						if (this.childData) {
							if (this.childData.path == 'supplyMarket') {
								this.visibleProduct = true;
							} else if (this.childData.popTitle) {
								this.showPop()
							}
							this.show = true;
						}else{
							this.visibleProduct = false;
							this.visible = false;
						}

						this.setTargetState(recordType);
					}else{
						this.visibleProduct = false;
						this.visible = false;
					}


					//单独判断'一键添加'按钮
					var list = res.Result.filter(item => {
						return item.RecordType === 2;
					})
					if (!list.length) {
						this.showBatchBtn = true;
					}

				} catch (e) {}
			},
			async setTargetState(recordType) {
				if (recordType < 0) return;

				try {
					const res = await triggeredmallguidsave({
						RecordType: recordType
					})
				} catch (e) {
					//TODO handle the exception
				}
			},
			async getProduct() {
				try {
					let data = {
						SupplierProductCategoryIdList: [1],
						Skip: 0,
						Take: 100000000,
						IsAsc: true,
						OrderBy: "SupplierProductLastReleaseTime",
					}
					let result = await apiList.supplierMarketProductList(data)
					this.productList = result.Result.Results || [];
				} catch (e) {}
			},
			async batchAddProduct() {
				try {
					let res = await apiList.BatchSupplierMarketProductSave({
						BatchSupplierMarketProductSaveRequestList: this.productList.map(item => {
							return {
								SupplierMallId: item.MallId,
								SupplierProductId: item.Id
							}
						})
					})

					if (res.IsSuccess) {
						this.$message.success('系统正在为您添加热卖单品，您可以继续其他操作');
						this.showBatchBtn = false;
					}

				} catch (e) {
					console.log(e)
				}
			},
			initCurrent() {

				var name = this.$route.name;
				if (name == 'homeTitle') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 0,
						second: -1,
						third: -1
					})
				}
				if (name == 'supplyMarket') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 0,
						second: 0,
						third: -1
					})
				}
				if (name == 'goodsList') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 0,
						second: 1,
						third: -1
					})
				}
				if (name == 'goodsExport') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 0,
						second: 2,
						third: -1
					})
				}
				if (name == 'coupon') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 1,
						second: 0,
						third: -1
					})
				}
				if (name == 'limitedDiscount') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 1,
						second: 1,
						third: -1
					})
				}
				if (name == 'discountActivity') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 1,
						second: 2,
						third: -1
					})
				}
				if (name == 'mallPagemange') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 2,
						second: 0,
						third: -1
					})
				}
				if (name == 'editSetting') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 3,
						second: 0,
						third: -1
					})
				}
				if (name == 'wxAppManage') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 4,
						second: 0,
						third: -1
					})
				}
				if (name == 'settingPay') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 4,
						second: 1,
						third: -1
					})
				}
				if (name == 'orderMange') {
					this.$store.commit('set_noviceGuidanceCurrent', {
						first: 4,
						second: 2,
						third: -1
					})
				}

				this.showChild = this.noviceGuidanceCurrent.second >= 0;

				this.checkShowHelp();
			},
			checkShowHelp(){
				var name = this.$route.name;
				
				var list = helpList.filter(item => {
					return item.path === name;
				})
				
				if (list.length) {
					this.showHelp = true;
					this.helpData = list[0].data;
				} else {
					this.showHelp = false;
					this.helpData = [];
				}
				
				if (!this.showModel) {
					this.tabCurrent = 1;
					
					this.$nextTick(()=>{
						this.getHelpHeight();
					})
				}
			},
			menuChange(index) {
				this.$store.commit('set_noviceGuidanceCurrent', {
					first: index,
					second: -1,
					third: -1
				})
				this.showChild = false;
			},
			childChange(index, record) {
				this.$store.commit('set_noviceGuidanceCurrent', {
					second: index,
					third: 0
				})

				if (record.path) {
					if (this.$route.name != record.path) {
						this.$emit('close');
						this.$router.push({
							name: record.path
						})
					}
				}
			},
			jump() {
				this.showChild = true;

				this.$store.commit('set_noviceGuidanceCurrent', {
					second: 0,
					third: 0
				})

				this.$emit('close');

				if (this.currentData.path) {
					if (this.$route.name != this.currentData.path) {
						setTimeout(() => {
							this.$router.push({
								name: this.currentData.path
							})
						}, 300)

					} else {
						this.initCurrent();
					}
				}


			},
			showPop() {
				this.visible = true;
				this.popStep = '0';
			},
			popPrev() {
				var val = +this.popStep - 1;
				this.popStep = val + '';
			},
			popNext() {
				var val = +this.popStep + 1;
				this.popStep = val + '';
			},
			popIKnow() {
				this.visible = false;
			},
			handleExpand(bol) {
				
				if (this.tabCurrent === 1){
					this.getHelpHeight();
				}
				
				this.show = bol;
				this.$emit('close');
			},
			getHelpHeight(){
				if(document.querySelector('.hidden-help-content')){
					var height = document.querySelector('.hidden-help-content').clientHeight;
					this.helpHeight = height > 250 ? 250:height;
				}
			},
			tabChange(index){
				
				//只有一个选项的时候 不自动展开
				if (!this.showModel || !this.showHelp) return;
				
				this.tabCurrent = index;
				this.handleExpand(true)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.pop-data ::v-deep .el-dialog__body {
		padding-top: 10px;
	}

	.novice-guidance {
		position: relative;
		background-color: #fff;
		// box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
		border-radius: 4px;
		overflow: hidden;
		margin-bottom: 15px;

		.title-wraper {
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 20px;

			.tabs {
				display: flex;
				align-items: baseline;

				.tab {
					margin-right: 20px;
					font-size: 14px;
					color: #999;
					cursor: pointer;
					
					display: flex;
					align-items: center;
				}

				.tabCurrent {
					font-size: 18px;
					color: #333;
				}
			}

			.left {
				font-size: 18px;

				span {
					font-size: 14px;
					color: #606266;
					padding-left: 3px;
				}
			}

			.right {
				font-size: 14px;
				cursor: pointer;
			}
		}

		.content {
			display: flex;
			padding-right: 30px;
			max-height: 270px;
			overflow: hidden;
			transition: all .3s;

			.menu {
				flex: 0 0 auto;
				margin-right: 50px;
				padding-bottom: 20px;
				padding-top: 10px;

				.menu-item {
					height: 38px;
					line-height: 38px;
					font-size: 16px;
					padding-left: 20px;
					padding-right: 20px;
					margin-bottom: 10px;
					cursor: pointer;

					span {
						font-weight: bold;
						padding-right: 5px;
					}
				}

				.menuItemCurrent {
					background-color: #d9ecfc;
					border-radius: 0 19px 19px 0;
				}
			}

			.desc-wraper {
				position: relative;
				flex: 1 1 auto;
				overflow: hidden;
				padding: 15px;
				border-radius: 3px;
				box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
				display: flex;
				margin-top: 10px;
				margin-bottom: 20px;

				.desc-box {
					display: flex;
					overflow: auto;
					flex: 1 1 auto;
					height: 210px;
				}

				.icon {
					width: 48px;
					height: 48px;
					flex: 0 0 auto;
					margin-right: 15px;
				}

				.info {
					flex: 1 1 auto;

					.name {
						font-size: 18px;
						font-weight: bold;
						margin: 10px 0 10px 0;
					}

					.desc {
						font-size: 14px;
						color: #606266;
						line-height: 22px;
						margin-bottom: 15px;
					}

					.step {
						font-size: 14px;
						color: #606266;
						line-height: 22px;
						margin-bottom: 10px;
					}

					.btn {
						font-size: 14px;
						color: #409EEF;
						cursor: pointer;
					}
				}
			}

			.detail-box {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				overflow: auto;
				padding: 15px 0;
				background-color: #fff;
				transition: all .3s;
				transform: translateX(100%);

				.detail-left {
					flex: 0 0 auto;
					border-right: 1px solid #ddd;

					.nav {
						font-size: 14px;
						margin-bottom: 30px;
						margin-left: 20px;
						cursor: pointer;
					}

					.detail-nav {
						padding-right: 40px;

						.detail-nav-item {
							height: 38px;
							line-height: 38px;
							width: 200px;
							padding-left: 20px;
							font-size: 14px;
							cursor: pointer;
						}

						.detailItemCurrent {
							background-color: #F7f7f7;
						}
					}
				}

				.detail-right {
					flex: 1 1 auto;
					overflow: auto;
					margin-left: 20px;
					margin-right: 20px;

					.desc {
						font-size: 14px;
						color: #606266;
						line-height: 22px;
						margin-bottom: 15px;
					}

					.step {
						font-size: 14px;
						color: #606266;
						line-height: 22px;
						margin-bottom: 10px;

						>div {
							margin-bottom: 5px;
						}
					}

					.btn {
						font-size: 14px;
						color: #409EEF;
						cursor: pointer;
					}

					.product-list {
						display: flex;
						align-items: center;

						.product-item {
							flex: 0 0 auto;
							width: 100px;
							height: 100px;
							overflow: hidden;
							border: 1px solid #eee;
							margin-right: 10px;

							img {
								display: block;
								width: 100%;
							}
						}

						.product-tip {
							font-size: 14px;
							color: #999;
							white-space: nowrap;
							cursor: pointer;
						}
					}
				}
			}

			.detailBoxShow {
				transform: translateX(0);
			}

			.help-item {
				font-size: 14px;
				color: #606266;
				line-height: 18px;
				margin-bottom: 5px;
				
			}
		}
		
		.help-content{
			display: block;
			padding-left: 20px;
			margin-bottom: 20px;
			overflow: auto;
		}

		.pop-list {
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;
			height: 50vh;
			overflow: auto;

			.pop-item {
				flex: 0 0 auto;
				width: 180px;
				height: 262px;
				margin-right: 30px;
				margin-bottom: 30px;

				.img {
					width: 180px;
					height: 180px;
					overflow: hidden;

					img {
						display: block;
						width: 100%;
					}
				}

				.sale {
					height: 26px;
					line-height: 26px;
					color: #F56C6C;
					background-color: #FEF0F0;
					font-size: 14px;
					text-align: center;
				}

				.name {
					height: 56px;
					padding: 9px 10px;
					box-sizing: border-box;
					border-left: 1px solid #eee;
					border-bottom: 1px solid #eee;
					border-right: 1px solid #eee;

					div {
						line-height: 20px;
						height: 40px;
						font-size: 14px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
				}
			}
		}

	}
</style>
