import request from '@/utils/request'

//获取消息订阅信息
export const mallNoticeSubscribeConfig = () => request('/pc/mall/mallNoticeSubscribeConfig');

// 获取路由菜单

export const MenuInit = () => request('/pc/employee/getMenuPermission');
export const supplierproductList = (data) => request('/admin/supplierproduct/list', data);


// 商品
// 商品列表
export const productList = (data) => request('/pc/product/list', data);

// 商品上下架
export const productOpen = (data) => request('/pc/product/open', data);

// 商品删除
export const productDelete = (data) => request('/pc/product/delete', data);

// 品牌筛选列表
export const productBrandList = (data) => request('/pc/productBrand/filterBoxList', data);

// 分组筛选列表
export const productGroupList = (data) => request('/pc/productGroup/filterBoxList', data);

// 获取商品规格信息
export const productSpecGet = (data) => request('/pc/productSpec/get', data);

// 商品编辑名称、编码
export const productChangeNameAndNo = (data) => request('/pc/product/changeNameAndNo', data);

// 商品编辑价格
export const productChangePrice = (data) => request('/pc/product/changePrice', data);

// 商品出入库
export const productSpeChangeStock = (data) => request('/pc/productSpec/changeStock', data);

// 商品批量上下架
export const productBatchOpen = (data) => request('/pc/product/batchOpen', data);

// 商品批量删除
export const productBatchDelete = (data) => request('/pc/product/batchDelete', data);

// 商品获取历史规格名
export const productSpecSpecTitleList = (data) => request('/pc/productSpec/specTitleList', data);

// 商品获得某一规格下的历史规格
export const productSpecSpecValueListe = (data) => request('/pc/productSpec/specValueList', data);

// 商品保存
export const productSave = (data) => request('/pc/product/save', data);

// 商品 子商品列表
export const productSpecList = (data) => request('/pc/productSpec/list', data);

// 类目筛选框列表
export const productCategoryLibraryList = () => request('/pc/productCategoryLibrary/filterBoxList');


// 推手等级
export const distributorRoleFilterBoxList = () => request('/pc/distributorRole/filterBoxList');

// 商品信息
export const productInfo = (data) => request('/pc/product/info', data);

// 商品修改收益与提成
export const changeSellReward = (data) => request('/pc/product/changeSellReward', data);

// 商品-批量修改分组初始化
export const batchChangeGroupInit = (data) => request('/pc/product/batchChangeGroupInit', data);

//  商品-批量修改分组
export const batchChangeGroup = (data) => request('/pc/product/batchChangeGroup', data);

// 批量修改 是否加入购物车
export const productBatchAddCart = (data) => request('/pc/product/batchAddCart', data);

// 页面管理编辑-初始化
export const editSelectinit = (data) => request('/pc/mall-shop-decorate/edit-select-product-init', data);

// 修改商品虚拟销量初始化
export const changeVirtualSalesInit = (data) => request('/pc/product/changeVirtualSalesInit', data);

// 修改商品虚拟销量
export const changeVirtualSales = (data) => request('/pc/product/changeVirtualSales', data);

// 批量修改商品虚拟销量
export const batchChangeVirtualSales = (data) => request('/pc/product/batchChangeVirtualSales', data);

// 商品-单个修改供货市场状态
export const changeSupplierState = (data) => request('/pc/product/changeSupplierState', data);

// 商品-批量修改供货市场状态
export const batchChangeSupplierState = (data) => request('/pc/product/batchChangeSupplierState', data);






// 分组管理

// 分组列表
export const productGroupListData = (data) => request('/pc/productGroup/list', data);

// 分组保存
export const productGroupSave = (data) => request('/pc/productGroup/save', data);

// 分组删除
export const productGroupDelete = (data) => request('/pc/productGroup/delete', data);

// 品牌

// 品牌列表
export const productBrandListAll = (data) => request('/pc/productBrand/list', data);

// 品牌保存
export const productBrandListAllsave = (data) => request('/pc/productBrand/save', data);

// 品牌删除
export const productBrandListAlldelete = (data) => request('/pc/productBrand/delete', data);

// 订单

// 订单列表
export const orderIndex = (data) => request('/pc/order/index', data);

// 订单列表初始化
export const orderIndexinit = () => request('/pc/order/index-init');

// 订单关闭
export const orderIndexClose = (data) => request('/pc/order/close', data);

// 加星
export const orderIndexStar = (data) => request('/pc/order/star', data);

// 订单发货
export const orderSend = (data) => request('/pc/order/send', data);
// 修改物流
export const orderEditExpress = (data) => request('/pc/order/edit-express', data);
// 修改分销单物流
export const supplierOrderEditExpress = (data) => request('/pc/supplier-order/edit-express', data);


// 订单查看物流
export const orderlookExpress = (data) => request('/pc/order/look-express', data);

// 订单备注
export const orderRemark = (data) => request('/pc/order/remark', data);

// 订单审单
export const orderCheck = (data) => request('/pc/order/check', data);

// 批量审单
export const orderbatchCheck = () => request('/pc/order/batch-check');

// 批量审单 获取进度条
export const ordergetProgress = () => request('/pc/order/batch-check-get-progress');

// 订单详情
export const orderlookDetail = (data) => request('/pc/order/look-detail', data);

// 订单修改收货信息
export const ordereditReceive = (data) => request('/pc/order/edit-receive', data);

// 订单主动退款初始化
export const ordererefundInit = (data) => request('/pc/order/refund-init', data);

// 订单主动退款保存
export const ordererefundsave = (data) => request('/pc/order/refund-save', data);

// 商品详情组合商品
export const detailcomboProduct = (data) => request('/pc/order/look-detail-combo-product', data);

// 订单详情优惠明细
export const detailcomboDetail = (data) => request('/pc/order/look-detail-reduce-detail', data);

// 订单修改配送方式保存
export const editendTypesave = (data) => request('/pc/order/edit-send-type-save', data);

// 订单改价初始化
export const editPriceinit = (data) => request('/pc/order/edit-price-init', data);

// 订单改价保存
export const editPricesave = (data) => request('/pc/order/edit-price-save', data);

// 订单导入发货获取进度信息
export const orderimportGetprogress = (data) => request('/pc/order/order-import-send-get-progress', data);

/**
 * 限时折扣
 */
//列表
export const timelimitdiscountlist = (data) => request('/pc/timelimitdiscount/timelimitdiscountlist', data);
//编辑
export const timelimitdiscountedit = (data) => request('/pc/timelimitdiscount/timelimitdiscountedit', data);
//详情
export const timelimitdiscountdetail = (data) => request('/pc/timelimitdiscount/timelimitdiscountdetail', data);
//删除
export const timelimitdiscountdelete = (data) => request('/pc/timelimitdiscount/timelimitdiscountdelete', data);
//失效
export const timelimitdiscountsetisopen = (data) => request('/pc/timelimitdiscount/timelimitdiscountsetisopen', data);
//产品弹框
export const discountchoseproductlist = (data) => request('/pc/timelimitdiscount/discountchoseproductlist', data);


/**
 * 打包一口价
 */
//列表
export const activityBaleList = (data) => request('/pc/activity-bale/index', data);
//弹框商品列表
export const editProduct = (data) => request('/pc/activity-bale/edit-product', data);
//编辑初始化
export const editInit = (data) => request('/pc/activity-bale/edit-init', data);
//保存编辑
export const editSave = (data) => request('/pc/activity-bale/edit-save', data);
//使失效
export const baleClose = (data) => request('/pc/activity-bale/close', data);
//删除
export const baleDelete = (data) => request('/pc/activity-bale/delete', data);


// 运费列表





// 包邮工具

// 包邮列表
export const activityFreeDeliveryList = (data) => request('/pc/activityFreeDelivery/list', data);

export const activityFreeDeliveryproductList = (data) => request('/pc/activityFreeDelivery/productList', data);

// 包邮工具使终止
export const activityFreeDeliveryopen = (data) => request('/pc/activityFreeDelivery/open', data);

// 包邮工具 删除
export const activityFreeDeliveryDelete = (data) => request('/pc/activityFreeDelivery/delete', data);

// 包邮工具保存
export const activityFreeDeliverySave = (data) => request('/pc/activityFreeDelivery/save', data);

// 包邮工具 活动信息
export const activityFreeDeliveryInfo = (data) => request('/pc/activityFreeDelivery/info', data);


/**
 * 运费模板
 */
export const freightTemplateList = (data) => request('/pc/freightTemplate/list', data);

// 运费模板删除
export const freightTemplatedeletet = (data) => request('/pc/freightTemplate/delete', data);

// 运费模板详情
export const freightTemplateinfo = (data) => request('/pc/freightTemplate/info', data);

// 运费模板保存
export const freightTemplateSave = (data) => request('/pc/freightTemplate/save', data);


/**
 * 会员等级
 */

// 会员等级主页
export const memberVipRoleIndex = () => request('/pc/memberVipRole/index');

// 会员等级信息
export const memberVipRoleInfo = (data) => request('/pc/memberVipRole/info', data);

// 优惠券弹框
export const activitycoupoList = (data) => request('/pc/activitycoupon/popupList', data);

// 会员等级保存
export const activitycoupoSave = (data) => request('/pc/memberVipRole/save', data);

// 开启关闭微信卡
export const changeisopenwxvipcard = (data) => request('/pc/wxvipcard/changeisopenwxvipcard', data);

// 创建会员卡(提交审核)
export const wxvipcardCreatevipcard = (data) => request('/pc/wxvipcard/createvipcard', data);

// 自定义菜单
// 获取公众号菜单
export const getWxMenu = () => request('/pc/wxMenu/getWxMenu');

// 保存公主号菜单
export const saveWxMenu = (data) => request('/pc/wxMenu/saveWxMenu', data);

// 生成公主号菜单
export const createWxMenu = (data) => request('/pc/wxMenu/createWxMenu', data);

// 客服
// 自助菜单
// 自助菜单列表
export const chatCustomerMenuList = () => request('/pc/chatCustomerMenu/list');

// 自助菜单保存
export const chatCustomerMenuSave = (data) => request('/pc/chatCustomerMenu/save', data);

// 工作量
export const customWorkloadList = (data) => request('/pc/customStatis/workloadList', data);

// 小尖商户平台商户网关
export const xjmallplatformgateway = (data) => request('/xjmallplatform/gateway', data);

// 销售业绩
export const customStatiSalesList = (data) => request('/pc/customStatis/salesList', data);


// 数据统计

// 数据统计-流量概览
export const dataStatisFlowOverview = (data) => request('/pc/dataStatis/flowOverview', data);

// 商品分析
// 商详页流量概况
export const dataProductDetailOverview = (data) => request('/pc/dataStatisProductDetail/flowOverview', data);

// 商品详情页-商品信息
export const dataProductDetailOverviewIndex = (data) => request('/pc/dataStatisProductDetail/index', data);

// 商品交易分析
// 商品交易概况
export const dataStatisProductSellAnalyzesell = (data) => request('/pc/dataStatisProductSellAnalyze/sellOverview', data);

// 商品交易分析
export const dataStatisProductSellAnalyzeindex = (data) => request('/pc/dataStatisProductSellAnalyze/index', data);

// 单品分析-商品分析
export const dataStatisProductInfo = (data) => request('/pc/dataStatis/skuAnalyze/skuAnalyzeProductInfo', data);

// sku-销售分析
export const dataStatisSkuSaleAnalyze = (data) => request('/pc/dataStatis/skuAnalyze/skuSaleAnalyze', data);

// 复购分析
export const dataStatisRepeatPayAnalyze = (data) => request('/pc/dataStatis/skuAnalyze/repeatPayAnalyze', data);

// 直播间列表
export const liveRoomList = (data) => request('/pc/liveRoom/list', data);

// 直播间-获取最新直播间
export const liveRoomRefreshCache = () => request('/pc/liveRoom/RefreshCache');

// 直播间-是否展示在首页
export const liveRoomisShow = (data) => request('/pc/liveRoom/isShow', data);

// 直播间删除
export const liveRoomDelete = (data) => request('/pc/liveRoom/delete', data);



// 门店业绩列表
export const shopPerformanceindex = (data) => request('/pc/shop-performance/index', data);
// 门店业绩列表4.1.0
export const shopPerformanceindexv2 = (data) => request('/pc/shop-performance/index/v2', data);

// 门店业绩详情支付订单列表
export const shopPerformanceorder = (data) => request('/pc/shop-performance/order-index', data);
// 门店业绩详情支付订单列表4.1.0
export const shopPerformanceorderv2 = (data) => request('/pc/shop-performance/order-index/v2', data);

// 门店业绩列表初始化
export const shopPerformanceInt = () => request('/pc/shop-performance/init');

// 门店业绩退款单
export const shopPerformanceRefund = (data) => request('/pc/shop-performance/refund-index', data);
// 门店业绩退款单4.1.0
export const shopPerformanceRefundv2 = (data) => request('/pc/shop-performance/refund-index/v2', data);


// 店员业绩
export const employeePerformanceList = (data) => request('/pc/employeePerformance/list', data);

// 销售明细-退款单-列表
export const employeSupportList = (data) => request('/pc/employeePerformance/supportList', data);

// 商品导入

// 商品导入记录列表
export const productImportRecordList = (data) => request('/pc/productImportRecord/list', data);

// 接口配置
export const mallerponfig = () => request('/pc/mall-erp-config/index');

// 接口配置生成密钥
export const mallerponfigGenerate = () => request('/pc/mall-erp-config/generate');

// 接口密钥启用
export const mallerponfigtartuse = () => request('/pc/mall-erp-config/start-use');

// 接口配置重置密钥
export const mallerponfigReset = () => request('/pc/mall-erp-config/reset');

// 接口配置回调地址保存
export const mallerponfigallbackurl = (data) => request('/pc/mall-erp-config/save-callback-url', data);

// 物流助手
// 打单列表
export const expressAssistantorder = (data) => request('/pc/wx-express-assistant-order/index', data);

// 打单列表展开详情
export const expressAssistantDetail = (data) => request('/pc/wx-express-assistant-order/extend-detail', data);

// 组合详情
export const expressAssistantextenmix = (data) => request('/pc/wx-express-assistant-order/extend-mix', data);

// 批量打单
export const expressAssistanBatchpush = (data) => request('/pc/wx-express-assistant-order/batch-push', data);

// 打单列表初始化
export const expressAssistanInit = (data) => request('/pc/wx-express-assistant-order/init', data);

// 获取银行卡列表
export const commissionWithdrawalList = () => request('/pc/commissionWithdrawal/bankList');

// 获取锁粉客户各个类型数量
export const memberLockFansTypeCount = (data) => request('/pc/member/memberLockFansTypeCount', data);

// 获取分销员锁粉页面
export const memberLockFansPageInit = (data) => request('/pc/member/memberLockFansPageInit', data);

// 获取提现人当前账号
export const commissionWithdrawalAccount = (data) => request('/pc/commissionWithdrawal/withdrawalAccount', data);

// 直播间修改状态
export const liveRoomUpdate = (data) => request('/pc/liveRoom/update', data);

// 个人中心配置
// 个人中心配置获取
export const mallMemberCenterBaseInfo = (data) => request('/pc/mall/mallMemberCenterBaseInfo', data);

// 个人中心配置保存
export const mallMemberCenterEdit = (data) => request('/pc/mall/mallMemberCenterEdit', data);

// 提现中心，页面初始化
export const commissionWithdrawalInt = () => request('/pc/commissionWithdrawal/init');


// 直播修改时间
export const liveRoomupdate = (data) => request('/pc/liveRoom/update', data);

// 支付有礼
export const activityPayGiftList = (data) => request('/pc/activityPayGift/list', data);

// 支付有礼删除
export const activityPayGiftDelete = (data) => request('/pc/activityPayGift/delete', data);

// 支付有礼使终止
export const activityPayGiftOpen = (data) => request('/pc/activityPayGift/open', data);

// 支付有礼保存
export const activityPayGiftSave = (data) => request('/pc/activityPayGift/save', data);

// 支付有礼详情
export const activityPayGiftInfo = (data) => request('/pc/activityPayGift/info', data);

//抽奖活动-弹窗列表
export const activityWheelSurfPopupList = (data) => request('/pc/activityWheelSurf/popupList', data);

//商品-是否共享素材 cxd
export const sharedMaterial = (data) => request('/pc/product/sharedMaterial', data);

//商品-共享素材列表 cxd
export const sharedMaterialList = (data) => request('/pc/product/sharedMaterialList', data);

//商品-共享素材信息 cxd
export const sharedMaterialInfo = (data) => request('/pc/product/sharedMaterialInfo', data);


//获取群分享活动列表
export const groupShareIndex = (data) => request('/pc/activitygroupshare/activitygroupshareindex', data);
//删除群分享活动
export const groupShareDelete = (data) => request('/pc/activitygroupshare/activitygroupsharedelete', data);
//失效群分享活动
export const groupShareClose = (data) => request('/pc/activitygroupshare/activitygroupshareclose', data);
//获取群分享设置
export const groupShareConfig = (data) => request('/pc/activitygroupshare/getactivitygroupshareconfig', data);
//群分享设置
export const groupShareSet = (data) => request('/pc/activitygroupshare/setactivitygroupshareconfig', data);
//-群列表-列表
export const groupList = (data) => request('/pc/group/list', data);
//-群列表-群成员列表
export const groupMemberList = (data) => request('/pc/group/detailList', data);
//-获取群分享活动详情
export const groupaActivityDetail = (data) => request('/pc/activitygroupshare/activitygroupsharedetail', data);
//-群列表-群成员列表
export const groupaActivitySave = (data) => request('/pc/activitygroupshare/save', data);

//获取配置面板信息
export const mallFreeVersionConfigInfo = (data) => request('/pc/mallFreeVersionConfig/mallFreeVersionConfigInfo', data);
//设置商城小程序秘钥
export const freeSetWxAppSecret = (data) => request('/pc/mallFreeVersionConfig/setWxAppSecret', data);
//配置公众号秘钥
export const wxPublicPlatFromAppSecretEdit = (data) => request('/pc/mall/wxPublicPlatFromAppSecretEdit', data);
//更新公众号信息
export const wxPublicPlatFromUpdate = (data) => request('/pc/mall/wxPublicPlatFromUpdate', data);
//获取公众号配置信息
export const wxPublicPlatFromInfo = (data) => request('/pc/mall/wxPublicPlatFromInfo', data);
//配置面板-支付申请信息
export const freePayApplyInfo = (data) => request('/pc/mallFreeVersionConfig/payApplyInfo', data);
//保存配置面板-支付申请
export const freePayApply = (data) => request('/pc/mallFreeVersionConfig/payApply', data);
//配置面板-支付申请初始化
export const freePayApplyInit = (data) => request('/pc/mallFreeVersionConfig/payApplyInit', data);
//配置页跳转验证
export const freePayApplySignFinish = (data) => request('/pc/mallFreeVersionConfig/payApplySigning', data);

//身份证图片识别
export const recognitionIdCard = (data) => request('/pc/imageRecognition/idCard', data);
//营业执照识别
export const recognitionBusinessLicense = (data) => request('/pc/imageRecognition/businessLicense', data);

//开户行
export const bankFilterList = (data) => request('/pc/bank/filterBoxList', data);
//配置页跳转验证
export const freeJumpValite = (data) => request('/pc/mallFreeVersionConfig/jumpValite', data);


//获取优惠券券码配置
export const activitycouponcodeinfo = (data) => request('/pc/activitycoupon/activitycouponcodeinfo', data);
//优惠券码编辑
export const activitycouponcodeedit = (data) => request('/pc/activitycoupon/activitycouponcodeedit', data);
//获取优惠券码列表
export const activitycouponcodeindex = (data) => request('/pc/activitycoupon/activitycouponcodeindex', data);
//获取优惠券券码生成
export const activitycouponcodegenerate = (data) => request('/pc/activitycoupon/activitycouponcodegenerate', data);


//获取信息分组列表
export const informationgrouppageinit = (data) => request('/pc/information/informationgrouppageinit', data);
//修改信息分组序号
export const editinformationgroupsort = (data) => request('/pc/information/editinformationgroupsort', data);
//修改信息分组是否显示
export const editinformationgroupisdisplay = (data) => request('/pc/information/editinformationgroupisdisplay', data);
//获取信息分组信息
export const informationgroupdetail = (data) => request('/pc/information/informationgroupdetail', data);
//删除分组信息
export const informationgroupdelete = (data) => request('/pc/information/informationgroupdelete', data);
//添加编辑分组信息
export const informationgroupedit = (data) => request('/pc/information/informationgroupedit', data);
//信息管理列表
export const informationpageinit = (data) => request('/pc/information/informationpageinit', data);
//修改信息序号
export const editinformationsort = (data) => request('/pc/information/editinformationsort', data);
//修改信息是否显示
export const editinformationisdisplay = (data) => request('/pc/information/editinformationisdisplay', data);
//删除信息
export const informationdelete = (data) => request('/pc/information/informationdelete', data);
//编辑信息
export const informationedit = (data) => request('/pc/information/informationedit', data);
//获取信息详细
export const informationdetail = (data) => request('/pc/information/informationdetail', data);


//批量改价列表
export const changepricelist = (data) => request('/pc/product/producteditpricerecordpagelist', data)

//获取订单查询条件支付方式列表
export const querypaylist = (data)=>request('/pc/order/searchpaybylist',data)

//导入
export const producteditpriceimport = (data)=>request('/pc/product/producteditpriceimport',data)





//供货商信息设置
export const mallsupplierInfoSave = (data)=>request('/pc/mall/supplierInfoSave',data)
//获取供货商信息设置
export const mallsupplierInfoInit = (data)=>request('/pc/mall/supplierInfoInit',data)


//供货市场商品发布协议内容、是否签署协议
export const productsupplierproductagreementinfo = (data)=>request('/pc/product/supplierproductagreementinfo',data)

//同意供货市场商品发布协议内容
export const productagreesupplierproductagreement = (data)=>request('/pc/product/agreesupplierproductagreement',data)


//供货商品分类-筛选框列表
export const supplierProductCategoryfilterBoxList = (data)=>request('/pc/supplierProductCategory/filterBoxList',data)




//主图水印列表
export const activityWatermarkwatermarkList = (data)=>request('/pc/activityWatermark/watermarkList',data)
//主图水印 删除
export const activityWatermarkwatermarkDelete = (data)=>request('/pc/activityWatermark/watermarkDelete',data)
//主图水印 保存详情
export const activityWatermarkactivityWatermarkSave = (data)=>request('/pc/activityWatermark/activityWatermarkSave',data)
//主图水印 获取详情
export const activityWatermarkactivityWatermarkInfo = (data)=>request('/pc/activityWatermark/activityWatermarkInfo',data)




//毛利明细
export const grossprofitdetailindexv2 = (data)=>request('/pc/shop-performance/gross-profit-detail-index/v2',data)




//同城配送-获取第三方配送费用
export const inCityDeliverytotalPrice = (data)=>request('/pc/order/inCityDelivery/totalPrice',data)
//同城配送-取消呼叫
export const inCityDeliverycancelCall = (data)=>request('/pc/order/inCityDelivery/cancelCall',data)
//同城配送-获取取消收费
export const inCityDeliverydeductionFee = (data)=>request('/pc/order/inCityDelivery/deductionFee',data)
//同城配送-加小费
export const inCityDeliveryaddGratuityFee = (data)=>request('/pc/order/inCityDelivery/addGratuityFee',data)
//同城配送-加小费初始化
export const inCityDeliveryaddGratuityFeeinit = (data)=>request('/pc/order/inCityDelivery/addGratuityFee/init',data)
//同城配送-发送呼叫
export const inCityDeliverysendCall = (data)=>request('/pc/order/inCityDelivery/sendCall',data)



//旺店通配置-初始化
export const wdtConfiginit = (data)=>request('/pc/wdtConfig/init',data)
//旺店通配置-编辑
export const wdtConfigedit = (data)=>request('/pc/wdtConfig/edit',data)
//ERP旺店通旗舰版-订单推送
export const wdtQjborderpush = (data)=>request('/job/wdtQjb/order/push',data)
//ERP旺店通旗舰版-同步物流
export const wdtQjbordersyncExpress = (data)=>request('/job/wdtQjb/order/syncExpress',data)
//物流公司映射-列表
export const expressMappinglist = (data)=>request('/pc/erpConfig/expressMapping/list',data)
//物流公司映射-编辑
export const expressMappingedit = (data)=>request('/pc/erpConfig/expressMapping/edit',data)
//物流公司映射-删除
export const expressMappingdelete = (data)=>request('/pc/erpConfig/expressMapping/delete',data)


//获取客户手机号
export const membermemberPhone = (data)=>request('/pc/member/memberPhone',data)
//订单信息解敏
export const orderdesensitize = (data)=>request('/pc/order/desensitize',data)



//获取信息分组列表
export const informationinformationgrouppageinitnew = (data)=>request('/pc/information/informationgrouppageinitnew',data)
//聚水潭配置-初始化
export const juShuiTanConfiginit = (data)=>request('/pc/juShuiTanConfig/init',data)
//聚水潭配置-编辑
export const juShuiTanConfigedit = (data)=>request('/pc/juShuiTanConfig/edit',data)
//立即推送聚水潭订单
export const juShuiTanConfigpushJuShuiTanOrder = (data)=>request('/pc/juShuiTanConfig/pushJuShuiTanOrder',data)








//视频号订单列表
export const channelShopchannelShopOrderList = (data)=>request('/pc/channelShop/channelShopOrderList',data)
//视频号订单列表导出
export const channelShopchannelShopOrderListExport = (data)=>request('/pc/channelShop/channelShopOrderListExport',data)
//视频号订单详情
export const channelShopchannelShopOrderInfo = (data)=>request('/pc/channelShop/channelShopOrderInfo',data)
//视频号小店售后单列表
export const channelShopchannelShopOrderSupportList = (data)=>request('/pc/channelShop/channelShopOrderSupportList',data)
//视频号小店售后单详情
export const channelShopchannelShopOrderSupportInfo = (data)=>request('/pc/channelShop/channelShopOrderSupportInfo',data)
//视频号小店分享员列表
export const channelShopchannelShopSharerList = (data)=>request('/pc/channelShop/channelShopSharerList',data)
//视频号小店分享员解绑
export const channelShopchannelShopSharerUnBind = (data)=>request('/pc/channelShop/channelShopSharerUnBind',data)
//视频号小店分享员邀请配置
export const channelShopchannelShopSharerInviteInfo = (data)=>request('/pc/channelShop/channelShopSharerInviteInfo',data)
//视频号小店分享员邀请配置保存
export const channelShopchannelShopSharerInviteInfoSave = (data)=>request('/pc/channelShop/channelShopSharerInviteInfoSave',data)
//同步分享员
export const channelShopchannelShopSharerSync = (data)=>request('/pc/channelShop/channelShopSharerSync',data)
//视频号小店绑定判断
export const channelShopchannelShopBindValite = (data)=>request('/pc/channelShop/channelShopBindValite',data)
//获取视频号小店信息 
export const channelShopchannelShopInfo = (data)=>request('/pc/channelShop/channelShopInfo',data)
//刷新店铺信息 
export const channelShopchannelShopRefresh = (data)=>request('/pc/channelShop/channelShopRefresh',data)
//关闭与视频号店铺打通 
export const channelShopchannelShopConfigClose = (data)=>request('/pc/channelShop/channelShopConfigClose',data)
//视频号店铺保存
export const channelShopchannelShopConfigSave = (data)=>request('/pc/channelShop/channelShopConfigSave',data)
//通过APPID获取店铺基础信息
export const channelShopchannelShopBasicsInfoByAppId = (data)=>request('/pc/channelShop/channelShopBasicsInfoByAppId',data)
//获取视频号小店直播配置 
export const channelShopchannelShopLiveConfig = (data)=>request('/pc/channelShop/channelShopLiveConfig',data)
//视频号小店直播配置保存  
export const channelShopchannelShopLiveConfigSave = (data)=>request('/pc/channelShop/channelShopLiveConfigSave',data)
//视频号小店商品列表
export const channelShopchannelShopProductList = (data)=>request('/pc/channelShop/channelShopProductList',data)
//视频号小店选择商品弹框
export const channelShopchannelShopProductChoseBox = (data)=>request('/pc/channelShop/channelShopProductChoseBox',data)
//视频号小店类目列表
export const channelShopchannelShopCategoryList = (data)=>request('/pc/channelShop/channelShopCategoryList',data)
//视频号小店品牌列表
export const channelShopchannelShopBrandList = (data)=>request('/pc/channelShop/channelShopBrandList',data)
//获取产品规格
export const productproductspecinfo = (data)=>request('/pc/product/productspecinfo',data)
//视频号小店类目详情
export const channelShopchannelShopCategoryDetail = (data)=>request('/pc/channelShop/channelShopCategoryDetail',data)
//视频号运费模板列表
export const channelShopchannelShopFreightTemplateList = (data)=>request('/pc/channelShop/channelShopFreightTemplateList',data)
//视频号小店商品提交审核
export const channelShopchannelShopProductSubmit = (data)=>request('/pc/channelShop/channelShopProductSubmit',data)
//视频号小店商品详情
export const channelShopchannelShopProductDetail = (data)=>request('/pc/channelShop/channelShopProductDetail',data)
//视频号小店商品撤回审核
export const channelShopchannelShopProductCancelAudit = (data)=>request('/pc/channelShop/channelShopProductCancelAudit',data)
//视频号小店商品上/下架 
export const channelShopchannelShopProductListingOrDelist = (data)=>request('/pc/channelShop/channelShopProductListingOrDelist',data)
//视频号小店订单查看物流
export const channelShopchannelShopOrderLookExpress = (data)=>request('/pc/channelShop/channelShopOrderLookExpress',data)
//视频号小店商品删除
export const channelShopchannelShopProductDelete = (data)=>request('/pc/channelShop/channelShopProductDelete',data)
//视频号小店售后单详情
export const channelShopchannelShopOrderSupportLookExpress = (data)=>request('/pc/channelShop/channelShopOrderSupportLookExpress',data)


