import router from './router/index'
import store from './store/index'
import {
	Message
} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
	getToken
} from '@/utils/auth' // getToken from cookie

NProgress.configure({
	showSpinner: false
}) // NProgress Configuration
const whiteList = ['/login', '/authredirect', '/freeOpen', '/forget'] // no redirect whitelist

router.beforeEach((to, from, next) => {
	NProgress.start() // start progress bar
	if (getToken()) { // determine if there has token
		/* has token*/
		// console.log(to.path)
		if (to.path === '/selectMall' || to.path === '/versionOrder' || to.path === '/buyRule') {
			next();
			NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
		}else if (to.path === '/login') {
			
			next({
				path: '/'
			})
			
			NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
		} else {
			
			if (!store.getters.isGet) { // 判断是否已经获取过路由信息
				store.dispatch('GetPcMallName', {})
				store.dispatch('GenerateRoutes').then(() => { // 生成可访问的路由表
					router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
					// console.log(store.state.permission.allRouters)
					next({ ...to,
						replace: true
					}) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
				}).catch((err) => {
					console.log(err)
					store.dispatch('FedLogOut').then(() => {
						Message.error('身份验证失败，请重新登录！')
						next({
							path: '/login'
						})
					})
				})
				
			} else {
				// console.log(to.name)
				let freeMallWhiteRoutes = store.getters.freeMallWhiteRoutes
				let isWhiteRoutes = freeMallWhiteRoutes.indexOf(to.path)>-1

				// 免费版商户若没有设置支付申请,且跳转路由不在免费商户白名单之列，则强制进入'/home'
				if(window.localStorage.getItem('mlmzFreePayState')!=2 && window.localStorage.getItem('mlmzIsFree')== 'true' && !isWhiteRoutes) {
					next('/home')
					NProgress.done()
					return 
				}
				
				next()
			}
		}
	} else {
		/* has no token*/
		if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
			next()
		} else {
			next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
			NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
		}
	}
})




router.afterEach(() => {
	NProgress.done() // finish progress bar
})
