import request from '@/utils/request'

//获取消息订阅信息
export const mallNoticeSubscribeConfig = () => request('/pc/mall/mallNoticeSubscribeConfig');


//收支记录
export const membercommissionbalancerecordpagelist = (data)=> request('/pc/commission/membercommissionbalancerecordpagelist',data);
//调整收益
export const memberCommissionChange = (data)=> request('/pc/commission/memberCommissionChange',data);
//通过手机号查询推手信息
export const memberinfobyphone = (data)=> request('/pc/member/memberinfobyphone',data);

/**
 * 角色管理
 */
//角色列表
export const employeerolelist = (data)=> request('/pc/employee/employeerolelist',data);
//门店标签
export const shoptaglist = (data)=> request('/pc/shop/shoptaglist',data);
//门店列表
export const employeeshoplist = (data)=> request('/pc/employee/employeeshoplist',data);
//保存
export const employeeroleedit = (data)=> request('/pc/employee/employeeroleedit',data);
//获取角色信息
export const employeeroleinfo = (data)=> request('/pc/employee/employeeroleinfo',data);
//删除角色
export const employeeroledelete = (data)=> request('/pc/employee/employeeroledelete',data);


/**
 * 员工管理
 */
//员工列表
export const employeelist = (data)=> request('/pc/employee/employeelist',data);
//添加员工
export const employeeedit = (data)=> request('/pc/employee/employeeedit',data);
//编辑员工
export const employeeroleidedit = (data)=> request('/pc/employee/employeeroleidedit',data);
//获取员工邀请二维码
export const employeewxqrcode = (data)=> request('/pc/employee/employeewxqrcode',data);
//获取角色列表
export const employeeroledroplist = (data)=> request('/pc/employee/employeeroledroplist',data);
//获取门店列表
export const shopdroplist = (data)=> request('/pc/shop/shopdroplist',data);
//获取员工详细信息
export const employeeinfo = (data)=> request('/pc/employee/employeeinfo',data);
//删除员工
export const employeedelete = (data)=> request('/pc/employee/employeedelete',data);
//离职员工
export const employeedeparture = (data)=> request('/pc/employee/employeedeparture',data);
//获取员工客户列表
export const lockfansmemberlist = (data)=> request('/pc/employee/lockfansmemberlist',data);
//修改锁粉店员
export const editlockmallemployeeid = (data)=> request('/pc/employee/editlockmallemployeeid',data);


// 获取员工匿名客户
export const anonymousFansList= (data) => request('/pc/employee/anonymousFansList',data);


//获取商户短信条数、短信协议信息
export const mallsmsmallsmsinfo= (data) => request('/pc/mallsms/mallsmsinfo',data);
//获取短信变动记录
export const mallsmsmallsmsquantityrecordlist= (data) => request('/pc/mallsms/mallsmsquantityrecordlist',data);
//获取短信变动记录详情
export const mallsmsmallsmsquantityrecorddetail= (data) => request('/pc/mallsms/mallsmsquantityrecorddetail',data);
//获取短信包列表
export const mallsmssystemsmspackageconfiglist= (data) => request('/pc/mallsms/systemsmspackageconfiglist',data);
//申请购买短信包
export const mallsmsapplypaysmspackage= (data) => request('/pc/mallsms/applypaysmspackage',data);
//获取短信支付结果
export const mallsmsbuysmspackageResult= (data) => request('/pc/mallsms/buysmspackageResult',data);


//保存已触发的引导
export const triggeredmallguidsave= (data) => request('/pc/mall/triggeredmallguidsave',data);
//获取已触发商城引导
export const triggeredmallguidlist= (data) => request('/pc/mall/triggeredmallguidlist',data);
//修改商城名称
export const mallNameEdit= (data) => request('/pc/mall/mallNameEdit',data);



//物流公司列表的接口
export const commonallexpress= (data) => request('/common/allexpress',data);//获取当前员工信息
export const employeeloginEmployeeInfo= (data) => request('/pc/employee/loginEmployeeInfo',data);




//获取商户特殊权限管理
export const mallmallSpecialLimitManagerInit= (data) => request('/pc/mall/mallSpecialLimitManagerInit',data);





 


























































