const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.userName,
  introduction: state => state.user.introduction,
  status: state => state.user.status,
  MallName:state => state.user.Mallname,
  mallPhone: state => state.user.mallPhone,
  MallAvatar:state => state.user.MallAvatar,
  UserId:state => state.user.UserId,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  routerList: state => state.permission.routerList,
  freeMallWhiteRoutes: state => state.permission.freeMallWhiteRoutes,
  haveLiveRouter: state => state.permission.haveLiveRouter,
  haveStoreRouter: state => state.permission.haveStoreRouter,
  errorLogs: state => state.errorLog.logs,
  isGet: state => state.permission.isGet,
  loginState: state => state.user.loginState,
  ShopName: state => state.user.ShopName,
  ShopLogo: state => state.user.ShopLogo,
  ShopmallName:state => state.user.ShopmallName,
  ShopthirdName:state => state.user.ShopthirdName,
  ComponentId:state => state.user.ComponentId,
  isNeedEditPass: state => state.user.isNeedEditPass,
  IsMoreShop: state => state.user.IsMoreShop,
  mallInfo: state => state.user.mallInfo,
  isopen498pay:state=>state.user.isopen498pay,
  pcImg:state=>state.user.pcImg,
  isShowUpdateTips:state=>state.user.isShowUpdateTips,
  allRouters:state=>state.permission.allRouters,
  functionalNavChange:state=>state.app.functionalNavChange,
  pagemsg:state=>state.user.pagemsg,
  pagename:state=>state.user.pagename,
  noviceGuidanceCurrent: state=>state.user.noviceGuidanceCurrent,
  RecruitmentType:state=>state.user.RecruitmentType,
  DistributorLockFansType:state=>state.user.DistributorLockFansType,
  IsAdmin:state=>state.user.IsAdmin,
  ShopProductStockModel:state=>state.user.ShopProductStockModel,
  IsOpenLookUserPrivacyAuthority:state=>state.user.IsOpenLookUserPrivacyAuthority,
  IsOpenTaoKeDistributorModel:state=>state.user.IsOpenTaoKeDistributorModel,
  IsDockingRoutuan:state=>state.user.IsDockingRoutuan,
  IsXiTai:state=>state.user.IsXiTai
}
export default getters
