<template>
<div >
    <div v-loading="loading" v-if="show"> 
        <!-- 判断是否授权微信客服v-if="!list.IsConfigure"-->
        <div v-if="!list.IsConfigure">
            <div class="case auto">
                <!-- 微信客服 -->
                <div style="margin:0 auto;width:1200px" >
                    <div class="title" style="padding-top:58px;margin-bottom:10px">微信客服</div>
                    <br>
                    <span style="font-size: 14px;color: #666666;text-align:left;">
                    可以在微信内、外各个场景中接入微信客服，客户无需加好友或打开网页，延用微信习惯，直接发起会话与客服沟通，提高收发消息效率</span>
                   <div style="width:1200px;height: 1px;opacity: 1;border-top: 1px solid rgba(234,234,234);margin:0 auto;margin-top:40px;"> </div>
                </div>
                <!-- 1.多场景打开微信客服 -->
                <div class="center_es">
                    <div>
                        <p class="title1">1.多场景打开微信客服</p>
                        <div style="width:400px">
                          <p class="block" style="line-height: 25px;">可在企业的视频号、微信内（公众号、小程序、网页、搜一搜品牌官方区等）、微信外（App、网页）等场景中打开微信客服，实现微信全域场景接入</p>
                        </div>
                      
                    </div>
                    <div>
                        <img src="https://cdn.dkycn.cn/melyshop/image/kfzh0001.png" alt="" style="width:589px;height:350px;">
                    </div>
                </div>
                 <div style="width:1200px;height: 1px;opacity: 1;border-top: 1px solid rgba(234,234,234);margin:0 auto;margib-bottom:48px"> </div>
                  <!-- 1.多场景打开微信客服 -->
                  <div class="center_es">
                    <div >
                        <p class="title1">2.咨询体验提升</p>
                        <div style="width:465px;line-height: 25px;">
                         <p style="color: #9E9E9E;font-size: 14px;">（1）针对C端客户：延续微信使用习惯，直接在微信上即可发起会话，让咨询更简单</p>
                         <p style="color: #9E9E9E;font-size: 14px;">（2）针对B端商家：所有客服人员只需要在企业微信中统一进行回复即可，让接待与管理更高效</p>
                        </div>
                        
                    </div>
                    <div>
                        <img src="https://cdn.dkycn.cn/melyshop/image/kfzh0002.png" alt="" style="width:589px;height:350px;">
                    </div>
                </div>
                  <div class="center_es">
                    <div style="width:475px;line-height: 25px;">
                        <p style="color: #9E9E9E;font-size: 14px;">（3）如同微信上「订阅号」，消息统一集纳在单独会话栏，消息提示醒目，让客户不再遗漏消息</p>
                    </div>
                    <div>
                          <img src="https://cdn.dkycn.cn/melyshop/image/kfzh0003.png" alt="" style="width:221px;height:326px;margin-right:46px">
                       
                        <img src="https://cdn.dkycn.cn/melyshop/image/kfzh0004.png" alt="" style="width:221px;height:326px;">
                        
                    </div>
                    
                </div>
                 <div style="width:1200px;height: 1px;opacity: 1;border-top: 1px solid rgba(234,234,234);margin:0 auto;"> </div>
                 <!-- 3.升级服务能力，缩短私域路径 -->
                  <div class="center_es">
                    <div style="width:475px;line-height: 25px;">
                        <p class="title1">3.升级服务能力,缩短私域路径</p>
                        <p style="color: #9E9E9E;font-size:14px;">直接发送员工名片或群二维码，邀请客户添加员工企业微信或加入客户群，升级为专属的、持续的服务，便于开展后续运营</p>
                    </div>
                    <div>
                        <img src="https://cdn.dkycn.cn/melyshop/image/kfzh0005.png" alt="" style="width:221px;height:326px;margin-right:46px">
                       
                        <img src="https://cdn.dkycn.cn/melyshop/image/kfzh0006.png" alt="" style="width:221px;height:326px;">
                    </div>
                    
                </div>
                 <div style="width:1200px;height: 1px;opacity: 1;border-top: 1px solid rgba(234,234,234);margin:0 auto;"> </div>
                 <!-- 4.客服工具栏，提高服务效率 -->
                  <div class="center_es">
                    <div style="width:475px;line-height: 25px;">
                        <p class="title1">4.客服工具栏，提高服务效率</p>
                        <p style="color: #9E9E9E;font-size:14px;">可配置访客信息、访客订单工具栏，便于客服快速了解访客情况和处理订单业务</p>
                    </div>
                    <div>
                        <img src="https://cdn.dkycn.cn/melyshop/image/kfzh007.png" alt="" style="width:635px;height:325px;">       
                    </div>
                    
                </div>
            </div>
            <div class="serve">
               <div class="box">
                <el-button type="button" @click="handclick" class="btn">
                    <span class="serve_es">立即使用</span> 
                </el-button>
               </div>    
        </div> 
        </div>
        <div v-else>
            <slot />
        </div>
         <el-dialog 
          class="dialog"
          width="500px"
          height="366px"
          :visible.sync="centerDialogVisible"
          center> 
          <img src="https://cdn.dkycn.cn/melyshop/image/design.png" alt="" style="width:500px;margin-left:-25px;margin-top:-60px">
          <div class="ab">授权绑定企业微信</div>
          <div class="posi">
           <el-button type="primary" style="width:300px;margin-left:75px;" @click="binding">我已有企业微信,立即绑定</el-button>
           <el-button plain style="width:300px;margin-left:75px;margin-top:26px;margin-bottom:26px"  @click="handle(url)">
               我还没有企业微信,现在去注册
            </el-button>
           <div style="text-align:center;">
               <span>绑定或注册过程中,若有疑问,请</span> 
            <el-popover
            trigger="hover"
            placement="bottom"
            width="200"
            v-model="visible">
            <div class="center" >
            <span class="l" style="margin:0;padding:">添加专属客服</span>
            <p class="r" style="margin:0;padding:">一对一为您答疑解惑</p>
            <img src="@/assets/img/20210719171848.jpg" class="dialog-rwm"/>
            </div>     
            <el-button slot="reference" type="text"  class="top" style="margin:0;padding:0">联系客服</el-button>
            </el-popover>  
          </div>
 
          </div>   
        </el-dialog>
        <!-- 授权绑定企业微信弹框 -->     
    </div>
</div>
</template>

<script>
	import {
		qyWeixinKfInit,
	} from '@/api/sv3.0.0.js';
    export default {
     data(){
         return{
             //接口返回延迟
             show:false,
             loading:false,
             visible: false,
             centerDialogVisible: false,
             list:{
                IsConfigure:true
             },
         }
     },
     created(){
      
         this.getqyWeixinKfInit()
         },
     methods:{        
         handle(url){
              window.open('https://work.weixin.qq.com/wework_admin/register_wx?from=myhome')
              return false
         },
         binding(){
           this.$router.push({
               //跳转微信客服授权绑定页
					path: "/saleChannel/qyWechat",
				})
         },
         handclick(){
             if(!this.list.IsFinishQyWeixinSuiteStp){
                 this.centerDialogVisible=true
             }else{
                this.$router.push('/customerServe/authorization',)
             }  
         },
         //查看授权企店助手
        async getqyWeixinKfInit(){
            try{
                let wxList=await qyWeixinKfInit()
                this.list=wxList.Result
                // if(!this.list.IsFinishQyWeixinSuiteStp){
                //     this.$router.push('/customerServe/authorization',)
                // }
            //    console.log(this.list)
            }finally{ 
                    //减少接口返回延迟  
                    this.show = true
            }
           },
        }, 
       
}
</script>

<style lang="less" scoped>
.ab{
    font-size: 16px;
    color: #303133;
    position: absolute;
    top: 20px;
    left: 186px;
    
}
.posi{
    position: absolute;
    top: 200px;
    margin: 0 auto;
}
.auto{
    min-width:1300px
}
.center_es{ 
    width: 1200px;
    margin: 0 auto;
    height: 500px;
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.block_{
width: 461px;
height: 90px;
font-size: 14px;
font-family: Source Han Sans CN-Regular, Source Han Sans CN;
font-weight: 400;
color: #9E9E9E;

}
.block{
width: 461px;
height: 90px;
text-align: left;
font-size: 14px;
line-height: 25px;
font-family: Source Han Sans CN-Regular, Source Han Sans CN;
font-weight: 400;
color: #9E9E9E;
}
.title1{
font-size: 30px;
font-family: Source Han Sans CN-Medium, Source Han Sans CN;
font-weight: 500;
color: #333333 ;
}
.title{
font-size: 36px;
font-family: Source Han Sans CN-Medium, Source Han Sans CN;
font-weight: 500;
color: #333333;
}
.l{
width: 84px;
height: 21px;
font-size: 14px;
font-family: Source Han Sans CN-Medium, Source Han Sans CN;
font-weight: 500;
color: #303133;
line-height: 21px;
}
.r{
height: 21px;
font-size: 12px;
font-family: Source Han Sans CN-Regular, Source Han Sans CN;
font-weight: 400;
color: #999999;
line-height: 21px;
text-align: center;
}
.center{

    text-align: center;
}
.dialog-rwm{
		display:block;
		width: 140px;
        height: 140px;
		margin:0  auto;
	}
.case{
height: 2800px;
background: #FFFFFF;
border-radius: 0px 0px 0px 0px;
opacity: 1;
}
.serve{
width: 100%;
height: 80px;
background: #FFFFFF;
box-shadow: 0px -2px 4px 1px rgba(0, 0, 0, 0.10000000149011612);
border-radius: 0px 0px 0px 0px;
opacity: 1;
display: flex;
justify-content: center;
align-items: center;
position: fixed;
bottom: 0;
}
.btn{
width: 96px;
height: 36px;
background: #409EEF;
border-radius: 2px 2px 2px 2px;
opacity: 1;
}
.box{
width: 96px;
height: 36px;
background: #409EEF;
text-align: center;
border-radius: 2px 2px 2px 2px;
opacity: 1;
margin: auto;
}
.serve_es{
width: 56px;
font-size: 14px;
text-align: center;
font-family: Source Han Sans CN-Regular, Source Han Sans CN;
font-weight: 400;
color: #FFFFFF;
line-height: 14px;
cursor: pointer;
}

</style>