// 生成商品图片
const createPosterImg = function(options, that) {

	return new Promise(function(resolve, reject) {

		getImgsInfo([options.bg, options.qrCode]).then(function(res) {

			var elem = document.createElement('canvas');
			elem.style = 'position: fixed;left: -100000px;width:'+res[0].width+'px;height: '+res[0].height+'px;';
			elem.id = 'canvas'+new Date().getTime();
			document.body.appendChild(elem);
			
			var c = document.getElementById(elem.id);
			c.width = res[0].width;
			c.height = res[0].height;
			var context = c.getContext("2d");
			
			context.clearRect(0, 0, res[0].width, res[0].height)
			
			// console.log(res[0].width, res[0].height,res[1].width, res[1].height)
			if(res[0]){
				context.drawImage(res[0].path?res[0].path:'', 0, 0, res[0].width, res[0].height, )
				
			}
			if (res[1]){
				context.drawImage(res[1].path, 130, 1360, 330, 330)
			}
			
			setTimeout(()=>{
				resolve(c.toDataURL("image/png",1));
			}, 100)
		}).catch(function(err) {
			console.log('getImgsInfo err', err);
			reject(err);
		})
	})
}

// 获取单张图片信息
const getImgInfo = (path) => {
	// console.log(path)
	return new Promise((resolve, reject) => {
		if (path) {
			var img = new Image();
			img.setAttribute("crossOrigin",'Anonymous')
			img.onload = function(){
				resolve({
					path: img,
					width: img.width,
					height: img.height
				});
			}
			img.onerror = function(){
				resolve('');
			}
			img.src = path;
		} else {
			resolve('');
		}

	})
}


// 获取多张图片的信息
const getImgsInfo = (paths) => {
	// console.log(paths)
	return new Promise((resolve, reject) => {
		let promises = paths.map(item => {
			return getImgInfo(item);
		});
		Promise.all(promises).then(res => {
			// console.log(res)
			resolve(res);
		}).catch(err => {
			reject(err);
		});
	})
}

export default {
	createPosterImg
}