/* eslint-disable no-sparse-arrays */
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	// console.log(location,'location')
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'
import AppMain from '../views/layout/components/AppMainChild'

// 固定路由表
export const constantRouterMap = [
	// component: () => import('./views/About.vue')
	{
		path: '/login',
		component: () => import('@/views/login/index'),
		hidden: true
	},
	{
		path: '/selectMall',
		component: () => import('@/views/login/selectMall'),
		hidden: true
	},
	{
		path: '/buyRule',
		component: () => import('@/views/login/buyRule'),
		hidden: true
	},
	{
		path: '/versionOrder',
		component: () => import('@/views/login/versionOrder'),
		hidden: true
	},
	{
		path: '/freeOpen',
		component: () => import('@/views/login/freeOpen'),
		hidden: true
	},
	{
		path: '/forget',
		component: () => import('@/views/login/forget'),
		hidden: true
	},
	{
		path: '/authredirect',
		component: () => import('@/views/login/authredirect'),
		hidden: true
	},
	{
		path: '/order/orderMangepage/exportOrderList',
		component: () => import('@/views/order/orderMangepage/exportList'),
		hidden: true
	},
	{
		path: '/login/Errorpage',
		component: () => import('@/views/login/Errorpage'),
		hidden: true
	},
	{
		path: '/',
		component: Layout,
		redirect: ()=>{
			return store.getters.IsXiTai?'/cluesManage/clueData':'home'
		},
		meta: {
			title: 'home',
			icon: 'goods'
		},
		children: [
			{
				path: '/home',
				component: () => import('@/views/home/index'),
				name: 'homeTitle',
				meta: {
					title: 'homeTitle',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shouye/shouye.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/shouye.png'
				}
			},
			{
				path: '/myNeed',
				component: () => import('@/views/login/myNeed'),
				name: 'myNeed',
				meta: {
					title: 'myNeed',
					// icon: 'drag'
				},
				hidden: true
			},
			{
				path: '/addmyNeed',
				component: () => import('@/views/login/addmyNeed'),
				name: 'addmyNeed',
				meta: {
					title: 'addmyNeed',
					// icon: 'drag'
				},
				hidden: true
			},
			{
				path: '/needDetail',
				component: () => import('@/views/login/needDetail'),
				name: 'needDetail',
				meta: {
					title: 'needDetail',
					// icon: 'drag'
				},
				hidden: true

			},
			//需要支付页面
			{
				path: '/components/NeedPay',
				component: () => import('@/views/components/NeedPay'),
				hidden:true,
				name: 'NeedPay',
				meta: {
					title: 'NeedPay'
				}
			},
		]
	},
	{
		path: '/myNeed',
		component: Layout,
		hidden: true,
		meta: {
			title: 'myNeed',
		},
		children: [{
				path: '/myNeed/needPage',
				component: () => import('@/views/login/myNeed'),
				name: 'myNeedTitle',
				meta: {
					title: 'myNeedTitle',
				}
			},

		]
	},
	//系统消息列表
	{
		path: '/SystemNews',
		component: Layout,
		meta: {
			title: 'SystemNews',
		},
		children: [{
				path: '/SystemNews/SystemNewsIndex',
				component: () => import('@/views/login/SystemNews'),
				name: 'SystemNewsTitle',
				meta: {
					title: 'SystemNewsTitle',
				}
			},

		]
	},
	//授权成功
	{
		path: '/authResult/success',
		component: () => import('@/views/authResult/success'),
		hidden: true
	},
	//授权失败
	{
		path: '/authResult/fail',
		component: () => import('@/views/authResult/fail'),
		hidden: true
	},
	//授权失败2.0
	{
		path: '/authResult/fail2',
		component: () => import('@/views/authResult/fail2'),
		hidden: true
	},
	//授权失败3.0
	{
		path: '/authResult/fail3',
		component: () => import('@/views/authResult/fail3'),
		hidden: true
	},
	//财务中心
	{
		path: '/assets',
		component: Layout,
		meta: {
			title: 'assets'
		},
		hidden: true,
		children: [
			//服务费账单
			// {
			// 	path: '/assets/myAccount',
			// 	component: () => import('@/views/assets/myAccount'),
			// 	name: 'myAccount',
			// 	hidden: true,
			// 	meta: {
			// 		title: 'myAccount',
			// 		icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/caiwuzhongxin/fuwufeizhangdan.png',
			// 		active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/fuwufeizhangdan.png'
			// 	}
			// },
			//供货平台账户
			{
				path: '/assets/suplatAccount',
				component: () => import('@/views/assets/suplatAccount'),
				name: 'suplatAccount',
				hidden: true,
				meta: {
					title: 'suplatAccount',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/caiwuzhongxin/gonghuopingtaizhangdan.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/gonghuopingtaizhangdan.png'
				}
			},
	
			// {
			// 	path: '/distribution/withDrawal',
			// 	component: () => import('@/views/distribution/withDrawal'),
			// 	name: 'withDrawal',
			// 	meta: {
			// 		title: 'withDrawal',
			// 		// icon: 'goods'
			// 	}
			// },
			
			//充值记录
			{
				path: '/assets/payRecord',
				component: () => import('@/views/assets/payRecord'),
				name: 'payRecord',
				hidden: true,
				meta: {
					title: 'payRecord'
					// icon: 'goods'
				}
			},
			
			// 分销采购单和分销供货单，页面美化完，中途弃用
			// {
			// 	path: '/assets/purchaseSettle',
			// 	component: () => import('@/views/assets/purchaseSettle'),
			// 	name: 'purchaseSettle',
			// 	meta: {
			// 		title: 'purchaseSettle',
			// 	}
			// },
			// {
			// 	path: '/assets/suplierSettle',
			// 	component: () => import('@/views/assets/suplierSettle'),
			// 	name: 'suplierSettle',
			// 	meta: {
			// 		title: 'suplierSettle',
			// 	}
			// }
		]
	},
	//这里配置完需要去TagsView里特殊判断
	//充值服务费
	{
		path: '/myAccountparent',
		component: Layout,
		meta: {
			title: 'myAccountparent',
		},
		children: [{
			path: '/assets/myAccount',
			component: () => import('@/views/assets/myAccount'),
			name: 'myAccount',
			// hidden: true,
			meta: {
				title: 'myAccount',
				icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/caiwuzhongxin/fuwufeizhangdan.png',
				active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/fuwufeizhangdan.png'
			}
		},

		]
	},
	//我的推荐奖励
	{
		path: '/myrecommended',
		component: Layout,
		meta: {
			title: 'myrecommended',
		},
		children:[
			//我的推荐奖励
			{
				path: '/assets/myrecommended/index',
				component: () => import('@/views/assets/myrecommended/index'),
				name: 'myrecommendedindex',
				// hidden: true,
				meta: {
					title: 'myrecommendedinex',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/caiwuzhongxin/tixianzhongxin.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tixianzhongxin.png'
					// icon: 'goods'
				}
			},
			//推荐奖励提现
			{
				path: '/assets/myrecommended/withdrawal',
				component: () => import('@/views/assets/myrecommended/withdrawal'),
				name: 'myrecommendedwithdrawal',
				hidden: true,
				meta: {
					title: 'myrecommendedwithdrawal'
					// icon: 'goods'
				}
			},
		]
	},
	//短信账户
	{
		path: '/smsaccount',
		component: Layout,
		meta: {
			title: 'smsaccount',
		},
		children: [{
				path: '/smsaccount/smsaccountindex',
				component: () => import('@/views/login/smsaccount'),
				name: 'smsaccountindex',
				// hidden:true,
				meta: {
					title: 'smsaccountindex',
				}
			},

		]
	},
	// }
	
	
]

//固定放到首页的路由， 从asyncRouterMap里摘取一些放在此处
export const homeRouter = [
	{
		title: '流量导入',
		icon: 'https://cdn.dkycn.cn/images/melyshop/home-nav-icon/liuliangdaoru.png',
		children: [
			{
				name: 'batchAddFriend',
				title: '批量加好友'
			},
			{
				name: 'friendsfission',
				title: '客户裂变'
			},
		]
	},
	{
		title: '私域沉淀',
		icon: 'https://cdn.dkycn.cn/images/melyshop/home-nav-icon/siyuchendian.png',
		children: [
			{
				name: 'code',
				title: '小程序码'
			},
			// {
			// 	name: 'GroupLiveCode',
			// 	title: '客户群活码'
			// },
			{
				name: 'channel',
				title: '渠道推广码'
			},
		]
	},
	{
		title: '营销转化',
		icon: 'https://cdn.dkycn.cn/images/melyshop/home-nav-icon/yingxiaozhuanhua.png',
		children: [
			// {
			// 	name: 'vipLevel',
			// 	title: '会员等级管理'
			// },
			// {
			// 	name: 'integralMall',
			// 	title: '积分商城'
			// },
			// {
			// 	name: 'storedrule',
			// 	title: '余额储值'
			// },
			{
				name: 'coupon',
				title: '优惠券'
			},
			{
				name: 'limitedDiscount',
				title: '限时折扣'
			},
			// {
			// 	name: 'discountActivity',
			// 	title: '满减活动'
			// },
		]
	},
	{
		title: '精细化运营',
		icon: 'https://cdn.dkycn.cn/images/melyshop/home-nav-icon/jingxihuayunying.png',
		children: [
			{
				name: 'tagLibrary',
				title: '标签库'
			},
			{
				name: 'autoAddTag',
				title: '智能标签'
			},
			// {
			// 	name: 'growthValue',
			// 	title: '客户成长值'
			// }
		]
	},
	{
		title: '分享裂变',
		icon: 'https://cdn.dkycn.cn/images/melyshop/home-nav-icon/fenxiangliebian.png',
		children: [
			{
				name: 'sellGroup',
				title: '多人拼团'
			},
			{
				name: 'inquiry',
				title: '分销中心'
			},
			
			// {
			// 	name: 'Assistindex',
			// 	title: '助力领取'
			// },
			// {
			// 	name: 'friendsfission',
			// 	title: '客户裂变'
			// }
		]
	},
	{
		title: '数据分析',
		icon: 'https://cdn.dkycn.cn/images/melyshop/home-nav-icon/shujufenxi.png',
		children: [
			{
				name: 'PageAnalysis',
				title: '流量分析'
			},
			// {
			// 	name: 'productDealAnaly',
			// 	title: '商品交易分析'
			// },
			// {
			// 	name: 'marketAnalysis',
			// 	title: '营销交易分析'
			// },
			{
				name: 'businessAnalysis',
				title: '交易分析'
			}
		]
	},
	{
		title: '常用功能',
		icon: 'https://cdn.dkycn.cn/images/melyshop/home-nav-icon/changyonggongneng.png',
		children: [
			{
				name: 'mallPagemange',
				title: '商城装修'
			},
			{
				name: 'goodsList',
				title: '商品管理'
			},
			{
				name: 'orderMange',
				title: '订单管理'
			},
			{
				name: 'staffMange',
				title: '员工管理'
			},
			// {
			// 	name: 'roleManage',
			// 	title: '角色权限设置'
			// }
		]
	},
]

export default new Router({
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRouterMap
})

// 异步路由表，需判断用户角色权限

// if asyncRouterMap.children.length === 1 ,表示是没有子菜单的菜单,asyncRouterMap.children[0]的信息就显示在根菜单上
// 如果设置了 alwaysShow: true ,不管子菜单的数量是1还是更多,都显示为根菜单
export const asyncRouterMap = [
	//商城中心
	{
		path: '/shopCenter',
		component: Layout,
		meta: {
			title: 'shopCenter'
		},
		children: [
			//商品模块
			{
				path: '/goods',
				component: AppMain,
				meta: {
					title: 'goodsName',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shangchengzhongxin/shangpin.png'
				},
				children: [{
						path: '/goods/goodsList',
						component: () => import('@/views/goods/goodsList'),
						name: 'goodsList',
						meta: {
							title: 'goodsList',
							// icon: 'goods'
						}
					},
					{
						path: '/goods/creatGoodDetails',
						component: () => import('@/views/goods/creatGoodDetails'),
						hidden: true,
						name: 'creatGoodDetails',
						meta: {
							title: 'creatGoodDetails',
							// icon: 'goods'
						},
			
					},
					//一键铺货
					{
						path: '/goods/keyproline',
						component: () => import('@/views/goods/keyproline'),
						name: 'keyproline',
						meta: {
							title: 'keyproline',
							// icon: 'goods'
						}
					},
					{
						path: '/goods/creatGoodDetailsAgain',
						component: () => import('@/views/goods/creatGoodDetailsAgain'),
						hidden: true,
						name: 'creatGoodDetailsAgain',
						meta: {
							title: 'creatGoodDetailsAgain',
						},
					},
					{
						path: '/goods/goodGroup',
						component: () => import('@/views/goods/goodGroup'),
						name: 'goodGroup',
						meta: {
							title: 'goodGroup',
							// icon: 'goods'
						}
					},
					{
						path: '/goods/goodsBrand',
						component: () => import('@/views/goods/goodsBrand'),
						name: 'goodsBrand',
						meta: {
							title: 'goodsBrand',
							// icon: 'goods'
						}
					},
					{
						path: '/goods/shopattribute',
						component: () => import('@/views/goods/shopattribute/index'),
						name: 'shopattribute',
						meta: {
							title: 'shopattribute',
						}
					},
					{
						path: '/goods/remindArrival',
						component: () => import('@/views/goods/remindArrival'),
						name: 'remindArrival',
						meta: {
							title: 'remindArrival',
							// icon: 'goods'
						}
					},
					{
						path: '/goods/goodsExport',
						component: () => import('@/views/goods/goodsExport'),
						name: 'goodsExport',
						meta: {
							title: 'goodsExport',
							// icon: 'goods'
						}
					},
					// 供货商品	
					{
						path: '/goods/supplyGoods',
						component: () => import('@/views/goods/supplyGoods'),
						name: 'supplyGoods',
						meta: {
							title: 'supplyGoods'
						}
					},
					{
						path: '/goods/supplyMarket',
						component: () => import('@/views/goods/supplyMarket'),
						name: 'supplyMarket',
						meta: {
							title: 'supplyMarket'
						}
					},
					{
						path: '/goods/supplyDetail',
						component: () => import('@/views/goods/supplyDetail'),
						hidden: true,
						name: 'supplyDetail',
						meta: {
							title: 'supplyDetail'
						}
					},
					// {
					// 	path: '/goods/supplyShop',
					// 	component: () => import('@/views/goods/supplyShop'),
					// 	hidden: true,
					// 	name: 'supplyShop',
					// 	meta: {
					// 		title: 'supplyShop'
					// 	}
					// },
					{
						path: '/goods/MeterialManagelist',
						component: () => import('@/views/goods/MeterialManagelist'),
						name: 'MeterialManagelist',
						hidden: true,
						meta: {
							title: 'MeterialManagelist'
						}
					},
					{
						path: '/goods/AddMeterialist',
						component: () => import('@/views/goods/AddMeterialist'),
						name: 'AddMeterialist',
						hidden: true,
						meta: {
							title: 'AddMeterialist'
						}
					},
					{
						path: '/goods/batchChangePrice',
						component: () => import('@/views/goods/batchChangePrice'),
						name: 'batchChangePrice',
						meta: {
							title: 'batchChangePrice'
						}
					},
			
				]
			},
			
			//订单模块
			{
				path: '/order',
				component: AppMain,
				meta: {
					title: 'order',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shangchengzhongxin/dingdan.png'
				},
				children: [{
						path: '/order/orderMange',
						component: () => import('@/views/order/orderMangepage/orderMange'),
						name: 'orderMange',
						meta: {
							title: 'orderMange',
						}
					},
			
					{
						path: '/order/distributeOrder/distributePage',
						component: () => import('@/views/order/distributeOrder/distributePage'),
						name: 'distributePage',
						meta: {
							title: 'distributePage',
						}
					},
					{
						path: '/order/refundAfterSale/index',
						component: () => import('@/views/order/refundAfterSale/index'),
						name: 'refundAfterSale',
						meta: {
							title: 'refundAfterSale',
						}
					},
					{
						path: '/order/refundAfterSale/refundDetails',
						component: () => import('@/views/order/refundAfterSale/refundDetails'),
						name: 'refundDetails',
						hidden: true,
						meta: {
							title: 'refundDetails',
						}
					},
					{
						path: '/order/supplyRefund/index',
						component: () => import('@/views/order/supplyRefund/index'),
						name: 'supplyRefund',
						meta: {
							title: 'supplyRefund',
						}
					},
					{
						path: '/order/supplyRefund/supplyRefundDetails',
						component: () => import('@/views/order/supplyRefund/supplyRefundDetails'),
						name: 'supplyRefundDetails',
						hidden: true,
						meta: {
							title: 'supplyRefundDetails',
						}
					},
					{
						path: '/order/shippingTemplates',
						component: () => import('@/views/setting/frightModelpage/shippingTemplates'),
						name: 'shippingTemplates',
						meta: {
							title: 'shippingTemplates',
						}
					},
					// 物流助手
					// 批量打单
					{
						path: '/order/bathOrdersPrant',
						component: () => import('@/views/treeMenu/threeMenupage'),
						// name: 'bathOrdersPrant',
						meta: {
							title: 'bathOrdersPrant',
							// icon: 'goods'
						},
						children: [
							// 批量打单
							{
								path: '/order/logisticsAssistant/batchOrders',
								component: () => import('@/views/order/logisticsAssistant/batchOrders'),
								name: 'batchOrders',
								meta: {
									title: 'batchOrders',
								}
							},
							// 打单记录			
							{
								path: '/order/logisticsAssistant/typingRecord',
								component: () => import('@/views/order/logisticsAssistant/typingRecord'),
								name: 'typingRecord',
								meta: {
									title: 'typingRecord',
								}
							},
							// 绑定物流
							{
								path: '/order/logisticsAssistant/bindingLogistics',
								component: () => import('@/views/order/logisticsAssistant/bindingLogistics'),
								name: 'bindingLogistics',
								meta: {
									title: 'bindingLogistics',
								}
							},
							// 打印员管理
							{
								path: '/order/logisticsAssistant/printerManagement',
								component: () => import('@/views/order/logisticsAssistant/printerManagement'),
								name: 'printerManagement',
								meta: {
									title: 'printerManagement',
								}
							},
							// 发货地址
							{
								path: '/order/logisticsAssistant/shipAddress',
								component: () => import('@/views/order/logisticsAssistant/shipAddress'),
								name: 'shipAddress',
								meta: {
									title: 'shipAddress',
								}
							},
			
						]
					},
					//快递助手
					{
						path: '/order/ExpressAssistant',
						component: () => import('@/views/order/ExpressAssistant'),
						name: 'ExpressAssistant',
						meta: {
							title: 'ExpressAssistant',
						}
					},
					{
						path: '/order/orderDetailpage',
						component: () => import('@/views/order/orderMangepage/orderDetailpage'),
						name: 'orderDetailpage',
						hidden: true,
						meta: {
							title: 'orderDetailpage',
						}
					},
					{
						path: '/order/distributeOrder/distributeDetail',
						component: () => import('@/views/order/distributeOrder/distributeDetail'),
						name: 'distributeDetail',
						hidden: true,
						meta: {
							title: 'distributeDetail',
						}
					},
					{
						path: '/order/refundAfterSale/refundDetails',
						component: () => import('@/views/order/refundAfterSale/refundDetails'),
						name: 'refundDetails',
						hidden: true,
						meta: {
							title: 'refundDetails',
						}
					},
					{
						path: '/order/addTemplate',
						component: () => import('@/views/setting/frightModelpage/addTemplate'),
						name: 'addTemplate',
						hidden: true,
						meta: {
							title: 'addTemplate',
						}
					},
					{
						path: '/goods/comment',
						component: () => import('@/views/goods/comment'),
						name: 'comment',
						meta: {
							title: 'comment',
							// icon: 'goods'
						}
					},
					{
						path: '/goods/commentDetail',
						component: () => import('@/views/goods/commentDetail'),
						name: 'commentDetail',
						hidden: true,
						meta: {
							title: 'commentDetail',
							// icon: 'goods'
						}
					},
					{
						path: '/order/routuanOrder',
						component: () => import('@/views/order/orderMangepage/routuanOrder'),
						name: 'routuanOrder',
						meta: {
							title: 'routuanOrder',
						}
					},
					{
						path: '/order/wanliniu',
						component: () => import('@/views/order/wanliniu/index'),
						name: 'wanliniu',
						meta: {
							title: 'wanliniu',
						}
					},
					{
						path: '/order/wangdiantong',
						component: () => import('@/views/order/wangdiantong/index'),
						name: 'wangdiantong',
						meta: {
							title: 'wangdiantong',
						}
					},
					{
						path: '/order/jushuitan',
						component: () => import('@/views/order/jushuitan/index'),
						name: 'jushuitan',
						meta: {
							title: 'jushuitan',
						}
					},
					{
						path: '/order/logisticscompany',
						component: () => import('@/views/order/logisticscompany/index'),
						name: 'logisticscompany',
						meta: {
							title: 'logisticscompany',
						}
					},
				]
			},
			
			// 装修
			{
				path: '/mallPage',
				component: AppMain,
				meta: {
					title: 'mallPage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shangchengzhongxin/zhuangxiu.png'
				},
				children: [{
						path: '/mallPage/mallPagemange',
						component: () => import('@/views/mallPage/mallPagemange'),
						name: 'mallPagemange',
						meta: {
							title: 'mallPagemange',
						}
					},
					{
						path: '/mallPage/mallNavconfig',
						component: () => import('@/views/mallPage/mallNavconfig'),
						name: 'mallNavconfig',
						meta: {
							title: 'mallNavconfig',
						}
					},
					{
						path: '/mallPage/mallFooterConfig',
						component: () => import('@/views/mallPage/mallFooterConfig'),
						name: 'mallFooterConfig',
						meta: {
							title: 'mallFooterConfig',
						}
					},
					{
						path: '/mallPage/personalMall',
						component: () => import('@/views/mallPage/personalMall'),
						name: 'personalMall',
						meta: {
							title: 'personalMall',
						}
					},
					{
						path: '/mallPage/mallPageDetails',
						component: () => import('@/views/mallPage/mallPageDetails'),
						name: 'mallPageDetails',
						hidden: true,
						meta: {
							title: 'mallPageDetails',
						}
					},
					{
						path: '/mallPage/barrageAd',
						component: () => import('@/views/mallPage/barrageAd'),
						name: 'barrageAd',
						meta: {
							title: 'barrageAd',
						}
					},
					{
						path: '/mallPage/paySuccess',
						component: () => import('@/views/mallPage/paySuccess'),
						name: 'paySuccess',
						meta: {
							title: 'paySuccess',
						}
					},
					{
						path: '/mallPage/goodsdetails',
						component: () => import('@/views/mallPage/goodsdetails/index'),
						name: 'goodsdetails',
						meta: {
							title: 'goodsdetails',
						}
					},
			
				],
			},
		
			
		]
	},
	{
		path: '/sellManage',
		component: Layout,
		meta: {
			title: 'sellManage'
		},
		children:[
			{
				path: '/cluesManage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'cluesManage',
					icon: 'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/menuIcon/xiaoshouguanli/xiansuoguanli.png'
				},
				children:[
					{
						path: '/cluesManage/clueData',
						component: () => import('@/views/sellManage/cluesManage/clueData'),
						name: 'clueData',
						meta: {
							title: 'clueData',
						},
					},
					{
						path: '/cluesManage/clueData/edit',
						component: () => import('@/views/sellManage/cluesManage/editClue'),
						name: 'clueDataEdit',
						hidden: true,
						meta: {
							title: 'clueDataEdit',
						},
					},
					{
						path: '/cluesManage/clueData/details',
						component: () => import('@/views/sellManage/cluesManage/clueDataDetails'),
						name: 'clueDataDetails',
						hidden: true,
						meta: {
							title: 'clueDataDetails',
						},
					},
					{
						path: '/cluesManage/sellClue',
						component: () => import('@/views/sellManage/cluesManage/sellClue'),
						name: 'sellClue',
						meta: {
							title: 'sellClue',
						},
					},
					{
						path: '/cluesManage/clueSource',
						component: () => import('@/views/sellManage/cluesManage/clueSource'),
						name: 'clueSource',
						meta: {
							title: 'clueSource',
						},
					},
					{
						path: '/cluesManage/clueRecycleBin',
						component: () => import('@/views/sellManage/cluesManage/clueRecycleBin'),
						name: 'clueRecycleBin',
						meta: {
							title: 'clueRecycleBin',
						},
					},
				]
			},
			{
				path: '/sellCustomer/customerManagePage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'sellCustomerManagePage',
					icon: 'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/menuIcon/xiaoshouguanli/kehuguanli.png'
				},
				children:[
					{
						path: '/Customer/CustomerList',
						component: () => import('@/views/sellManage/customerManagePage/CustomerList/index'),
						name: 'CustomerList',
						meta: {
							title: 'CustomerList',
						},
					},
					{
						path: '/Customer/SellCustomerDetail',
						component: () => import('@/views/sellManage/customerManagePage/CustomerList/detail'),
						name: 'SellCustomerDetail',
						hidden:true,
						meta: {
							title: 'SellCustomerDetail',
						},
					},
					{
						path: '/Customer/SellCustomerEdit',
						component: () => import('@/views/sellManage/customerManagePage/CustomerList/edit'),
						name: 'SellCustomerEdit',
						hidden:true,
						meta: {
							title: 'SellCustomerEdit',
						},
					},
					{
						path: '/Customer/CustomerType',
						component: () => import('@/views/sellManage/customerManagePage/CustomerType/index'),
						name: 'CustomerType',
						meta: {
							title: 'CustomerType',
						},
					},
					{
						path: '/Customer/CustomerSource',
						component: () => import('@/views/sellManage/customerManagePage/CustomerSource/index'),
						name: 'CustomerSource',
						meta: {
							title: 'CustomerSource',
						},
					},
					{
						path: '/Customer/CustomerRecycleBin',
						component: () => import('@/views/sellManage/customerManagePage/CustomerRecycleBin/index'),
						name: 'CustomerRecycleBin',
						meta: {
							title: 'CustomerRecycleBin',
						},
					},
				]
			}
		]
	},
	{
		path: '/XTTagLibrary',
		component: Layout,
		meta: {
			title: 'XTTagLibrary'
		},
		children:[
			{
				path: '/TagLibraryList',
				component: () => import('@/views/XTTagLibrary/TagLibraryList/index'),
				name:'TagLibraryList',
				meta: {
					title: 'TagLibraryList',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/02/XTbiaoqianku2.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/02/XTbiaoqianku1.png'
				},
			}
		]
	},
	//客户运营
	{
		path: '/Customer',
		component: Layout,
		meta: {
			title: 'Customer'
		},
		children: [
			
			
			//客户管理
			{
				path: '/Customer/customerManagePage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'customerManagePage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/kehuyunying/kehuguanli.png'
				},
				children: [

					{
						path: '/Customer/CustomerSearch',
						component: () => import('@/views/Customer/CustomerSearch'),
						name: 'CustomerSearch',
						meta: {
							title: 'CustomerSearch',
						},

					},
					{
						path: '/Customer/vipLevel',
						component: () => import('@/views/Customer/vipLevel'),
						name: 'vipLevel',
						meta: {
							title: 'vipLevel',
						}
					},
					{
						path: '/Customer/growthValue',
						component: () => import('@/views/Customer/growthValue'),
						name: 'growthValue',
						meta: {
							title: 'growthValue',
						}
					},
					{
						path: '/Customer/tagLibrary',
						component: () => import('@/views/Customer/tagLibrary'),
						name: 'tagLibrary',
						meta: {
							title: 'tagLibrary',
						}
					},
					
					//客户设置
					{
						path: '/Customer/customerset',
						component: () => import('@/views/Customer/customerset'),
						name: 'customerset',
						meta: {
							title: 'customerset',
						}
					},
					//黑名单
					{
						path: '/Customer/blacklist',
						component: () => import('@/views/Customer/blacklist'),
						name: 'blacklist',
						meta: {
							title: 'blacklist',
						}
					},

				]
			},
			
			//客户裂变
			// {
			// 	path: '/Customer/customerFission',
			// 	component: () => import('@/views/treeMenu/threeMenupage'),
			// 	meta: {
			// 		title: 'customerFission',
			// 		icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/kehuyunying/kehuguanli.png'
			// 	},
			// 	children: [
			// 		{
			// 			path: '/Customer/agentFission',
			// 			component: () => import('@/views/Customer/AuditReply'),
			// 			name: 'agentFission',
			// 			meta: {
			// 				title: 'agentFission',
			// 			}
			// 		},
			// 		{
			// 			path: '/Customer/agentFission',
			// 			component: () => import('@/views/Customer/AuditReply'),
			// 			name: 'agentFission',
			// 			meta: {
			// 				title: 'agentFission',
			// 			}
			// 		},
			// 	]
			// },
			
			//客户沉淀
			{
				path: '/Customer/customerMaintainPage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'customerMaintainPage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/kehuyunying/kehuchendian.png'
				},
				children: [{
						path: '/Customer/pointsManager',
						component: () => import('@/views/Customer/pointsManager'),
						name: 'pointsManager',
						meta: {
							title: 'pointsManager',
						}
					}, {
						path: '/Customer/customerTag',
						component: () => import('@/views/Customer/customerTag'),
						name: 'customerTag',
						meta: {
							title: 'customerTag',
						}
					}, {
						path: '/Customer/calendarCheckIn/index',
						component: () => import('@/views/Customer/calendarCheckIn/index'),
						name: 'calendarCheckIn',
						meta: {
							title: 'calendarCheckIn'
						}
					},
					{
						path: '/Customer/integralMall/index',
						component: () => import('@/views/Customer/integralMall/index'),
						name: 'integralMall',
						meta: {
							title: 'integralMall',
						}
					},
					{
						path: '/Customer/issueCoupon/index',
						component: () => import('@/views/Customer/issueCoupon/index'),
						name: 'issueCoupon',
						meta: {
							title: 'issueCoupon',
						}
					},
					{
						path: '/Customer/pointsCode/index',
						component: () => import('@/views/Customer/pointsCode/index'),
						name: 'pointsCode',
						meta: {
							title: 'pointsCode',
						}
					},
					{
						path: '/Customer/pointsCode/data',
						component: () => import('@/views/Customer/pointsCode/data'),
						name: 'pointsCodedata',
						hidden: true,
						meta: {
							title: 'pointsCodedata',
						}
					},
					{
						path: '/Customer/pointsCode/edit',
						component: () => import('@/views/Customer/pointsCode/edit'),
						name: 'pointsCodeedit',
						hidden: true,
						meta: {
							title: 'pointsCodeedit',
						}
					},
				]
			},
			
			
			//储值中心
			{
				path: '/Customer/balanceCenterPage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'balanceCenterPage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/kehuyunying/chuzhizhongxin.png'
				},
				children: [
					//储值规则
					{
						path: '/Customer/storedrule',
						component: () => import('@/views/Customer/storedrule'),
						name: 'storedrule',
						meta: {
							title: 'storedrule',
						}
					},
					//储值礼包
					{
						path: '/Customer/storedPackage',
						component: () => import('@/views/Customer/storedPackage/index'),
						name: 'storedPackage',
						meta: {
							title: 'storedPackage',
						}
					},
					//余额明细
					{
						path: '/Customer/storedbalanceDetail',
						component: () => import('@/views/Customer/storedbalanceDetail'),
						name: 'storedbalanceDetail',
						meta: {
							title: 'storedbalanceDetail',
						}
					},
				]

			},

			//认证审核
			{
				path: '/Customer/AuditReply',
				component: () => import('@/views/Customer/AuditReply'),
				name: 'AuditReply',
				meta: {
					title: 'AuditReply',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/kehuyunying/renzhengshenhe.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/renzhengshenhe.png'
				}
			},

			{
				path: '/Customer/pointsRulesEdit',
				component: () => import('@/views/Customer/pointsRulesEdit'),
				name: 'pointsRulesEdit',
				hidden: true,
				meta: {
					title: 'pointsRulesEdit',
				}
			},
			{
				path: '/Customer/issueCoupon/editIssueCoupon',
				component: () => import('@/views/Customer/issueCoupon/editIssueCoupon'),
				name: 'editIssueCoupon',
				hidden: true,
				meta: {
					title: 'editIssueCoupon',
				}
			},
			{
				path: '/Customer/integralMall/editIntegralExchange',
				component: () => import('@/views/Customer/integralMall/editIntegralExchange'),
				name: 'editIntegralExchange',
				hidden: true,
				meta: {
					title: 'editIntegralExchange',
				}
			},
			{
				path: '/Customer/vipEdit',
				component: () => import('@/views/Customer/vipEdit'),
				name: 'vipEdit',
				hidden: true,
				meta: {
					title: 'vipEdit',
				}
			}, 
			{
				path: '/Customer/CustomerDetail',
				component: () => import('@/views/Customer/CustomerDetail'),
				name: 'CustomerDetail',
				hidden: true,
				meta: {
					title: 'CustomerDetail',
				}
			}, 
			{
				path: '/Customer/storedPackageEdit',
				component: () => import('@/views/Customer/storedPackage/edit'),
				name: 'storedPackageEdit',
				hidden: true,
				meta: {
					title: 'storedPackageEdit',
				}
			},

		]
	},
	
	//店铺营销
	{
		path: '/market',
		component: Layout,
		meta: {
			title: 'market'
		},
		children: [
			
			//基础营销
			{
				path: '/basemarket',
				component: AppMain,
				meta: {
					title: 'basemarket',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/dianpuyingxiao/jichuyingxiao.png'
				},
				children:[
					//优惠券
					{
						path: '/market/coupon/index',
						component: () => import('@/views/market/coupon/index'),
						name: 'coupon',
						meta: {
							title: 'coupon',
						}
					},
					//满减送
					{
						path: '/market/discountActivity/index',
						component: () => import('@/views/market/discountActivity/index'),
						name: 'discountActivity',
						meta: {
							title: 'discountActivity',
						}
					},
					//限时折扣
					{
						path: '/market/limitedDiscount',
						component: () => import('@/views/market/limitedDiscount'),
						name: 'limitedDiscount',
						meta: {
							title: 'limitedDiscount',
							// icon: 'goods'
						}
					},
					//包邮工具
					{
						path: '/market/frightTool',
						component: () => import('@/views/market/frightToolpage/frightTool'),
						name: 'frightTool',
						meta: {
							title: 'frightTool',
							// icon: 'goods'
						}
					},
					//打包一口价
					{
						path: '/market/packPrice/index',
						component: () => import('@/views/market/packPrice/index'),
						name: 'packPrice',
						meta: {
							title: 'packPrice',
						}
					},
					//订单立返
					{
						path: '/market/orderCashBack/index',
						component: () => import('@/views/market/orderCashBack/index'),
						name: 'orderCashBack',
						meta: {
							title: 'orderCashBack',
						}
					},
					//支付有礼
					{
						path: '/market/payGifts',
						component: () => import('@/views/market/payGifts/index'),
						name: 'payGifts',
						meta: {
							title: 'payGifts',
							// icon: 'goods'
						}
					},
				]
			},
			
			//拉新裂变
			{
				path: '/newUserFission',
				component: AppMain,
				meta: {
					title: 'newUserFission',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/dianpuyingxiao/laxinliebian.png'
				},
				children:[
					//多人拼团
					{
						path: '/market/sellGroupBox/sellGroup',
						component: () => import('@/views/market/sellGroupBox/sellGroup'),
						name: 'sellGroup',
						meta: {
							title: 'sellGroup',
						}
					},
					//助力领取
					{
						path: '/market/Assistoreceive/Assistindex',
						component: () => import('@/views/market/Assistoreceive/Assistindex'),
						name: 'Assistindex',
						meta: {
							title: 'Assistindex',
						}
					},
					//拼团抢购
					{
						path: '/market/spellgroupbuy',
						component: () => import('@/views/market/spellgroupbuy/index'),
						name: 'spellgroupbuy',
						meta: {
							title: 'spellgroupbuy',
						}
					},
					{
						path: '/market/spellgroupbuy/details',
						component: () => import('@/views/market/spellgroupbuy/details'),
						name: 'spellgroupbuydetails',
						hidden:true,
						meta: {
							title: 'spellgroupbuydetails',
						}
					},
					//助力pro
					{
						path: '/market/helpPro',
						component: () => import('@/views/market/helpPro/index'),
						name: 'helpPro',
						meta: {
							title: 'helpPro',
						}
					},
					{
						path: '/market/helpPro/details',
						component: () => import('@/views/market/helpPro/details'),
						name: 'helpProdetails',
						hidden:true,
						meta: {
							title: 'helpProdetails',
						}
					},
				],
			},
			
			//社群营销
			{
				path: '/market/association',
				component: () => import('@/views/treeMenu/threeMenupage'),
				// name: 'association',
				meta: {
					title: 'association',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/dianpuyingxiao/shequnyingxiao.png'
				},
				children: [
					// 社群专享
					{
						path: '/market/association/associateShare',
						component: () => import('@/views/market/association/associateShare'),
						name: 'associateShare',
						meta: {
							title: 'associateShare',
						}
					},
					// 社群配置		
					{
						path: '/market/association/associateConfig',
						component: () => import('@/views/market/association/associateConfig'),
						name: 'associateConfig',
						meta: {
							title: 'associateConfig',
						}
					},
					// 社群数据
					{
						path: '/market/association/associateData',
						component: () => import('@/views/market/association/associateData'),
						name: 'associateData',
						meta: {
							title: 'associateData',
						}
					},
					{
						path: '/market/groupShare/shareActivities',
						component: () => import('@/views/market/groupShare/activities'),
						name: 'shareActivities',
						meta: {
							title: 'shareActivities',
						}
					},
					{
						path: '/market/groupShare/shareSet',
						component: () => import('@/views/market/groupShare/setting'),
						name: 'shareSet',
						meta: {
							title: 'shareSet',
						}
					},
					{
						path: '/market/groupShare/shareList',
						component: () => import('@/views/market/groupShare/list'),
						name: 'shareList',
						meta: {
							title: 'shareList',
						}
					}
				
				]
			},
			
			//配套工具
			{
				path: '/market/someTools',
				component: () => import('@/views/treeMenu/threeMenupage'),
				// name: 'association',
				meta: {
					title: 'someTools',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/dianpuyingxiao/peitaogongju.png'
				},
				children: [
					//渠道推广码
					{
						path: '/market/channel',
						component: () => import('@/views/market/channel/index'),
						name: 'channel',
						meta: {
							title: 'channel',
							// icon: 'goods'
						}
					},
					//转盘抽奖
					{
						path: '/market/lottery',
						component: () => import('@/views/market/lottery/index'),
						name: 'lottery',
						meta: {
							title: 'lottery',
							// icon: 'goods'
						}
					},
					//问卷调查
					{
						path: '/market/questionnaire/questionlist',
						component: () => import('@/views/market/questionnaire/questionlist'),
						name: 'questionlist',
						// hidden: true,
						meta: {
							title: 'questionlist',
						}
					},
					{
						path: '/market/message/manageList',
						component: () => import('@/views/market/message/manageList.vue'),
						name: 'manageList',
						meta: {
							title: 'manageList',
						}
					},
					// {
					// 	path: '/market/message/groupList',
					// 	component: () => import('@/views/market/message/groupList.vue'),
					// 	name: 'groupList',
					// 	meta: {
					// 		title: 'groupList',
					// 	}
					// },
					//主图水印
					{
						path: '/market/imgWatermark/list',
						component: () => import('@/views/market/imgWatermark/list.vue'),
						name: 'imgWatermarklist',
						meta: {
							title: 'imgWatermarklist',
						}
					},
					{
						path: '/market/imgWatermark/detail',
						component: () => import('@/views/market/imgWatermark/detail.vue'),
						name: 'imgWatermarkdetail',
						hidden:true,
						meta: {
							title: 'imgWatermarkdetail',
						}
					},
					//短视频带货
					{
						path: '/market/shortvideo/index',
						component: () => import('@/views/market/shortvideo/index.vue'),
						name: 'shortvideoindex',
						meta: {
							title: 'shortvideoindex',
						}
					},
					{
						path: '/market/shortvideo/detail',
						component: () => import('@/views/market/shortvideo/detail.vue'),
						name: 'shortvideodetail',
						hidden:true,
						meta: {
							title: 'shortvideodetail',
						}
					},
					{
						path: '/market/AITongueExamination/index',
						component: () => import('@/views/market/AITongueExamination/index.vue'),
						name: 'AITongueExamination',
						meta: {
							title: 'AITongueExamination',
						}
					},
					
					//测一测
					{
						path: '/market/takeTest/takeTest',
						component: () => import('@/views/market/takeTest/index.vue'),
						name: 'takeTest',
						meta: {
							title: 'takeTest',
						}
					},
					{
						path: '/market/takeTest/takeTestEdit',
						component: () => import('@/views/market/takeTest/edit.vue'),
						name: 'takeTestEdit',
						hidden:true,
						meta: {
							title: 'takeTestEdit',
						}
					},
					{
						path: '/market/takeTest/takeTestRecord',
						component: () => import('@/views/market/takeTest/record.vue'),
						name: 'takeTestRecord',
						hidden:true,
						meta: {
							title: 'takeTestRecord',
						}
					},
				]
			},
			// 群分享
			// {
			// 	path: '/market/groupShare',
			// 	component: () => import('@/views/treeMenu/threeMenupage'),
			// 	meta: {
			// 		title: 'groupShare',
			// 		icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/dianpuyingxiao/peitaogongju.png'
			// 	},
			// 	children: [
					
			// 	]
			// },
			
			
			{
				path: '/market/coupon/editDiscountTicketDetail',
				component: () => import('@/views/market/coupon/editDiscountTicketDetail'),
				name: 'editDiscountTicketDetail',
				hidden: true,
				meta: {
					title: 'editDiscountTicketDetail',
				}
			},
			{
				path: '/market/discountActivity/editOverMoney',
				component: () => import('@/views/market/discountActivity/editOverMoney'),
				name: 'editOverMoney',
				hidden: true,
				meta: {
					title: 'editOverMoney',
				}
			},
			{
				path: '/market/addDiscount',
				component: () => import('@/views/market/addDiscount'),
				name: 'addDiscount',
				hidden: true,
				meta: {
					title: 'addDiscount',
					// icon: 'goods'
				}
			},
			{
				path: '/market/packPrice/editPriceDetaile',
				component: () => import('@/views/market/packPrice/editPriceDetaile'),
				name: 'editPriceDetaile',
				hidden: true,
				meta: {
					title: 'editPriceDetaile',
				}
			},
			{
				path: '/market/orderCashBack/editCashDetaile',
				component: () => import('@/views/market/orderCashBack/editCashDetaile'),
				name: 'editCashDetaile',
				hidden: true,
				meta: {
					title: 'editCashDetaile',
				}
			},
			{
				path: '/market/addFeighttool',
				component: () => import('@/views/market/frightToolpage/addFeighttool'),
				name: 'addFeighttool',
				hidden: true,
				meta: {
					title: 'addFeighttool',
					// icon: 'goods'
				}
			},
			{
				path: '/market/lookFeighttool',
				component: () => import('@/views/market/frightToolpage/lookFeighttool'),
				name: 'lookFeighttool',
				hidden: true,
				meta: {
					title: 'lookFeighttool',
					// icon: 'goods'
				}
			},
			
			{
				path: '/market/channelStatistics',
				component: () => import('@/views/market/channel/statistics'),
				name: 'channelStatistics',
				hidden: true,
				meta: {
					title: 'channelStatistics',
					// icon: 'goods'
				}
			},
			
			{
				path: '/market/lottery/editLotteryDetail',
				component: () => import('@/views/market/lottery/editLotteryDetail'),
				name: 'editLotteryDetail',
				hidden: true,
				meta: {
					title: 'editLotteryDetail',
					// icon: 'goods'
				}
			},
			
			{
				path: '/market/payGifts/editPayGiftsDetail',
				component: () => import('@/views/market/payGifts/editPayGiftsDetail'),
				name: 'editPayGiftsDetail',
				hidden: true,
				meta: {
					title: 'editPayGiftsDetail',
					// icon: 'goods'
				}
			},
			{
				path: '/market/payGifts/lookGiftActive',
				component: () => import('@/views/market/payGifts/lookGiftActive'),
				name: 'lookGiftActive',
				hidden: true,
				meta: {
					title: 'lookGiftActive',
					// icon: 'goods'
				}
			},
			
			{
				path: '/market/sellGroupBox/editsellGroupDetail',
				component: () => import('@/views/market/sellGroupBox/editsellGroupDetail'),
				name: 'editsellGroupDetail',
				hidden: true,
				meta: {
					title: 'editsellGroupDetail',
					// icon: 'goods'
				}
			},
			{
				path: '/market/sellGroupBox/looksellGroupActive',
				component: () => import('@/views/market/sellGroupBox/looksellGroupActive'),
				name: 'looksellGroupActive',
				hidden: true,
				meta: {
					title: 'looksellGroupActive',
					// icon: 'goods'
				}
			},
			
			{
				path: '/market/Assistoreceive/Editassistindex',
				component: () => import('@/views/market/Assistoreceive/Editassistindex'),
				name: 'Editassistindex',
				hidden: true,
				meta: {
					title: 'Editassistindex',
				}
			},
			
	
			// 添加、编辑社群专享
			{
				path: '/market/association/EditassociateData',
				component: () => import('@/views/market/association/EditassociateData'),
				name: 'EditassociateData',
				hidden: true,
				meta: {
					title: 'EditassociateData',
				}
			},
	
			// 查看社群专享
			{
				path: '/market/association/lookassociate',
				component: () => import('@/views/market/association/lookassociate'),
				name: 'lookassociate',
				hidden: true,
				meta: {
					title: 'lookassociate',
				}
			},
	
			
	
			// 信息管理
			// {
			// 	path: '/market/messageManage',
			// 	component: () => import('@/views/treeMenu/threeMenupage'),
			// 	meta: {
			// 		title: 'messageManage',
			// 	},
			// 	children: [
			// 	]
			// },
			{
				path: '/market/message/addMessage',
				component: () => import('@/views/market/message/addMessage.vue'),
				name: 'addMessage',
				hidden: true,
				meta: {
					title: 'addMessage',
				}
			},
			{
				path: '/market/groupShare/editShareActivities',
				component: () => import('@/views/market/groupShare/editActivities'),
				name: 'editShareActivities',
				hidden: true,
				meta: {
					title: 'editShareActivities',
				}
			},
	
			//调查问卷
			{
				path: '/market/questionnaire/editQuestionnaire',
				component: () => import('@/views/market/questionnaire/editQuestionnaire'),
				name: 'editQuestionnaire',
				hidden: true,
				meta: {
					title: 'editQuestionnaire',
				},
			},
			// {
			// 	path: '/market/questionnaire',
			// 	component: () => import('@/views/market/questionnaire/questionnaire'),
			// 	name: 'questionnaire',
			// 	meta: {
			// 		title: 'questionnaire',
			// 	},
			// },
			
			// {
			// 	path: '/market/questionnaire',
			// 	component: () => import('@/views/market/questionnaire/questionnaire'),
			// 	// name: 'questionnaire',
			// 	meta: {
			// 		title: 'questionnaire',
			// 	},
			// 	children: [{
			// 		path: '/market/questionnaire/questionlist',
			// 		component: () => import('@/views/market/questionnaire/questionlist'),
			// 		name: 'questionlist',
			// 		// hidden: true,
			// 		meta: {
			// 			title: 'questionlist',
			// 		}
			// 	}, ]
			// },
	
			{
				path: '/market/questionnaire/questionresult',
				component: () => import('@/views/market/questionnaire/questionresult'),
				name: 'questionresult',
				hidden: true,
				meta: {
					title: 'questionresult',
				}
			},


		]
	},
	
	//企微客户
	{
		path: '/Customer/qyWechatOperation',
		component: Layout,
		meta: {
			title: 'qyWechatOperation'
		},
		children: [
			//客户数据概览
			{
				path: '/Customer/dataOverView',
				component: () => import('@/views/Customer/dataOverView/index'),
				name:'dataOverView',
				meta: {
					title: 'dataOverView',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/kehushujugailan.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/kehushujugailan.png'
				},
			},
			//引流获客
			{
				path: '/Customer/flow',
				component: AppMain,
				meta: {
					title: 'flow',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/piliangjiahaoyou.png'
				},
				children: [
					
					//批量加好友
					{
						path: '/Customer/batchAddFriend',
						component: () => import('@/views/Customer/batchAddFriend'),
						name: 'batchAddFriend',
						meta: {
							title: 'batchAddFriend'
						}
					},
					//企微涨粉
					{
						path: '/qyCustomer/risefans',
						component: () => import('@/views/qyCustomer/risefans/index'),
						name: 'risefans',
						meta: {
							title: 'risefans'
						}
					},
					{
						path: '/qyCustomer/risefans/risefansdetail',
						component: () => import('@/views/qyCustomer/risefans/risefansdetail'),
						hidden:true,
						name: 'risefansdetail',
						meta: {
							title: 'risefansdetail'
						}
					},
					{
						path: '/qyCustomer/risefans/risefanssuccess',
						component: () => import('@/views/qyCustomer/risefans/risefanssuccess'),
						hidden:true,
						name: 'risefanssuccess',
						meta: {
							title: 'risefanssuccess'
						}
					},
					//联系我
					{
						path: '/qyCustomer/callme',
						component: () => import('@/views/qyCustomer/callme/index'),
						name: 'callme',
						meta: {
							title: 'callme'
						}
					},
					{
						path: '/qyCustomer/callmeedit',
						component: () => import('@/views/qyCustomer/callme/edit'),
						name: 'callmeedit',
						hidden:true,
						meta: {
							title: 'callmeedit'
						}
					},
					{
						path: '/qyCustomer/callmedata',
						component: () => import('@/views/qyCustomer/callme/data'),
						name: 'callmedata',
						hidden:true,
						meta: {
							title: 'callmedata'
						}
					},
					//好友裂变
					{
						path: '/Customer/friendsfission/friendsfission',
						component: () => import('@/views/Customer/friendsfission/friendsfission'),
						name: 'friendsfission',
						meta: {
							title: 'friendsfission'
						}
					},
					{
						path: '/Customer/friendsfission/friendsfissionEdit',
						component: () => import('@/views/Customer/friendsfission/friendsfissionEdit'),
						name: 'friendsfissionEdit',
						hidden:true,
						meta: {
							title: 'friendsfissionEdit'
						}
					},
					{
						path: '/Customer/friendsfission/friendsfissionData',
						component: () => import('@/views/Customer/friendsfission/friendsfissionData'),
						name: 'friendsfissionData',
						hidden:true,
						meta: {
							title: 'friendsfissionData'
						}
					},
					
					//群裂变
					{
						path: '/Customer/groupfission/groupfission',
						component: () => import('@/views/Customer/groupfission/groupfission'),
						name: 'groupfission',
						meta: {
							title: 'groupfission'
						}
					},
					{
						path: '/Customer/groupfission/groupfissionEdit',
						component: () => import('@/views/Customer/groupfission/groupfissionEdit'),
						name: 'groupfissionEdit',
						hidden:true,
						meta: {
							title: 'groupfissionEdit'
						}
					},
					{
						path: '/Customer/groupfission/groupfissionData',
						component: () => import('@/views/Customer/groupfission/groupfissionData'),
						name: 'groupfissionData',
						hidden:true,
						meta: {
							title: 'groupfissionData'
						}
					},
					{
						path: '/Customer/batchAddFriendRecord',
						component: () => import('@/views/Customer/batchAddFriendRecord'),
						name: 'batchAddFriendRecord',
						hidden: true,
						meta: {
							title: 'batchAddFriendRecord',
						}
					},
					//客户群活码
					{
						path: '/Customer/GroupLiveCode',
						component: () => import('@/views/Customer/GroupLiveCode/index'),
						name: 'GroupLiveCode',
						meta: {
							title: 'GroupLiveCode',
						}
					},
					//编辑 添加 群活码
					{
						path: '/Customer/GroupLiveCode/changeLiveCode',
						component: () => import('@/views/Customer/GroupLiveCode/changeLiveCode'),
						name: 'changeLiveCode',
						hidden:true,
						meta: {
							title: 'changeLiveCode',
						}
					},
					//门店群活码
					{
						path: '/Customer/storesLiveCode',
						component: () => import('@/views/Customer/storesLiveCode/index'),
						name: 'storesLiveCode',
						meta: {
							title: 'storesLiveCode',
						}
					},
					//编辑 添加 群活码
					{
						path: '/Customer/storesLiveCode/changeLiveCode',
						component: () => import('@/views/Customer/storesLiveCode/changeLiveCode'),
						name: 'storeschangeLiveCode',
						hidden:true,
						meta: {
							title: 'storeschangeLiveCode',
						}
					},
					// 涨粉营销
					{
						path: '/Customer/rosepowder/index',
						component: () => import('@/views/Customer/rosepowder/index'),
						name: 'rosepowderindex',
						meta: {
							title: 'rosepowderindex'
						}
					},
					//marketingConfiguration  配置涨粉营销
					{
						path: '/Customer/rosepowder/detail',
						component: () => import('@/views/Customer/rosepowder/detail'),
						name: 'rosepowderdetail',
						hidden:true,
						meta: {
							title: 'rosepowderdetail'
						}
					},
					{
						path: '/Customer/rosepowder/data',
						component: () => import('@/views/Customer/rosepowder/data'),
						name: 'rosepowderdata',
						hidden:true,
						meta: {
							title: 'rosepowderdata'
						}
					},
				]
			},
			
			//客户管理
			{
				path: '/customManage',
				component: AppMain,
				meta: {
					title: 'customManage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/kehuguanli.png'
				},
				children: [
					{
						path: '/Customer/qyCustomerManage',
						component: () => import('@/views/Customer/qyCustomerManage'),
						name: 'qyCustomerManage',
						meta: {
							title: 'qyCustomerManage',
						}
					},
					//客户流失提醒
					{
						path: '/Customer/lossManage',
						component: () => import('@/views/Customer/lossManage'),
						name: 'lossManage',
						meta: {
							title: 'lossManage',
						}
					},
					//员工删人提醒
					{
						path: '/Customer/deleteEmployeeManage',
						component: () => import('@/views/Customer/deleteEmployeeManage'),
						name: 'deleteEmployeeManage',
						meta: {
							title: 'deleteEmployeeManage',
						}
					},
					//群发记录列表
					{
						path: '/qyCustomer/Massrecord',
						component: () => import('@/views/qyCustomer/Massrecord/index'),
						name: 'Massrecord',
						meta: {
							title: 'Massrecord',
						}
					},
					//群发记录列表详情
					{
						path: '/qyCustomer/Massrecord/MassrecordDetails',
						component: () => import('@/views/qyCustomer/Massrecord/MassrecordDetails'),
						name: 'MassrecordDetails',
						hidden:true,
						meta: {
							title: 'MassrecordDetails',
						}
					},
				]
			},
			//客户群管理
			{
				path: '/Customer/clientBase',
				component: () => import('@/views/Customer/clientBase/index'),
				meta: {
					title: 'clientBase',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/kehuqunguanli.png'
				},
				children:[
					{
						path: '/Customer/clientBase/clientGroupList',
						component: () => import('@/views/Customer/clientBase/clientGroupList'),
						meta: {
							title: 'clientGroupList',
						},
						name:'clientGroupList'
					},
					{
						path: '/Customer/clientBase/clientGroupDetail',
						component: () => import('@/views/Customer/clientBase/clientGroupDetail'),
						hidden:true,
						meta: {
							title: 'clientGroupDetail',
						},
						name:'clientGroupDetail'
					},
					{
						path: '/Customer/clientBase/groupPerformance',
						component: () => import('@/views/Customer/clientBase/groupPerformance'),
						meta: {
							title: 'groupPerformance',
						},
						name:'groupPerformance'
					},
				]
			},
		
			//聊天侧边栏
			{
				path: '/chatSidebar',
				component: () => import('@/views/Customer/clientBase/index'),
				meta: {
					title: 'chatSidebar',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/kehuqunguanli.png'
				},
				children:[
					{
						path: '/qyCustomer/chatSidebar/customerPortrayal',
						component: () => import('@/views/qyCustomer/chatSidebar/customerPortrayal/customerPortrayal'),
						meta: {
							title: 'customerPortrayal',
						},
						name:'customerPortrayal'
					},
					{
						path: '/qyCustomer/chatSidebar/customerOrder',
						component: () => import('@/views/qyCustomer/chatSidebar/customerOrder/customerOrder'),
						meta: {
							title: 'customerOrder',
						},
						name:'customerOrder'
					},
					{
						path: '/qyCustomer/chatSidebar/productRecommend',
						component: () => import('@/views/qyCustomer/chatSidebar/productRecommend/productRecommend'),
						meta: {
							title: 'productRecommend',
						},
						name:'productRecommend'
					},
					// {
					// 	path: '/createApp',
					// 	component: () => import('@/views/qyCustomer/createApp/createApp'),
					// 	hidden: true,
					// 	meta: {
					// 		title: 'createApp',
					// 	},
					// 	name:'createApp'
					// },
				],
			},
			{
				path: '/createApp',
				component: () => import('@/views/qyCustomer/createApp/createApp'),
				meta: {
					title: 'createApp',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/chuangjianzijianyingyong.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/chuangjianzijianyingyong.png',
				},
				name:'createApp'
			},
			//素材管理
			{
				path: '/material',
				component: () => import('@/views/Customer/clientBase/index'),
				meta: {
					title: 'material',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/sucaiguanli.png',
					// active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/sucaiguanli.png',
				},
				children:[
					//好友欢迎语
					{
						path: '/material/friendWelcomeMessage',
						component: () => import('@/views/qyCustomer/material/friendWelcomeMessage/friendWelcomeMessage'),
						meta: {
							title: 'friendWelcomeMessage',
						},
						name:'friendWelcomeMessage'
					},
					{
						path: '/material/friendWelcomeMessage/edit',
						component: () => import('@/views/qyCustomer/material/friendWelcomeMessage/edit'),
						meta: {
							title: 'friendWelcomeMessageEdit',
						},
						name: 'friendWelcomeMessageEdit',
						hidden: true
					},
					//进群欢迎语
					{
						path: '/material/joinGroupMessage',
						component: () => import('@/views/qyCustomer/material/joinGroupMessage/joinGroupMessage'),
						meta: {
							title: 'joinGroupMessage',
						},
						name:'joinGroupMessage'
					},
					{
						path: '/material/joinGroupMessage/edit',
						component: () => import('@/views/qyCustomer/material/joinGroupMessage/edit'),
						meta: {
							title: 'joinGroupMessageEdit',
						},
						name: 'joinGroupMessageEdit',
						hidden: true
					}
				],
			},
			
		],
	},
	//智能营销
	{
		path: '/smartmarket',
		component: Layout,
		meta: {
			title: 'smartmarket'
		},
		children:[
			{
				path: '/smartmarket/automarket',
				component: () => import('@/views/smartmarket/automarket/index'),
				name: 'automarket',
				meta: {
					title: 'automarket',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/kehushujugailan.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/kehushujugailan.png'
				}
			},
			//配置
			{
				path: '/smartmarket/automarket/configuration',
				component: () => import('@/views/smartmarket/automarket/configuration'),
				name: 'configuration',
				hidden:true,
				meta: {
					title: 'configuration',
				}
			},
			//数据
			{
				path: '/smartmarket/automarket/data',
				component: () => import('@/views/smartmarket/automarket/data'),
				name: 'automarketdata',
				hidden:true,
				meta: {
					title: 'automarketdata',
				}
			},
			
			
			//自动打标签
			{
				path: '/Customer/autoAddTag',
				component: () => import('@/views/Customer/autoAddTag/autoAddTag'),
				name: 'autoAddTag',
				meta: {
					title: 'autoAddTag',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/zidongdabiaoqian.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/zidongdabiaoqian.png'
				}
			},
			{
				path: '/Customer/autoAddTagEdit',
				component: () => import('@/views/Customer/autoAddTag/autoAddTagEdit'),
				name: 'autoAddTagEdit',
				hidden: true,
				meta: {
					title: 'autoAddTagEdit',
				}
			},
			
			//营销模板
			{
				path: '/smartmarket/marketadvice',
				component: () => import('@/views/smartmarket/marketadvice'),
				name: 'marketadvice',
				meta: {
					title: 'marketadvice',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/qiweikehu/yingxiaojianyi.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/yingxiaojianyi.png'
				}
			},
		]
	},
	//数据分析
	{
		path: '/dataAnalysis',
		component: Layout,
		meta: {
			title: 'dataAnalysis'
		},
		children: [
			// {
			// 	path: '/data/dataStatistic',
			// 	component: () => import('@/views/data/dataStatistic'),
			// 	name: 'dataStatistic',
			// 	meta: {
			// 		title: 'dataStatistic',
			// 	}
			// },
			// 流量分析
			{
				path: '/data/dataStatisticPage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				// name: 'dataStatisticPage',
				meta: {
					title: 'dataStatisticPage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shujufenxi/liuliangfenxi.png'
				},
				children: [
					// 流量分析
					{
						path: '/data/flowAnalysis',
						component: () => import('@/views/data/flowAnalysis'),
						name: 'flowAnalysis',
						meta: {
							title: 'flowAnalysis',
						}
					},
					// 页面分析
					{
						path: '/data/PageAnalysis',
						component: () => import('@/views/data/PageAnalysis'),
						name: 'PageAnalysis',
						meta: {
							title: 'PageAnalysis',
						}
					},
					// 访客分析
					{
						path: '/data/visitorAnalysis',
						component: () => import('@/views/data/visitorAnalysis'),
						name: 'visitorAnalysis',
						meta: {
							title: 'visitorAnalysis',
						}
					},
				]
			},

			// 商品分析
			{
				path: '/data/productAnalysisPage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				// name: 'productAnalysisPage',
				meta: {
					title: 'productAnalysisPage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shujufenxi/shangpinfenxi.png'
				},
				children: [
					// 商详页分析
					{
						path: '/data/productAnalysis',
						component: () => import('@/views/data/productAnalysis'),
						name: 'productAnalysis',
						meta: {
							title: 'productAnalysis',
						}
					},
					// 商品交易分析
					{
						path: '/data/productDealAnaly',
						component: () => import('@/views/data/productDealAnaly'),
						name: 'productDealAnaly',
						meta: {
							title: 'productDealAnaly',
						}
					},
					//商品销售排行
					{
						path: '/data/merchandising',
						component: () => import('@/views/data/merchandising'),
						name: 'merchandising',
						meta: {
							title: 'merchandising',
						}
					},

				]
			},
			// 交易分析
			{
				path: '/data/businessAnalysisPage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				// name: 'businessAnalysisPage',
				meta: {
					title: 'businessAnalysisPage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shujufenxi/jiaoyifenxi.png'
				},
				children: [
					// 交易分析
					{
						path: '/data/businessAnalysis',
						component: () => import('@/views/data/businessAnalysis/index'),
						name: 'businessAnalysis',
						meta: {
							title: 'businessAnalysis',
						}
					},
				]
			},

			// 客户分析
			{
				path: '/data/customAnalysisPage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				// name: 'customAnalysisPage',
				// hidden: true,
				meta: {
					title: 'customAnalysisPage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shujufenxi/kehufenxi.png'
				},
				children: [
					// 客户概况
					{
						path: '/data/customerOverview',
						component: () => import('@/views/data/customerOverview/index'),
						name: 'customerOverview',
						meta: {
							title: 'customerOverview',
						}
					},
					// 微信粉丝分析
					{
						path: '/data/wxFansAnalysis',
						component: () => import('@/views/data/wxFansAnalysis/index'),
						name: 'wxFansAnalysis',
						meta: {
							title: 'wxFansAnalysis',
						}
					},
					//客户交易分析
					{
						path: '/data/Customtransanaly',
						component: () => import('@/views/data/Customtransanaly/index'),
						name: 'Customtransanaly',
						meta: {
							title: 'Customtransanaly',
						}
					},
					//客户贡献排行
					{
						path: '/data/customercontribution',
						component: () => import('@/views/data/customercontribution'),
						name: 'customercontribution',
						meta: {
							title: 'customercontribution',
						}
					},

				]
			},
			//营销分析
			{
				path: '/data/marketAnalysisPage',
				component: () => import('@/views/treeMenu/threeMenupage'),
				// name: 'marketAnalysisPage',
				meta: {
					title: 'marketAnalysisPage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shujufenxi/yingxiaofenxi.png'
				},
				children: [
					// 营销概览
					{
						path: '/data/marketingOverview',
						component: () => import('@/views/data/marketingAnalytics/marketingOverview'),
						name: 'marketingOverview',
						meta: {
							title: 'marketingOverview',
						}
					},
					// 营销分析
					{
						path: '/data/marketAnalysis',
						component: () => import('@/views/data/marketingAnalytics/index'),
						name: 'marketAnalysis',
						meta: {
							title: 'marketAnalysis',
						}
					},
				]
			},

			{
				path: '/data/SingelAnalydetail',
				component: () => import('@/views/data/SingelAnalydetail'),
				name: 'SingelAnalydetail',
				hidden: true,
				meta: {
					title: 'SingelAnalydetail',
				}
			},

			//订单毛利预算
			{
				path: '/assets/grossMargin',
				component: () => import('@/views/assets/grossMargin'),
				name: 'grossMargin',
				meta: {
					title: 'grossMargin',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/caiwuzhongxin/dingdanmaoliyusuan.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/dingdanmaoliyusuan.png'
				}
			},
		],
	},
	
	//分销模块
	{
		path: '/distribution',
		component: Layout,
		meta: {
			title: 'distribution',
			icon: 'goods'
		},
		children: [
			//推手分销
			{
				path: '/inquiry',
				component: AppMain,
				meta: {
					title: 'inquiryDistribution',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/tuishoufenxiao.png',
				},
				children: [
					//推手查询
					{
						path: '/distribution/inquiry/index',
						component: () => import('@/views/distribution/inquiry/index'),
						name: 'inquiry',
						meta: {
							title: 'inquiry',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishouchaxun.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishouchaxun.png'
						}
					},
					//推手业绩
					// {
					// 	path: '/distribution/achievementStatistics',
					// 	component: () => import('@/views/distribution/achievementStatistics'),
					// 	name: 'achievementStatistics',
					// 	meta: {
					// 		title: 'achievementStatistics',
					// 		icon: 'el-icon-tickets'
					// 	}
					// }, 
					//推手收益
					{
						path: '/distribution/commissionStatistics',
						component: () => import('@/views/distribution/commissionStatistics'),
						name: 'commissionStatistics',
						meta: {
							title: 'commissionStatistics',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishoushouyi.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishoushouyi.png'
						}
					}, 
					//推手销售概况
					{
						path: '/distribution/referrer/salesOverview',
						component: () => import('@/views/distribution/referrer/salesOverview'),
						name: 'salesOverview',
						meta: {
							title: 'salesOverview',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishouxiaoshougaikuang.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishouxiaoshougaikuang.png'
						}
					}, 
					//推手销售明细
					{
						path: '/distribution/referrer/salesDetail',
						component: () => import('@/views/distribution/referrer/salesDetail'),
						name: 'salesDetail',
						meta: {
							title: 'salesDetail',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishouxiaoliangmingxi.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishouxiaoliangmingxi.png'
						}
					}, 
					//推手流量数据
					{
						path: '/distribution/Pushtradeanalysis',
						component: () => import('@/views/distribution/Pushtradeanalysis'),
						name: 'Pushtradeanalysis',
						meta: {
							title: 'Pushtradeanalysis',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishouliuliangshuju.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishouliuliangshuju.png'
						}
					},
					//商品推广效果
					{
						path: '/distribution/referrer/promotionEffect',
						component: () => import('@/views/distribution/referrer/promotionEffect'),
						name: 'promotionEffect',
						meta: {
							title: 'promotionEffect',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shujufenxi/shangpinfenxi.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/shangpinfenxi.png'
						}
					},
					//推手团队业绩
					{
						path: '/distribution/referrer/Teamperformance',
						component: () => import('@/views/distribution/referrer/Teamperformance'),
						name: 'Teamperformance',
						meta: {
							title: 'Teamperformance',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishoutuanduiyeji.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishoutuanduiyeji.png'
						}
					}, 
					//收支记录
					{
						path: '/distribution/receiptAndPaymentRecord',
						component: () => import('@/views/distribution/receiptAndPaymentRecord'),
						name: 'receiptAndPaymentRecord',
						meta: {
							title: 'receiptAndPaymentRecord'
						}
					}, 
					//模式配置
					{
						path: '/distribution/modeConfig',
						component: () => import('@/views/distribution/modeConfig'),
						name: 'modeConfig',
						meta: {
							title: 'modeConfig',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/shangpintuiguangxiaoguotu.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/shangpintuiguangxiaoguotu.png'
						}
					},
					//推手招募
					{
						path: '/distribution/driverrecruiting',
						component: () => import('@/views/distribution/driverrecruiting/index'),
						name: 'driverrecruiting',
						meta: {
							title: 'driverrecruiting',
							// icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/shangpintuiguangxiaoguotu.png',
							// active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/shangpintuiguangxiaoguotu.png'
						}
					},
					
					// 推手业绩
					// {
					// 	path: '/distribution/referrer',
					// 	component: () => import('@/views/treeMenu/threeMenupage'),
					// 	meta: {
					// 		title: 'referrer',
					// 		icon: 'el-icon-tickets'
					// 	},
					// 	children: [
							
					// 	]
					// },
				],
			},
			
			// 店员业绩
			{
				path: '/distribution/door/employeePerformance',
				component: () => import('@/views/treeMenu/threeMenupage'),
				// name: 'employeePerformance',
				meta: {
					title: 'employeePerformance',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/dianyuanfenxiao.png',
				},
				children: [
					// 店员业绩
					{
						path: '/distribution/door/employeePerformanceIndex',
						component: () => import('@/views/door/employeePerformance/index'),
						name: 'employeePerformanceIndex',
						meta: {
							title: 'employeePerformanceIndex',
						}
					},
				
					// 锁粉明细报表
					{
						path: '/distribution/door/lockDetailpage',
						component: () => import('@/views/door/employeePerformance/lockDetailpage'),
						name: 'lockDetailpage',
						meta: {
							title: 'lockDetailpage',
						}
					},
					// 销售明细报表
					{
						path: '/distribution/door/sellDetailpage',
						component: () => import('@/views/door/employeePerformance/sellDetailpage'),
						name: 'sellDetailpage',
						meta: {
							title: 'sellDetailpage',
						}
					},
					//提成概况
					{
						path: '/distribution/door/commissionDetail',
						component: () => import('@/views/distribution/commissionDetail'),
						name: 'commissionDetail',
						meta: {
							title: 'commissionDetail',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/dianyuanyeji.png'
						}
					},
					// 提成明细报表
					{
						path: '/distribution/door/drawDetailpage',
						component: () => import('@/views/door/employeePerformance/drawDetailpage'),
						name: 'drawDetailpage',
						meta: {
							title: 'drawDetailpage',
						}
					},
				]
			}, 
			
			//邀请海报
			{
				path: '/distribution/setting/invitationPoster',
				component: () => import('@/views/setting/invitationPoster'),
				name: 'invitationPoster',
				meta: {
					title: 'invitationPoster',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/yaoqinghaibao.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/01/yaoqinghaibao.png'
				}
			},
			
			//分销提现
			{
				path: '/distribution/assets/withDrawal',
				component: () => import('@/views/assets/withDrawal'),
				name: 'withDrawal',
				meta: {
					title: 'withDrawal',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/fenxiaotixian.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/01/fenxiaotixian.png',
					// icon: 'goods'
				}
			},
		]
	},
	// 代理模块,
	{
		path: '/agent',
		component: Layout,
		meta: {
			title: 'agent',
		},
		children: [
			//推手分销
			{
				path: '/agent',
				component: AppMain,
				meta: {
					title: 'agentDistribution',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/tuishoufenxiao.png',
				},
				children: [
					//推手管理
					{
						path: '/agent/agentmanagelist',
						component: () => import('@/views/agent/agentmanagelist.vue'),
						name: 'agentmanagelist',
						meta: {
							title: 'agentmanagelist',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishouchaxun.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishouchaxun.png'
						}
					},
					//推手收益
					{
						path: '/agent/benefitList',
						component: () => import('@/views/agent/benefitList.vue'),
						name: 'benefitList',
						meta: {
							title: 'benefitList',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishoushouyi.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishoushouyi.png'
						}
					},
					//推手收益明细
					{
						path: '/agent/benefitDetail',
						component: () => import('@/views/agent/benefitDetail.vue'),
						name: 'benefitDetail',
						meta: {
							title: 'benefitDetail',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishouxiaoliangmingxi.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishouxiaoliangmingxi.png'
						}
					},
					//推手出货奖励
					{
						path: '/agent/sellRewardList',
						component: () => import('@/views/agent/sellRewardList.vue'),
						name: 'sellRewardList',
						meta: {
							title: 'sellRewardList',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishouxiaoshougaikuang.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishouxiaoshougaikuang.png'
						}
					},
					//出货奖励规则
					{
						path: '/agent/rewardRules',
						component: () => import('@/views/agent/rewardRules.vue'),
						name: 'rewardRules',
						hidden:true,
						meta: {
							title: 'rewardRules',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shujufenxi/shangpinfenxi.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/shangpinfenxi.png'
						}
					},
					//推手级别配置
					{
						path: '/agent/agentConfig',
						component: () => import('@/views/agent/agentConfig.vue'),
						name: 'agentConfig',
						meta: {
							title: 'agentConfig',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/shangpintuiguangxiaoguotu.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/shangpintuiguangxiaoguotu.png'
						}
					},
					//推手出货明细
					{
						path: '/agent/sellRewardDetail',
						component: () => import('@/views/agent/sellRewardDetail.vue'),
						name: 'sellRewardDetail',
						meta: {
							title: 'sellRewardDetail',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/tuishouxiaoliangmingxi.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/tuishouxiaoliangmingxi.png'
						}
					},
					
					//收支记录
					{
						path: '/agent/receiptAndPaymentRecord',
						component: () => import('@/views/distribution/receiptAndPaymentRecord'),
						name: 'agentReceiptAndPaymentRecord',
						meta: {
							title: 'agentReceiptAndPaymentRecord'
						}
					}, 
					//推手招募
					{
						path: '/agent/driverrecruiting',
						component: () => import('@/views/agent/driverrecruiting/index'),
						name: 'agentdriverrecruiting',
						meta: {
							title: 'agentdriverrecruiting',
							// icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/tuishouzhongxin/shangpintuiguangxiaoguotu.png',
							// active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/shangpintuiguangxiaoguotu.png'
						}
					},
				],
			},
			//淘客
			{
				path: '/agent/taokeDistribution',
				component: AppMain,
				meta: {
					title: 'taokeDistribution',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/tuishoufenxiao.png',
				},
				children:[
					{
						path: '/agent/taokeDistribution/shop',
						component: () => import('@/views/agent/taokeDistribution/shop/index.vue'),
						name: 'taokeShop',
						meta: {
							title: 'taokeShop',
						}
					},
					{
						path: '/agent/taokeDistribution/order',
						component: () => import('@/views/agent/taokeDistribution/order/index.vue'),
						name: 'taokeOrder',
						meta: {
							title: 'taokeOrder',
						}
					},
					{
						path: '/agent/taokeDistribution/earnings',
						component: () => import('@/views/agent/taokeDistribution/earnings/index.vue'),
						name: 'taokeEarnings',
						meta: {
							title: 'taokeEarnings',
						}
					},
					{
						path: '/agent/taokeDistribution/config',
						component: () => import('@/views/agent/taokeDistribution/config/index.vue'),
						name: 'taokeConfig',
						meta: {
							title: 'taokeConfig',
						}
					},
				]
			},
			// 店员业绩
			{
				path: '/agent/door/employeePerformance',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'agentemployeePerformance',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/dianyuanfenxiao.png',
				},
				children: [
					// 店员业绩
					{
						path: '/agent/door/employeePerformanceIndex',
						component: () => import('@/views/door/employeePerformance/index'),
						name: 'agentemployeePerformanceIndex',
						meta: {
							title: 'agentemployeePerformanceIndex',
						}
					},
				
					// 锁粉明细报表
					{
						path: '/agent/door/lockDetailpage',
						component: () => import('@/views/door/employeePerformance/lockDetailpage'),
						name: 'agentlockDetailpage',
						meta: {
							title: 'agentlockDetailpage',
						}
					},
					// 销售明细报表
					{
						path: '/agent/door/sellDetailpage',
						component: () => import('@/views/door/employeePerformance/sellDetailpage'),
						name: 'agentsellDetailpage',
						meta: {
							title: 'agentsellDetailpage',
						}
					},
					//提成概况
					{
						path: '/agent/door/commissionDetail',
						component: () => import('@/views/distribution/commissionDetail'),
						name: 'agentcommissionDetail',
						meta: {
							title: 'agentcommissionDetail',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/dianyuanyeji.png'
						}
					},
					// 提成明细报表
					{
						path: '/agent/door/drawDetailpage',
						component: () => import('@/views/door/employeePerformance/drawDetailpage'),
						name: 'agentdrawDetailpage',
						meta: {
							title: 'agentdrawDetailpage',
						}
					},
				]
			}, 
			
			//邀请海报
			{
				path: '/agent/setting/invitationPoster',
				component: () => import('@/views/setting/invitationPoster'),
				name: 'agentinvitationPoster',
				meta: {
					title: 'agentinvitationPoster',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/yaoqinghaibao.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/01/yaoqinghaibao.png'
				}
			},
			
			//提现
			{
				path: '/agent/assets/withDrawal',
				component: () => import('@/views/assets/withDrawal'),
				name: 'agentModwithDrawal',
				meta: {
					title: 'agentModwithDrawal',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/01/fenxiaotixian.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/01/fenxiaotixian.png',
					// icon: 'goods'
				}
			},
		]
	},
	
	
	
	//销售渠道
	{
		path: '/saleChannel',
		component: Layout,
		meta: {
			title: 'saleChannel'
		},
		children: [
			// 公众号管理
			{
				path: '/setting/officialAccount',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'officialAccount',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/xiaoshouqudao/weixingongzhonghao.png'
				},
				children: [
					// 公众号设置
					{
						path: '/setting/setOfficialAccount',
						component: () => import('@/views/setting/officialAccount/setOfficialAccount'),
						name: 'setOfficialAccount',
						meta: {
							title: 'setOfficialAccount',
						}
					},
					// 自定义菜单
					{
						path: '/setting/setMenupage',
						component: () => import('@/views/setting/setMenu/setMenupage'),
						name: 'setMenupage',
						meta: {
							title: 'setMenupage',
						}
					}
				]
			},
			
			//微信小程序
			{
				path: '/setting/wxAppManage',
				component: () => import('@/views/setting/wxAppManage'),
				name: 'wxAppManage',
				meta: {
					title: 'wxAppManage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/xiaoshouqudao/weixinxiaochengxu.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/weixinxiaochengxu.png'
				}
			},
			//企业微信
			{
				path: '/saleChannel/qyWechat',
				component: () => import('@/views/saleChannel/qyWechat/qyWechat'),
				name: 'qyWechat',
				meta: {
					title: 'qyWechat',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/xiaoshouqudao/qiyeweixin.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/qiyeweixin.png'
				}
			},
			
			//小程序直播
			{
				path: '/broadCast',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'broadCast',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/xiaoshouqudao/xiaochengxuzhibo.png'
				},
				children: [
			
					{
						path: '/broadCast/index',
						component: () => import('@/views/broadcastManage/index'),
						name: 'broadcastManage',
						meta: {
							title: 'broadcastManage',
						}
					},
					{
						path: '/broadCast/LiveGroup',
						component: () => import('@/views/broadcastManage/LiveGroup'),
						name: 'LiveGroup',
						meta: {
							title: 'LiveGroup',
						}
					},
					{
						path: '/broadCast/GoodstockNo',
						component: () => import('@/views/broadcastManage/GoodstockNo'),
						name: 'GoodstockNo',
						meta: {
							title: 'GoodstockNo',
						}
					},
					{
						path: '/broadCast/AlreadyCheckgood',
						component: () => import('@/views/broadcastManage/AlreadyCheckgood'),
						name: 'AlreadyCheckgood',
						meta: {
							title: 'AlreadyCheckgood',
						}
					}
			
				]
			},
			//视频号小店
			{
				path: '/videoShop',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'videoShop',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/xiaoshouqudao/shipinhaoxiaodian.png'
				},
				children:[
					{
						path: '/videoShop/videoGoodsList',
						component: () => import('@/views/setting/videoShop/videoGoodsList/index'),
						name: 'videoGoodsList',
						meta: {
							title: 'videoGoodsList',
						}
					},
					{
						path: '/videoShop/videoGoodsDetails',
						component: () => import('@/views/setting/videoShop/videoGoodsList/Details'),
						name: 'videoGoodsDetails',
						hidden: true,
						meta: {
							title: 'videoGoodsDetails',
						}
					},
					{
						path: '/videoShop/videoOrderList',
						component: () => import('@/views/setting/videoShop/videoOrderList/index'),
						name: 'videoOrderList',
						meta: {
							title: 'videoOrderList',
						}
					},
					{
						path: '/videoShop/videoOrderDetails',
						component: () => import('@/views/setting/videoShop/videoOrderList/Details'),
						name: 'videoOrderDetails',
						hidden: true,
						meta: {
							title: 'videoOrderDetails',
						}
					},
					{
						path: '/videoShop/videoAfterSale',
						component: () => import('@/views/setting/videoShop/videoAfterSale/index'),
						name: 'videoAfterSale',
						meta: {
							title: 'videoAfterSale',
						}
					},
					{
						path: '/videoShop/videoAfterSaleDetails',
						component: () => import('@/views/setting/videoShop/videoAfterSale/Details'),
						name: 'videoAfterSaleDetails',
						hidden: true,
						meta: {
							title: 'videoAfterSaleDetails',
						}
					},
					{
						path: '/videoShop/videoSharer',
						component: () => import('@/views/setting/videoShop/videoSharer/index'),
						name: 'videoSharer',
						meta: {
							title: 'videoSharer',
						}
					},
					{
						path: '/videoShop/videoLiveBinding',
						component: () => import('@/views/setting/videoShop/videoLiveBinding/index'),
						name: 'videoLiveBinding',
						meta: {
							title: 'videoLiveBinding',
						}
					},
					{
						path: '/videoShop/videoShopBinding',
						component: () => import('@/views/setting/videoShop/videoShopBinding/index'),
						name: 'videoShopBinding',
						meta: {
							title: 'videoShopBinding',
						}
					},
				]
			},
			//视频号直播
			{
				path: '/setting/liveVideo',
				component: () => import('@/views/setting/liveVideo/index'),
				// name: 'liveVideo',
				meta: {
					title: 'liveVideo',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/xiaoshouqudao/shipinhaozhibo.png',
				},
				children:[
					{
						path: '/setting/liveVideo/liveCutIn',
						component: () => import('@/views/setting/liveVideo/liveCutIn'),
						name: 'liveCutIn',
						meta: {
							title: 'liveCutIn',
						}
					},
					{
						path: '/setting/liveVideo/shopwindow',
						component: () => import('@/views/setting/liveVideo/shopwindow'),
						name: 'shopwindow',
						meta: {
							title: 'shopwindow',
						}
					},
					{
						path: '/setting/liveVideo/categorymanagement',
						component: () => import('@/views/setting/liveVideo/categorymanagement'),
						name: 'categorymanagement',
						meta: {
							title: 'categorymanagement',
						}
					},
					{
						path: '/setting/liveVideo/sharemember/sharemember',
						component: () => import('@/views/setting/liveVideo/sharemember/sharemember'),
						name: 'sharemember',
						meta: {
							title: 'sharemember',
						}
					},
					//分享员 导出列表
					{
						path: '/setting/liveVideo/sharemember/exportlist',
						component: () => import('@/views/setting/liveVideo/sharemember/exportlist'),
						name: 'sharememberexportlist',
						hidden:true,
						meta: {
							title: 'sharememberexportlist',
						}
					},
					//分享员 详情
					{
						path: '/setting/liveVideo/sharemember/detail',
						component: () => import('@/views/setting/liveVideo/sharemember/detail'),
						name: 'sharememberdetail',
						hidden:true,
						meta: {
							title: 'sharememberdetail',
						}
					},
					//直播推广
					{
						path: '/setting/liveVideo/promote',
						component: () => import('@/views/setting/liveVideo/promote'),
						name: 'promote',
						meta: {
							title: 'promote',
						}
					},
				]
			},
			//门店管理
			{
				path: '/door',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'door',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/mendianguanli.png'
				},
				children: [
					// //门店管理
					// {
					// 	path: '/',
					// 	component: AppMain,
					// 	meta: {
					// 		title: 'doorManagerMenu',
					// 		icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/mendianguanli.png'
					// 	},
					// 	children: [
							{
								path: '/door/doorManager',
								component: () => import('@/views/door/doorManager'),
								name: 'doorManager',
								meta: {
									title: 'doorManager',
									// icon: 'goods'
								}
							}, {
								path: '/door/doorTags',
								component: () => import('@/views/door/doorTags'),
								name: 'doorTags',
								meta: {
									title: 'doorTags',
									// icon: 'goods'
								}
							},
					// 	]
					// },
					//门店对账(废弃)
					// {
					// 	path: '/door/doorBill',
					// 	component: () => import('@/views/door/doorBill'),
					// 	name: 'doorBill',
					// 	meta: {
					// 		title: 'doorBill',
					// 		icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/mendianguanli.png'
					// 	}
					// },
					//店员业绩(废弃)
					// {
					// 	path: '/door/achievementDetail',
					// 	component: () => import('@/views/distribution/achievementDetail'),
					// 	name: 'achievementDetail',
					// 	meta: {
					// 		title: 'achievementDetail',
					// 		icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/dianyuanyeji.png'
					// 	}
					// }, 
					
					//门店业绩
					{
						path: '/door/storePerformance',
						component: () => import('@/views/door/storePerformance'),
						name: 'storePerformance',
						meta: {
							title: 'storePerformance',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/mendianyeji.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/mendianyeji.png'
						}
					},
					//门店业绩
					{
						path: '/door/businessdata',
						component: () => import('@/views/door/businessdata'),
						name: 'businessdata',
						meta: {
							title: 'businessdata',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/mendianyeji.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/mendianyeji.png'
						}
					},
					//毛利项目明细
					{
						path: '/door/profitdetail',
						component: () => import('@/views/door/profitdetail'),
						name: 'profitdetail',
						hidden:true,
						meta: {
							title: 'profitdetail',
						}
					},
					
					//核销记录
					{
						path: '/door/writeOffRecords',
						component: () => import('@/views/door/writeOffRecords'),
						name: 'writeOffRecords',
						meta: {
							title: 'writeOffRecords',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/hexiaojilv.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/hexiaojilv.png'
						}
					},
						
					//到店支付数据
					{
						path: '/door/TostorePay',
						component: () => import('@/views/door/TostorePay'),
						name: 'TostorePay',
						meta: {
							title: 'TostorePay',
							icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/mendianguanli/daodianzhifushuju.png',
							active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/active/daodianzhifushuju.png'
						}
					},
					//到店支付数据详情
					{
						path: '/door/TostorePayDetails',
						component: () => import('@/views/door/TostorePayDetails'),
						name: 'TostorePayDetails',
						hidden: true,
						meta: {
							title: 'TostorePayDetails'
						}
					},
					
					{
						path: '/door/settleDetails',
						component: () => import('@/views/door/settleDetails'),
						name: 'settleDetails',
						hidden: true,
						meta: {
							title: 'settleDetails',
						}
					},
					{
						path: '/door/payDetails',
						component: () => import('@/views/door/payDetails'),
						name: 'payDetails',
						hidden: true,
						meta: {
							title: 'payDetails',
						}
					},
					{
						path: '/door/doorGoodsEdit',
						component: () => import('@/views/door/doorGoodsEdit'),
						name: 'doorGoodsEdit',
						hidden: true,
						meta: {
							title: 'doorGoodsEdit',
						}
					},
					{
						path: '/door/doorSetting',
						component: () => import('@/views/door/doorSetting'),
						name: 'doorSetting',
						hidden: true,
						meta: {
							title: 'doorSetting',
						}
					},
					{
						path: '/door/AddStore',
						component: () => import('@/views/door/AddStore'),
						name: 'AddStore',
						hidden: true,
						meta: {
							title: 'AddStore',
						}
					}
				]
			},
		]
	},	
	// 网页客服
	{
		path: '/customerServe',
		component: Layout,
		meta: {
			title: 'customerServe',
			icon: 'goods'
		},
		children: [
			 {
				path: '/shopServe',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'shopServe',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/kefuzhongxin/shangchengkefu.png'
				},
				children: [
				
					{
						path: '/customerServe/greetings',
						component: () => import('@/views/customerServe/greetings'),
						name: 'greetings',
						meta: {
							title: 'greetings',
						}
					},
					{
						path: '/customerServe/quickReply',
						component: () => import('@/views/customerServe/quickReply'),
						name: 'quickReply',
						meta: {
							title: 'quickReply',
						}
					},
					{
						path: '/customerServe/selfMenu',
						component: () => import('@/views/customerServe/selfMenu'),
						name: 'selfMenu',
						meta: {
							title: 'selfMenu',
						}
					},
					{
						path: '/customerServe/salesPerformance',
						component: () => import('@/views/customerServe/salesPerformance'),
						name: 'salesPerformance',
						meta: {
							title: 'salesPerformance',
						}
					},
					//客服设置
					{
						path: '/customerServe/serviceSet',
						component: () => import('@/views/customerServe/serviceSet'),
						name: 'serviceSet',
						// hidden:true,
						meta: {
							title: 'serviceSet',
						}
					},
					{
						path: '/customerServe/workload',
						component: () => import('@/views/customerServe/workload'),
						name: 'workload',
						meta: {
							title: 'workload',
						}
					},
					//联系顾问
					{
						path: '/customerServe/Contactcustomer',
						component: () => import('@/views/customerServe/Contactcustomer'),
						name: 'Contactcustomer',
						meta: {
							title: 'Contactcustomer',
						}
					},
				]
			},
      // 微信客服
     {
				path:"/WxCustomerServe",
				component: () => import('@/views/customerServe/WeChatCustomerService/WeChatCustomerService'),
				meta: {
					title: 'WxCustomerServe',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/kefuzhongxin/shangchengkefu.png'
				},
				children: [
					{
						path: '/customerServe/authorization',
						component: () => import('@/views/customerServe/authorization'),
						name: 'authorization',
						hidden:true,
						meta: {
							title: 'authorization',
						}
					},
					
					
					// {
					// 	path: '/customerServe/ConfigurationSmart',
					// 	component: () => import('@/views/customerServe/ConfigurationSmart'),
					// 	name: 'ConfigurationSmart',
					// 	hidden:true,
					// 	meta: {
					// 		title: 'ConfigurationSmart',
					// 	}
					// },
					{
						path: '/customerServe/WxCustomerServe/serviceaccount',
						component: () => import('@/views/customerServe/WeChatCustomerService/serviceaccount/serviceaccount'),
						name: 'serviceaccount',
						meta: {
							title: 'serviceaccount',
						}
					},
					{
						path: '/customerServe/WxCustomerServe/edit',
						component: () => import('@/views/customerServe/WeChatCustomerService/serviceaccount/edit'),
						name: 'editserviceaccount',
						hidden:true,
						meta: {
							title: 'editserviceaccount',
						}
					},
					{
						path: '/customerServe/WxCustomerServe/menumessage',
						component: () => import('@/views/customerServe/WeChatCustomerService/menumessage/menumessage'),
						name: 'menumessage',
						meta: {
							title: 'menumessage',
						}
					},
					{
						path: '/customerServe/WxCustomerServe/editmenumessage',
						component: () => import('@/views/customerServe/WeChatCustomerService/menumessage/edit'),
						name: 'editmenumessage',
						hidden:true,
						meta: {
							title: 'editmenumessage',
						}
					},
					{
						path: '/customerServe/WxCustomerServe/sceneaccess',
						component: () => import('@/views/customerServe/WeChatCustomerService/sceneaccess'),
						name: 'sceneaccess',
						meta: {
							title: 'sceneaccess',
						}
					},
					{
						path: '/customerServe/WxCustomerServe/servicetoolbar',
						component: () => import('@/views/customerServe/WeChatCustomerService/servicetoolbar'),
						name: 'servicetoolbar',
						meta: {
							title: 'servicetoolbar',
						}
					},
					//智能回复
					{
						path: '/customerServe/WxCustomerServe/smartReply/smartreply',
						component: () => import('@/views/customerServe/WeChatCustomerService/smartReply/smartreply'),
						name: 'smartreply',
						
						meta: {
							title: 'smartreply',
						}
					},
					//ConfigurationSmart 智能回复配置页路由
					{
						path: '/customerServe/WxCustomerServe/smartReply/ConfigurationSmart',
						component: () => import('@/views/customerServe/WeChatCustomerService/smartReply/ConfigurationSmart'),
						name: 'ConfigurationSmart',
						hidden:true,
						meta: {
							title: 'ConfigurationSmart',
						}
					},
					//升级服务
					{
						path: '/customerServe/WxCustomerServe/upgradeservice',
						component: () => import('@/views/customerServe/WeChatCustomerService/upgradeservice'),
						name: 'upgradeservice',
						meta: {
							title: 'upgradeservice',
						}
					},
					//客服数据统计
					{
						path: '/customerServe/WxCustomerServe/receptiondata',
						component: () => import('@/views/customerServe/WeChatCustomerService/receptiondata'),
						name: 'receptiondata',
						meta: {
							title: 'receptiondata',
						}
					},
				]
			},
			{
				path: '/wechatServe',
				component: () => import('@/views/treeMenu/threeMenupage'),
				meta: {
					title: 'wechatServe',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/kefuzhongxin/xiaochengxukefu.png'
				},
				children: [
					//小程序客服回复配置
					{
						path: '/customerServe/Codeconfig',
						component: () => import('@/views/customerServe/Codeconfig'),
						name: 'Codeconfig',
						meta: {
							title: 'Codeconfig',
						}
					},
				],
			},
			
		],
	},

	//设置模块
	{
		path: '/setting',
		component: Layout,
		meta: {
			title: 'setting',
			icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shangchengzhongxin/shezhi.png'
		},
		children: [

			{
				path: '/setting/index',
				component: () => import('@/views/setting/index'),
				name: 'editSetting',
				meta: {
					title: 'editSetting',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/02/shangchengshezhi.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/02/shangchengshezhi.png'
				}
			},
			
			{
				path: '/setting/roleManage/index',
				component: () => import('@/views/setting/roleManage/index'),
				name: 'roleManage',
				meta: {
					title: 'roleManage',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/02/jueseguanli.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/02/jueseguanli.png'
				}
			},
			{
				path: '/setting/staffMange',
				component: () => import('@/views/setting/staffMange'),
				name: 'staffMange',
				meta: {
					title: 'staffMange',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/02/yuangongguanli.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/02/yuangongguanli.png'
				}
			},
	
			
			{
				path: '/setting/Interfacelicensing',
				component: () => import('@/views/setting/Interfacelicensing'),
				name: 'Interfacelicensing',
				meta: {
					title: 'Interfacelicensing',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/02/jiekouxuke.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/02/jiekouxuke.png'
				}
			},
			{
				path: '/setting/exportsSetting',
				component: () => import('@/views/setting/exportsSetting'),
				name: 'exportsSetting',
				meta: {
					title: 'exportsSetting',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/02/jiekoupeizhi.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/02/jiekoupeizhi.png'
				}
			},
			{
				path: '/setting/settingPay',
				component: () => import('@/views/setting/settingPay/settingPay.vue'),
				name: 'settingPay',
				meta: {
					title: 'settingPay',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/02/zhifushezhi.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/00/02/zhifushezhi.png'
				}
			},
			{
				path: '/setting/roleManage/editRoleManage',
				component: () => import('@/views/setting/roleManage/editRoleManage'),
				name: 'editRoleManage',
				hidden: true,
				meta: {
					title: 'editRoleManage',
				}
			},
			
			{
				path: '/setting/payApplyResult',
				component: () => import('@/views/setting/payApply/applyResult'),
				name: 'payApplyResult',
				hidden: true,
				meta: {
					title: 'payApplyResult',
				}
			},
			{
				path: '/setting/setPayApply',
				component: () => import('@/views/setting/payApply/setInfos.vue'),
				name: 'setPayApply',
				hidden: true,
				meta: {
					title: 'setPayApply',
				}
			},
			
			{
				path: '/setting/submitInformation',
				component: () => import('@/views/setting/settingPay/submitInformation.vue'),
				name: 'submitInformation',
				hidden: true,
				meta: {
					title: 'submitInformation',
				}
			},
			{
				path: '/setting/replenishInformation',
				component: () => import('@/views/setting/settingPay/replenishInformation.vue'),
				name: 'replenishInformation',
				hidden: true,
				meta: {
					title: 'replenishInformation',
				}
			},
			{
				path: '/setting/submitInformationDetail',
				component: () => import('@/views/setting/settingPay/submitInformationDetail.vue'),
				name: 'submitInformationDetail',
				hidden: true,
				meta: {
					title: 'submitInformationDetail',
				}
			},
			{
				path: '/setting/openPayby',
				component: () => import('@/views/setting/settingPay/openPayby.vue'),
				name: 'openPayby',
				hidden: true,
				meta: {
					title: 'openPayby',
				}
			},
			{
				path: '/setting/Newssubscription',
				component: () => import('@/views/setting/Newssubscription/index.vue'),
				name: 'Newssubscription',
				meta: {
					title: 'Newssubscription',
					icon: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shezhi/xiaoxidingyue.png',
					active: 'https://cdn.dkycn.cn/images/melyshop/menuIcon/shezhi/xiaoxidingyue2.png',
				}
			},
		]
	},

]
