export const helpList = [{
      		path: 'goodsList',
      		data: [
      			'<div>1.如何发布或添加一个新商品？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnt7SkPvKIdiLJObOpJzpbIc" target="_blank" class="helpjs-link">通过商品发布，您可以将商品上架到商城。操作路径：企店商城后台-商城管理-商品列表，点击「发布商品」。</a>',

      			'<div class="helpjs-title">2.列表上销量的统计规则</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccny5e199yFHGBovvICdK7Ekf" target="_blank" class="helpjs-link"> 商品销量数量在客户支付成功时进行增加，产生退款销量不会减少；若1个客户购买多件商品，销量计多个。</a>',

      			'<div class="helpjs-title">3.列表上访问数据的统计规则</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnFitRYKQfZZgEWz0Or7LhcO" target="_blank" class="helpjs-link"> 访客数：商城上线到当前时间，访问该商品的详情页的人数，同1个客户在1天内访问多次，当天访问人数计1人。</a>',

      			'<div class="helpjs-title">4.什么是组合商品？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnx39DV2sQZw30R18lNn9fVb" target="_blank" class="helpjs-link">当您需要将多个单品组合在一起，打包销售，您可以发布组合商品，例如超市里常见的可乐+雪碧大瓶装的打包销售形式。</a>',
      		]
      	}, {
      		path: 'goodGroup',
      		data: [
      			'<div>1.商品分组使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnuWCulFb6Yi9SImrK1VfNsb" target="_blank" class="helpjs-link"> 商品分组其实就是商品分类，当商品种类繁多时，需要按照不同标准给商品进行分类，比如：衣服、鞋类等。操作路径：企店商城后台-商城管理-商品分组，点击「添加」。</a>',

      			'<div class="helpjs-title">2.什么是列表隐藏？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn6QFE3IpTTybIdymcnagbxg" target="_blank" class="helpjs-link">商家若想添加一些分组用于内部工作人员更好的管理商品，可以将分组设置列表隐藏。设置了隐藏的分组，客户在小程序商城内，选择根据分组查看商品时，隐藏的分组不显示。 </a>',

      			'<div class="helpjs-title">3.设置了商品分组的序号，有什么作用？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnoaRnOX6NdO6MTFWwH1ie0g" target="_blank" class="helpjs-link">商品分组的序号影响小程序内分组筛选的排序，序号值越大，分组排序越靠前。例如：添加一个分组为本月上新，为该分组设置一个较大的序号值，客户在商城内根据分组查找商品时，将优先展示该分组。</a>',

      			'<div class="helpjs-title">4.分组导入功能使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnv9DczcxmLxLhqZNwceNHLc" target="_blank" class="helpjs-link">进入企店商城后台-商城管理-商品分组，点击「导入」，下载商品分组导入模板表格，完善分组名称并上传表格即可导入。</a>',
      		]
      	}, {
      		path: 'goodsBrand',
      		data: [
      			'<div>1.商品品牌使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnApdCDSympECz9TppjhtHDh" target="_blank" class="helpjs-link">以品牌为维度，对商品进行管理，完善商品基本信息。操作路径：企店商城后台-商城管理-商品品牌，点击「添加」。</a>',
      		]
      	}, {
      		path: 'remindArrival',
      		data: [
      			'<div>1.到货提醒人数统计规则</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnicdobqJh6JUICCM1CFLj4b" target="_blank" class="helpjs-link">客户进入商城小程序，浏览商品时，如果商品已售罄，此时客户可以点击【到货提醒】，到货提醒人数即点击了该按钮的人数</a>',

      			'<div class="helpjs-title">2.什么时候商品会显示在这个列表？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnGWlUQmKYA0Va8LMj4WQlzf" target="_blank" class="helpjs-link"> 当商品总库存小于10个，商品将显示在入库提醒列表中 。</a>',
      		]
      	}, {
      		path: 'goodsExport',
      		data: [
      			'<div>1.商品导入功能使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnYHJNs2AUVIjecnklsX0DXe" target="_blank" class="helpjs-link"> 通过商品导入模板表格将商品导入到商城中。操作路径：企店商城后台-商城管理-商品导入，点击「导入」。</a>',

      			'<div class="helpjs-title">2.各导入失败原因以及解决方案</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn3kjNZW9Emf7ehUFtoljQtg" target="_blank" class="helpjs-link">（1）商品编码重复导致失败，检查重复的商品编码并修改；(2)图片地址错误导致失败，检查商品主图或详情图的图片地址是否有误；点击查看所有失败原因及解决方案</a>',
      		]
      	}, {
      		path: 'supplyGoods',
      		data: [
      			'<div>1.如何把商品发布到供货市场？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn9sU8SMDqZkNjCMh49z6pTe" target="_blank" class="helpjs-link">在商品列表，可管理商品是否需要发布到供货市场。操作路径：企店商城后台—商城管理—商品列表，列表点击【更多】—【发布到供货市场】；</a>',

      			'<div class="helpjs-title">2.发布到供货市场的商品，分销商成功销售后，订单谁来发货？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnP4R7w7Kb3YKhrdrpRslnOh" target="_blank" class="helpjs-link"> 分销商成功销售供货商的商品，订单会自动进入商品供货商的后台，供货商可前往企店商城后台-商城管理-分销供货单，查看所有分销商销售成功的订单并进行发货。 </a>',

      			'<div class="helpjs-title">3.列表上分销商家数量统计规则</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnYIAJbrHjwOMgEVjVnbpqqb" target="_blank" class="helpjs-link">分销商家数量：有多少个商家在供货市场选货页，将商品添加到自己的商城中，分销商家数量计为多少个。该数据针对已发布到供货市场上的每个商品分别进行统计。</a>',

      			'<div class="helpjs-title">4.列表上分销销量统计规则</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnNDbq22TXRt6Bs5ajdimg1c" target="_blank" class="helpjs-link">所有分销了该商品的分销商，通过他们的商城，成功销售成功的数量。该数量只增不减，商品退款不影响该数据。</a>',
      		]
      	}, {
      		path: 'supplyMarket',
      		data: [
      			'<div>1.销售供货市场里的商品，订单谁来发货？运费谁来承担？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnJHgyxCnyDD2VwlUHsK9FGh" target="_blank" class="helpjs-link">订单由商品对应的供货商进行发货。关于订单运费，分销商可以设置订单运费由商家自己承担或客户承担。点击查看详细说明。</a>',
      		]
      	}, {
      		path: 'batchChangePrice',
      		data: [
      			'<div>1.批量改商品使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnLEuCXwTf4M8qnWPIhXYP0d" target="_blank" class="helpjs-link">商家可以通过该功能，批量修改商品的售价、商品推手收益或员工提成等。在该功能页，点击「批量修改」，根据功能指引进行操作。</a>',

      			'<div class="helpjs-title">2.批量改商品失败原因及解决方案</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnXuO6ZrQMwbNsE9nWEb1Fie" target="_blank" class="helpjs-link">(1)规格ID为空导致失败，请前往商品列表导出商品数据，并找到对应规格的规格ID补充到批量改商品模板表格中；（2）规格不存在导致失败，请前往商品列表导出商品数据，并核对对应规格的规格ID是否有误。点击查看更多失败原因及解决方案</a>',
      		]
      	}, {
      		path: 'orderMange',
      		data: [
      			'<div>1.订单管理列表操作教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnJuJVczyDisje9NsqDoC2Hg" target="_blank" class="helpjs-link">在该页面，商家可以通过关键字、订单支付时间、发货时间等多维度筛选条件，查找订单，可对订单进行发货、备注等操作。点击查看详细说明。</a>',

      			'<div class="helpjs-title">2.客户申请售后流程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn7gFolBj8jxls3GPdzCtyrf" target="_blank" class="helpjs-link">客户进入商城小程序-个人中心-点击全部订单，找到需要申请售后的订单，点击「申请退款」，即可进行售后申请。申请售后时可选择仅退款或退货退款。点击查看完整的售后申请流程。</a>',

      			'<div class="helpjs-title">3.订单发货功能说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnoYTee4pCX13MvZ5VAVuYsc" target="_blank" class="helpjs-link">商家对待发货订单进行发货操作，发货时，可选择拆包发货或整单发货。也可通过导入表格批量发货。订单发货成功，若发现物流单号填写错误，可进入订单详情页，修改物流单号。</a>',

      			'<div class="helpjs-title">4.自提订单核销教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnRa5ox3ps2VMnY06gtzdoWh" target="_blank" class="helpjs-link">客户在商城内消费，选择配送方式为上门自提时，系统会生成到店自提订单，客户到店后，店员进入员工版工作台，即可对订单进行核销。点击查看详细教程。</a>',

      			'<div class="helpjs-title">5.什么是分销采购单？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccniBTAcFuUW7Egc1lc0g2nvc" target="_blank" class="helpjs-link">商家在市场选货页面，选择供货商的商品添加到自己的商城内进行销售，销售成功产生的订单即分销采购单。</a>',
      		]
      	}, {
      		path: 'refundAfterSale',
      		data: [
      			'<div>1.退款售后单管理教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnYE98Pyuc0WlwKJbDhhjake" target="_blank" class="helpjs-link">商家可在该页面管理商城客户申请的所有售后订单。根据实际情况，选择是否同意客户的申请。点击查看详细教程。</a>',

      			'<div class="helpjs-title">2.客户申请仅退款流程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnbPeaYOJVxm7D2vkI78Ktec" target="_blank" class="helpjs-link">客户进入商城小程序-个人中心-订单列表，在订单详情中，点击「申请退款」，选择退款类型—仅退款。未发货的订单，申请退款时，只能选择仅退款类型。</a>',

      			'<div class="helpjs-title">3.客户申请退货退款流程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnRI9adE9w8xZml4A8P8tRqc" target="_blank" class="helpjs-link"> 客户进入商城小程序-个人中心-订单列表，在订单详情中，点击「申请退款」，选择退款类型—退货退款。退货退款的情况下，商家可设置是否需要退回运费。点击查看如何配置。 </a>',
      		]
      	}, {
      		path: 'distributePage',
      		data: [
      			'<div>1.什么是分销供货单？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnGAONaKnh76HosjWQQBD4Lf" target="_blank" class="helpjs-link"> 供货商把商品发布到供货市场，并且有分销商将商品添加到自己的商城销售成功后，产生的订单，对于供货商来说，即为分销供货单。 </a>',

      			'<div class="helpjs-title">2.分销供货单管理列表操作教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnWDY8zHeP9FThJ18YduonJf" target="_blank" class="helpjs-link">在该功能页，供货商可以针对分销商家销售成功的订单，进行订单发货，订单备注等操作。发货时，可选择拆包发货或整单发货。也可通过导入表格批量发货。点击查看详细操作说明。</a>',
      		]
      	}, {
      		path: 'supplyRefund',
      		data: [
      			'<div>1.什么是供货退款单？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnzsNn4bIKMOAd7SdFSwEccR" target="_blank" class="helpjs-link">由分销商成功销售的订单，客户若申请商品售后，产生的售后单即为供货退款单。</a>',
      		]
      	}, {
      		path: 'shippingTemplates',
      		data: [
      			'<div>1.运费模板使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnzYrKz7P5Yt531XYpa3T9bb" target="_blank" class="helpjs-link">通过该功能，添加商品运费模板：根据不同区域设置运费规则，支持设置不配送区域。已保存的运费模板，可在添加或编辑商品时使用。点击查看详细教程。</a>',

      			'<div class="helpjs-title">2.商品运费计算规则</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnaY06VfAURELGfRF6alBlxe" target="_blank" class="helpjs-link">单商品订单运费计算：(1)若商品设置运费为全国统一运费，则根据后台商品管理中保存的运费核算；(2)若商品设置使用运费模板，则根据收货地址以及运费计费方式核算运费。点击查看完整计算规则。</a>',

      			'<div class="helpjs-title">3.订单发货时可以选择哪些快递？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnKVFwFglkLj1648ldhefPy5" target="_blank" class="helpjs-link">订单发货是可以选择：中通快递，圆通快递，汇通快递，韵达速运，顺丰快递，宅急送，EMS，申通快递，天天快递，快捷快递，国通快递，德邦，全峰快递等近40个快递。</a>',


      			'<div class="helpjs-title">4.客户申请退款，运费会不会退回？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn7m2xeczflVjx8CsNJsfvGc" target="_blank" class="helpjs-link">订单还未发货，客户申请退款，把整单商品都退款成功，此时默认退回运费，如果客户只是退款部分商品，此时不退还运费；订单已发货，客户申请退款，商家可在设置中心配置是否退回运费。点击查看设置教程。</a>',


      			'<div class="helpjs-title">5.可以设置满N元包邮吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnpCoA0kO21dCKuhc7U1xL5c" target="_blank" class="helpjs-link">可以的。操作路径：商城营销-基础营销-包邮工具，选择包邮商品和包邮区域；商家还可以设置会员包邮政策、消费满N元或满N件包邮形式等。点击查看配置方法。</a>',
      		]
      	}, {
      		path: 'comment',
      		data: [
      			'<div>1.订单评价管理使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnyBy5H2chiB70anAGyNVrAf" target="_blank" class="helpjs-link">在该功能页面，商家可以看到所有在商城内消费的客户提交的评价信息，可针对客户的评价进行回复等。</a>',

      			'<div class="helpjs-title">2.未审核通过的评价会在商城内展示吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnQ1KkIyTeivldXGbYZPzcod" target="_blank" class="helpjs-link">待审核或者审核不通过的评价不会显示在商城里。</a>',
      		]
      	}, {
      		path: 'mallPagemange',
      		data: [
      			'<div>1.商城装修使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccni0juu4FyG99GrE55wiWHqb" target="_blank" class="helpjs-link">使用商城装修功能，自定义搭建商城每一个页面。进入装修页面后，商家可通过添加左侧的装修模块完成装修，也可以使用企店提供的装修模板，替换模板数据即可。点击查看完整教程。</a>',

      			'<div class="helpjs-title">2.商城装修上传图片尺寸说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnyKnni0BDS9sj1wcf9dTOGf" target="_blank" class="helpjs-link">(1)图片导航模块，建议尺寸：宽750px，高350px；(2)热区链接模块，建议尺寸：宽750px，高度不限。</a>',
      		]
      	}, {
      		path: 'mallNavconfig',
      		data: [
      			'<div>1.主页导航使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnUJuLnWx36cagVeyM2HNRuT" target="_blank" class="helpjs-link">添加商城主页导航版块，让商城能够承载更多内容。例如以下导航：为你精选，年度新品，热销榜单等。点击查看更多示例。</a>',
      		]
      	}, {
      		path: 'mallFooterConfig',
      		data: [
      			'<div>1.底部导航使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnekXwPz8bjL6GGk6dHeJeXg" target="_blank" class="helpjs-link">通过配置底部导航，将客户常用的页面关联在导航中，方便客户在商城内实现快速切换；也可将主推活动落地页关联在导航中，引导客户进入您希望曝光的页面。</a>',
      		]
      	}, {
      		path: 'personalMall',
      		data: [
      			'<div>1.个人中心配置教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnecPH49QgOqH2zG1BNymixe" target="_blank" class="helpjs-link">针对前端客户的个人中心页面进行编辑配置，个人中心分为4个模块：个人信息栏，订单信息栏，邀请人信息栏，工具栏，商家可以根据商城实际需要，对每个栏目进行设置。点击查看配置教程。</a>',
      		]
      	}, {
      		path: 'barrageAd',
      		data: [
      			'<div>1.弹屏广告配置教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnmU7c0rKuVWc1pAzVC5Ryff" target="_blank" class="helpjs-link">设置进入商城弹屏广告图片，可配置广告推送人群和广告链接。客户进入商城后，如果符合广告推送人群范围，将会弹出广告。</a>',

      			'<div class="helpjs-title">2.客户进入商城后就会弹出广告吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnTXUIHl3TzQo2o8YJVqpl0b" target="_blank" class="helpjs-link">如果弹屏广告并不是面向全部客户的，需要客户成功登录以后才会弹出哦。</a>',

      			'<div class="helpjs-title">3.为什么有些客户进入商城后，没有弹出广告？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnJipYuHuvxOELMrsqmVy2ch" target="_blank" class="helpjs-link">可能原因：(1)广告限定了推送人群，但是客户没有登录，系统无法检验客户是否符合；(2)广告限定了推送人群，客户不符合人群范围。</a>',
      		]
      	}, {
      		path: 'paySuccess',
      		data: [
      			'<div>1.支付成功页广告位配置教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnW4uh9WGUwnsscf4lmyD8fd" target="_blank" class="helpjs-link">在支付成功页设置图片广告，商家可指向图片广告跳转页面。例如在大促期间，设计和商城大促营销优惠相关的海报，并投放在支付成功页，全面推广活动，还可增长客户在商城的停留时长。点击查看示例。</a>',
      		]
      	}, {
      		path: 'CustomerSearch',
      		data: [
      			'<div>1.客户查询功能使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnHC6pbMy1N7m9khapAvbrze" target="_blank" class="helpjs-link">在客户查询页面，商家可添加会员、查看不同角色客户数、给客户设置标签、修改客户等级、赠送\扣减积分、授权推手等操作。点击查看完整教程。</a>',

      			'<div class="helpjs-title">2.各查询条件的定义</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnAnqBFsUnruVyLudkJnXzdb" target="_blank" class="helpjs-link">(1)上次支付时间：客户最近一次在商城中支付成功的时间；(2)成为客户时间：客户首次在小程序商城内登录成功的时间；(3)成为会员时间：客户成为会员的时间；(4)成为推手时间：客户在商城内成功购买推手授权礼包，并获得推手身份的时间；点击查看全部条件定义。</a>',

      			'<div class="helpjs-title">3.如何给客户打标签？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn0sj5ZhCDFYBdDGDd6oyShh" target="_blank" class="helpjs-link">(1)客户查询列表，给某个客户设置标签，或者批量给多个客户新增标签；(2)通过智能营销-自动打标签服务，根据客户基本属性、行为特征等，自动打上指定标签。</a>',

      			'<div class="helpjs-title">4.客户怎么成为会员？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnTscb78N7k3RFiFe7yQOiCf" target="_blank" class="helpjs-link">商城内的客户通过累加成长值成为会员或升级为更高会员级别。商家需要先在商城后台开启会员等级机制，会员等级配置成功后，您可以：(1)手动在商城后台将客户设置为会员或直接添加新会员；(2)在商城页面放置成为会员入口，引导进入商城的客户成为会员等。点击查看配置方法。</a>',


      			'<div class="helpjs-title">5.什么是锁粉店员？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccndeHeVCKaX9StgJyr7ICDdh" target="_blank" class="helpjs-link">商城的员工发送小程序商城链接给客户，客户点击进入商城后，即与员工绑定锁粉关系，分享链接的员工即为该客户的锁粉店员。商家还可为店员设置销售提成，激励店员在线上也能积极销售。</a>',

      			'<div class="helpjs-title">6.什么是锁粉推手？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnJ2BuAVEv9w0VNre6pFE7Ah" target="_blank" class="helpjs-link">已经获得商城授权的推手发送小程序商城链接给客户，客户点击进入商城后，即与推手绑定锁粉关系，分享链接的推手即为该客户的锁粉推手，后续客户若成功被转化为推手身份，即与锁粉推手直接绑定推荐关系。</a>',
      		]
      	}, {
      		path: 'vipLevel',
      		data: [
      			'<div>1.会员等级配置教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnwle3ADt2ZbInZwrcbXhVwd" target="_blank" class="helpjs-link">会员等级配置功能，用于商家搭建商城会员体系，在该功能模块下，商家可以设置多个会员等级（最多添加5个等级），并且支持设置不同等级的会员权益，例如：会员等级折扣、会员等级积分、会员包邮等。点击查看配置教程。</a>',

      			'<div class="helpjs-title">2.客户怎么成为会员？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnTscb78N7k3RFiFe7yQOiCf" target="_blank" class="helpjs-link">商城内的客户通过累加成长值成为会员或升级为更高会员级别。商家需要先在商城后台开启会员等级机制，会员等级配置成功后，您可以：(1)手动在商城后台将客户设置为会员或直接添加新会员；(2)在商城页面放置成为会员入口，引导进入商城的客户成为会员等。点击查看配置方法。</a>',
      		]
      	}, {
      		path: 'growthValue',
      		data: [
      			'<div>1.成长值配置教程及发放规则</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnjfDmfzk3cPOtDXbmp2FHrd" target="_blank" class="helpjs-link">成长值用于客户能否成为会员或能否升级成为更高级别会员的指标，商家可配置成长值获取途径包括：绑定手机号发放成长值、购买商品发放成长值、完成订单发放成长值等，还可通过满减送活动营销赠送成长值。</a>',
      		]
      	}, {
      		path: 'tagLibrary',
      		data: [
      			'<div>1.标签库功能使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccncQfKgWgMgxqWkMvKGK7uOc" target="_blank" class="helpjs-link">在该功能下，你可以搭建属于自己商城的标签库。(1)利用标签库的数据，为商城内的客户进行分类管理；(2)支持创建标签组，让标签管理更加清晰；(3)安装了【企店助手】的商家，系统还支持与企业微信的标签库数据实时同步，包括添加标签组及组内标签、编辑标签组名称、编辑标签名称、删除标签组、删除标签、给客户打标签。点击查看说明。</a>',

      			'<div class="helpjs-title">2.如何给客户打标签？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn0sj5ZhCDFYBdDGDd6oyShh" target="_blank" class="helpjs-link">(1)客户查询列表，给某个客户设置标签，或者批量给多个客户新增标签；(2)通过智能营销-自动打标签服务，根据客户基本属性、行为特征等，自动打上指定标签。</a>',

      			'<div class="helpjs-title">3.如何根据客户属性、行为特征等自动给客户打标签？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccngkQcqRUpRLAjqt73t3vTVh" target="_blank" class="helpjs-link">操作路径：智能营销-自动打标签，点击「创建规则」，进入规则配置页，选择条件标签并设置具体条件。企店为商家准备了一些常用打标规则，商家可以直接执行对应规则。点击查看操作教程。</a>',

      			'<div class="helpjs-title">4.如何实现与企业微信的标签同步？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn6mfD0ZidBm6Z1K44yrEN4e" target="_blank" class="helpjs-link">若想实现商城标签库数据与企业微信标签库数据同步，以及客户打标签数据同步，请安装企店助手。点击查看安装教程。</a>',
      		]
      	}, {
      		path: 'pointsManager',
      		data: [
      			'<div>1.积分规则配置说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnx3LvxiTjEkBJwjepLLCx1c" target="_blank" class="helpjs-link">商家可以配置购买指定商品赠送积分或消费下单赠送积分。点击查看配置教程。</a>',

      			'<div class="helpjs-title">2.客户如何获得积分？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccni5m9qoowPKfjiMwdDbTo2U" target="_blank" class="helpjs-link">客户获得积分的途径：(1)根据商家配置的积分发放规则，购买商品或消费下单获得积分；(2)商家可通过满减送活动营销，给满足活动门槛的客户赠送积分；(3)商家可在商城后台，手动给客户增加积分。点击查看更多途径。</a>',

      			'<div class="helpjs-title">3.获得的积分可以如何使用？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccne7VgwVRrYCLbv2y2T4a4fc" target="_blank" class="helpjs-link">积分可用于：(1)兑换优惠券；(2)兑换商品；点击查看兑换方法和入口。</a>',
      		]
      	}, {
      		path: 'calendarCheckIn',
      		data: [
      			'<div>1.日历签到配置说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn6acohQVECmDdbtbKY50RSh" target="_blank" class="helpjs-link">开启签到功能，设置签到订阅，提高商城打开率，商城客户活跃度。日历签到功能支持设置每日签到奖励、连续奖励等，商家还可在签到页设置广告位。点击查看教程。</a>',

      			'<div class="helpjs-title">2.客户签到入口</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnlXI9faYVy2SqL91WwRj53b" target="_blank" class="helpjs-link">商家开启了签到功能后，客户进入商城首页或个人中心页，若客户当天未签到，自动显示签到入口，引导客户完成签到。点击查看示例。</a>',
      		]
      	}, {
      		path: 'integralMall',
      		data: [
      			'<div>1.积分商城使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnNF6dP40G3Ai29HSPDDdxwc" target="_blank" class="helpjs-link">通过积分商城，设置积分兑换优惠券或兑换商品活动。在设置兑换商品时，支持X个积分+Y元兑换商品的方式，例如：1000积分+9.9元兑换商品。</a>',

      			'<div class="helpjs-title">2.客户如何使用积分兑换优惠券或商品？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnTPGNit4KQZK9phA97RITxb" target="_blank" class="helpjs-link">客户进入小程序商城-个人中心，点击积分即可进入积分商城入口；商家还可在装修商城页面时，通过积分兑换装修模块，直接将可兑换的优惠券或商品展示在商城页面中。</a>',

      			'<div class="helpjs-title">3.如何手动给客户修改积分？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnqTxdWy55MjLnLRZK980Lag" target="_blank" class="helpjs-link">操作路径：客户运营-客户查询，列表上点击「更多」-「赠送积分」或「扣减积分」。</a>',
      		]
      	}, {
      		path: 'issueCoupon',
      		data: [
      			'<div>1.发券宝使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccngG5xiWO0JHBdtmiYttkqrc" target="_blank" class="helpjs-link">发券宝营销：给客户主动发送优惠券。商家可以针对商城新客设置活动，以提高新客成交转化率；也可针对老客设置活动，作为老客回馈福利，提高老客复购等。点击查看配置教程。</a>',

      			'<div class="helpjs-title">2.发券宝营销中，新客户和老客户的定义是什么？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnIkcpvoHBreuU3D7R4lRndd" target="_blank" class="helpjs-link">(1)新客户：从未在您的商城支付成功过一笔订单的客户，定义为新客户；(2)老客户：之前在您的商城内至少支付成功过一笔订单的客户，定义为老客户。</a>',

      			'<div class="helpjs-title">3.创建了活动后，客户怎么获得优惠券？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn2baZcc3TLfJdphjGSoLTuf" target="_blank" class="helpjs-link">活动创建成功，并且开始后，客户进入商城，根据活动配置，系统自动发放优惠券。点击查看示例。</a>',
      		]
      	}, {
      		path: 'storedrule',
      		data: [
      			'<div>1.余额储值功能使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccntFXS7fnI6NAxEypX2IzYje" target="_blank" class="helpjs-link">开启余额储值功能，提前沉淀资金，同时有效绑定客户与商城的黏性。商家可以配置储值礼包，例如充值赠送优惠券、现金等，吸引客户充值。点击查看使用教程。</a>',

      			'<div class="helpjs-title">2.客户怎么充值余额？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn0f99iYhGjPKPSSblsElRte" target="_blank" class="helpjs-link">商家开启余额储值功能后，客户进入小程序商城-个人中心，点击「余额」，进入余额充值页，即可选择金额进行充值。点击查看更多充值渠道。</a>',

      			'<div class="helpjs-title">3.如何配置储值礼包？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnXUdU1ySfSUaKibADWsV0Zb" target="_blank" class="helpjs-link">企店商城后台-客户运营-储值中心，点击「储值有礼」，进入页面添加储值礼包；礼包添加成功后，进入余额储值配置页，设置礼包赠送门槛。</a>',


      			'<div class="helpjs-title">4.什么是本金？什么是赠送金？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnmRiLQX2j2sBRYqs4AoW7gd" target="_blank" class="helpjs-link">(1)本金：客户充值时支付的金额，即为本金；(2)赠送金：根据商家设置的储值规则，客户充值成功后，自动赠送的金额，即为赠送金。</a>',

      			'<div class="helpjs-title">5.可以手动给客户充值余额吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnECFC4jIpevWlhbmpVYbAHg" target="_blank" class="helpjs-link">可以的。操作路径：客户运营-客户查询，列表页点击「更多」-「充值余额」。</a>',
      		]
      	}, {
      		path: 'storedPackage', 
      		data: [
      			'<div>1.储值礼包配置教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnXUdU1ySfSUaKibADWsV0Zb" target="_blank" class="helpjs-link">储值礼包用于客户充值时赠送。礼包内可配置赠送金、积分、优惠券、赠品、抽奖机会等。可添加多个储值礼包，礼包添加成功后，您可前往余额储值配置页，根据不同充值门槛设置赠送不同礼包。</a>',
      		]
      	}, {
      		path: 'coupon',
      		data: [
      			'<div>1.优惠券管理功能使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnBHyr2N4YBdEy8ku7F3fQih" target="_blank" class="helpjs-link">企店目前支持创建3种类型的优惠券：减金额券、打折券、随机减金额券。优惠券可设置适用的商品范围、使用门槛、使用期限等。</a>',

      			'<div class="helpjs-title">2.优惠券可以通过哪些形式发放？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnsrdAs8KE0uJTuKuRom0H6c" target="_blank" class="helpjs-link">创建成功的优惠券，您可以用于：（1）新客户进入商城自动赠送，促转化；（2）满减送活动中，达到活动门槛赠送优惠券，促复购；（3）在装修商城时，关联优惠券等。</a>',

      			'<div class="helpjs-title">3.客户下单时，可以使用多张优惠券吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnPOdjbeMq2ctAr18SBfOGce" target="_blank" class="helpjs-link">目前不支持使用多张优惠券。</a>',

      			'<div class="helpjs-title">4.客户下单时使用了优惠券，后来订单退款，优惠券会返还给客户吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnq7KPY68YgTBsYA5PApyAQc" target="_blank" class="helpjs-link">客户仅是提交了订单，并未完成最后的支付，订单取消后优惠券会返还给客户；若客户已经完成了最后的支付，再申请订单退款，此时不会返还优惠券。</a>',
      		]
      	}, {
      		path: 'discountActivity',
      		data: [
      			'<div>1.满减送活动使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnA95pjrlfMJxv3FSlGd1gWd" target="_blank" class="helpjs-link">选择满减活动类型：满N元参加活动、满N件参加活动→设置活动基本信息，选择参与活动的商品→设置活动优惠内容，保存活动。</a>',

      			'<div class="helpjs-title">2.活动配置中，什么是阶梯优惠？什么是循环优惠？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnBfVTcMnhQmCCpyvvQxpCsg" target="_blank" class="helpjs-link">(1)阶梯循环：满100元减5元，满200减20元，封顶满500元减50元，超过500元的仍然减50元；(2)循环优惠：每满100元减5元，如果客户购买满200元，此时减10元。</a>',

      			'<div class="helpjs-title">3.参加满减送活动的商品，客户下单时还可以使用优惠券吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnCnDPWR8affNKZ5IAUmPAOg" target="_blank" class="helpjs-link">客户购买活动商品，并且活动商品在优惠券适用商品范围内，此时可叠加使用优惠券。</a>',
      		]
      	}, {
      		path: 'limitedDiscount',
      		data: [
      			'<div>1.限时折扣活动配置教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnmMP0P5UFsRgFMnXGfvpwYd" target="_blank" class="helpjs-link">选择活动商品→设置活动折扣→预热活动。通过限时折扣，商家可以选定一款或多款商品，在一段时间内以指定的折扣出售。以更优的价格吸引消费者，产生更多的销量。</a>',

      			'<div class="helpjs-title">2.限时折扣活动优惠和满减送活动优惠可以叠加吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn3YSDpqxmztyUaGTBzpMxQf" target="_blank" class="helpjs-link">可以叠加。系统在进行优惠金额核算时，优先计算限时折扣活动优惠后的价格，使用优惠后的价格检验购买的商品是否同时满足满减送活动的参与门槛。</a>',

      			'<div class="helpjs-title">2.活动配置中，折扣设置打折、减金额和活动价，3者的区别是什么？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnppqzDNt8BjzvAYeCFouYVh" target="_blank" class="helpjs-link">(1)设置折扣：在商品原价基础上打N折。一种常规的打折形式，在您对商品的原价进行调整时，根据活动里设置的折扣，灵活计算折后价；(2)设置扣减金额：在商品原价的基础上，扣减N元，这种形式和设置折扣差不多，只是将折扣换成了扣减的金额，当您的折扣值并没有那么大幅度时，您可以使用这种形式；(3)设置活动价：直接设置活动价，设置完成后，无论商品原价如何变更，客户均以活动价进行购买，这种形式比较适合用于低价引流，例如：1分钱抢洗脸巾。</a>',

      			'<div class="helpjs-title">3.如果下单的客户是会员，会员折扣和限时折扣同时存在的时候，使用哪个折扣？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnyuJWVq1IQ5Gjc7tdzSCwre" target="_blank" class="helpjs-link">在这种情况下，系统会使用会员折扣和限时折扣，分别计算2个折后价，取优惠力度较大的一个。例如：购买原价100元的商品，会员享9折优惠，同时商品参加限时折扣活动，活动打8折，此时客户使用80元即可购买该商品。</a>',

      			'<div class="helpjs-title">4.可以按照商品规格设置折扣价吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnxsvUtm1JVN1uffIMMr5QoF" target="_blank" class="helpjs-link">目前暂不支持根据商品规格设置折扣价。</a>',
      		]
      	}, {
      		path: 'frightTool',
      		data: [
      			'<div>1.包邮工具使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnynFlGuhLpe84YdKr1uQxnh" target="_blank" class="helpjs-link">在该功能下，商家可以选择包邮商品和包邮区域，客户在商城内购买商品时，根据包邮工具规则校验客户是否需要支付运费。</a>',
      		]
      	}, {
      		path: 'packPrice',
      		data: [
      			'<div>1.打包一口价活动配置教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn144RVYBZKZ61CP879YRwxh" target="_blank" class="helpjs-link">例如：99元5件。通过打包一口价营销工具建立商品池子，可以让买家随意挑选，提高客单。配置活动时，需要设置打包规则、活动商品等。</a>',
      		]
      	}, {
      		path: 'payGifts',
      		data: [
      			'<div>1.支付有礼活动使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnVy0dWf9wVFgu3isRJIcIkc" target="_blank" class="helpjs-link">支付有礼是一款帮助商家提升客户粘性的营销应用，商家可以根据客户的购买活动商品，设置其付款成功后可参加的转盘抽奖活动。</a>',

      			'<div class="helpjs-title">2.客户购买了活动商品，获得了抽奖次数，又把订单退款了，还能抽奖吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn8eocxCOQPSE7C165iYNdbh" target="_blank" class="helpjs-link">在这种情况下，若赠送的抽奖次数还未使用，此时抽奖次数将被回收；若已使用，无法回收。</a>',
      		]
      	}, {
      		path: 'sellGroup',
      		data: [
      			'<div>1.多人拼团活动使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnLvs2afb3ZlkRFSSsVlbTee" target="_blank" class="helpjs-link">设置活动商品、拼团价、成团人数、成团有效期等，还可配置一键参团、团长优惠、模拟成团。</a>',

      			'<div class="helpjs-title">2.什么是模拟成团？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnkKEQqPgykRMQ6aHhHEX9Mx" target="_blank" class="helpjs-link">成团有效期内人数未满的团，系统将会模拟“匿名买家”凑满人数，使该团成团。 你只需要对已付款参团的真实买家发货。商家可通过这种方式，提高拼团活动成团率，减少订单丢失。</a>',

      			'<div class="helpjs-title">3.拼团订单可以申请退款吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnHdMKdXpZVuKh9roIZ1iAGj" target="_blank" class="helpjs-link">还在组团中的团，团内成员不可临时申请退款；拼团失败的订单，系统会自动退款；拼团成功的订单，您可前往商城设置，配置申请退款时间。</a>',
      		]
      	}, {
      		path: 'Assistindex',
      		data: [
      			'<div>1.助力领取活动使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnBUJTPrFqyEFRGwze7KPMub" target="_blank" class="helpjs-link">0元助力领取商品活动。设置活动商品、需要帮忙助力的人数、助力有效期等，开启活动。客户进入商城，选择心仪的商品发起助力，同时分享给自己的好友帮忙，好友点击邀请链接，帮助客户助力的同时，还可自己也发起助力，实现客户裂变增长。</a>',
      		]
      	}, {
      		path: 'channel',
      		data: [
      			'<div>1.什么是渠道推广码？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn3NGjklz1sspIL0KJXS54gf" target="_blank" class="helpjs-link">渠道推广码功能可为商家用于其他渠道推广使用的，商家可查看不同渠道推广码导流进入商城的用户访问、注册、购买等数据，商家可分析不同渠道的导流能力，优化商家推广方式。</a>',
      		]
      	}, {
      		path: 'lottery',
      		data: [
      			'<div>1.转盘抽奖活动使用教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccneOKlIJuWXoFA3uLuaZ8gjh" target="_blank" class="helpjs-link">添加转盘→设置中奖概率→设置转盘奖励→投放转盘。转盘抽奖活动商家可与支付有礼活动配合，买家购买支付有礼活动商品可获得转盘抽奖机会。</a>',

      		]
      	}, {
      		path: 'dataOverView',
      		data: [
      			'<div>1.为什么商城后台展示的全部客户数量和企业微信后台展示的不一样？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnOJ8SqJSCYqQV5hBbRWbTfh" target="_blank" class="helpjs-link">企业微信客户数量：如果同一个客户添加了3个员工的企业微信，企业微信会在客户数量指标上增加3个客户</a>',
      		]
      	}, {
      		path: 'batchAddFriend',
      		data: [
      			'<div>1.批量加好友操作说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccncRSG37Ct57ZZoZA8JEKnEb" target="_blank" class="helpjs-link">批量导入潜在客户手机号，自动给员工发送添加任务提醒，并实时跟进员工添加情况。若员工长时间未与客户添加，系统支持自动回收重新分配。商家还可开启短信提醒，开启成功后，当员工复制手机号添加客户时，会同时给对应手机号发送短信通知，提高添加成功率。</a>',

      			'<div class="helpjs-title">2.给员工分配了手机号，为什么员工企业微信收不到消息提醒？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnO2qMB9IMPGA4v3LzK5opWh" target="_blank" class="helpjs-link">可能有以下原因：(1)被分配的员工，没有客户联系权限；(2)被分配的员工，不在第三方应用「企店助手」的使用成员范围；(3)被分配的员工未成功激活身份；点击查看更多原因以及解决方案。</a>',

      			'<div class="helpjs-title">3.开启了短信提醒，什么时候会发送？</div>',
      			'<a href=" https://jusnn6k8al.feishu.cn/docs/doccnb5Uu3iNRMmYb8veQp1XZmd" target="_blank" class="helpjs-link">开启了短信提醒，员工进入添加客户页面，复制手机号前往添加客户时，系统自动给对应手机号发送短信提醒。</a>',
      		]
      	}, {
      		path: 'risefans',
      		data: [
      			'<div>1.企微涨粉功能使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn034RSVU0WSY8hxTmG7e7ib" target="_blank" class="helpjs-link">在该功能模块下，结合员工活码，开启商品详情页让利涨粉或支付成功页引导加粉，通过加粉权益等吸引客户添加企业微信。同时支持给客户打上标签、发送指定欢迎语等。</a>',
      		]
      	}, {
      		path: 'callme',
      		data: [
      			'<div>1.员工活码功能使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnA3KwQBFxX8qKGC7A1TltBc" target="_blank" class="helpjs-link">员工活码，是带参数的员工企业微信二维码，创建成功后，可以将活码配置在商城小程序内各页面。客户进入商城小程序，点击相应页面内配置的员工活码入口 ，添加了员工企业微信后，系统会自动记录客户通过哪个渠道添加的员工企业微信，并给客户打上活码相应标签。</a>',

      			'<div class="helpjs-title">2.员工活码使用方式</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnnEZ5uWnBahzJa20Cgno3ue" target="_blank" class="helpjs-link">(1)开启商详页让利涨粉，通过首次添加企业微信员工，可获得商品购买减金额或打折的权益，吸引客户添加企业微信；(2)开启支付成功页引导加粉，客户支付完成后，在页面内显示加粉入口，给客户提供完善的售后服务；等，点击查看活码投放说明。</a>',

      			'<div class="helpjs-title">3.员工活码数据统计说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccns132cLXPQ2GlmFIyMYVjNe" target="_blank" class="helpjs-link">在该统计页面，系统将对每个活码不同落地页（商品详情页、支付成功页、商城首页、商城自定义页面、其他渠道落地页）投放效果进行统计，数据指标包括：活码点击人数、新增好友关系数、新增客户数、活码转化率、活码流失率等。</a>',
      		]
      	}, {
      		path: 'friendsfission',
      		data: [
      			'<div>1.企微客户裂变使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnH1EJXAOKwp5h9tC0J0HPEg" target="_blank" class="helpjs-link">活动流程概述：商家设置裂变活动→员工进行首次推广裂变→客户参与活动→客户发起二次推广裂变。商家可通过该功能，通过邀请企业微信内老客户参与，为企业吸引新客户进入，从而达到以旧带新的目的；客户完成活动里的任务，可获得相应的奖励（优惠券、积分等），从而提高老客活跃度和参与度。</a>',
      		]
      	}, {
      		path: 'groupfission',
      		data: [
      			'<div>1.客户群裂变使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnRqPD0A8P2WZggZVh8jWPkb" target="_blank" class="helpjs-link">活动流程概述：商家设置裂变活动→群主在各自群内进行首次推广裂变→群内成员参与活动→群内成员发起二次推广裂变。商家可通过该功能，通过邀请企业微信客户群内的老成员参与，为对应的客户群聊吸引新成员进入，从而达到以旧带新的目的；客户完成活动里的任务，可获得相应的奖励（优惠券、积分等），从而提高老客活跃度和参与度。</a>',
      		]
      	}, {
      		path: 'GroupLiveCode',
      		data: [
      			'<div>1.客户群活码使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnP9hFresM0DV4LIQQL3zdPh" target="_blank" class="helpjs-link">通过该功能创建的群活码可以不受企业微信1个活码只能关联5个群聊的限制，您可以创建一个固定不变的加入群聊的二维码入口 ，客户扫描活码二维码或点击活码链接，进入页面即可长按加入群聊。</a>',
      		]
      	}, {
      		path: 'storesLiveCode',
      		data: [
      			'<div>1.门店群活码使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnJebsyYAyIx4kjovOnZnhSc" target="_blank" class="helpjs-link">如果您有线下门店，可以使用该功能，为每个门店创建一个固定不变的进群二维码，设置进群权益（例如赠送优惠券，领取商品等），同时将活码打印成台签放在门店收银台，作为社群日常引流的手段。</a>',
      		]
      	}, {
      		path: 'qyCustomerManage',
      		data: [
      			'<div>1.企微客户管理使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnwkiTFfUXvvCzR6dgprgekL" target="_blank" class="helpjs-link">在该功能模块下，您可以查看所有和员工企业微信添加成为好友的客户数据；可以按照添加时间、添加的员工、客户标签进行查询筛选客户数据；可以查看客户与员工的好友关系，以及好友关系添加时间等。</a>',
      		]
      	}, {
      		path: 'clientGroupList',
      		data: [
      			'<div>1.客户群管理使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnzCBHoyaEBH1TkTD21Cqvae" target="_blank" class="helpjs-link">在该功能模块下，您可以查看所有已经创建成功的未解散或已解散的企业微信客户群数据，还可以查看群内成员数据。</a>',
      		]
      	}, {
      		path: 'friendWelcomeMessage',
      		data: [
      			'<div>1.好友欢迎语配置说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnGD1pZPfWW1Ml85ryH1EYrd" target="_blank" class="helpjs-link">通过该功能，为员工设置适用的好友欢迎语。设置完成后，客户添加员工企业微信，将会发送该员工适用的欢迎语内容。好友欢迎语支持上传网页、图片、小程序卡片3种类型的附近。</a>',

      			'<div class="helpjs-title">2.好友欢迎语在什么时候发送？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnWX0GAzcQFlKbIKtCDQFk6b" target="_blank" class="helpjs-link">客户在成功添加员工的企业微信后，将会发送欢迎语。</a>',

      			'<div class="helpjs-title">3.客户成功添加员工的企业微信，为什么没有发送商城后台保存的好友欢迎语？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnEX1ZbklNuqUcNsmTQj8Teh" target="_blank" class="helpjs-link">根据企业微信规定，如果商家在企业微信后台或在企业微信APP内，已给员工配置好友欢迎语，企店商城后台配置的欢迎语将失效，若需要使用企店商城后台配置的欢迎语，请先前往企业微信后台删除。</a>',
      		]
      	}, {
      		path: 'joinGroupMessage',
      		data: [
      			'<div>1.进群欢迎语配置说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn1OlUrveTEY15g4FskuVA5b" target="_blank" class="helpjs-link">通过该功能，管理进群欢迎语素材。欢迎语素材添加完成后，企业微信内的员工将会收到由企业微信发送的通知提醒，需要员工进入对应客户群聊，手动为客户群设置欢迎语。</a>',

      			'<div class="helpjs-title">2.商城后台群欢迎语保存成功了，为什么客户加入群聊，没有发送？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnwoC90Ee4sDvL3eb1iuG8Rh" target="_blank" class="helpjs-link">请对应客户群聊的群主，打开企业微信，进入对应客户群，查看是否已为该群选择了正确的欢迎语。</a>',
      		]
      	}, {
      		path: 'inquiry',
      		data: [
      			'<div>1.什么是推手？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn8DSVxXQkxnhJ1gk6hQikwf" target="_blank" class="helpjs-link">推手是客户的另一层身份。成为推手的客户，相当于拥有了一家自己的商城。成为推手后，既能自购省钱，还能分享赚钱。同时商家可通过推手分销机制，为商城推广扩大传播量。</a>',

      			'<div class="helpjs-title">2.普通客户如何成为推手？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn7yyXMDZQ0iWAjWrww4x3xg" target="_blank" class="helpjs-link">目前企店支持购买推手专属授权礼包成为推手。商家成功添加授权礼包后，客户可以(1)进入商城,主动购买推手授权礼包，支付成功即可自动享有推手身份；(2)现有推手分享礼包购买链接，邀请身边好友成为推手，不断裂变；(3)商家在商城后台，手动授权客户推手身份。</a>',

      			'<div class="helpjs-title">3.推手查询页功能使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnnxQ5s9QBL3agqoO5FL6Qhv" target="_blank" class="helpjs-link">在该页面，商家可以查看到当前商城授权成功的推手数据：查看推手授权信息、修改推手推荐关系、查看推手锁粉数据；同时通过推手流量数据分析，查看通过推手分享链接为商城带来的流量数据、支付数据、转化数据等。</a>',

      			'<div class="helpjs-title">4.推手如何获得收益？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn8LiXTFzE9sFg9UIsJGJZPb" target="_blank" class="helpjs-link">推手收益根据商品配置。商家在管理商城商品时，可配置推手收益。推手进入小程序商城，将商品分享卡片或邀请海报发送给好友，好友成功购买商品，根据商品里的收益配置，给推手发放对应收益。</a>',

      			'<div class="helpjs-title">5.推手如何升级？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnDUeAXZodc75tpLXh0jjjRb" target="_blank" class="helpjs-link">在推手模式中，商家可以配置升级条件：推手销售额达到N元或推手邀请N人等。推手达到升级门槛后，自动升为更高等级。级别越高，可获得的销售收益越高。</a>',
      		]
      	}, {
      		path: 'commissionStatistics',
      		data: [
      			'<div>1.推手如何获得收益？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn8LiXTFzE9sFg9UIsJGJZPb" target="_blank" class="helpjs-link">推手收益根据商品配置。商家在管理商城商品时，可配置推手收益。推手进入小程序商城，将商品分享卡片或邀请海报发送给好友，好友成功购买商品，根据商品里的收益配置，给推手发放对应收益。</a>',

      			'<div class="helpjs-title">2.推手如何提现收益？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnuEmh1p23Jpou4kbtShp8kd" target="_blank" class="helpjs-link">推手进入小程序商城-推手中心，点击「立即提现」，即可提现收益（仅确认收货的订单带来的收益可被提现）。收益申请成功后，由商家在后台分销中心-分销提现中进行提现审核，目前企店支持商家通过企业支付宝自动转账提现金额。</a>',

      			'<div class="helpjs-title">3.客户如果退款了，收益还会发放给推手吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnvl3dgvX0q9pztKy1isEA0d" target="_blank" class="helpjs-link">客户通过推手的链接成功购买商品，后续又申请了退款：(1)若订单还未确认收货订单，退款成功后，将会根据退款金额占比扣减推手收益(具体扣减金额根据商品中配置的收益发放规则和比例决定)；(2)若订单已确认收货，此时客户退款将不再扣减推手收益。</a>',

      			'<div class="helpjs-title">4.为什么推手下单会出现没有收益的情况？ </div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnNi9PGPg6ljTzbkq47qfOud" target="_blank" class="helpjs-link">可能有以下原因：(1)商城设置中开启了推手自购省钱（推手自己进入商城下单，可直接使用收益抵扣掉订单总价）；(2)推手在购买时，商品并未设置推手收益。</a>',
      		]
      	}, {
      		path: 'salesOverview',
      		data: [
      			'<div>1.推手销售数据统计说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnGVus1N3TZ8LTPB8yuAA0Vb" target="_blank" class="helpjs-link">推手销售概况、销售明细中，主要统计：(1)自营销售：通过推手的链接进入商城，或与推手锁粉的客户，在商城内成功购买商品；或者推手自己进入商城购买商品；(2)与推手绑定直接推荐关系的其他推手，他们的自营销售额。商家可以在销售明细中，查看推手销售的每笔订单明细。</a>',
      		]
      	},

      	{
      		path: 'salesDetail',
      		data: [
      			'<div>1.推手销售数据统计说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnGVus1N3TZ8LTPB8yuAA0Vb" target="_blank" class="helpjs-link">推手销售概况、销售明细中，主要统计：(1)自营销售：通过推手的链接进入商城，或与推手锁粉的客户，在商城内成功购买商品；或者推手自己进入商城购买商品；(2)与推手绑定直接推荐关系的其他推手，他们的自营销售额。商家可以在销售明细中，查看推手销售的每笔订单明细。</a>',
      		]
      	},

      	{
      		path: 'promotionEffect',
      		data: [
      			'<div>1.商品推广效果统计说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnRDQZ3eS5apvsBDraaMBPJc" target="_blank" class="helpjs-link">针对商品进行数据统计：(1)销售额：通过推手分享的链接进入商城成功购买商品产生的销售额；(2)分享次数：推手分享商品的次数，1个推手分享商品多次，计多次；(3)分享访问次数：通过推手分享的链接，点击链接进入商城的次数，同1个客户多次点击链接进入商城，计多次；(4)素材下载次数：推手进入商品素材管理列表，下载商品素材的次数。</a>',
      		]
      	}, {
      		path: 'modeConfig',
      		data: [
      			'<div>1.推手模式配置说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn5LEXVL6DL4fmtFV3VYF4Gn" target="_blank" class="helpjs-link">商家可在该页面设置推手等级名称、权益说明、各等级升级条件。推手之间只有推荐关系，不同级别间的推手可以互相推荐。例如：A推荐B，A可能为级别一，B可能为级别二。</a>',
      		]
      	}, {
      		path: 'employeePerformanceIndex',
      		data: [
      			'<div>1.什么是店员分销？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnX8U1ziNo204FjDCSymSxkf" target="_blank" class="helpjs-link">将商城或门店的员工作为线上商城推广裂变中的一环，员工进入商城并分享商城链接给客户，客户点击链接后可与员工锁粉。商家可针对商品设置员工线上销售提成，促进员工分享积极性和主动性。</a>',

      			'<div class="helpjs-title">2.如何开启店员分销？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnNltIgVYz9DbnnVIuzGFBdf" target="_blank" class="helpjs-link">商城后台添加员工帐号→员工进入手机端工作台，成功登录→员工进入商城登录成功，此时系统即可识别员工身份，对应员工即可开启分销。</a>',

      			'<div class="helpjs-title">3.员工如何获得销售提成？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnjB4iGuNwCKAN7Vbez5ySlb" target="_blank" class="helpjs-link">员工（已经成功识别员工身份）进入小程序商城，将商品分享卡片或邀请海报发送给客户或分享商品素材到朋友圈，客户成功购买商品，根据商品里的提成配置，给员工发放线上销售提成。</a>',

      			'<div class="helpjs-title">4.员工如何提现销售提成？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnEEjTKjn07jUkEkmVVxt1fh" target="_blank" class="helpjs-link">员工进入手机版员工工作台，点击「立即提现」，即可提现提成（仅确认收货的订单带来的提成可被提现）。提现申请成功后，由商家在后台分销中心-分销提现中进行提现审核，目前企店支持商家通过企业支付宝自动转账提现金额。</a>',

      			'<div class="helpjs-title">5.店员业绩统计说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnFPc7GsuNszw6bdlxFEiCEp" target="_blank" class="helpjs-link">商家可以在该页面查看加入分销的员工，在线上的销售业绩、锁粉数据。可针对每个数据指标查看明细。其中：(1)支付业绩：和员工锁粉的客户，在商城内购买成功后，订单实付金额计入支付业绩；(2)结算业绩：和员工锁粉的客户，在商城内购买成功并确认收货后，扣除在确认收货前退款成功的金额，剩余金额计入结算业绩。</a>',
      		]
      	}, {
      		path: 'lockDetailpage',
      		data: [
      			'<div>1.员工如何和客户锁粉？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnY4LvCAhBJg2VRQUCUs02dh" target="_blank" class="helpjs-link">员工与客户锁粉的方式有3种：(1)员工分享商城小程序卡片或分享海报给客户，客户进入商城与员工锁粉；(2)员工发展客户成为推手，客户继续发展其他人成为推手，此时由客户发展的推手将自动与员工锁粉；(3)安装了企店助手的商家，客户成功添加了员工的企业微信后，双方锁粉。员工与客户的锁粉关系唯一，如果客户已经和其他员工锁粉，上述3种方式不会变更现有的锁粉关系。</a>',

      			'<div class="helpjs-title">2.客户和推手锁粉了，还能和员工锁粉吗？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnCExqtAluzJsSQVEkOufwEQ" target="_blank" class="helpjs-link">仍然支持和员工锁粉，锁粉成功后不影响和推手的锁粉关系，2条锁粉逻辑互相独立。</a>',
      		]
      	}, {
      		path: 'invitationPoster',
      		data: [
      			'<div>1.邀请海报使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnLN2YSRT6tnEiGy9dJ0AGLc" target="_blank" class="helpjs-link">商家可针对推手、员工分别设置对应的邀请海报。邀请海报设置成功后，推手进入小程序商城，在页面内点击「赚」图标，即可生成专属邀请海报；员工进入手机版工作台，点击「分享商城」，生成员工专属邀请海报。</a>',
      		]
      	}, {
      		path: 'withDrawal',
      		data: [
      			'<div>1.推手如何提现收益？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnuEmh1p23Jpou4kbtShp8kd" target="_blank" class="helpjs-link">推手进入小程序商城-推手中心，点击「立即提现」，即可提现收益（仅确认收货的订单带来的收益可被提现）。收益申请成功后，由商家在后台分销中心-分销提现中进行提现审核，目前企店支持商家通过企业支付宝自动转账提现金额。</a>',

      			'<div class="helpjs-title">2.员工如何提现销售提成？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnEEjTKjn07jUkEkmVVxt1fh" target="_blank" class="helpjs-link">员工进入手机版员工工作台，点击「立即提现」，即可提现提成（仅确认收货的订单带来的提成可被提现）。提现申请成功后，由商家在后台分销中心-分销提现中进行提现审核，目前企店支持商家通过企业支付宝自动转账提现金额。</a>',
      		]
      	}, {
      		path: 'wxAppManage',
      		data: [
      			'<div>1.微信小程序审核不通过原因及解决方案</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn5Gi7hi8VASElhOsKmCt75c" target="_blank" class="helpjs-link">小程序审核不通过主要会涉及：小程序服务类目、基础信息、上线后功能使用、内容、可用性和完整性等，同时微信审核人员还会根据微信小程序运营规范，对小程序里的内容进行综合审核。若遇到小程序审核不通过的情况，点击查看对应失败原因及解决方案。</a>',
      		]
      	}, {
      		path: 'qyWechat',
      		data: [
      			'<div>1.企业微信销售渠道开通说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnzh3LFdzEiyl2Cji4Bi17Rg" target="_blank" class="helpjs-link">操作路径：销售渠道→企业微信，如果您已有认证成功的企业微信，点击「安装企店助手」，进行扫码授权安装即可；若您还未申请企业微信，点击「前往申请」，进入企业微信官网网站，申请您公司的企业微信，待申请成功后回到该页面继续完成安装。</a>',
      		]
      	}, {
      		path: 'broadcastManage',
      		data: [
      			'<div>1.如何开通小程序直播？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnvED2TOlSKkVMrNGy0Quf8g" target="_blank" class="helpjs-link">满足以下条件的商家即可前往微信小程序后台，申请开通微信小程序直播能力：(1))微信小程序在目前开放的18个类目范围内；(2)主体下微信小程序近半年没有严重违规；(3)微信小程序近90天存在支付行为。</a>',

      			'<div class="helpjs-title">2.如何设置直播间分组？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnERlMRuuI31VQRLJsIloE8f" target="_blank" class="helpjs-link">在直播间分组管理列表页，点击「添加」，添加直播间分组；添加好的直播间分组，进入商城装修页，使用小程序直播间装修模块时，可选择按照分组展示直播间。</a>',


      			'<div class="helpjs-title">3.为什么直播已经结束了，小程序里还是显示进行中？ </div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnmgpFAzw1w3D4HtDcXKapic" target="_blank" class="helpjs-link">直播在小程序中显示的状态是根据直播间管理列表中，该场直播的状态显示，若商家直播结束了，但是根据商家设置的直播时间还未结束，直播的状态在后台显示还是在直播中，所有在小程序中显示也还在直播中。</a>',
      		]
      	}, {
      		path: 'LiveGroup',
      		data: [
      			'<div>1.直播间分组使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnk4fRri0Kfcjq0E69WOqORc" target="_blank" class="helpjs-link">添加好的直播间分组，进入商城装修页，使用小程序直播间装修模块时，可选择按照分组展示直播间。</a>',
      		]
      	}, {
      		path: 'GoodstockNo',
      		data: [
      			'<div>1.直播间商品库使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnhATJ7JO5rLnphS0DdVToku" target="_blank" class="helpjs-link">未入库商品：已经提交给微信官方审核，但还处理审核中或审核不通过的商品；通过直播间商品库-未入库商品、已入库商品功能，直接在商城后台选择直播间商品提交给微信官方审核，以及管理已审核通过的商品。</a>',
      		]
      	}, {
      		path: 'AlreadyCheckgood',
      		data: [
      			'<div>1.直播间商品库使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnhATJ7JO5rLnphS0DdVToku" target="_blank" class="helpjs-link">已入库商品：微信官方已经审核通过的商品，这些商品您可以在直播间控制台管理；通过直播间商品库-未入库商品、已入库商品功能，直接在商城后台选择直播间商品提交给微信官方审核，以及管理已审核通过的商品。</a>',
      		]
      	}, {
      		path: 'liveCutIn',
      		data: [
      			'<div>1.视频号直播接入说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccntRD6jBJ9Gb45gxq0Ay2xkh" target="_blank" class="helpjs-link">在该功能模块下，您可以申请开通视频号直播交易组件；开通成功后，视频号的推广员可以在视频号创作者中心，将商城里的商品添加至视频号的商品橱窗内。</a>',

      		]
      	}, {
      		path: 'shopwindow',
      		data: [
      			'<div>1.商品橱窗功能使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccns288uXMQn5a0pkatveRBpf" target="_blank" class="helpjs-link">完成视频号直播接入后，您可在企店商城后台→商品橱窗管理列表中，选择商城里的商品添加并提交给微信官方审核。微信官方审核通过的商品，才可上架到视频中。</a>',
      		]
      	}, {
      		path: 'categorymanagement',
      		data: [
      			'<div>1.商品类目说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnFFz9aMGqOftm2pqnL3eBYb" target="_blank" class="helpjs-link">根据微信官方要求，在视频号里上架的商品，需要设置具体的商品类目，微信官方会对商品以及商品类目进行审核，商品信息与选择的类目不符合，会审核失败，请根据商城经营实际情况添加类目与商品。</a>',
      		]
      	}, {
      		path: 'doorManager',
      		data: [
      			'<div>1.门店管理功能使用说明</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccn8qS5IawVt8mVZEyx8KXWgg" target="_blank" class="helpjs-link">若您在线下有开设门店，您可以在此处管理线下的门店信息，设置门店名称、门店定位、门店营业时间等；门店添加成功后，还可配置商品配送方式为：上门自提，客户进入商城购买商品时，可就近选择门店进行提货。</a>',
      		]
      	}, {
      		path: 'storePerformance',
      		data: [
      			'<div>1.什么是门店支付业绩？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnpr5p6jIBMwGRJOPwqbGLnf" target="_blank" class="helpjs-link">客户进入商城购买商品，支付成功后，如果客户与A门店的员工存在锁粉关系，该笔订单将被计入A门店的支付业绩中。</a>',

      			'<div class="helpjs-title">2.什么是门店结算业绩？</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnRCwBvQmUE7Hmdh31ou0Cxb" target="_blank" class="helpjs-link">客户购买商品时，如果与A门店员工存在锁粉关系，等待客户确认收货订单时，从订单实付金额中扣除确认收货前退款成功后金额，剩余金额计入A门店的结算业绩中。特别注意：如果客户消费时，选择上门自提，并且最终前往B门店完成提货，订单确认收货后，则计入B门店的结算业绩。</a>',
      		]
      	}, {
      		path: 'writeOffRecords',
      		data: [
      			'<div>1.自提订单核销教程</div>',
      			'<a href="https://jusnn6k8al.feishu.cn/docs/doccnRa5ox3ps2VMnY06gtzdoWh" target="_blank" class="helpjs-link">客户在商城内消费，选择配送方式为上门自提时，系统会生成到店自提订单，客户到店后，店员进入员工版工作台，即可对订单进行核销。点击查看详细教程。</a>',
      		]
      	}
      ]
