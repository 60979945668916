export const templateList = [{
		no: '01',
		name: '发布商品',
		icon: 'https://cdn.dkycn.cn/images/melyshop/noviceGuidance-icon/step1.png',
		desc: [
			'发布商品,迈出交易第一步(至少发布一个)',
			'企店为您提供了3种快速上架商品的方式，您可以选择：'
		],
		step: [
			'1.一键添加热卖单品',
			'2.直接发布商品',
			'3.通过表格导入',
		],
		btn: '开始发布 >',
		path: 'supplyMarket',
		children: [{
				name: '·一键添加热卖单品',
				path: 'supplyMarket',
				RecordType: 1,
				desc: [
					'我们从企店供货市场为您挑选了74款热卖单品，您可以点击一键添加到自己商城进行销售，丰富商品品类，快速完成商城铺货'
				],
				descBtn: '一键添加'
			},
			{
				name: '·直接发布商品',
				RecordType: 3,
				path: 'goodsList',
				desc: [
					'在商品列表页，点击【发布商品】，您可以选择发布单品或发布组合商品：'
				],
				
				step: [{
						type: 'text',
						value: '·单品：单个销售的商品。单品销售，库存独立。(为了您能更快提交小程序审核，我们为您添加了4款测试商品，您可以在小程序审核通过后删除或者选择直接删除，添加自己商城的商品)',
					},
					{
						type: 'text',
						value: '·组合商品：当您需要将多个单品组合在一起，打包销售，您可以发布组合商品。组合打包销售商品，库存由组合内的单品控制。',
					}
				],
				btn: '查看说明 >',
				popTitle: '直接发布商品',
				popList: [{
						title: '设置商品基本信息',
						data: [{
								type: 'text',
								value: '·  进入商品管理列表页，点击【发布商品】'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-2b0c2f80-4d59-4513-8f45-60edbb5b5187.png'
							},
							{
								type: 'text',
								value: '·  设置商品基本信息，包括：商品名称、商品图片、商品卖点等'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-c193a994-a0d4-4e6d-9e32-2c5555c5af55.png'
							}
						]
					}, {
						title: '添加商品规格信息',
						data: [{
								type: 'text',
								value: '·  添加单品时，您可以设置商品规格，并根据规格设置售价、库存等信息'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-4aea135c-19bd-4c1a-8689-d1818e1d7681.png'
							},
							{
								type: 'text',
								value: '·  添加组合商品（打包销售的商品）时，您直接选择组合内的单品'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-26a1e7fd-8b51-4105-82df-107a20f09279.png'
							}
						]
					},
					{
						title: '选择商品配送方式',
						data: [{
								type: 'text',
								value: '·  选择商品配送方式：快递发货、到店自提（若您没有线下门店，选择快递发货即可）'
							},
							{
								type: 'text',
								value: '<span style="color:#999;font-size:12px">当选中快递发货时，需要您给商品配置运费规则，您可以选择全国统一运费或者使用运费模板，在体验发布商品这个功能的过程当中，您可以先选择全国统一运费</span>'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-ed314341-da58-4000-a7fe-e585a9e03397.png'
							}
						]
					},
					{
						title: '编辑商品详情内容',
						data: [{
								type: 'text',
								value: '·  编辑商品详情信息，您可以上传图片或编辑文字'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-5b7cd420-cc23-4aa9-98ec-7bcc2f8fb666.png'
							}, {
								type: 'text',
								value: '·  商品详情编辑完后，您就可以保存商品信息了哦'
							},
							{
								type: 'text',
								value: '<span style="color:#999;font-size:12px"> 此时您可以选择：暂时将商品保存到仓库、直接上架到商城进行销售</span>'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-e860a0ae-b460-4a1a-b154-6282e915031f.png'
							}
						]
					}
				]
			},
			{
				name: '·通过表格导入',
				RecordType: 11,
				path: 'goodsExport',
				desc: [
					'如果您之前使用过其他电商软件或在电商平台有商品数据，您可以使用商品导入功能，导入商品数据表格批量添加商品，操作步骤说明如下：'
				],
				step: [{
						type: 'text',
						value: '·在商品导入页，点击【商品导入】，并下载商品导入模板',
					},
					{
						type: 'text',
						value: '·在下载好的模板中，填充商品信息，包含：商品名称、商品规格信息、规格售价、库存、商品图片等',
					},
					{
						type: 'text',
						value: '·返回商品导入页，上传填写完成的表格，点击【确认导入】即可',
					},
					{
						type: 'text',
						value: '·导入完成后，若存在导入失败的商品数据，系统支持下载相应数据，待您修改完成后，按照上述步骤重新完成导入',
					},
				],
				btn: '查看图文说明 >',
				popTitle: '表格导入商品',
				popList: [{
					title: '下载商品导入模板',
					data: [{
							type: 'text',
							value: '·  进入商品导入页面，点击【商品导入】，并在弹框中下载导入模板'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-8da16f51-fdbb-44b6-a5f7-5cdb5f7d9731.png'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-2d025d86-6476-49ad-a161-25445a2f3a79.png'
						}
					]
				}, {
					title: '完善需要导入的商品信息',
					data: [{
							type: 'text',
							value: '·  在下载好的模板文件中，补充要导入的商品信息'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px"> 补充商品名称、商品规格、规格售价、规格库存、商品配送方式；如果您原来的商品数据，支持导出商品图片和商品详情图片的地址，您可以将地址复制粘贴在表格里的对应列，系统会在导入时将图片一同导入</span>'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-e45a2a36-8685-49dc-b7b9-69c986956a03.png'
						}
					]
				}, {
					title: '开始导入',
					data: [{
							type: 'text',
							value: '·  商品数据完善好后，回到商品导入功能页，点击【确定导入】，上传您的表格，开始导入'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px"> 如果您的商品数据中，配送方式包含快递发货的商品，为了保证商品导入后的数据完整性，您需要在开始导入前，先为商品选定好运费规则哦</span>'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-d3ca6c39-24f4-4e27-8682-df8f675d3c7b.png'
						}
					]
				}]
			}
		]
	},
	{
		no: '02',
		name: '创建营销活动',
		icon: 'https://cdn.dkycn.cn/images/melyshop/noviceGuidance-icon/step2.png',
		desc: [
			'商城上线初期，配合一些简单的营销活动，更快促进首单成交。',
			'你可以添加:'
		],
		step: [
			'1.商城优惠券',
			'2.限时折扣活动',
			'3.满减送活动',
		],
		btn: '开始创建 >',
		path: 'coupon',
		children: [{
				name: '·商城优惠券',
				RecordType: 4,
				path: 'coupon',
				desc: [
					'在优惠券管理列表页，点击【添加】，开始创建优惠券：'
				],
				step: [{
						type: 'text',
						value: '·您可以选择添加减金额券，或者打折券',
					},
					{
						type: 'text',
						value: '·给优惠券设置使用的门槛，商城搭建初期，您可以创建无门槛的优惠券，便于吸引客户领取与成交',
					},
					{
						type: 'text',
						value: '·创建成功的优惠券，您可以用于：（1）新客户进入商城自动赠送，促转化；（2）满减送活动中，达到活动门槛赠送优惠券，促复购；（3）在装修商城时，关联优惠券等<a href="https://jusnn6k8al.feishu.cn/docs/doccnsrdAs8KE0uJTuKuRom0H6c" target="_blank" style="color:#409EEF">查看优惠券更多用途</a>',
					}
				],
				btn: '查看说明 >',
				popTitle: '创建商城优惠券',
				popList: [{
					title: '选择优惠券类型',
					data: [{
							type: 'text',
							value: '·  进入优惠券管理列表页，点击【添加】，选择添加的优惠券类型'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">满减券：使用优惠券时，按照优惠券上的面值，扣减金额</span>'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">打折券：使用优惠券时，按照优惠券上的折扣，进行打折</span>'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">随机金额券：您可以给优惠券面值设置一个随机金额，比如0.01~100元，客户在成功领取了优惠券后，系统将随机产生优惠券面值</span>'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-8df91de5-fb7f-47d4-bcd9-a33801b4a197.png'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-2fd49ab4-9067-44a1-a7bb-44ee419f1703.png'
						}
					]
				}, {
					title: '设置优惠券基本信息',
					data: [{
							type: 'text',
							value: '·  设置优惠券的名称、适用的商品范围、适用门槛和面值'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-ec99f36c-c597-4b2c-8b0e-771c1b642646.png'
						}
					]
				}, {
					title: '设置优惠券使用期限',
					data: [{
							type: 'text',
							value: '·  优惠券的使用期限，您可以设置：固定的使用期限、自客户领取成功当天起N天内使用、自客户领取成功次日起N天内使用'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-5bb1e495-1502-489e-b581-7440bbf92426.png'
						}
					]
				}, {
					title: '投放优惠券',
					data: [{
							type: 'text',
							value: '·  添加完成的优惠券，您可以通过以下方式进行投放：'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">公众号菜单中，给菜单关联一张优惠券的链接，关注公众号的客户可点击领取</span>'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-60e71efb-6d17-40cf-83e1-ca52b9c36545.png'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">在装修小程序商城时，将优惠券放置在商城页面中，进入商城的客户可点击领取</span>'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-9abd66d3-9a52-4a1d-8d63-e771acee8e1f.png'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">与其他营销活动相配合，给客户赠送优惠券，例如：满减送活动可配置赠送优惠券、转盘抽奖可配置奖品是优惠券等</span>'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-6f000863-2f32-457b-b6f1-ca129bc98339.png'
						}
					]
				}]
			},
			{
				name: '·限时折扣活动',
				RecordType: 5,
				path: 'limitedDiscount',
				desc: [
					'在限时折扣活动管理列表页，点击【添加】，开始创建活动：'
				],
				step: [{
						type: 'text',
						value: '·选择活动商品',
					},
					{
						type: 'text',
						value: '·设置商品折扣',
					},
					{
						type: 'text',
						value: '·活动曝光',
					},
				],
				btn: '查看说明>',
				popTitle: '创建限时折扣活动',
				popList: [{
					title: '设置活动基本信息',
					data: [{
							type: 'text',
							value: '·  进入限时折扣活动管理列表页，点击【添加】，开始创建限时折扣活动'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-d97757fd-1e7a-48cf-a663-4fcadda80c64.png'
						}
					]
				}, {
					title: '选择参与活动的商品',
					data: [{
							type: 'text',
							value: '·  设置活动基本信息，选择参与折扣活动的商品'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-b1909320-ca16-494a-8647-e44ff8165ada.png'
						}
					]
				}, {
					title: '设置商品打折形式',
					data: [{
							type: 'text',
							value: '·  设置商品打折形式，打折形式支持：'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">设置折扣：这是一种常规的打折形式，在您对商品的原价进行调整时，根据活动里设置的折扣，灵活计算折后价</span>'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">设置扣减金额：在商品原价的基础上，扣减N元，这种形式和设置折扣差不多，只是将折扣换成了扣减的金额，当您的折扣力度并没有那么大幅度时，您可以使用这种形式</span>'
						},
						{
							type: 'text',
							value: '<span style="color:#999;font-size:12px">设置活动价：直接设置活动价，设置完成后，无论商品原价如何变更，客户均以活动价进行购买，这种形式比较适合用于低价引流，例如：1分钱抢洗脸巾</span>'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-a683dd5a-f4f4-4707-b761-cfde65d64039.png'
						}
					]
				}, {
					title: '活动曝光',
					data: [{
							type: 'text',
							value: '·  保存成功的限时折扣活动，您可以在商城装修时，通过添加限时折扣活动模块进行活动曝光'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-2bf21ba7-52ee-4499-b469-73df74afd180.png'
						}
					]
				}]
			},
			{
				name: '·满减送活动',
				RecordType: 6,
				path: 'discountActivity',
				desc: [
					'在满减送活动管理列表页，点击【添加】，开始创建活动：'
				],
				step: [{
						type: 'text',
						value: '·选择满减活动类型：满N元参加活动、满N件参加活动',
					},
					{
						type: 'text',
						value: '·设置活动基本信息，选择参与活动的商品',
					},
					{
						type: 'text',
						value: '·设置活动优惠内容，保存活动',
					},
				],
				btn: '查看说明>',
				popTitle: '创建满减送活动',
				popList: [{
						title: '选择满减类型',
						data: [{
								type: 'text',
								value: '·  进入满减送活动管理列表，点击【添加】，并选择对应的活动类型，进入创建活动'
							},
							{
								type: 'text',
								value: '<span style="color:#999;font-size:12px">活动类型可选择：消费满N元减/送，或者购买满N件减/送</span>'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-657d1d7a-1fb2-4dca-bb17-672f0054d0e4.png'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-9d436328-2bbd-4dcd-b7cb-9efc1646c8af.png'
							}
						]
					}, {
						title: '设置活动基本信息',
						data: [{
								type: 'text',
								value: '·  设置活动基本信息，选择参与活动的商品'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-cda60190-2904-467e-b383-9d9fd01318d8.png'
							}
						]
					}, {
						title: '设置活动优惠',
						data: [{
								type: 'text',
								value: '·  设置活动门槛和活动优惠内容'
							},
							{
								type: 'text',
								value: '<span style="color:#999;font-size:12px">活动门槛：您可以设置阶梯门槛或者循环门槛；阶梯门槛：满100元减5元，满200减减10元，以此类型；循环门槛：每满100元减5元</span>'
							},
							{
								type: 'text',
								value: '<span style="color:#999;font-size:12px">活动优惠内容：您可以设置减金额或打折、赠送优惠券、积分或者商品等</span>'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-ad9d8463-e7ec-4ad3-85dd-13dbb0720b74.png'
							}
						]
					},
					{
						title: '保存活动',
						data: [{
								type: 'text',
								value: '·  设置完成后，保存活动，等活动开始后，客户进入小程序，可看到活动信息'
							},
							{
								type: 'image',
								value: 'https://cdn.dkycn.cn/melyshop/image/20211213-143b5d52-17a8-42ad-845d-f1125ba6ec5e.png'
							}
						]
					}
				]
			}
		]
	},
	{
		no: '03',
		name: '装修商城',
		icon: 'https://cdn.dkycn.cn/images/melyshop/noviceGuidance-icon/step3.png',
		desc: [
			'让好的装修留住客户。',
			'我们为您的商城预先创建了一个默认的商城首页，您可以在这个页面继续完成装修。'
		],
		step: [],
		btn: '开始装修 >',
		path: 'mallPagemange',
		children: [{
			name: '·装修商城',
			RecordType: 7,
			path: 'mallPagemange',
			desc: [
				'在装修功能模块下，您可以通过系统现有的装修模块完成您商城首页和其他页面的搭建：'
			],
			step: [{
					type: 'text',
					value: '·我们为您的商城预先创建了一个默认的商城首页，您可以在这个页面继续完成装修。',
				},
				{
					type: 'text',
					value: '·我们还为您提供了一些装修模板，使用模板后，您只需要更换页面中的内容即可。',
				}
			],
			btn: '查看说明>',
			popTitle: '装修商城',
			popList: [{
				title: '添加或编辑商城页面',
				data: [{
						type: 'text',
						value: '·  为了让您可以快速的提交小程序审核，我们为您添加了一个默认首页，您可以直接对这个首页进行编辑'
					},
					{
						type: 'image',
						value: 'https://cdn.dkycn.cn/melyshop/image/20211213-e854a2f8-a376-4eab-9bd1-182103aebc37.png'
					}
				]
			}, {
				title: '选择装修模块',
				data: [{
						type: 'text',
						value: '·  进入到商城装修界面，您可以从左侧模块中，挑选需要的模块，点击添加，模块添加成功后，在中间实时预览区，您可以看到这个模块'
					},
					{
						type: 'image',
						value: 'https://cdn.dkycn.cn/melyshop/image/20211213-9c1fe2b2-92d9-4dd0-8558-83b42e793756.png'
					}
				]
			}, {
				title: '编辑模块内容',
				data: [{
						type: 'text',
						value: '·  模块添加成功后，在右侧编辑区，您可以对模块的内容进行编辑'
					},
					{
						type: 'image',
						value: 'https://cdn.dkycn.cn/melyshop/image/20211213-25d6e464-ee3a-40a7-b81f-faf67cca9e75.png'
					}
				]
			}, {
				title: '预览或保存装修效果',
				data: [{
						type: 'text',
						value: '·  编辑完成后，点击【预览】，您可以扫码在手机端查看预览效果；预览效果满意，您可以发布这个页面'
					},
					{
						type: 'image',
						value: 'https://cdn.dkycn.cn/melyshop/image/20211213-c86ece92-d471-4bab-b7d6-491141779234.png'
					}
				]
			}]
		}]
	},
	{
		no: '04',
		name: '设置商城售后信息',
		icon: 'https://cdn.dkycn.cn/images/melyshop/noviceGuidance-icon/step4.png',
		desc: [
			'完善商城售后信息，设置售后地址&售后电话等,提高客户满意度'
		],
		step: [],
		btn: '开始设置 >',
		path: 'editSetting',
		children: [{
			name: '·设置商城售后信息',
			RecordType: 8,
			path: 'editSetting',
			desc: [
				'完善商城售后信息，设置售后地址&售后电话等,提高客户满意度'
			],
			btn: '查看说明>',
			popTitle: '商城基础设置',
			popList: [{
				title: '页面内点击添加',
				data: [{
						type: 'text',
						value: '·  进入设置中心→商城设置页，对售后联系电话和地址进行设置'
					},
					{
						type: 'image',
						value: 'https://cdn.dkycn.cn/melyshop/image/20211213-2bb69ddd-f595-4a34-b15b-75ec6c0012fd.png'
					}
				]
			}]
		}]
	},
	{
		no: '05',
		name: '推广商城，完成首单交易',
		icon: 'https://cdn.dkycn.cn/images/melyshop/noviceGuidance-icon/step5.png',
		desc: [
			'到了这一步，商城里的内容都搭建的差不多啦',
			'接下来我们就要开始发布小程序，正式推广商城，完成首单交易！'
		],
		step: [],
		btn: '开始推广 >',
		path: 'wxAppManage',
		children: [{
				name: '·小程序提交审核与发布',
				RecordType: 9,
				path: 'wxAppManage',
				desc: [
					'企店目前以小程序为载体为您搭建商城，因此在正式推广商城之前，请您先将小程序授权绑定给我们：'
				],
				step: [{
						type: 'text',
						value: '·在微信当前页面，点击【授权绑定微信小程序】，进入扫码授权绑定',
					},
					{
						type: 'text',
						value: '·若您暂时没有小程序，可以前往<a href="https://mp.weixin.qq.com/" target="_blank" style="color:#409EEF">微信公众平台</a>申请',
					}
				],
				btn: '查看小程序绑定说明>',
				popTitle: '小程序提交审核与发布',
				popList: [{
					title: '扫码绑定小程序',
					data: [{
							type: 'text',
							value: '·  进入销售渠道→微信小程序页面，点击【授权绑定微信小程序】，并联系小程序管理员扫码页面上出现的二维码，扫码完成后，按照手机上的提示确认授权'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-3c5ba001-38a5-4bd5-a610-707846150882.png'
						}
					]
				}, {
					title: '提交小程序审核',
					data: [{
							type: 'text',
							value: '·  小程序绑定成功后，在页面上点击【提交审核】，等待微信审核'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-1e493f7d-6abb-4f12-951a-ead94dbde7a2.png'
						}
					]
				}, {
					title: '发布小程序上线',
					data: [{
							type: 'text',
							value: '·  小程序审核成功后，小程序管理员的微信上将会收到由微信官方发送的通知提醒，此时您仅需要再次进入该页面，点击【发布】，商城小程序即发布成功'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-1ce30c92-55b0-47c8-96fe-ab8e34c22fa7.png'
						}
					]
				}]
			},
			{
				name: '·微信支付申请与签约',
				path: 'settingPay',
				RecordType: 10,
				desc: [
					'申请支付，开通商城支付通道，申请流程如下：'
				],
				step: [{
						type: 'text',
						value: '·开户进件：在线提交营业执照、身份证、银行账户等基本信息，并按指引提交资料',
					},
					{
						type: 'text',
						value: '·补充证照：进件成功后，补充照片材料，并提交审核',
					},
					{
						type: 'text',
						value: '·签约入网：证照材料审核成功后，按照页面引导，完成实名认证，开通支付',
					}
				],
				btn: '查看图文说明>',
				popTitle: '微信支付申请与签约',
				popList: [{
					title: '开户进件',
					data: [{
							type: 'text',
							value: '·  开户进件：在线提交营业执照、身份证、银行账户等基本信息，并按指引提交资料'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-fce41cfc-2095-4e23-b4ce-731b3389dda2.png'
						}
					]
				}, {
					title: '补充证照材料',
					data: [{
							type: 'text',
							value: '·  补充证照：进件成功后，补充照片材料，并提交审核'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-06ab400c-ffae-4e6c-a44f-1483f9fbe724.png'
						}
					]
				}, {
					title: '签约入网',
					data: [{
							type: 'text',
							value: '·  签约入网：证照材料审核成功后，按照页面引导，完成实名认证，开通支付'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-71e16f5f-af79-4a5f-9f3f-bcde6c0e8f0f.png'
						}
					]
				}, {
					title: '客户支付',
					data: [{
							type: 'text',
							value: '·  客户进入商城，购买商品即可支付'
						},
						{
							type: 'image',
							value: 'https://cdn.dkycn.cn/melyshop/image/20211213-da99e415-5fec-454c-a3a6-3c4e34ecc498.png'
						}
					]
				}]
			},
			{
				name: '·推广商城，完成首单',
				path: 'orderMange',
				desc: [
					'一切准备就绪，快开始推广您的新商城吧！'
				],
				step: [{
					type: 'text',
					value: '·准备好发圈文案，<a href="https://mp.weixin.qq.com/wxopen/waregister?action=step1&token=&lang=zh_CN" target="_blank" style="color:#409EEF">点击下载商城小程序码</a>，下载好的小程序码，您可以直接分至朋友圈或者微信群聊，引导客户扫码进入您的商城；如果您有设计能力或者设计人员，您可以设计一些精美的海报图和文案，更有利于商城推广',
				},{
					type: 'text',
					value: '·如果您有线下门店，您还可以在门店张贴成为会员的小程序码，通过线下门店的流量，为线上商城吸引新客。商城创建初期，您可以降低成为会员的门槛，例如：0元入会并赠送优惠券等<a href="https://jusnn6k8al.feishu.cn/docs/doccnwle3ADt2ZbInZwrcbXhVwd3" target="_blank" style="color:#409EEF">（查看如何设置）</a>。<a href="https://jusnn6k8al.feishu.cn/docs/doccnwle3ADt2ZbInZwrcbXhVwd3" target="_blank" style="color:#409EEF">点击下载成为会员小程序码</a>',
				},{
					type: 'text',
					value: '·您还可以发动您的员工，为您推广商城。您可以给员工开启锁粉提成，给员工一些小小的奖励，从而提高员工的积极性。<a href="https://jusnn6k8al.feishu.cn/docs/doccnNltIgVYz9DbnnVIuzGFBdf" target="_blank" style="color:#409EEF">查看如何开启员工锁粉提成</a>',
				}]
			}
		]
	}
]
