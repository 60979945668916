<template>
	<div :class="classObj" class="app-wrapper">
		<div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
		<!-- @getIshowmenu="getIshowmenu" -->
		<sidebar class="sidebar-container" :mylyshopList='mylyshopList' :mySelectMenu="mySelectMenu"  />
		<div class="main-container">
			<!-- <navbar/> -->
			<!-- :IsgetIshowmenu='IsgetIshowmenu' -->
			<tags-view  @getRourterList="getRourterList"  @selctMenu="selctMenu"  :mySelectMenu="mySelectMenu"/>
			
			<app-main  />
		</div>
		
		<step></step>
		
	</div>
</template>

<script>
	
	import {
		Sidebar,
		AppMain,
		TagsView,
		Navbar
	} from './components'
	import ResizeMixin from './mixin/ResizeHandler'
	
	import step from './components/step.vue';

	export default {
		name: 'Layout',
		components: {
			Navbar,
			Sidebar,
			AppMain,
			TagsView,
			step
		},
		created () {
			
		},
		mixins: [ResizeMixin],
		computed: {
			sidebar() {
				return this.$store.state.app.sidebar
			},
			device() {
				return this.$store.state.app.device
			},
			classObj() {
				return {
					hideSidebar: !this.sidebar.opened,
					openSidebar: this.sidebar.opened,
					withoutAnimation: this.sidebar.withoutAnimation,
					mobile: this.device === 'mobile'
				}
			}
		},
		data(){
			return{
				mylyshopList:{},
				mySelectMenu:''
			}
		},
		methods: {
			handleClickOutside() {
				this.$store.dispatch('closeSideBar', {
					withoutAnimation: false
				})
			},
			getRourterList(data){
				this.mylyshopList = data;
				// console.log(this.mylyshopList,123123)
			},
			selctMenu(record){
				this.mySelectMenu = record
			},
			
			
			// 获取是否展示左边菜单
			// getIshowmenu(val){
			// 	this.IsgetIshowmenu=val
			// }
		}
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/css/mixin.less";
	.app-wrapper {
		@include clearfix;
		position: relative;
		height: 100%;
		width: 100%;

		&.mobile.openSidebar {
			position: fixed;
			top: 0px;
		}
	}

	.drawer-bg {
		background: #000;
		opacity: 0.3;
		width: 100%;
		top: 0;
		height: 100%;
		position: absolute;
		z-index: 999;
	}
	
	
	
</style>
