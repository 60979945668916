import request from '@/utils/request'
// 枚举列表
const enumList = (data) => request('/pc/enum/list', data);

//获取协议标题 内容
const systemAgreementConfiglist = (data) => request('/admin/systemAgreementConfig/list', data);

// 优惠券列表
const activitycouponCouponlist = (data) => request('/pc/activitycoupon/couponlist', data);
// 优惠券删除
const activitycouponActivitycoupondelete = (data) => request('/pc/activitycoupon/activitycoupondelete', data);
// 获取优惠券详情
const activitycouponActivitycoupondetail = (data) => request('/pc/activitycoupon/activitycoupondetail', data);
// 添加编辑优惠券
const activitycouponActivitycouponedit = (data) => request('/pc/activitycoupon/activitycouponedit', data);
// 优惠券设置失效
const activitycouponActivitycouponsetisopen = (data) => request('/pc/activitycoupon/activitycouponsetisopen', data);
// 优惠券数据统计
const activitycouponActivitycouponstatistical = (data) => request('/pc/activitycoupon/activitycouponstatistical', data);
// 获取弹窗产品列表
const activitycouponChoseproductlist = (data) => request('/pc/activitycoupon/choseproductlist', data);
// 优惠券是否展示员工小程序
const activitycouponisshowemployeewxapp = (data) => request('/pc/activitycoupon/activitycouponisshowemployeewxapp',
	data);


// 满减活动-选择商品弹框,与activitycouponChoseproductlist接口类似
const activityMarkdownGiftChoseProduct = (data) => request('/pc/ActivityMarkdownGift/ChoseProduct', data);

// 满减活动-添加/编辑满减送活动
const activityMarkdownGiftAdd = (data) => request('/pc/ActivityMarkdownGift/Add', data);
// 满减活动-选择优惠卷弹框
const activityMarkdownGiftChoseCoupon = (data) => request('/pc/ActivityMarkdownGift/ChoseCoupon', data);

// 满减送活动-点击删除
const activityMarkdownGiftDel = (data) => request('/pc/ActivityMarkdownGift/Del', data);
// 满减送活动-设置循环满减
const activityMarkdownGiftIsLoop = (data) => request('/pc/ActivityMarkdownGift/IsLoop', data);
// 满减送活动-点击终止
const activityMarkdownGiftIsOpen = (data) => request('/pc/ActivityMarkdownGift/IsOpen', data);
// 满减送活动-满减送列表
const activityMarkdownGiftList = (data) => request('/pc/ActivityMarkdownGift/List', data);
//  满减送活动-获取满减送详情
const activityMarkdownGiftModel = (data) => request('/pc/ActivityMarkdownGift/Model', data);
//  满减送活动-验证分销礼包商品
const verifyAuthGift = (data) => request('/pc/ActivityMarkdownGift/verifyAuthGift', data);

// 抽奖活动-删除
const activityWheelSurfDel = (data) => request('/pc/activityWheelSurf/delete', data);
// 抽奖活动-详情
const activityWheelSurfInfo = (data) => request('/pc/activityWheelSurf/info', data);
// 抽奖活动-详情
const activityWheelSurfDetail = (data) => request('/pc/activityWheelSurf/detail', data);
// 抽奖活动-列表
const activityWheelSurfList = (data) => request('/pc/activityWheelSurf/list', data);
// 抽奖活动-使终止
const activityWheelSurfOpen = (data) => request('/pc/activityWheelSurf/open', data);
//  抽奖活动-商品列表
const activityWheelSurfProductList = (data) => request('/pc/activityWheelSurf/productList', data);
//  抽奖活动-保存
const activityWheelSurfSave = (data) => request('/pc/activityWheelSurf/save', data);
//  抽奖活动-弹窗列表 
const activityWheelSurfPopupList = (data) => request('/pc/activityWheelSurf/popupList', data);
//  抽奖活动-赠送抽奖次数-查找客户
const activityWheelSurfSearchMember = (data) => request('/pc/activityWheelSurf/searchMember', data);
//  抽奖活动-赠送抽奖次数-查找客户
const activityWheelSurfSendNumber = (data) => request('/pc/activityWheelSurf/sendNumber', data);


//   社群配置-初始化
const communityGroupSettingInit = (data) => request('/pc/communityGroupSetting/init', data);
//  社群配置-保存 
const communityGroupSettingSave = (data) => request('/pc/communityGroupSetting/save', data);
//   社群数据
const communityGroupData = (data) => request('/pc/communityGroup/communityGroupData', data);

// 限时折扣弹窗列表 
const activityDiscountPopupList = (data) => request('/pc/activityDiscount/popupList', data);

//商品-获取商品规格信息
const productSpecGet = (data) => request('/pc/productSpec/get', data);

// 订单返现失效
const activitycashbackClose = (data) => request('/pc/activity-cashback/close', data);
// 订单返现删除
const activitycashbackDelete = (data) => request('/pc/activity-cashback/delete', data);
// 订单返现编辑初始化
const activitycashbackEditInit = (data) => request('/pc/activity-cashback/edit-init', data);
//  订单返现编辑保存
const activitycashbackEditsave = (data) => request('/pc/activity-cashback/edit-save', data);
//  订单返现活动列表
const activitycashbackIndex = (data) => request('/pc/activity-cashback/index', data);

// 公共-优惠券弹窗列表 cxd
const activitycouponpopupList = (data) => request('/pc/activitycoupon/popupList', data);
// 商品弹窗列表 cxd
const productSpecPopList = (data) => request('/pc/productSpec/popList', data);


// 积分商城-删除
const activityPointExchangeDelete = (data) => request('/pc/activityPointExchange/delete', data);
// 积分商城-详情
const activityPointExchangeInfo = (data) => request('/pc/activityPointExchange/info', data);
//  积分商城-列表
const activityPointExchangeList = (data) => request('/pc/activityPointExchange/list', data);
//  积分商城-使终止
const activityPointExchangeOpen = (data) => request('/pc/activityPointExchange/open', data);
//  积分商城-商品列表
const activityPointExchangeProductList = (data) => request('/pc/activityPointExchange/productList', data);
//  积分商城-保存
const activityPointExchangeSave = (data) => request('/pc/activityPointExchange/save', data);


//  签到统计
const memberSignRecordList = (data) => request('/pc/MemberSignRecord/List', data);
//  签到设置-编辑
const signConfigEdit = (data) => request('/pc/SignConfig/Edit', data);
//  签到设置-详情
const signConfigModel = (data) => request('/pc/SignConfig/Model', data);
// 签到订阅初始化
const SignConfigSubscribeInit = (data) => request('/pc/SignConfig/SubscribeInit', data);
//  签到订阅设置
const SignConfigSubscribeEdit = (data) => request('/pc/SignConfig/SubscribeEdit', data);
// 签到页广告位初始化
const SignConfigAdvertiseSpaceInit = (data) => request('/pc/SignConfig/AdvertiseSpaceInit', data);
//  签到页广告位设置
const SignConfigAdvertiseSpaceSave = (data) => request('/pc/SignConfig/AdvertiseSpaceSave', data);



// 退款/售后列表
const ordersupportIndex = (data) => request('/pc/order-support/index', data);
// 退款/售后列表导出orderExport
const ordersupportExport = (data) => request('/pc/order-support/index-export', data);
// 退款/售后列表初始化
const ordersupportInit = (data) => request('/pc/order-support/init', data);
//  订单退款详情
const ordersupportDetail = (data) => request('/pc/order-support/look-detail', data);
//  售后单查看物流
const ordersupportExpress = (data) => request('/pc/order-support/look-express', data);
//  订单退款同意申请
const ordersupportAgreeapply = (data) => request('/pc/order-support/agree-apply', data);
//  订单退款同意退款
const ordersupportAgreerefund = (data) => request('/pc/order-support/agree-refund', data);
//  订单退款不同意申请
const ordersupportRefuseapply = (data) => request('/pc/order-support/refuse-apply', data);
//  订单退款不同意退款
const ordersupportRefuserefund = (data) => request('/pc/order-support/refuse-refund', data);
//  订单退款关闭申请
const ordersupportCloseApply = (data) => request('/pc/order-support/close-apply', data);
//  订单发货初始化
const orderSendInit = (data) => request('/pc/order/send-init', data);






//  订单导出
const orderExport = (data) => request('/pc/order/export', data);
//  订单导出记录 
const orderExportRecordIndex = (data) => request('/pc/order-export-record/index', data);
//  同团订单
const orderSameGroupOrder = (data) => request('/pc/order/same-group-order', data);

// 分销供货单初始化
const supplierOrderInit = (data) => request('/pc/supplier-order-support/init', data);
// 分销供货单列表
const supplierOrderIndex = (data) => request('/pc/supplier-order-support/index', data);
//  供货商订单详情
const supplierOrderDetail = (data) => request('/pc/supplier-order-support/look-detail', data);
//  供货商售后单查看物流 
const supplierOrderExpress = (data) => request('/pc/supplier-order-support/look-express', data);
//  供货商售后单查看物流 
const supplierMarketProductInfo = (data) => request('/pc/supplierChain/supplierMarketProductInfo', data);
//  供货商品详情运费规则 
const freightTemplate = (data) => request('/pc/product/freightTemplate', data);

//  供货商订单退款同意退款
const supplierOrderAgreeRefund = (data) => request('/pc/supplier-order-support/agree-refund', data);
//  供货商订单退款同意退款
const supplierOrderAgreeApply = (data) => request('/pc/supplier-order-support/agree-apply', data);
//  供货商订单退款否决退款申请
const supplierOrderRefuseApply = (data) => request('/pc/supplier-order-support/refuse-apply', data);
//  供货商订单退款同意退款
const supplierOrderRefuseRefund = (data) => request('/pc/supplier-order-support/refuse-refund', data);
//   供货商订单发货初始化
const supplierOrderSendInit = (data) => request('/pc/supplier-order/send-init', data);

//批量添加供货商品
const BatchSupplierMarketProductSave = (data) => request('/pc/supplierChain/BatchSupplierMarketProductSave', data);


//  页面管理编辑选择商品
const mallShopDecorateSelect = (data) => request('/pc/mall-shop-decorate/index', data);
//  页面管理编辑通过分组选择商品
const mallShopDecorateSelectGroup = (data) => request('/pc/mall-shop-decorate/edit-select-product-by-group', data);

//  评价列表批量审核（审核也用这个）
const commentBatchCheck = (data) => request('/pc/product-comment/batch-check', data);
//  评价列表删除（删除评论同时会删除追评）
const commentDelete = (data) => request('/pc/product-comment/delete', data);
//  评价列表
const commentList = (data) => request('/pc/product-comment/index', data);
//  评价列表商家回复
const commentMallReply = (data) => request('/pc/product-comment/mall-reply', data);
//  签到设置-是否开启配置
const SignConfigIsOpen = (data) => request('/pc/SignConfig/IsOpen', data);
//  签到设置-开启状态初始化
const SignConfigIsOpenState = (data) => request('/pc/SignConfig/IsOpenState', data);

//  数据统计-流量概览SZT
const dataStatisFlowOverview = (data) => request('/pc/dataStatis/flowOverview', data);
//  数据统计-流量来源（前端分页）
const dataStatisFlowReferer = (data) => request('/pc/dataStatis/flowReferer', data);
//  数据统计-流量来源趋势（返回30天数据）
const dataStatisFlowRefererTrend = (data) => request('/pc/dataStatis/flowRefererTrend', data);
//  数据统计-流量趋势SZT
const dataStatisFlowTrend = (data) => request('/pc/dataStatis/flowTrend', data);
//  访客分析--访客地域分析
const visitorVisitorAreaAnalyze = (data) => request('/pc/dataStatis/visitor/visitorAreaAnalyze', data);
//  访客分析-访客深度
const visitorVisitorDepth = (data) => request('/pc/dataStatis/visitor/visitorDepth', data);
//  页面分析
const dataStatisPageAnalyze = (data) => request('/pc/dataStatisPageAnalyze/index', data);
//  客户概览
const dataStatisCustomerOverview = (data) => request('/pc/dataStatisCustomerOverview/customerOverview', data);
//  客户概览-客户留存分析
const dataStatisCustomerRetention = (data) => request('/pc/dataStatisCustomerOverview/customerRetention', data);
//  客户概览--客户成交分析、会员粉丝成交
const dataStatisTradingAnalyze = (data) => request('/pc/dataStatisCustomerOverview/tradingAnalyze', data);

//  微信分析分析-粉丝分层
const wxFansAnalyzeFanLayered = (data) => request('/pc/dataStatisWxFansAnalyze/fanLayered', data);
//  粉丝增减
const FansIncreaseOrDecrease = (data) => request('/pc/dataStatisWxFansAnalyze/fansIncreaseOrDecreaseApi', data);
//  粉丝信息
const wxFansAnalyzeFansInfo = (data) => request('/pc/dataStatisWxFansAnalyze/fansInfo', data);
//  营销-营销分析-其他活动-数据趋势
const dataStatisActivityDetail = (data) => request('/pc/dataStatisActivityAnalyze/activityDetail', data);
//  营销-营销分析-其他活动-营销概况
const dataStatisActivityGeneral = (data) => request('/pc/dataStatisActivityAnalyze/activityGeneral', data);
//  营销-营销分析-其他活动-商品效果
const dataStatisActivityProduct = (data) => request('/pc/dataStatisActivityAnalyze/activityProduct', data);
//  营销-营销分析-优惠券-数据趋势
const dataStatisCouponDetail = (data) => request('/pc/dataStatisActivityAnalyze/couponDetail', data);
//  营销-营销分析-优惠券-营销概况
const dataStatisCouponGeneral = (data) => request('/pc/dataStatisActivityAnalyze/couponGeneral', data);
//  营销-营销分析-优惠券-商品效果
const dataStatisCouponProduct = (data) => request('/pc/dataStatisActivityAnalyze/couponProduct', data);
//  营销分析，满减送
const activityMardownGiftPopupList = (data) => request('/pc/activityMardownGift/popupList', data);

//  获取小程序二维码
const mallGetQrCode = (data) => request('/pc/mall/getQrCode', data);

//  我的账户-列表
const mallBalanceIndex = (data) => request('/pc/mallBalance/index', data);
//  我的账户-初始化
const mallBalanceInit = (data) => request('/pc/mallBalance/init', data);

//  销售明细-列表
const performanceOrderList = (data) => request('/pc/employeePerformance/orderList', data);
//  锁粉明细-列表
const performanceLockFansList = (data) => request('/pc/employeePerformance/lockFansList', data);

//  自提单核销列表
const verificationOrderIndex = (data) => request('/pc/verification-record/order-index', data);
//  自提单核销提货明细
const verificationOrderDetail = (data) => request('/pc/verification-record/order-detail', data);
//  优惠券核销列表
const verificationCouponIndex = (data) => request('/pc/verification-record/coupon-index', data);
//  核销记录筛选框
const verificationCouponInit = (data) => request('/pc/verification-record/init', data);

//  到货提醒-列表
const productSubscribeList = (data) => request('/pc/productSubscribe/list', data);

//  微信物流助手发货地址
const expressAssistantIndex = (data) => request('/pc/wx-express-assistant-sender/index', data);
//  微信物流助手发货地址保存
const expressAssistantSave = (data) => request('/pc/wx-express-assistant-sender/save', data);

//  微信物流助手打单记录
const assistantRecordIndex = (data) => request('/pc/wx-express-assistant-push-record/index', data);
//  微信物流助手打单记录确认发货
const assistantRecordSend = (data) => request('/pc/wx-express-assistant-push-record/send', data);
//  微信物流助手打单列表批量打单中止
const assistantRecordStop = (data) => request('/pc/wx-express-assistant-push-record/stop', data);

//  渠道推广码-删除
const promoteCodeDelete = (data) => request('/pc/promoteCode/delete', data);
//  渠道推广码-列表
const promoteCodeList = (data) => request('/pc/promoteCode/list', data);
//  渠道推广码-保存
const promoteCodeSave = (data) => request('/pc/promoteCode/save', data);
// 自定义页面-弹框列表
const mallShopDecorateBoxList = (data) => request('/pc/mallShopDecorate/filterBoxList', data);


// 供货市场
const supplierMarketProductList = (data) => request('/pc/supplierChain/supplierMarketProductList', data);
// 供货市场-添加到仓库/上架到商城
const supplierMarketProductSave = (data) => request('/pc/supplierChain/supplierMarketProductSave', data);
//  供货市场-供货商信息
const supplierMarketMallInfo = (data) => request('/pc/supplierChain/supplierMarketMallInfo', data);

// 门店标签
const shopTagList = (data) => request('/pc/ShopTag/list', data);

// 订单毛利预算
const grossProfitList = (data) => request('/pc/order/grossProfit/list', data);


// 配置员工社群配置Id
const setEmployeeCommunityGroupId = (data) => request('/pc/employee/setEmployeeCommunityGroupId', data);

// 推手销售明细
const memberDistributorSellDetail = (data) => request('/pc/member/memberDistributorSellDetail', data);
// 推手销售总额 
const memberDistributorSellTotal = (data) => request('/pc/member/memberDistributorSellTotal', data);
// 推手退款总额
const memberDistributorRefundTotal = (data) => request('/pc/member/memberDistributorRefundTotal', data);

// 推手退款明细 
const memberDistributorRefundDetail = (data) => request('/pc/member/memberDistributorRefundDetail', data);

//  主页导航
const homeNavigationIndex = (data) => request('/pc/home-navigation/index', data);
//  主页导航保存
const homeNavigationSave = (data) => request('/pc/home-navigation/save', data);
//  弹窗广告-列表
const playScreenAdvertisingList = (data) => request('/pc/playScreenAdvertising/list', data);
//  弹窗广告-保存
const playScreenAdvertisingSave = (data) => request('/pc/playScreenAdvertising/save', data);
//  底部导航-主页
const bottomNavigationIndex = (data) => request('/pc/bottomNavigation/index', data);
//  弹窗广告-保存
const bottomNavigationSave = (data) => request('/pc/bottomNavigation/save', data);

// 支付成功页图片配置-列表
const paySuccessImgList = (data) => request('/pc/paySuccessPageImgSetting/list', data);
//  支付成功页图片配置-保存
const paySuccessImgSave = (data) => request('/pc/paySuccessPageImgSetting/save', data);
//  客服回复内容配置-初始化 cxd
const communityGroupCodeInit = (data) => request('/pc/communityGroupCodeSetting/init', data);
//  客服回复内容配置-弹框列表
const communityGroupCodeList = (data) => request('/pc/communityGroupCodeSetting/popList', data);

//  代理收益明细
const agentDetail = (data) => request('/pc/commission/agent/detail', data);
//  代理收益
const agentIndex = (data) => request('/pc/commission/agent/index', data);
//  代理收益初始化
const agentInit = (data) => request('/pc/commission/agent/init', data);
//  出货奖励规则
const agentDeliveryConfigIndex = (data) => request('/pc/agentDeliveryRewardConfig/index', data);
//  出货奖励规则-保存
const agentDeliveryConfigSave = (data) => request('/pc/agentDeliveryRewardConfig/save', data);
//  代理出货奖励明细
const agentSellAwardDetail = (data) => request('/pc/agent-sell-award/detail', data);
//  代理出货奖励
const agentSellAwardIndex = (data) => request('/pc/agent-sell-award/index', data);
//  代理出货奖励
const liveRoomRefreshPage = (data) => request('/pc/liveRoom/refreshPage', data);

//  更新小程序信息
const mcroProgramUpdate = (data) => request('/pc/wxapp/mcroProgramUpdate', data);
//  获取小程序信息
const mcroProgramList = (data) => request('/pc/wxapp/mcroProgramList', data);
//  小程序提交审核
const microprogramsubmitaudit = (data) => request('/pc/wxapp/microprogramsubmitaudit', data);
//  小程序撤回审核
const microprogramrecallcheck = (data) => request('/pc/wxapp/microprogramrecallcheck', data);
//  已审核的小程序一键发布
const microprogramrelease = (data) => request('/pc/wxapp/microprogramrelease', data);

//  储值礼包-删除
const activityTopUpGiftDelete = (data) => request('/pc/activityTopUpGift/delete', data);
//  储值礼包-详情
const activityTopUpGiftInfo = (data) => request('/pc/activityTopUpGift/info', data);
//  储值礼包-列表
const activityTopUpGiftList = (data) => request('/pc/activityTopUpGift/list', data);
//  储值礼包-使终止
const activityTopUpGiftOpen = (data) => request('/pc/activityTopUpGift/open', data);
//  储值礼包-保存
const activityTopUpGiftSave = (data) => request('/pc/activityTopUpGift/save', data);


//保存问卷
const questionnairesave = (data) => request('/pc/questionnaire/questionnairesave', data);
const questionnairedetail = (data) => request('/pc/questionnaire/questionnairedetail', data);
const questionnairevalite = (data) => request('/pc/questionnaire/questionnairevalite', data);


//抽奖活动-核销发放
const activityWheelSurfwriteOff = (data) => request('/pc/activityWheelSurf/writeOff', data);



//退回退款详细地址
const ordersupportreturnAddress = (data) => request('/pc/order-support/returnAddress', data);



//售后单重新发起退款
const ordersupportrefundagain = (data) => request('/pc/order-support/refund-again', data);



//自定义成本-信息
const customCostinfo = (data) => request('/pc/order/customCost/info', data);
//自定义成本-编辑
const customCostedit = (data) => request('/pc/order/customCost/edit', data);



//订单导出记录下载记录
const downloadrecordlist = (data) => request('/pc/order-export-record/download-record-list', data);
//是否需要短信验证
const isneedsmsverify = (data) => request('/pc/order-export-record/is-need-sms-verify', data);
//发送验证码 
const downloadsendsmscode = (data) => request('/pc/order-export-record/download/send-sms-code', data);
//发送短信图片验证码
const downloadsendsmsimgcode = (data) => request('/pc/order-export-record/download/send-sms-img-code', data);
//验证验证码
const downloadverifycode = (data) => request('/pc/order-export-record/download/verify-code', data);



//付费记录查询
const activityWheelSurfpayRecord = (data) => request('/pc/activityWheelSurf/payRecord', data);


export default {
	activityWheelSurfpayRecord,
	downloadverifycode,
	downloadsendsmsimgcode,
	downloadsendsmscode,
	isneedsmsverify,
	downloadrecordlist,
	customCostinfo,
	customCostedit,
	ordersupportrefundagain,
	ordersupportreturnAddress,
	activityWheelSurfwriteOff,
	enumList,
	activitycouponCouponlist,
	activitycouponActivitycoupondelete,
	activitycouponActivitycoupondetail,
	activitycouponActivitycouponedit,
	activitycouponActivitycouponsetisopen,
	activitycouponActivitycouponstatistical,
	activitycouponChoseproductlist,
	activitycouponisshowemployeewxapp,
	activityMarkdownGiftAdd,
	activityMarkdownGiftChoseCoupon,
	activityMarkdownGiftChoseProduct,
	activityMarkdownGiftDel,
	activityMarkdownGiftIsLoop,
	activityMarkdownGiftIsOpen,
	activityMarkdownGiftList,
	activityMarkdownGiftModel,
	activitycashbackClose,
	activitycashbackDelete,
	activitycashbackEditInit,
	activitycashbackEditsave,
	activitycashbackIndex,
	activitycouponpopupList,
	activityPointExchangeDelete,
	activityPointExchangeInfo,
	activityPointExchangeList,
	activityPointExchangeOpen,
	activityPointExchangeProductList,
	activityPointExchangeSave,
	productSpecPopList,

	activityWheelSurfDel,
	activityWheelSurfInfo,
	activityWheelSurfDetail,
	activityWheelSurfList,
	activityWheelSurfOpen,
	activityWheelSurfProductList,
	activityWheelSurfSave,
	activityWheelSurfPopupList,
	activityWheelSurfSearchMember,
	activityWheelSurfSendNumber,

	communityGroupSettingInit,
	communityGroupSettingSave,
	communityGroupData,

	memberSignRecordList,
	signConfigEdit,
	signConfigModel,
	ordersupportIndex,
	ordersupportExport,
	ordersupportInit,
	ordersupportDetail,
	ordersupportExpress,
	ordersupportAgreeapply,
	ordersupportAgreerefund,
	ordersupportRefuseapply,
	ordersupportRefuserefund,
	ordersupportCloseApply,
	orderSendInit,
	orderExport,
	orderExportRecordIndex,
	orderSameGroupOrder,

	homeNavigationIndex,
	homeNavigationSave,
	mallShopDecorateSelect,
	mallShopDecorateSelectGroup,
	commentBatchCheck,
	commentDelete,
	commentList,
	commentMallReply,
	SignConfigIsOpen,
	SignConfigIsOpenState,
	dataStatisFlowOverview,
	dataStatisFlowReferer,
	dataStatisFlowRefererTrend,
	dataStatisFlowTrend,
	visitorVisitorAreaAnalyze,
	visitorVisitorDepth,
	dataStatisPageAnalyze,
	dataStatisCustomerOverview,
	dataStatisCustomerRetention,
	dataStatisTradingAnalyze,
	wxFansAnalyzeFanLayered,
	FansIncreaseOrDecrease,
	wxFansAnalyzeFansInfo,
	mallGetQrCode,
	dataStatisActivityDetail,
	dataStatisActivityGeneral,
	dataStatisActivityProduct,
	dataStatisCouponDetail,
	dataStatisCouponGeneral,
	dataStatisCouponProduct,
	activityDiscountPopupList,
	mallBalanceIndex,
	mallBalanceInit,
	verifyAuthGift,
	activityMardownGiftPopupList,
	performanceOrderList,
	performanceLockFansList,
	verificationOrderIndex,
	verificationOrderDetail,
	verificationCouponIndex,
	verificationCouponInit,
	productSubscribeList,
	productSpecGet,
	expressAssistantIndex,
	expressAssistantSave,
	assistantRecordIndex,
	assistantRecordSend,
	assistantRecordStop,
	promoteCodeDelete,
	promoteCodeList,
	promoteCodeSave,
	mallShopDecorateBoxList,
	supplierMarketProductList,
	supplierMarketProductSave,
	supplierMarketMallInfo,
	supplierOrderInit,
	supplierOrderIndex,
	supplierOrderDetail,
	supplierOrderExpress,
	supplierMarketProductInfo,
	freightTemplate,
	supplierOrderAgreeRefund,
	supplierOrderAgreeApply,
	supplierOrderRefuseApply,
	supplierOrderRefuseRefund,
	supplierOrderSendInit,
	shopTagList,
	grossProfitList,
	setEmployeeCommunityGroupId,
	memberDistributorSellDetail,
	memberDistributorSellTotal,
	memberDistributorRefundTotal,
	memberDistributorRefundDetail,

	playScreenAdvertisingList,
	playScreenAdvertisingSave,
	bottomNavigationIndex,
	bottomNavigationSave,
	paySuccessImgList,
	paySuccessImgSave,
	communityGroupCodeInit,
	communityGroupCodeList,

	agentDetail,
	agentIndex,
	agentInit,
	agentDeliveryConfigIndex,
	agentDeliveryConfigSave,
	agentSellAwardDetail,
	agentSellAwardIndex,

	liveRoomRefreshPage,
	mcroProgramUpdate,
	mcroProgramList,
	microprogramsubmitaudit,
	microprogramrecallcheck,
	microprogramrelease,

	activityTopUpGiftDelete,
	activityTopUpGiftInfo,
	activityTopUpGiftList,
	activityTopUpGiftOpen,
	activityTopUpGiftSave,


	questionnairesave,
	questionnairedetail,

	questionnairevalite,
	SignConfigSubscribeInit,
	SignConfigSubscribeEdit,
	SignConfigAdvertiseSpaceInit,
	SignConfigAdvertiseSpaceSave,

	systemAgreementConfiglist,
	BatchSupplierMarketProductSave
}
