import request from '@/utils/request'

//获取消息订阅信息
export const mallNoticeSubscribeConfig = () => request('/pc/mall/mallNoticeSubscribeConfig');

//批量去除标签
export const tagMemberBatchRemove = (data) => request('/pc/tagMember/batchRemove', data);
//关闭0元自提单
export const orderpickUpclose = (data) => request('/pc/order/pickUp/close', data);
//获取员工活码
export const qyWeixinContactMeQrCode = (data) => request('/pc/qyWeixinContactMe/qyWeixinContactMeQrCode', data);

//门店便签列表
export const GetStoretagList = (data) => request('/pc/ShopTag/list', data);
//添加编辑标签
export const EditStoretagInfo = (data) => request('/pc/ShopTag/Edit', data);
//删除标签
export const DeleteStoretag = (data) => request('/pc/ShopTag/Del', data);
//门店管理列表
export const StoreManageList = (data) => request('/pc/shop/shopmanagerlist', data);
//获取门店标签
export const StoreTagList = (data) => request('/pc/shop/shoptaglist', data);
//编辑添加门店保存
export const shopedit = (data) => request('/pc/shop/shopedit', data);
//删除门店
export const shopdelete = (data) => request('/pc/shop/shopdelete', data);
//启用门店
export const shopOpen = (data) => request('/pc/shop/shopOpen', data);
//删除限制
export const shopdeletelimitdata = (data) => request('/pc/shop/shopdeletelimitdata', data);
//门店详情
export const shopinfo = (data) => request('/pc/shop/shopinfo', data);
//商品设置
export const productsetshopproductconfig = (data) => request('/pc/shop/productsetshopproductconfig', data);
//按商品管理门店-列表
export const productshoplist = (data) => request('/pc/shop/productshoplist', data);
//商品信息
export const productbaseinfo = (data) => request('/pc/product/productbaseinfo', data);
//单个修改配送方式
export const shopproductsendtypeedit = (data) => request('/pc/shop/shopproductsendtypeedit', data);
//批量设置配送方式
export const setshopproductsendtype = (data) => request('/pc/shop/setshopproductsendtype', data);
//获取规格列表
export const productspecinfo = (data) => request('/pc/product/productspecinfo', data);
//设置供货价
export const setshopproductspecsupplyprice = (data) => request('/pc/shop/setshopproductspecsupplyprice', data);
//管理门店商品列表
export const shopproductconfigmanager = (data) => request('/pc/shop/shopproductconfigmanager', data);

export const shopproductsendtypebatchedit = (data) => request('/pc/shop/shopproductsendtypebatchedit', data);
//获取客户标签
export const GetmemberTaglist = (data) => request('/pc/memberTag/index', data);
//保存客户标签
export const SaveMembertags = (data) => request('/pc/memberTag/save', data);
//删除客户标签
export const MemberTagdelete = (data) => request('/pc/memberTag/delete', data);
//积分明细总统计
export const ScoresTotal = (data) => request('/pc/point/pointstatistics', data);
//积分管理列表
export const Scoreconfiglist = (data) => request('/pc/pointgiving/pointgivingconfiglist', data);
//获取积分明细列表
export const ScoreDetaillist = (data) => request('/pc/point/pointrecordlist', data);

//列表
export const activityPointExchangeList = (data) => request('/pc/activityPointExchange/list', data);
/**
 * 成长值配置
 */
//保存 
export const TaskConfigEdit = (data) => request('/pc/TaskConfig/Edit', data);
//获取配置信息
export const TaskConfigList = (data) => request('/pc/TaskConfig/List', data);
//积分管理添加/编辑
export const EditScoreconfig = (data) => request('/pc/pointgiving/pointgivingconfigedit', data);
//积分获取详情
export const getScoreconfigInfo = (data) => request('/pc/pointgiving/pointgivingconfigInfo', data);
// 门店对账账单汇总列表
export const BillTotalist = (data) => request('/pc/orderSummary/list', data);
//门店对账获取门店列表
export const Getallstorelist = (data) => request('/pc/shop/shopdroplist', data);
//门店对账账单明细
export const GetBilldetailist = (data) => request('/pc/orderParticulars/list', data);
//删除积分赠送配置
export const DeleteConfiginfo = (data) => request('/pc/pointgiving/pointgivingconfigdelete', data);
//初始化客户查询筛选条件
export const MemberInit = (data) => request('/pc/member/init', data);
//客户查询列表
export const MemberList = (data) => request('/pc/member/list', data);
//根据Id获取标签Ids
export const MemberIdTaginfos = (data) => request('/pc/member/getMemberTagList', data);
//会员等级筛选列表
export const MemberUserlevels = (data) => request('/pc/memberVipRole/filterBoxList', data);
//获取所有标签名称
// TagType传0
export const MemberTaglists = (data) => request('/pc/memberTag/popupList', data);
//锁粉店员查询
export const SearchLockEmployee = (data) => request('/pc/member/findLockEmployee', data);
//保存会员信息
export const SaveEmployeenInfo = (data) => request('/pc/member/save', data);
//单个修改客户标签
export const SingelChangetags = (data) => request('/pc/member/changeTag', data);
//根据Id获取客户标签
export const MemberIDtags = (data) => request('/pc/member/getMemberTagList', data);
//批量修改客户标签
export const EditsomeTags = (data) => request('/pc/member/batchAddTag', data);
//初始化会员等级
export const ChangelevelInit = (data) => request('/pc/member/changeVipLevelInit', data);
//修改单个等级
export const Savesingelevel = (data) => request('/pc/member/changeVipLevel', data);
//批量修改等级
export const SavesomeViplevels = (data) => request('/pc/member/batchChangeVipLevel', data);
//单个修改积分
export const Changesingelpoint = (data) => request('/pc/member/changePoint', data);
//批量修改积分
export const Changesomepoints = (data) => request('/pc/member/batchChangePoint', data);
//单个修改归属门店
export const ChangesingelShop = (data) => request('/pc/member/changeBelongShop', data);
//批量修改归属门店
export const ChangesomeShops = (data) => request('/pc/member/batchChangeBelongShop', data);
//修改单个锁粉店员
export const ChangesingelLock = (data) => request('/pc/member/changeLockMallEmployee', data);
//获取推手级别列表
export const filterBoxList = (data) => request('/pc/distributorRole/filterBoxList', data);
//根据手机号查找推荐人
export const findRecommendMember = (data) => request('/pc/member/findRecommendMember', data);
//初始化授权推手信息
export const authDistributorInit = (data) => request('/pc/member/authDistributorInit', data);
//保存推手信息
export const SaveauthDistributor = (data) => request('/pc/member/authDistributor', data);
//客户查询详情
export const MemberdetailInfo = (data) => request('/pc/member/info', data);
//积分清零
export const EmptyPoint = (data) => request('/pc/member/emptyPoint', data);
//返现列表
export const cashbackOrderList = (data) => request('/pc/member/cashbackOrderList', data);
//根据Id获取推荐人信息
export const memRecommendInfobyId = (data) => request('/pc/member/memberRecommendInfo', data);
//商户所有权限
export const Mallalljurisdict = (data) => request('/pc/employee/pcMenuList', data);
//首页实时数据
export const Indexrealtinedata = (data) => request('/pc/dataStatis/realTimeOverview', data);
//首页商城事项
export const IndexShoptings = (data) => request('/pc/dataStatis/realTimeMallItems', data);
//折线图
export const Indextrendsdata = (data) => request('/pc/dataStatis/realTimeSellInfo', data);
//交易分析
export const dataTransSurvey = (data) => request('/pc/dataStatis/dataTransactionAnalyze/tradeOverview', data);
//交易分析趋势图
export const dataTransTrade = (data) => request('/pc/dataStatis/dataTransactionAnalyze/tradeTrend', data);
//地域分布
export const AreaDistributdata = (data) => request('/pc/dataStatis/dataTransactionAnalyze/areaDistribution', data);
//交易构成
export const Constitutedata = (data) => request('/pc/dataStatis/dataTransactionAnalyze/transactionConstitute', data);
//支付金额分布图
export const payMoneybarData = (data) => request('/pc/dataStatis/dataTransactionAnalyze/payMoneyDistribution', data);
//标签成交分析
export const tagAnalyzeData = (data) => request('/pc/dataStatis/dataTransactionAnalyze/tagTransactionAnalyze', data);
//消费能力分析
export const consumeAbilityData = (data) => request('/pc/dataStatis/Customer/consumeAbility', data);
//消费频次分析
export const consumeFrequencyData = (data) => request('/pc/dataStatis/Customer/consumeFrequencyAnalyze', data);
//回购周期分析
export const memberRepeatPaydata = (data) => request('/pc/dataStatis/Customer/memberRepeatPay', data);
//营销概览
export const ActivityoverView = (data) => request('/pc/dataStatisActivity/overView', data);
//直播间交易数据
export const LiveroomTradedata = (data) => request('/pc/liveRoom/tradeStatis', data);
//结算业绩详情
export const shoperformancedetail = (data) => request('/pc/shop-performance/order-index', data);
//退款单列表
export const shoprefundList = (data) => request('/pc/shop-performance/refund-index', data);
//商城全局初始化
export const pcBaseInfo = () => request('/pc/mall/mallbaseinfo', {});
//提成明细
export const commissionlist = (data) => request('/pc/employeePerformance/commissionRecord', data);
//绑定物流列表
export const bindAccountlist = (data) => request('/pc/wx-express-assistant-account/index', data);
//打印员列表
export const printerlist = (data) => request('/pc/wx-express-assistant-printer/index', data);
//删除打印员
export const deletePrinter = (data) => request('/pc/wx-express-assistant-printer/delete', data);
//获取物流公司
export const RefreshExpressinfo = (data) => request('/pc/wx-express-assistant-account/sync', data);
//锁粉推手初始化
export const changeFirstFromMemberInit = (data) => request('/pc/member/changeFirstFromMemberInit', data);
//修改锁粉推手
export const changeFirstFromMember = (data) => request('/pc/member/changeFirstFromMember', data);
//门店停用
export const shopClose = (data) => request('/pc/shop/shopClose', data);
//停用限制接口
export const shopCloseLimitData = (data) => request('/pc/shop/shopCloseLimitData', data);
//渠道数据统计接口
export const flowOverview = (data) => request('/pc/dataStatisPromoteCode/flowOverview', data);
//推广明细数据
export const generalizeDatalist = (data) => request('/pc/dataStatisPromoteCode/list', data);
//渠道商品明细
export const PromoteCodeproductList = (data) => request('/pc/dataStatisPromoteCode/productList', data);
//我的需求
export const malldemandList = (data) => request('/pc/malldemand/pcMallDemandList', data);
//新增需求
export const SubmitMallDemand = (data) => request('/pc/malldemand/submitMallDemand', data);
//需求详情
export const mallDemandDetail = (data) => request('/pc/malldemand/mallDemandDetail', data);
//1.3.0版本接口
//供货平台初始化
export const supplierMallBalanceInit = (data) => request('/pc/supplierMallBalance/init', data);
//供货平台列表
export const supplierMallBalanceList = (data) => request('/pc/supplierMallBalance/list', data);
//供货商品列表
export const supplierProductList = (data) => request('/pc/supplierProduct/list', data);
//分销供货单列表
export const supplierOrderList = (data) => request('/pc/supplier-order/index', data);
//分销供货单加星
export const orderIndexStar = (data) => request('/pc/supplier-order/star', data);
//分销单发货
export const orderSend = (data) => request('/pc/supplier-order/send', data);
//分销单查看物流
export const orderlookExpress = (data) => request('/pc/supplier-order/look-express', data);
// 分销单订单备注
export const orderRemark = (data) => request('/pc/supplier-order/remark', data);
//获取进度信息
export const orderimportGetprogress = (data) => request('/pc/supplier-order/order-import-send-get-progress', data);
//分销供货单详情
export const orderlookDetail = (data) => request('/pc/supplier-order/look-detail', data);
//分销单修改收货地址
export const ordereditReceive = (data) => request('/pc/supplier-order/edit-receive', data);
//分销单主动退款初始化
export const ordererefundInit = (data) => request('/pc/supplier-order/refund-init', data);
//分销单主动退款保存
export const ordererefundsave = (data) => request('/pc/supplier-order/refund-save', data);
//素材列表
export const productMateriaList = (data) => request('/pc/productMaterial/list', data);
//素材删除
export const productMateriaDelete = (data) => request('/pc/productMaterial/delete', data);
//保存素材
export const productMateriaSave = (data) => request('/pc/productMaterial/save', data);
//获取素材详情
export const productMateriaInfo = (data) => request('/pc/productMaterial/info', data);
//商品素材初始化
export const productMateriaInit = (data) => request('/pc/productMaterial/init', data);
//社群专享列表
export const activityCommunityGroupList = (data) => request('/pc/activityCommunityGroup/list', data);
//社群专享选择商品
export const activityCommunityGroupProductlist = (data) => request('/pc/activityCommunityGroup/productList', data);
//删除社群活动
export const activityCommunityGroupDelete = (data) => request('/pc/activityCommunityGroup/delete', data);
//社群活动使终止
export const activityCommunityGroupOpen = (data) => request('/pc/activityCommunityGroup/open', data);
//获取社群活动详情
export const activityCommunityGroupInfo = (data) => request('/pc/activityCommunityGroup/info', data);
//保存社群活动
export const activityCommunityGroupSave = (data) => request('/pc/activityCommunityGroup/save', data);
//推手销售概览
export const memberDistributorSellInfo = (data) => request('/pc/member/memberDistributorSellInfo', data);
//首页-实时概览-商城账户
export const realTimeBalanceInfo = (data) => request('/pc/dataStatis/realTimeBalanceInfo', data);
//获取系统公告列表 cxd
export const systemNoticeList = (data) => request('/pc/mallDemand/systemNoticeList', data);
//首页-实时概览-系统公告 cxd
export const realTimeSystemAffiche = (data) => request('/pc/dataStatis/realTimeSystemAffiche', data);
//商品推广-列表 cxd
export const dataStatisProductPromotionIndex = (data) => request('/pc/dataStatisProductPromotion/index', data);
//商品推广-导出 cxd
export const dataStatisProductPromotionExport = (data) => request('/pc/dataStatisProductPromotion/export', data);
//首页获取mallInfo cxd
export const mallDataInfo = (data) => request('/pc/mall/info', data);
//客服设置接口
export const xjmallplatformGateway = (data) => request('/xjmallplatform/gateway', data);
//
//客服设置配置系统
export const customSettingIndex = (data) => request('/pc/customSetting/index', data);
//客服系统设置保存
export const customSettingSave = (data) => request('/pc/customSetting/save', data);

//1.4.4版本接口
//拼团列表
export const activitySpellGroupList = (data) => request('/pc/activitySpellGroup/list', data);
//拼团使终止
export const activitySpellGroupClose = (data) => request('/pc/activitySpellGroup/close', data);
//删除拼团
export const activitySpellGroupDelete = (data) => request('/pc/activitySpellGroup/delete', data);
//初始化拼团信息
export const activitySpellGroupInfo = (data) => request('/pc/activitySpellGroup/info', data);
//拼团商品列表
export const activitySpellGroupproductList = (data) => request('/pc/activitySpellGroup/productList', data);
//拼团列表保存
export const activitySpellGroupSave = (data) => request('/pc/activitySpellGroup/save', data);
//获取推手数据
export const DistributortrafficData = (data) => request('/pc/dataStatis/dataDistributorTraffic/trafficData', data);
//推手信息
export const memberinfobyMemberId = (data) => request('/pc/member/memberinfobyphone', data);
//推手数据明细
export const DistributorTrafficpromoteDetail = (data) => request('/pc/dataStatis/dataDistributorTraffic/promoteDetail', data);
//渠道商品明细
export const DistributechannelProductData = (data) => request('/pc/dataStatis/dataDistributorTraffic/channelProductData', data);

//1.5.0 
//推手级别配置
export const AgentRoleList = (data) => request('/pc/agentRole/list', data);
//保存级别配置
export const SaveRoleconfig = (data) => request('/pc/agentRole/save', data);
//代理管理列表
export const Agentmanagelist = (data) => request('/pc/agent/agentManagerPage', data);
//代理级别列表
export const AgentfilterBoxList = (data) => request('/pc/agentRole/filterBoxList', data);
//保存代理
export const addAgentSave = (data) => request('/pc/ageng/addAgent', data);
//统计团队数据
export const AgentLevelTeamManager = (data) => request('/pc/agent/agentLevelTeamManager', data);
//统计团队顶部
export const AgentLevelTeamEachRoleCount = (data) => request('/pc/agent/agentLevelTeamEachRoleCount', data);
//统计推荐关系数据
export const AgentRecommendTeam = (data) => request('/pc/agent/agentRecommendTeam', data);
//锁粉关系数据
export const AgentLockFansPageInit = (data) => request('/pc/agent/agentLockFansPageInit', data);
//锁粉关系顶部数据
export const AgentLockFansTypeCount = (data) => request('/pc/agent/agentLockFansTypeCount', data);
//客户查询-查找代理 
export const AgentfindbyPhone = (data) => request('/pc/member/findAgent', data);
//授权初始化
export const AuthAgentInit = (data) => request('/pc/member/authAgentInit', data);
//代理授权推手
export const authAgentSave = (data) => request('/pc/member/authAgent', data);
//取消授权代理数据
export const AgentCancelConfirmData = (data) => request('/pc/agent/agentCancelConfirmData', data);
//确认取消授权
export const AgentCancelAuth = (data) => request('/pc/agent/agentCancelAuth', data);
//初始化上级信息
export const AgentSuperInfo = (data) => request('/pc/agent/agentSuperInfo', data);
//初始化推荐人信息
export const AgentRecommendInfo = (data) => request('/pc/agent/agentRecommendInfo', data);
//确认上级数据
export const AgentSuperEditConfirmData = (data) => request('/pc/agent/agentSuperEditConfirmData', data);
//确认修改上级
export const AgentSuperEdit = (data) => request('/pc/agent/agentSuperEdit', data);
//确认修改推荐人
export const AgentRecommendEdit = (data) => request('/pc/agent/agentRecommendEdit', data);
// 1.5.1版本
// 更改商品分组
export const productGroupupdateSort = (data) => request('/pc/productGroup/updateSort', data);
//快递助手配置
export const kdzs_configIndex = (data) => request('/pc/kdzs-config/index', data);
//快递助手配置保存
export const kdzs_configSave = (data) => request('/pc/kdzs-config/save', data);
//立即推送订单
export const kdzs_configPushorder = (data) => request('/pc/kdzs-config/push-order', data);
//分页获取直播
export const liveRoomrefreshPage = (data) => request('/pc/liveRoom/refreshPage', data);
//根据评价列表选择商品
export const commentselectproduct = (data) => request('/pc/product-comment/select-product', data);
//保存评价图片
export const commentSave = (data) => request('/pc/product-comment/save', data);
//直播序号
export const livesortDescSave = (data) => request('/pc/liveRoom/sortDescSave', data);
//获取直播分组初始化
export const livegroupSetInit = (data) => request('/pc/liveRoom/groupSetInit', data);
//直播间分组列表
export const liveroomgroup = (data) => request('/pc/live-room-group/index', data);
//直播间分组保存
export const liveroomgroupSave = (data) => request('/pc/live-room-group/save', data);
//直播间分组删除
export const liveroomgroupDelete = (data) => request('/pc/live-room-group/delete', data);
//确认保存分组
export const livegroupSetSave = (data) => request('/pc/liveRoom/groupSetSave', data);
//直播间列表分组初始化
export const liveRoomgroupInit = (data) => request('/pc/liveRoom/init', data);
//未入库商品列表
export const unstockedIndexlist = (data) => request('/pc/live-room-product-repository/unstocked-index', data);
//未入库选择的商品
export const checkNogoodslist = (data) => request('/pc/product/popList', data);
//保存未入库商品
export const unstockedAddlist = (data) => request('/pc/live-room-product-repository/unstocked-add', data);
//未入库、已入库商品删除
export const unstockedelete = (data) => request('/pc/live-room-product-repository/delete', data);
//撤销审核
export const unstockedcancel = (data) => request('/pc/live-room-product-repository/unstocked-cancel', data);
//未入库商品提交审核
export const unstockedsubmit = (data) => request('/pc/live-room-product-repository/unstocked-submit', data);
//已入库商品列表
export const livestockedIndex = (data) => request('/pc/live-room-product-repository/stocked-index', data);
//已入库修改价格
export const stockedEditprice = (data) => request('/pc/live-room-product-repository/stocked-edit-price', data);
//编辑未入库商品信息
export const unstockededitInfo = (data) => request('/pc/live-room-product-repository/unstocked-edit', data);
//枚举
export const enumList = (data) => request('/pc/enum/list', data);
//2.1.1
//储值规则初始化
export const topUpConfigIndex = (data) => request('/pc/mall/topUpConfig/index', data);
//选择礼包列表
export const Giftpopuplist = (data) => request('/pc/activityTopUpGift/popuplist', data);
//储值规格保存
export const topUpConfigEdit = (data) => request('/pc/mall/topUpConfig/edit', data);
//礼包内容
export const topUpConfigiftInfo = (data) => request('/pc/mall/topUpConfig/giftInfo', data);
//修改单个金额
export const MemberchangeBalance = (data) => request('/pc/member/changeBalance', data);
//批量修改金额
export const batchChangeBalancelist = (data) => request('/pc/member/batchChangeBalance', data);
//余额列表
export const memberBalanceRecordList = (data) => request('/pc/memberBalance/memberBalanceRecordList', data);
//是否开启关闭余额充值
export const topUpConfigOpen = (data) => request('/pc/mall/topUpConfig/open', data);
//初始化配置码
export const communityGroupCodeSettingInit = (data) => request('/pc/communityGroupCodeSetting/init', data);
//保存配置码
export const communityGroupCodeSettingSave = (data) => request('/pc/communityGroupCodeSetting/save', data);


//2.1.2版本
//获取收款账户
export const collectionAccount = (data) => request('/pc/balanceRechargeRecord/collectionAccount', data);
//确认充值
export const balanceRechargeRecordApply = (data) => request('/pc/balanceRechargeRecord/apply', data);
//充值记录列表
export const balanceRechargeRecordlist = (data) => request('/pc/balanceRechargeRecord/list', data);
//充值详情
export const balanceRechargeRecordInfo = (data) => request('/pc/balanceRechargeRecord/info', data);
//联系顾问获取
export const greetingInfoget = (data) => request('/pc/mall/greeting/info', data);
//保存联系顾问问候语
export const greetingInfoEdit = (data) => request('/pc/mall/Greeting/edit', data);
//修改余额初始化
export const MemebrchangeBalanceInit = (data) => request('/pc/member/changeBalanceInit', data);
//优惠券明细列表
export const MembercouponList = (data) => request('/pc/member/couponList', data);
//余额明细概况
export const MemberBalanceRecordStatistics = (data) => request('/pc/memberBalance/memberBalanceRecordStatistics', data);

//3.1.0版本
//直播间批量删除
export const liveRoombatchdelete = (data) => request('/pc/liveRoom/batch-delete', data);
//会员等级推荐商品保存
export const memberPageProductSave = (data) => request('/pc/memberPageProduct/save', data);
//会员等级推荐商品列表
export const memberPageProductList = (data) => request('/pc/memberPageProduct/list', data);
//删除配置验证
export const communityGroupCodeSettingverify = (data) => request('/pc/communityGroupCodeSetting/verify', data);
//推手推荐团队业绩list
export const memberRecommendTeamShiplist = (data) => request('/pc/member/memberRecommendTeamShip', data);
//推手推荐个人团队业绩list
export const memberTeamShiplist = (data) => request('/pc/member/memberTeamShip', data);
//推手推荐个人团队详情list
export const memberTeamShipDetail = (data) => request('/pc/member/memberTeamShipDetail', data);
//推荐团队业绩详情
export const memberRecommendTeamShipDetail = (data) => request('/pc/member/memberRecommendTeamShipDetail', data);
//门店业绩列表统计
export const ShoperformanceInfo = (data) => request('/pc/shop-performance/info', data);

//批量修改配送方式
export const batchChangeDistributionSave = (data) => request('/pc/product/batchChangeDistribution', data);
// 修改默认分组
export const liveroomgroupDefault = (data) => request('/pc/live-room-group/default', data);
//平台标记
export const liveupdatePlatform = (data) => request('/pc/liveRoom/updatePlatform', data);
//一键铺货页面初始化
export const ipuhuoInit = (data) => request('/pc/ipuhuo/init', data);
//一键铺货设置key
export const ipuhuoSavesetting = (data) => request('/pc/ipuhuo/save-setting', data);
//一键铺货列表
export const ipuhuoIndexlist = (data) => request('/pc/ipuhuo/index', data);
//一键铺货获取商品
export const ipuhuogetItem = (data) => request('/pc/ipuhuo/get-item', data);
//一键铺货单个铺货
export const ipuhuosavetoproduct = (data) => request('/pc/ipuhuo/save-to-product', data);
//一键铺货单个删除
export const ipuhuodelete = (data) => request('/pc/ipuhuo/delete', data);
//一键铺货批量删除
export const ipuhuodeleteBatch = (data) => request('/pc/ipuhuo/delete-batch', data);
//一键铺货批量铺货
export const ipuhuosavetoproductBatch = (data) => request('/pc/ipuhuo/save-to-product-batch', data);
//初始化商品信息接口、
export const ipuhuoEditInit = (data) => request('/pc/ipuhuo/edit-init', data);
//一键铺货保存
export const ipuhuoEditSave = (data) => request('/pc/ipuhuo/edit-save', data);


//设置上下架时间
export const producttimingOpen = (data) => request('/pc/product/timingOpen', data);
//批量修改上下架时间
export const producttimingBatchOpen = (data) => request('/pc/product/timingBatchOpen', data);
//到店支付数据初始化
export const dataStatisOfflinePayInit = (data) => request('/pc/dataStatisOfflinePay/init', data);
//到店支付数据列表
export const dataStatisOfflinePaylist = (data) => request('/pc/dataStatisOfflinePay/list', data);
//到店支付数据详情核销单
export const dataStatisOfflinePayorderList = (data) => request('/pc/dataStatisOfflinePay/orderList', data);
//到店支付数据详情退款单
export const dataStatisOfflinePayrefundList = (data) => request('/pc/dataStatisOfflinePay/refundList', data);

//助力领取列表
export const activityHelpReceiveList = (data) => request('/pc/activityHelpReceive/list', data);
//助力领取删除
export const activityHelpReceiveDelete = (data) => request('/pc/activityHelpReceive/delete', data);
//助力领取使终止
export const activityHelpReceiveClose = (data) => request('/pc/activityHelpReceive/close', data);
//虚拟领取量初始化
export const activityHelpReceivechangeVirtualInit = (data) => request('/pc/activityHelpReceive/changeVirtualInit', data);
//虚拟领取量保存
export const activityHelpReceivechangeVirtual = (data) => request('/pc/activityHelpReceive/changeVirtual', data);
//初始化助力领取
export const activityHelpReceiveInfo = (data) => request('/pc/activityHelpReceive/info', data);
//助力领取保存
export const activityHelpReceiveSave = (data) => request('/pc/activityHelpReceive/save', data);
//助力领取参与人名单
export const activityHelpReceivepeopleList = (data) => request('/pc/activityHelpReceive/peopleList', data);
//获取认证列表
export const memberCertificateList = (data) => request('/pc/member/memberCertificateList', data);
//审核认证
export const memberCertificateCheck = (data) => request('/pc/member/memberCertificateCheck', data);
//取消认证
export const memberCertificateCancel = (data) => request('/pc/member/memberCertificateCancel', data);
//认证申请验证
export const certificateSettingInfo = (data) => request('/pc/mall/certificateSettingInfo', data);
//保存验证申请
export const certificateSettingEdit = (data) => request('/pc/mall/certificateSettingEdit', data);
//获取分销商品供货运费
export const productfreightTemplate = (data) => request('/pc/product/freightTemplate', data);





//获取问卷列表
export const questionlist = (data) => request('/pc/questionnaire/questionnairemanagerlist', data)
//验证问卷是否存在答题
export const validationtestpaper = (data) => request('/pc/questionnaire/questionnairevalite', data)
//获取问卷结果
export const getquestionresult = (data) => request('/pc/questionnaire/questionnaireresult', data)
//获取题目列表
export const getquestiontitle = (data)=>request('/pc/questionnaire/questionnairetopiclist',data)
//获取答题列表
export const getanswer = (data)=>request('/pc/questionnaire/memberquestionnairedetailpagelist',data)
//答题列表导出
export const exportlist = (data)=>request('/pc/questionnaire/memberquestionnairedetailexport',data)
//删除问卷
export const deltestpaper = (data)=>request('/pc/questionnaire/questionnairedelete',data)
//获取问卷详情
export const testpaperdetail = (data)=>request('/pc/questionnaire/questionnairedetail',data)


//支付设置
export const mallpayconfigsave = (data)=>request('/pc/mall/mallpayconfigsave',data)
export const mallpayconfig = (data)=>request('/pc/mall/mallpayconfig',data)
export const adapayMerchantEdit = (data)=>request('/pc/adapayMerchant/edit',data)
export const adapayMerchantappendPhoto = (data)=>request('/pc/adapayMerchant/appendPhoto',data)
export const adapayMerchantInfo = (data)=>request('/pc/adapayMerchant/info',data)
export const adapayMerchantOpen = (data)=>request('/pc/adapayMerchant/open',data)



//标签组
//标签组列表
export const groupList = (data)=>request('/pc/taggroup/list',data)
//标签组编辑
export const groupedit = (data)=>request('/pc/taggroup/edit',data)
//标签组添加
export const groupadd = (data)=>request('/pc/taggroup/add',data)
//标签组删除
export const groupdel = (data)=>request('/pc/taggroup/del',data)
//标签列表
export const tagList = (data)=>request('/pc/tagdetail/list',data)
//标签添加
export const tagadd = (data)=>request('/pc/tagdetail/add',data)
//标签删除
export const tagdel = (data)=>request('/pc/tagdetail/del',data)
//标签编辑
export const tagedit = (data)=>request('/pc/tagdetail/edit',data)



//企业微信  客户管理 头部数据统计
export const customerstatis = (data)=>request('/pc/qyweixincustomer/customerstatis',data)
//获取在职员工列表   好友关系
export const employeelistbystate = (data)=>request('/pc/employee/employeelistbystate',data)
//客户管理 全部客户  列表
export const qyweixincustomermanagerpagelist = (data)=>request('/pc/qyweixincustomer/qyweixincustomermanagerpagelist',data)
//客户管理 群聊  列表
export const qyweixincustomermanagerbygrouppagelist = (data)=>request('/pc/qyweixincustomer/qyweixincustomermanagerbygrouppagelist',data)



//客户群列表
export const qyweixinrealgrouppagelist = (data)=>request('/pc/qyweixincutomer/qyweixinrealgrouppagelist',data)
//客户群数据统计
export const customergroupstatis = (data)=>request('/pc/qyweixincutomer/customergroupstatis',data)
//客户群详情
export const qyweixinrealgroupdetail = (data)=>request('/pc/qyweixincutomer/qyweixinrealgroupdetail',data)
//客户群详情 数据统计 折线图
export const customergroupdetailstatis = (data)=>request('/pc/qyweixincutomer/customergroupdetailstatis',data)
//群内客户列表
export const groupcustomerpagelist = (data)=>request('/pc/qyweixincutomer/groupcustomerpagelist',data)


//客户数据概览  客户数据
export const customerStatis = (data)=>request('/pc/dataStatisQyWeixin/customerStatis',data)
//客户数据概览  客户群数据
export const groupStatis = (data)=>request('/pc/dataStatisQyWeixin/groupStatis',data)

//获取子商户号
export const adapayMerchantmerCode = (data)=>request('/pc/adapayMerchant/merCode',data)



//拼团抢购活动保存
export const activitySpellGroupactivitySpellGroupSnapUpSave = (data)=>request('/pc/activitySpellGroup/activitySpellGroupSnapUpSave',data)
//拼团抢购管理
export const activitySpellGroupactivitySpellGroupSnapUpManager = (data)=>request('/pc/activitySpellGroup/activitySpellGroupSnapUpManager',data)
//获取拼团抢购详情
export const activitySpellGroupactivitySpellGroupSnapUpDetail = (data)=>request('/pc/activitySpellGroup/activitySpellGroupSnapUpDetail',data)


//停用会员等级
export const memberVipRoledeleteMemberVipRole = (data)=>request('/pc/memberVipRole/deleteMemberVipRole',data)




//获取门店商品规格库存
export const shopshopProductSpecStock = (data)=>request('/pc/shop/shopProductSpecStock',data)
//获取门店商品规格库存
export const shopshopProductSpecChangeStock = (data)=>request('/pc/shop/shopProductSpecChangeStock',data)


//校验门店开通第三方配送服务
export const shopvaliteShopThirdPartyDelivery = (data)=>request('/pc/shop/valiteShopThirdPartyDelivery',data)




//获取拼团活动团列表
export const activitySpellGroupactivitySpellGroupJoinGroupList = (data)=>request('/pc/activitySpellGroup/activitySpellGroupJoinGroupList',data)
//拼团设置/取消官方团
export const activitySpellGroupsetMemberSpellGroupIsOfficialGroup = (data)=>request('/pc/activitySpellGroup/setMemberSpellGroupIsOfficialGroup',data)
//提前开奖
export const activitySpellGroupmemberSpellGroupSendAward = (data)=>request('/pc/activitySpellGroup/memberSpellGroupSendAward',data)


//获取拼团抢购活动中奖名单
export const activitySpellGroupactivitySpellGroupSnapUpAwardList = (data)=>request('/pc/activitySpellGroup/activitySpellGroupSnapUpAwardList',data)
//获取开播通知配置
export const activitySpellGrouppremiereNoticeInfo = (data)=>request('/pc/activitySpellGroup/premiereNoticeInfo',data)
//开播通知保存
export const activitySpellGrouppremiereNoticeSave = (data)=>request('/pc/activitySpellGroup/premiereNoticeSave',data)



//万里牛立即推送订单
export const wanliniuorderpush = (data) => request('/job/wanliniu/order/push', data);




//Adapay支付开启关闭支付渠道
export const adaPayConfigmodifyPayChannelOpenStatus = (data) => request('/pc/mall/adaPayConfig/modifyPayChannelOpenStatus', data);




//客户贡献排行
export const CustomercontributionRank = (data) => request('/pc/dataStatis/Customer/contributionRank', data);
//客户消费明细
export const CustomercustomerConsumeDetail = (data) => request('/pc/dataStatis/Customer/customerConsumeDetail', data);
//客户商品购买汇总
export const CustomercustomerBuyCollect = (data) => request('/pc/dataStatis/Customer/customerBuyCollect', data);
//商品销售排行
export const dataStatisProductSellRankinglist = (data) => request('/pc/dataStatisProductSellRanking/list', data);
//商品销售排行-购买客户明细
export const dataStatisProductSellRankingcustomerList = (data) => request('/pc/dataStatisProductSellRanking/customerList', data);





//测一测-列表
export const takeTestlist = (data) => request('/pc/takeTest/list', data);
//测一测-删除
export const takeTestdelete = (data) => request('/pc/takeTest/delete', data);
//测一测-添加
export const takeTestadd = (data) => request('/pc/takeTest/add', data);
//测一测-信息
export const takeTestinfo = (data) => request('/pc/takeTest/info', data);
//测一测-修改
export const takeTestedit = (data) => request('/pc/takeTest/edit', data);
//测一测-记录-列表
export const memberTakeTestRecordlist = (data) => request('/pc/memberTakeTestRecord/list', data);
//测一测-选项关联商品属性列表
export const questionOptionproductAttribute = (data) => request('/pc/takeTest/questionOption/productAttribute', data);



//商品属性列表
export const productAttributeproductAttributeInit = (data) => request('/pc/productAttribute/productAttributeInit', data);
//添加属性
export const productAttributeproductAttributeAdd = (data) => request('/pc/productAttribute/productAttributeAdd', data);
//获取商品子属性
export const productAttributeproductAttributeChild = (data) => request('/pc/productAttribute/productAttributeChild', data);
//添加下级属性
export const productAttributeproductAttributeAddChild = (data) => request('/pc/productAttribute/productAttributeAddChild', data);
//属性编辑
export const productAttributeproductAttributeEdit = (data) => request('/pc/productAttribute/productAttributeEdit', data);
//删除商品属性
export const productAttributeproductAttributeDelete = (data) => request('/pc/productAttribute/productAttributeDelete', data);
//获取商品属性树
export const productAttributeproductAttributeTree = (data) => request('/pc/productAttribute/productAttributeTree', data);


//保存商品关联属性
export const productproductAttributeRelationSave = (data) => request('/pc/product/productAttributeRelationSave', data);
//获取商品关联属性
export const productproductAttributeRelationList = (data) => request('/pc/product/productAttributeRelationList', data);


//保存销售员信息
export const productGroupproductGroupEmployeeSave = (data) => request('/pc/productGroup/productGroupEmployeeSave', data);
//获取销售员信息
export const productGroupproductGroupEmployeeInfo = (data) => request('/pc/productGroup/productGroupEmployeeInfo', data);


//信息分组树
export const informationinformationGroupTree = (data) => request('/pc/information/informationGroupTree', data);
//商品属性列表 全部
export const productAttributeproductAttributeInitNew = (data) => request('/pc/productAttribute/productAttributeInitNew', data);



//CRM标签组列表
export const crmTagGrouplist = (data) => request('/pc/crmTagGroup/list', data);
//CRM标签组-编辑
export const crmTagGroupedit = (data) => request('/pc/crmTagGroup/edit', data);
//标签组-添加
export const crmTagGroupadd = (data) => request('/pc/crmTagGroup/add', data);
//标签组-删除
export const crmTagGroupdel = (data) => request('/pc/crmTagGroup/del', data);


//CRM标签组内标签列表
export const crmTagDaillist = (data) => request('/pc/crmTagDail/list', data);
//CRM标签-添加
export const crmTagDetailadd = (data) => request('/pc/crmTagDetail/add', data);
//CRM标签-删除 
export const crmTagDetaildel = (data) => request('/pc/crmTagDetail/del', data);
//CRM标签-编辑 
export const crmTagDetailedit = (data) => request('/pc/crmTagDetail/edit', data);
