<template>
	 <!-- :class="{appMainNo:isShowclass}" -->
	<section class="app-main">
		<transition name="fade-transform" mode="out-in">
			<keep-alive :include="cachedViews">
				<payment>
					<novice-guidance></novice-guidance>
					<router-view :key="key" />
				</payment>
			</keep-alive>
		</transition>
		
		<el-dialog :visible.sync="visible" width="80%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<div style="margin-top: -60px;margin-left: -18px;margin-right: -18px;margin-bottom: -43px;">
				<novice-guidance @close="handleClose" :isPop="true"></novice-guidance>
			</div>
		</el-dialog>
		
		<el-dialog :visible.sync="visibleEditMallName" width="700px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
			<img class="logo" src="@/assets/img/xiaoqie.png" />
			<div class="tip">欢迎使用！企店-私域运营工具</div>
			<div style="text-align: center;" class="pop-edit-mallname">
				<el-input type="text" placeholder="请输入商城名称" v-model="mallName"></el-input>
			</div>
			<div style="text-align: center;margin-top: 40px;padding-bottom: 30px;">
				<el-button type="primary" style="width: 360px;" @click="handleSave">确认商城名称，开始使用企店</el-button>
			</div>
		</el-dialog>
		
	</section>
</template>

<script>
	
	import payment from './payment.vue';
	import noviceGuidance from "@/components/noviceGuidance/noviceGuidance";
	
	import {
		triggeredmallguidsave,
		triggeredmallguidlist,
		mallNameEdit
	} from '@/api/api.js';
	
	import {
		mapGetters
	} from 'vuex'
	
	export default {
		components: {payment, noviceGuidance},
		name: 'AppMain',
		data() {
			return {
				isShowclass: false,
				visible: false,
				visibleEditMallName: false,
				mallName: ''
			}
		},
		watch:{
			ShopmallName(){
				this.mallName = this.ShopmallName;
			},
		},
		mounted(){
			this.mallName = this.ShopmallName;
			this.getTriggeredmallguidlist();
		},
		computed: {
			...mapGetters([
				"ShopmallName"
			]),
			cachedViews() {
				return this.$store.state.tagsView.cachedViews
			},
			key() {
				if (this.$route.fullPath == '/goods/goodsList') {
					this.isShowclass = true
				} else {
					this.isShowclass = false
				}
				
				return this.$route.fullPath
			}
		},
		methods: {
			async getTriggeredmallguidlist(){
				try{
					const res = await triggeredmallguidlist();

					var list = res.Result.filter(item=>{
						return item.RecordType === 0;
					})
					if (!list.length){
						this.visibleEditMallName = true;
						this.setTargetState(0);
					}
					// this.visibleEditMallName = true;
				}catch(e){
				}
			},
			async setTargetState(recordType){
				try{
					const res = await triggeredmallguidsave({
						RecordType: recordType
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			handleClose() {
				this.visible = false;
				
			},
			async handleSave(){
				if (this.mallName.length < 2 || this.mallName.length > 12){
					this.$message.error('商城名称最多可输入2~12个字');
					return;
				}
				
				try{
					const res = await mallNameEdit({
						MallName: this.mallName
					})
					
					if (res.IsSuccess){
						this.$message.success('修改成功');
						this.$store.dispatch('GetPcShopInfo');
						this.visible = true;
						this.visibleEditMallName = false;
						
					}
					
				}catch(e){
					//TODO handle the exception
				}
				
				
			}
		},
	}
</script>

<style lang="scss" scoped>
	.app-main {
		/*84 = navbar + tags-view = 50 +34 */
		min-height: calc(100vh - 97px);
		position: relative;
		overflow: hidden;
		background: #f0f2f5;
		margin: 96px 0px 0 220px;
		padding: 10px;
	}
	
	@media screen and (max-width: 1280px) {
		.app-main{
			margin: 96px 0px 0 140px;
		}
	}

	.appMainNo {
		margin: 45px 0 0 85px;
	}
	
	.logo{
		display: block;
		width: 250px;
		margin: 10px auto;
	}
	.tip{
		text-align: center;
		font-size: 40px;
		padding: 30px 0 40px;
	}
	
	.pop-edit-mallname ::v-deep .el-input input{
		width: 530px;
		height: 60px;
		font-size: 30px;
		text-align: center;
		border: none;
		border-bottom: 1px solid #D8D8D8;
	}
</style>
