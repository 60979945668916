<template>
	 <!-- :class="{appMainNo:isShowclass}" -->
	<section class="app-main-child">
		<transition name="fade-transform" mode="out-in">
			<keep-alive :include="cachedViews">
				<router-view :key="key" />
			</keep-alive>
		</transition>
	</section>
</template>

<script>
	
	export default {
		components: {
			
		},
		name: 'AppMain',
		data() {
			return {
				isShowclass: false
			}
		},
		computed: {
			cachedViews() {
				return this.$store.state.tagsView.cachedViews
			},
			key() {
				if (this.$route.fullPath == '/goods/goodsList') {
					this.isShowclass = true
				} else {
					this.isShowclass = false
				}
				return this.$route.fullPath
			}
		}
	}
</script>

<style scoped>
	.app-main-child {
		min-height: calc(100vh - 116px);
		position: relative;
		overflow: hidden;
		background: #f0f2f5;
	}

	.appMainNo {
		margin: 45px 0 0 85px;
	}
</style>
