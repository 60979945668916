<template>
	<div>
		<div class="main" style="padding: 0 30px 50px;">
			<div class="flexRow" style="justify-content: space-between;align-items:flex-start">
				<el-card style="width:49.5%">
					<div class="titfont">开通{{pagemsg.RuleName}}</div>
					<div class="bluebox" v-if="pagemsg.RuleDescription">
						【{{pagemsg.RuleName}}】{{pagemsg.RuleDescription}}
					</div>
					<div v-if="pagemsg.Cost>0">
						<div class="secfont" style="margin-top:20px">●{{"\xa0\xa0"}}付费购买</div>
						<div class="bluebox" style="padding:0px;">
							<div class="flexRow center">
								<div class="flexCol leftbox" style="padding:10px">
									<div class="oneyear">1年</div>
									<div class="money">￥{{pagemsg.Cost}}</div>
								</div>
								<img src="@/assets/img/righttop.png" alt="" />
							</div>
						</div>
						<div style="text-align:right">
							<el-button type="primary" style="width:180px;margin:20px 0px" @click="paynow">立即支付
							</el-button>
							<br />
							<el-link class="link" @click="sucnoopen">
								<i style="color:#E6A23C" class="el-icon-warning-outline"></i>
								支付成功了却一直没开通?
							</el-link>
						</div>
					</div>
					<div v-if="pagemsg.IsShowRecommendModule">
						<div class="secfont" style="margin-top:30px">●{{"\xa0\xa0"}}邀请好友助力</div>
						<div class="grayfont" style="margin-top:10px">
							邀请<span style="color:#f56c6c">{{pagemsg.RecommendCount}}</span>
							个好友使用企店，并成功授权绑定商城小程序，您即可免费获得功能{{pagemsg.HelpSendMonths}}个月的使用权限
							<span v-if="pagemsg.RecommendReward>0">
								；免费使用权限仅可获得一次哦~
							</span>
							<span style="color:#f56c6c" v-if="pagemsg.RecommendReward>0">
								（邀请的好友后续如果付费购买功能，单笔支付您还可以获得{{pagemsg.RecommendReward}}元推荐奖励）
							</span>；
						</div>
						<div>
							<el-link class="link" :underline="false" type="primary" target="_blan"
								href="https://jusnn6k8al.feishu.cn/docs/doccnDDGHptpVb0YhyoWRZEPe6d">点击查看助力规则
							</el-link>
						</div>
						<div class="flexRow"
							style="justify-content: space-between;margin-top:15px;align-items:flex-start">
							<!-- <img :src="pagemsg.PosterUrl?pagemsg.PosterUrlComplete:poster" id="img" style="max-width:164px" alt=""> -->
							<img :src="posterImage" id="img" style="width:164px" alt="" />
							<vue-qr v-if="Inputlink" :text="Inputlink" :callback="qrcallback" :size="300"
								style="display:none" ref="Qrcode"></vue-qr>
							<div style="flex:1;margin-left:20px">
								<div class="flexRow">
									<el-input v-model="Inputlink" disabled></el-input>
									<el-button type="primary" v-clipboard:copy="Inputlink" v-clipboard:success="copysuc"
										v-clipboard:error="copyerr">复制邀请链接</el-button>
								</div>
								<el-button type="text" @click="downloadposter">下载邀请海报</el-button>
							</div>
						</div>
						<div class="bluebox">
							<div class="thifont">已邀请好友</div>
							<div class="grayfont">
								<!-- 好友累计付费 <span style="color:#f56c6c">{{pagemsg.PayCount}}</span> 次, -->
								您当前累计获得奖励 <span style="color:#f56c6c">{{pagemsg.AwardMoney}}</span> 元
								<el-button type="text" @click="topup">奖励提现</el-button>
							</div>
							<el-table style="margin-top:10px" :data="pagemsg.RecommendMallList" max-height="300px">
								<el-table-column prop="MallName" label="商家"></el-table-column>
								<el-table-column prop="IsAuth" label="小程序授权状态">
									<template slot-scope="scope">
										{{scope.row.IsAuth?'已授权':'未授权'}}
									</template>
								</el-table-column>
								<el-table-column prop="InviteTime" label="邀请时间">
									<template slot-scope="scope">
										{{scope.row.InviteTime.substring(0,10)}}
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div style="text-align:right">
							<el-button type="primary" style="width:180px;margin:20px 0px" @click="openplate">免费开通
							</el-button>
						</div>
					</div>
				</el-card>
				<el-card style="width:49.5%;min-height:100vh">
					<div class="titfont">功能详情</div>
					<div class="flexCol" style="margin-top:20px">
						<img v-for="(v,i) in pagemsg.FunctionDetailImgUrlComplete" :key="i" :src="v" alt="" />
					</div>
				</el-card>
			</div>
		</div>

		<el-dialog :visible.sync="payshow" v-loading="payloading" title="提示" width="400px">
			<div>使用支付宝扫描下方二维码，完成支付。二维码30分钟更新一次</div>
			<div class="flexRow" style="justify-content:center;">
				<!-- <img :src="paymsg.QrcodeUrl" alt=""> -->
				<vue-qr :text="paymsg.QrcodeUrl" :size="300" ref="Qrcode"></vue-qr>
			</div>
			<div style="text-align:center">
				<el-button type="text" @click="againgetpay">
					<i class="el-icon-refresh-right"></i>
					重新获取二维码
				</el-button>
			</div>
			<div style="text-align:right;margin-top:20px">
				<el-button @click="payerr">支付失败</el-button>
				<el-button type="primary" @click="paysuc">我已支付成功</el-button>
			</div>
		</el-dialog>

		<el-dialog title="提示" :visible.sync="serviceshow" width="480px">
			<div style="line-height:18px">
				{{getrulesFont}}，<span style="color:#E6A23C">添加专属客服</span>，一对一为您解答疑惑
			</div>
			<div style="width:100%;text-align:center">
				<img src="@/assets/img/20210719171848.jpg" style="width:300px;margin-top:20px" alt="">
			</div>
			<div style="margin-top:20px;text-align:right">
				<el-button size="mini" @click="serviceshow = false">我知道了</el-button>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="agreementshow" v-loading="agreementloading" @close="closeagreement">
			<div slot="title" class="dialogtitle">{{agreementrules.AgreementTitle}}</div>
			<div class="agrementcontent" ref="agreement" v-html="agreementrules.AgreementContent"></div>
			<div style="margin-top:15px;" class="flexCol-center">
				<el-button type="primary" style="width:70%" @click="knowrules">我已知晓并同意{{agreementrules.AgreementTitle}}
				</el-button>
				<el-button type="text" style="color:#303133;margin-top:15px" @click="agreementshow=false">关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import store from "@/store/index";
	import axios from 'axios';
	import config from '@/config/index';

	import {
		mapGetters
	} from "vuex"
	import {
		generateTitle
	} from '@/utils/i18n'
	import {
		paymentrulereceivepaymentrule,
		paymentruleapplypaymentrule,
		paymentrulebuypaymentruleResult,
		mallsystemAgreementInfoInit
	} from "@/api/sv1.0.0"
	import vueQr from 'vue-qr';

	import createImage from '@/utils/createPosterImage';


	export default {
		components: {
			vueQr
		},
		watch: {
			msg() {
				if (this.msg.issuccess) {
					// console.log(this.thispagemsg.operation3.path)
					sessionStorage.setItem('addRouter', this.thispagemsg.operation3.path ? this.thispagemsg.operation3
						.path : '');
				}
			}
		},
		data() {
			return {
				Inputlink: '',
				poster: 'https://cdn.dkycn.cn/melyshop/image/promoteposter.png',
				pageloading: false,
				payshow: false,
				payloading: false,
				paymsg: {},
				serviceshow: false,
				getrulesFont: '支付结果获取中，若遇到问题，使用微信扫描下方二维码',
				posterImage: '',

				agreementshow: false,
				agreementloading: false,
				agreementrules: {},
			}
		},
		created() {
			this.setinputlink();
		},
		computed: {
			...mapGetters(['pagemsg', 'pagename'])
		},
		methods: {
			closeagreement() {
				this.$refs.agreement.scrollTop = 0 + 'px'
			},
			qrcallback(url) {
				createImage.createPosterImg({
					bg: this.pagemsg.PosterUrl ? this.pagemsg.PosterUrlComplete : this.poster,
					qrCode: url
				}).then(res => {
					this.posterImage = res;
				})
			},
			setinputlink() {
				this.Inputlink = location.origin + '/index.html#/freeOpen?mallId=' + window.localStorage.getItem(
					'PCMallId')
			},
			sucnoopen() {
				this.serviceshow = true,
					this.getrulesFont = '支付过程中，若遇到问题，使用微信扫描下方二维码'
			},
			againgetpay() {
				this.toagain()
			},
			async toagain() {
				this.payloading = true
				try {
					let data = {
						SystemPaymentRuleId: this.pagemsg.SystemPaymentRuleId,
						IsRefresh: true
					}
					let result = await paymentruleapplypaymentrule(data)
					if (result.IsSuccess) {
						this.paymsg = result.Result
						this.payshow = true
						// console.log(result.Result)
					}
				} finally {
					this.payloading = false
				}
			},
			payerr() {
				this.serviceshow = true,
					this.getrulesFont = '支付过程中，若遇到问题，使用微信扫描下方二维码'
			},
			paysuc() {
				this.getpayresult()
			},
			async getpayresult() {
				this.payloading = true
				try {
					let data = {
						PayOrderNo: this.paymsg.PayOrderNo,
					}
					// console.log(data)
					let result = await paymentrulebuypaymentruleResult(data)
					if (result.IsSuccess) {
						if (result.Result.IsPay) {
							this.payshow = false
							this.$message.success('支付成功')
							if(this.$route.query.id){
								this.$router.go(-1)
							}else{
								this.$router.go(0)
							}
						} else {
							this.serviceshow = true
							this.getrulesFont = '支付结果获取中，若遇到问题，使用微信扫描下方二维码'
						}
					}
				} finally {
					this.payloading = false
				}
			},
			paynow() {
				this.getrules()
			},
			async getrules() {
				this.pageloading = true
				try {
					let data = {
						AgreementType: 4
					}
					let res = await mallsystemAgreementInfoInit(data)
					if (res.IsSuccess) {
						// console.log(res)
						this.agreementrules = res.Result
						this.agreementshow = true
					}
				} finally {
					this.pageloading = false
				}
			},
			knowrules() {
				this.getpaymsg()
			},
			async getpaymsg() {
				this.agreementloading = true
				try {
					let data = {
						SystemPaymentRuleId: this.pagemsg.SystemPaymentRuleId,
						IsRefresh: false
					}
					let result = await paymentruleapplypaymentrule(data)
					if (result.IsSuccess) {
						this.paymsg = result.Result
						this.agreementshow = false
						this.payshow = true
						// console.log(result.Result)
					}
				} finally {
					this.agreementloading = false
				}
			},
			generateTitle,
			copysuc() {
				this.$message.success('复制成功')
			},
			copyerr() {
				this.$message.error('复制失败')
			},
			downloadposter() {
				var image = new Image()
				// 解决跨域 Canvas 污染问题 解决跨域问题-并不好使，需要改对应的请求服务器
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height
			
					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png')
			
					// 生成一个a元素
					var a = document.createElement('a')
					// 创建一个单击事件
					var event = new MouseEvent('click')
			
					// 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
					a.download = '邀请海报'
					// 将生成的URL设置为a.href属性
					a.href = url
					// 触发a的单击事件
					a.dispatchEvent(event)
				}
			
				image.src = document.getElementById('img').src
			},
			openplate() {
				let num = this.pagemsg.RecommendMallList.filter((v) => {
					return v.IsAuth
				})
				// console.log(num)
				if (num.length < this.pagemsg.RecommendCount) {
					this.$message.error(`成功邀请${this.pagemsg.RecommendCount}人，并且小程序已授权，才可获得免费使用权限哦~`)
				} else {
					this.$confirm(`是否确认开通${this.pagemsg.RuleName}？[邀请好友助力免费获取]仅可使用1次`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						//console.log(this.pagename)
						this.tofreeopen()
					}).catch(() => {})
				}
			},
			async tofreeopen() {
				this.pageloading = true
				try {
					let data = {
						SystemPaymentRuleId: this.pagemsg.SystemPaymentRuleId
					}
					let result = await paymentrulereceivepaymentrule(data)
					if (result.IsSuccess) {
						this.$message.success('开通成功')
						if(this.$route.query.id){
							this.$router.go(-1)
						}else{
							this.$router.go(0)
						}
					}
				} finally {
					this.pageloading = false
				}
			},
			
			topup() {
				if (!this.pagemsg.IsShowRecommendModule || this.$store.state.permission.routerList.indexOf(
					'myrecommended') == -1) {
					this.$message.error('暂无【推荐奖励】功能权限')
				} else {
					this.$router.push({
						path: '/assets/myrecommended/index'
					})
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.main{
		margin:0px auto;
		max-width: calc(~'100vw - 200px');
	}
	@media screen and (max-width: 1280px) {
		.main{
			max-width: calc(~'100vw - 140px');
		}
	}
	.flexRow {
		display: flex;
		flex-direction: row;
	
	}
	
	.flexCol {
		display: flex;
		flex-direction: column;
	}
	
	.titfont {
		font-size: 18px;
		font-weight: bold;
	}
	
	.bluebox {
		padding: 10px;
		border: 1px solid #409eff;
		background: #fbfdfd;
		font-size: 14px;
		line-height: 1.5;
		margin-top: 20px;
	}
	
	.secfont {
		font-size: 16px;
		font-weight: bold;
	}
	
	.center {
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	
		/* height: 100px; */
		.leftbox {
			align-items: center;
			flex: 1;
			height: 120px;
			justify-content: space-evenly;
	
			.oneyear {
				width: 100px;
				height: 30px;
				color: white;
				font-size: 14px;
				font-weight: bold;
				background: #409EFF;
				display: flex;
				flex-direction: row;
				align-items: center;
				border-radius: 25px;
				justify-content: center;
			}
	
			.money {
				color: #409EFF;
				font-size: 26px;
				font-weight: bold;
			}
		}
	}
	
	.grayfont {
		font-size: 14px;
		color: #999999;
		line-height: 1.5;
	}
	
	.thifont {
		font-size: 14px;
		font-weight: bold;
	
	}
	
	.dialogtitle {
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 18px;
		/* font-weight: bold; */
		color: #303133;
	}
	
	.agrementcontent {
		max-height: 600px;
		height: 600px;
		overflow: hidden;
		overflow-y: auto;
		width: 95%;
		margin: 0px auto;
		/* border:1px solid black; */
		color: #606266;
		font-size: 14px;
	}
	
	.flexCol-center {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
</style>
