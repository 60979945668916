<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	// 入口组件  
  
	import {
		mapGetters
	} from 'vuex'

	var whiteList = ['/login', '/selectMall', '/freeOpen', '/forget']

	export default {
		name: 'App',
		computed: {
			...mapGetters([
				"loginState"
			]),
		},
		created() {
			// 在页面加载时读取sessionStorage
			// if (sessionStorage.getItem('store')) {
			// 	this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
			// }
			// 在页面刷新时将store保存到sessionStorage里
			window.addEventListener('beforeunload', () => {
				// sessionStorage.setItem('store', JSON.stringify(this.$store.state))
			})

			this.mallkey = '';
			window.addEventListener('visibilitychange', () => {

				if (document.hidden) {
					this.mallkey = localStorage.getItem('mlmzMallKey');
				}
				if (!document.hidden) {
					var newMallkey = localStorage.getItem('mlmzMallKey');
					// console.log(this.mallkey, newMallkey);
					if (whiteList.indexOf(this.$route.path) < 0 && this.mallkey && this.mallkey != newMallkey) {
						this.$alert('你已切换到其它商户后台，请刷新页面继续操作', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								location.reload();
							}
						});
					}
				}
			})

		},

	}
</script>

<style>
	
	@font-face {
		font-family: 'biao ti hei';
		font-weight: normal;
		font-style: normal;
		src: url('https://cdn.dkycn.cn/fonts/melyshop/youshebiaotihei.ttf') format('truetype');
	}
	
	.helpjs-link{
		color: #999;
	}
	.helpjs-link:hover{
		color: #409EEF;
	}
	.helpjs-title{
		margin-top: 15px;
	}
	
	.red {
		color: #F56C6C;
	}

	.scale-enter-active {
		animation: bounce-in .5s;
		transform-origin: 0 0;
	}

	.scale-leave-active {
		animation: bounce-in .5s reverse;
		transform-origin: 0 0;
	}

	.scaleRight-enter-active {
		animation: bounce-in .5s;
		transform-origin: 100% 0;
	}

	.scaleRight-leave-active {
		animation: bounce-in .5s reverse;
		transform-origin: 100% 0;
	}

	@keyframes bounce-in {
		0% {
			transform: scale(0);
		}

		100% {
			transform: scale(1);
		}
	}

	.bounce-out-left-enter-active {
		animation: bounce-out-left .2s;
	}

	.bounce-out-left-leave-active {
		animation: bounce-out-left .2s reverse;
	}

	@keyframes bounce-out-left {
		0% {
			transform: translateX(-30%);
		}

		100% {
			transform: translateX(0);
		}
	}

	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		background-color: none;
	}

	::-webkit-scrollbar-thumb {
		background-color: #d4d5d6;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #a4a5a6;
	}

	::-webkit-scrollbar-thumb:active {
		background-color: none;
	}
</style>
