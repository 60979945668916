<template>
	<div v-if="!navData.hidden&&navData.children" class="menu-wrapper" style="margin-top:30px;padding-bottom: 100px;">

		<el-menu :index="navData.name||navData.path" :default-openeds="openedData"  :default-active="sildebarMenu">

			<div v-for="(child,i) in navData.children" :key="i" v-if="!child.hidden&&navData.children&&navData.children.length" 
				 style="padding-bottom: 10px;">
				<!-- 没有三级菜单 -->
				<div v-if="!child.children" class="no-children-menu">
					<router-link :to="resolvePath(child.path)" :key="child.name" v-show="child.path!='/customerServe/goTocustomer'">					
						<el-menu-item :disabled='freeMallNoSet&&freeMallWhiteRoutes.indexOf(child.path)<0' :index="resolvePath(child.path)">
							<!-- <svg-icon v-if="child.meta&&child.meta.icon" :icon-class="child.meta.icon" /> -->
							<!-- <i v-if="child.meta&&child.meta.icon" style="font-size: 16px;margin-right: 0;vertical-align: -1px;" :class="[child.meta.icon]"></i> -->
							<img class="img-icon" v-if="child.meta&&child.meta.icon" :src="child.meta.icon+'?v=1'" />
							<img class="img-icon-active" v-if="child.meta&&child.meta.active" :src="child.meta.active+'?v=1'" />
							<span v-if="child.meta&&child.meta.title" slot="title" style="font-size: 15px;">
								{{ (child.meta.title=='homeTitle'&&IsXiTai)?'首页':generateTitle(child.meta.title) }}
							</span>
						</el-menu-item>
					</router-link>
					
				</div>
				<!-- 有三级菜单 -->
				<div v-if="child.children&&child.children.length">
					<el-submenu :disabled='freeMallNoSet&&freeMallWhiteRoutes.indexOf(child.path)<0' :index="resolvePath(child.path)">
						
						<template slot="title">
							<img class="img-icon" v-if="child.meta&&child.meta.icon" :src="child.meta.icon+'?v=1'" />
							<!-- <i v-if="child.meta&&child.meta.icon" style="font-size: 16px;margin-right: 0;vertical-align: -1px;" :class="[child.meta.icon]"></i> -->
							<span style="font-size: 15px;">{{generateTitle(child.meta.title)}}</span>
						</template>
						
						<div class="sub-menu">
							<div class="sub-menu-item" v-for="(record,indesx) in child.children" :key="indesx" 
							                           v-if="!record.hidden">
								<router-link :to="resolvePath(record.path)" :key="record.name">
									<el-menu-item style="padding-left: 57px;" :index="resolvePath(record.path)">{{generateTitle(record.meta.title)}}</el-menu-item>
								</router-link>
							</div>
							<div class="sub-menu-item" v-if="child.path == '/shopServe'">
								<el-menu-item @click="goCustmer" index="1">
									<span>进入网页客服</span>
								</el-menu-item>
							</div>
						</div>
					</el-submenu>
				</div>
				
				<el-menu-item :disabled='freeMallNoSet&&freeMallWhiteRoutes.indexOf(child.path)<0' @click="goLive" index="2"
				 style="padding-left: 40px;" v-if="showBroadCast&&child.path=='/broadCast'">进入主播系统</el-menu-item>
				<el-menu-item style="padding-left: 40px;" v-if="showStoreTags&&child.path=='/broadCast'" index="3"
				 @click="openStorePC">进入店铺管理后台</el-menu-item>
				
				
			</div>
			
			<div v-if="item.path === '/' && homeRouter.length && !IsXiTai">
				<div v-for="(child, index) in homeRouter" style="padding-bottom: 10px;" :key="index">
					<el-submenu :index="index+''">
						
						<template slot="title">
							<img class="img-icon" v-if="child.icon" :src="child.icon+'?v=1'" />
							<span style="font-size: 15px;">{{child.title}}</span>
						</template>
						
						<div class="sub-menu">
							<div class="sub-menu-item" v-for="(record,indesx) in child.children" :key="indesx">
								<el-menu-item style="padding-left: 57px;" :index="index + '-' + indesx" @click="handleJump(record)">{{record.title}}</el-menu-item>
							</div>
						</div>
					</el-submenu>
				</div>
			</div>
			
		</el-menu>

	</div>

</template>

<script>
	import {
		mapGetters
	} from 'vuex'

	import path from 'path'
	import {
		generateTitle
	} from '@/utils/i18n'
	import Hamburger from '@/components/Hamburger'

	import {
		XJChatGetLoginCert
	} from '../../../../api/login.js'
	
	import {homeRouter} from '@/router/index'

	export default {
		name: 'SidebarItem',
		props: {
			// route object
			item: {
				type: Object,
				required: true
			},
			isNest: {
				type: Boolean,
				default: false
			},
			basePath: {
				type: String,
				default: ''
			},
			slectRouter: {
				type: String,
				default: ''
			}


		},
		data() {
			return {
				showBroadCast: false,
				showStoreTags: false,
				onlyOneChild: null,
				sildebarMenu: '0',
				openedData: [],
				flag: true,

				freeMallNoSet: false,
				
				navData: {},
				homeRouter: homeRouter
			}
		},

		created() {
			this.showBroadCast = this.$store.getters.haveLiveRouter;
			this.showStoreTags = this.$store.getters.haveStoreRouter;
			this.sildebarMenu = this.$route.path
			// console.log(this.$route.path)
			this.freeMallNoSet = window.localStorage.getItem('mlmzFreePayState')!=2 && window.localStorage.getItem('mlmzIsFree')== 'true'
			// console.log(this.showStoreTags)
			this.filterItem();
		},
		watch: {
			mylyshopList(value) {
				this.routerObject = value;
			},
			slectRouter(value) {
				this.$nextTick(()=>{
					this.sildebarMenu = value;
				})
				
				if(this.item.path == '/'){
					this.homeRouter.map((item, index)=>{
						this.openedData.push(index + '')
					})
				}else{
					this.item.children.map(record => {
						if (record.children && record.children.length) {
							if (this.openedData.indexOf(record.path) < 0){
								this.openedData.push(record.path)
							}
						}
					})
				}
				
			},
			item(value) {
				this.filterItem();
			}
		},
		components: {
			Hamburger
		},
		computed: {
			...mapGetters([
				'sidebar',
				'freeMallWhiteRoutes',
				'functionalNavChange',
				'routerList',
				'IsXiTai'
			]),
		},
		methods: {
			filterItem(){
				if (!this.item.children) return;
				var list = [];
				var obj = JSON.parse(JSON.stringify(this.item));
				
				obj.children.map(item=>{
					if (item.children){
						var array =item.children.filter(item1=>{
							return !item1.hidden
						})
						if (array.length){
							list.push(item)
						}
					}else if (!item.hidden){
						list.push(item)
					}
				})
				
				obj.children = list;
				this.navData = obj;
				// console.log(this.navData, homeRouter)
			},
			// 进入客服系统
			async goCustmer() {
				this.sildebarMenu = this.resolvePath(this.item.children[0].path)

				const res = await XJChatGetLoginCert()

				if (process.env.NODE_ENV == 'production') {
					window.open('https://kf.dkycn.cn/page/#/home/clientlist?fromMely=1&cert=' + res.LoginCert + '&host=' +
						location.host)
				} else {
					window.open('http://localhost:8008/#/home/clientlist?fromMely=1&cert=' + res.LoginCert + '&host=' + location.host)
				}
			},
			// 进入直播系统
			goLive() {
				this.sildebarMenu = this.resolvePath(this.item.children[0].path)
				// 获取是否有主播系统的权限
				this.$store.dispatch('GenerateRoutes').then(() => {
					const haveLiveRouter = this.$store.getters.haveLiveRouter
					if (haveLiveRouter) {
						if (process.env.NODE_ENV == 'production') {
							window.open('/live')
						} else {
							window.open('http://localhost:8099/#/')
						}
					} else {
						this.$message({
							type: 'error',
							message: '主播系统未设置权限，跳转失败',
							showClose: true
						})
					}
				})
			},
			openStorePC() {
				// 获取是否有店铺系统的权限
				this.$store.dispatch('GenerateRoutes').then(() => {
					const haveStoreRouter = this.$store.getters.haveStoreRouter
					if (haveStoreRouter) {
						if (process.env.NODE_ENV == 'production') {
							window.open('/store')
						} else {
							window.open('http://localhost:8098/#/')
						}
					} else {
						this.$message({
							type: 'error',
							message: '店铺系统未设置权限，跳转失败',
							showClose: true
						})
					}
				})
			
			},

			toggleSideBar() {
				this.$store.dispatch('toggleSideBar')
			},
			hasOneShowingChild(children) {
				const showingChildren = children.filter(item => {
					if (item.hidden) {
						return false
					} else {
						this.onlyOneChild = item;
						return true
					}
				})
				if (showingChildren.length === 1) {
					return true
				}
				return false
			},
			resolvePath(...paths) {
				return path.resolve(this.basePath, ...paths)
			},
			handleJump(record){
				if (record.name == 'code'){
					this.$store.commit('SET_FUNCTOINALNAV', !this.functionalNavChange)
				}else if (record.name == 'inquiry'){
					var mod = localStorage.getItem('mlmzDistributionModel');
					if (mod === '0'){
						this.$router.push({
							name: "inquiry"
						})
					}else{
						this.$router.push({
							name: "agentmanagelist"
						})
					}
				}else{
					
					var list = this.routerList.filter(item=>{
						return item == record.name
					})
					
					if (list.length){
						this.$router.push({
							name: record.name
						})
					}else{
						this.$message.error('暂无【'+record.title+'】功能权限')
					}
					
				}
			},
			generateTitle
		}
	}
</script>
<style lang="less">
	.menu-wrapper{
		.sub-menu{
			overflow: hidden;
			padding-left: 24px;
			
			.sub-menu-item{
				float: left;
				width: 45%;
				transition: all .3s;
				
				.el-menu-item{
					padding: 0!important;
					font-size: 13px!important;
					min-width: 60px!important;
					padding-left: 10px!important;
					height: 40px;
					line-height: 40px;
				}
			}
			
		}
		.el-submenu__title{
			padding-left: 10px!important;
		}
		.el-menu-item{
			padding-left: 10px!important;
		}
	}
	
	.img-icon{
		width: 16px;
		margin-right: 5px;
		vertical-align: -3px;
	}
	.img-icon-active{
		width: 16px;
		margin-right: 5px;
		vertical-align: -3px;
		display: none;
	}
	
	.no-children-menu{
		.is-active{
			.img-icon-active{
				display: inline-block;
			}
			.img-icon{
				display: none;
			}
		}
	}
	
	@media screen and (max-width: 1280px) {
		.menu-wrapper{
			.sub-menu{
				.sub-menu-item{
					width: 100%;
				}
			}
		}
	}
	
</style>
