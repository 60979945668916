import {
	MenuInit
} from '@/api/goods'
import {
	asyncRouterMap,
	constantRouterMap
} from '@/router'

import config from '@/config/index'

import {
	List
} from '@/mock/menu'


import store from '@/store/index'

/**
 * 扁平化数组
 * @param  {[type]} array [description]
 * @param  {Number} depth [description]
 * @return {[type]}       [description]
 */
function flattenDepth(array, depth = 3) {
	let list = []
	let result = []
	// console.log(array)
	array.forEach(item => {
		let d = depth
		list.push(item)
		if (item.ChildMenuDtoList && Array.isArray(item.ChildMenuDtoList) && d > 0) {
			list.push(...(flattenDepth(item.ChildMenuDtoList, --d)))
		}
	})
	result = list.map(item => {
		if (item.Sref) {
			return item.Sref
		} else {
			return item
		}
	})
	return result
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routerMap
 * @param roles
 */
function filterAsyncRouter(routerMap, permissionList) {
	// console.log(asyncRouterMap,123321)
	const accessedRouters = routerMap.filter(route => {
		if (route.name && permissionList.indexOf(route.name) != -1 || route.hidden) {
			return true
		}
		if (!route.name && route.children && route.children.length) {
			route.children = filterAsyncRouter(route.children, permissionList)
			if (route.children.length > 0) {
				return true
			}
		}
		return false
	})
	return accessedRouters
}


const permission = {
	state: {
		allRouters:[],
		routers: constantRouterMap,
		localRouter: [],
		addRouters: [],
		isGet: false,
		routerList: [],
		haveLiveRouter: false,
		haveStoreRouter: false,
		freeMallWhiteRoutes: [
			'/',
			'/home',
			'/login',
			'/goods/goodsList',
			'/goods/creatGoodDetails',
			'/myNeed/needPage',
			'/mallPage/mallPagemange',
			'/mallPage/mallPageDetails',
			'/setting/payApplyResult',
			'/setting/setPayApply',
		]
	},
	mutations: {
		SET_ROUTERS: (state, routers) => {
			state.addRouters = routers
			state.routers = routers
		},
		SET_ISGET: (state, flag) => {
			state.isGet = flag
		},
		SET_ROUTERLIST: (state, routers) => {
			state.routerList = routers
		},
		SET_HAVELIVEROUTER: (state,isHave) => {
			state.haveLiveRouter = isHave
		},
		SET_HAVESTOREROUTER: (state,isHave) => {
			state.haveStoreRouter = isHave
		},
		SET_ALLROUTES:(state,routers)=>{
			state.allRouters = routers
		},
		set_localRouter:(state,routers)=>{
			state.localRouter = routers
		},
	},
	actions: {
		// 生成路由信息
		GenerateRoutes({
			commit
		}) {
			return new Promise((resolve, reject) => {
				MenuInit().then(response => {
					// console.log(response)
					const data = response;
					if (!response) return;
					
					let flag = false
					let flag2 = false
					response.Result.forEach(t => {
						if (t.Text.indexOf('主播-')!= -1 && t.ChildMenuDtoList.length) {
							flag = true
						}
						if (t.Text.indexOf('店铺-')!= -1 && t.ChildMenuDtoList.length) {
							flag2 = true
						}
					})
					commit('SET_ALLROUTES',data.Result)
					commit('SET_HAVELIVEROUTER', flag)
					commit('SET_HAVESTOREROUTER', flag2)

					if (!data.Result) {
						store.dispatch('FedLogOut').then(() => {
							location.reload() // 为了重新实例化vue-router对象 避免bug
						})
					}

					let list = flattenDepth(data.Result);
					// console.log(asyncRouterMap)
					// localStorage.setItem('asyncRouterMap', asyncRouterMap)
					
					// if (!store.state.permission.localRouter.length){
					// 	commit('set_localRouter', asyncRouterMap);
					// }
					const arr = flattenDepth(asyncRouterMap);
					
					let accessedRouters = null;
					commit('SET_ROUTERLIST', list)
					
					accessedRouters = filterAsyncRouter(arr, list)
					// let textroute = null
					// console.log(list)
					// textroute = filterAsyncRouter(data.Result, list)
					// console.log(data.Result)
					let newaccessedRouters = [];
					// console.log(newaccessedRouters)
					accessedRouters.map(item => {
						if (item) {
							let flages1=false;
							let counts=0
							item.children.map(record=>{
								if(record.hidden){
									counts++
								}
							})
							flages1= counts!=item.children.length
							if(flages1){
								newaccessedRouters.push(item)
							}
						}
					})

					let newRouters = constantRouterMap.concat(newaccessedRouters)
					if(store.getters.IsXiTai){
						// newRouters = newRouters.map(v=>{
						// 	if(v.path == '/'){
						// 		v.children = []
						// 	}
						// 	return v
						// })
						// newRouters = newRouters.filter(v=>v.path!='/')
					}
					// console.log(newRouters.map(v=>v.path))
					commit('SET_ROUTERS', newRouters)
					commit('SET_ISGET', true)
					resolve()
				}).catch(error => {
					// console(error,123)
					reject(error)
				})
			})
		},
	}
}

export default permission
