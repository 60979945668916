<template>
	<div v-if="isfinish">
		<div v-if="!needPay">
			<slot></slot>
		</div>
		<div v-else>
			
			<buyRuleMod></buyRuleMod>
			
		</div>
		
	</div>
</template>

<script>
	
	import store from "@/store/index";
	import axios from 'axios';
	import config from '@/config/index'

	import buyRuleMod from '@/components/buyRuleMod/buyRuleMod'
	
	export default {
		name: 'payment',
		components: {
			buyRuleMod
		},
		data() {
			return {
				isfinish:false,
				needPay: true
			}
		},
		watch:{
			$route(){
				this.isfinish = false
				this.needPay = true
				this.getPayInfo();
			}
		},
		created(){
			this.getPayInfo()
		},
		methods: {
			getPayInfo() {
				store.dispatch('getloginEmployeeInfo', ()=>{})
				if(this.$store.state.permission.routerList.indexOf(this.$route.name) > -1){
					store.dispatch('getpaymentrulevalitemenu',{Sref:this.$route.name}).then((res)=>{
						this.isfinish = true
						if(res.IsSuccess){
							if (res.Result.IsNeedPay){
								this.needPay = true;
							}else{
								this.needPay = false;
							}
						}else{
							this.$router.go(-1)
						}
						// console.log(this.isfinish,this.needPay)
					}).catch((rej)=>{
						var state = 0;
						try{
							state = rej.response.status
						}catch(e){}
						
						if (state != 401){
							this.isfinish = true
							this.$router.go(-1)
						}
						
					})
				}else{
					// console.log('else')
					this.isfinish = true
					this.needPay = false
				}
			},
			
		},
	}
</script>

<style scoped lang = "less">
	.flexRow {
		display: flex;
		flex-direction: row;
		
	}
	
	.flexCol {
		display: flex;
		flex-direction: column;
	}
	
	.titfont {
		font-size: 18px;
		font-weight: bold;
	}
	
	.bluebox {
		padding: 10px;
		border: 1px solid #409eff;
		background: #fbfdfd;
		font-size: 14px;
		line-height: 1.5;
		margin-top: 20px;
	}
	
	.secfont {
		font-size: 16px;
		font-weight: bold;
	}
	
	.center {
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	
		/* height: 100px; */
		.leftbox {
			align-items: center;
			flex: 1;
			height: 120px;
			justify-content: space-evenly;
	
			.oneyear {
				width: 100px;
				height: 30px;
				color: white;
				font-size: 14px;
				font-weight: bold;
				background: #409EFF;
				display: flex;
				flex-direction: row;
				align-items: center;
				border-radius: 25px;
				justify-content: center;
			}
	
			.money {
				color: #409EFF;
				font-size: 26px;
				font-weight: bold;
			}
		}
	}
	
	.grayfont {
		font-size: 14px;
		color: #999999;
		line-height: 1.5;
	}
	
	.thifont {
		font-size: 14px;
		font-weight: bold;
	
	}

	.dialogtitle{
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 18px;
		/* font-weight: bold; */
		color: #303133;
	}
	.agrementcontent{
		max-height:600px;
		height: 600px;
		overflow:hidden;
		overflow-y:auto;
		width: 95%;
		margin:0px auto;
		/* border:1px solid black; */
		color: #606266;
		font-size: 14px;
	}
	.flexCol-center{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
</style>
