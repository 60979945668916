import request from '@/utils/request'

//获取消息订阅
export const mallNoticeSubscribeConfig = () => request('/pc/mall/mallNoticeSubscribeConfig');

// ...好友欢迎语
// 好友欢迎语-列表
export const qyWeixinFriendWelcomelist = (data) => request('/pc/qyWeixinFriendWelcome/list',data);
// 好友欢迎语-详细信息 
export const qyWeixinFriendWelcomeinfo = (data) => request('/pc/qyWeixinFriendWelcome/info',data);
//  好友欢迎语-编辑
export const qyWeixinFriendWelcomeedit = (data) => request('/pc/qyWeixinFriendWelcome/edit',data);
// 好友欢迎语-删除
export const qyWeixinFriendWelcomedelete = (data) => request('/pc/qyWeixinFriendWelcome/delete',data);
//根据链接 爬链接内容
export const htmlinfobylink = (data) => request('/htmlhelper/htmlinfobylink',data);

// ...进群欢迎语
// 进群欢迎语-列表
export const qyWeixinJoinGroupWelcomelist = (data) => request('/pc/qyWeixinJoinGroupWelcome/list',data);
// 进群欢迎语-详细信息 
export const qyWeixinJoinGroupWelcomeinfo = (data) => request('/pc/qyWeixinJoinGroupWelcome/info',data);
// 进群欢迎语-编辑
export const qyWeixinJoinGroupWelcomeedit = (data) => request('/pc/qyWeixinJoinGroupWelcome/edit',data);
// 进群欢迎语-删除
export const qyWeixinJoinGroupWelcomedelete = (data) => request('/pc/qyWeixinJoinGroupWelcome/delete',data);

export const qyWeixinSelfSuiteAuthLink = (data) => request('/pc/qyweixin/qyWeixinSelfSuiteAuthLink',data);
export const setqyweixinsidebarconfig = (data) => request('/pc/qyweixin/setqyweixinsidebarconfig',data);
export const qyweixinsidebarconfig = (data) => request('/pc/qyweixin/qyweixinsidebarconfig',data);
export const employeelistbystate = (data) => request('/pc/employee/employeelistbystate',data);

export const isfinishupdatetips = (data) => request('/pc/mall/isfinishupdatetips',data);

export const setWxAppSecret = (data) => request('/pc/mallFreeVersionConfig/setWxAppSecret',data);

//申请充值
export const mallBalanceApplyRecharge = (data) => request('/pc/mallBalance/applyRecharge',data);
//获取充值结果
export const mallBalanceRechargeResult = (data) => request('/pc/mallBalance/rechargeResult',data);

// 客户流失提醒列表
export const customerchurnreminderpagelist = (data) => request('/pc/qyweixincutomer/customerchurnreminderpagelist',data);
//客户流失提醒保存
export const customerchurnreminderconfigsave = (data) => request('/pc/qyweixincutomer/customerchurnreminderconfigsave',data);
//获取客户流失提醒配置
export const customerchurnreminderconfig = (data) => request('/pc/qyweixincutomer/customerchurnreminderconfig',data);


//员工删人提醒
export const employeedeletecustomerreminder = (data) => request('/pc/qyweixincutomer/employeedeletecustomerreminder',data);
//员工删人配置
export const employeedeletecustomerreminderconfig = (data) => request('/pc/qyweixincutomer/employeedeletecustomerreminderconfig',data);
//员工删人配置保存
export const employeedeletecustomerreminderconfigsave = (data) => request('/pc/qyweixincutomer/employeedeletecustomerreminderconfigsave',data);


//批量加好友选择员工
export const chooseEmployee = (data) => request('/pc/qyWeixinAddFriend/chooseEmployee',data);
//批量加好友导入
export const qyWeixinAddFriendimport = (data) => request('/pc/qyWeixinAddFriend/import',data);
//统计数据初始化
export const qyWeixinAddFriendinit = (data) => request('/pc/qyWeixinAddFriend/init',data);
//列表
export const qyWeixinAddFriendlist = (data) => request('/pc/qyWeixinAddFriend/list',data);
//分配
export const qyWeixinAddFrienddirsribute = (data) => request('/pc/qyWeixinAddFriend/dirsribute',data);
//提醒
export const qyWeixinAddFriendremind = (data) => request('/pc/qyWeixinAddFriend/remind',data);
//删除
export const qyWeixinAddFrienddelete = (data) => request('/pc/qyWeixinAddFriend/delete',data);
//跟进设置-获取
export const qyWeixinAddFriendfollowget = (data) => request('/pc/qyWeixinAddFriend/follow/get',data);
//跟进设置-修改
export const qyWeixinAddFriendfollowupdate = (data) => request('/pc/qyWeixinAddFriend/follow/update',data);
//导入记录
export const qyWeixinAddFriendimportRecordlist = (data) => request('/pc/qyWeixinAddFriend/importRecord/list',data);

//获取企业微信授权链接
export const qyWeiXinPreAuthCodeLink = (data) => request('/qyWeiXin/Suite/preAuthCodeLink',data);


//自动打标签-初始化
export const tagRuleConfigInit = (data) => request('/pc/tagRuleConfig/init',data);
//自动打标签-列表
export const tagRuleConfiglist = (data) => request('/pc/tagRuleConfig/list',data);
//自动打标签-编辑
export const tagRuleConfigedit = (data) => request('/pc/tagRuleConfig/edit',data);
//自动打标签-详情
export const tagRuleConfigeinfo = (data) => request('/pc/tagRuleConfig/info',data);
//自动打标签--停用（修改状态）
export const tagRuleConfigemodifyState = (data) => request('/pc/tagRuleConfig/modifyState',data);
//自动打标签-删除
export const tagRuleConfigemodifydelete = (data) => request('/pc/tagRuleConfig/delete',data);
//自动打标签-更新
export const tagRuleConfigupdateTag= (data) => request('/pc/tagRuleConfig/updateTag',data);
//获取模板人数
export const templateMemberCount= (data) => request('/pc/tagRuleConfig/templateMemberCount',data);
//获取模板参数
export const getTemplateParam = (data) => request('/pc/autoMarketingTaskConfig/getTemplateParam',data);
//自动营销获取模板人数
export const autoMarketingTaskConfigtemplateMemberCount = (data) => request('/pc/autoMarketingTaskConfig/templateMemberCount',data);


//群活码列表
export const grouplivecodelist = (data)=>request('/pc/grouplivecode/list',data)
//群活码信息
export const grouplivecodeinfo = (data)=>request('/pc/grouplivecode/info',data)
//群活码编辑
export const grouplivecodesave = (data)=>request('/pc/grouplivecode/save',data)
//群活码删除
export const grouplivecodedelete = (data)=>request('/pc/grouplivecode/delete',data)
//群活码下载
export const grouplivecodedownload = (data)=>request('/pc/grouplivecode/download',data)
//门店群活码-总码初始化
export const grouplivecodeheadinfo = (data)=>request('/pc/grouplivecode/headinfo',data)
//门店群活码-总码编辑
export const grouplivecodeheadsave = (data)=>request('/pc/grouplivecode/headsave',data)
// 门店群活码筛选门店
export const grouplivecodeshopfilterBoxList = (data)=>request('/pc/grouplivecode/shopfilterBoxList',data)
//门店是否有群活码
export const shopdeleteishavecode = (data)=>request('/pc/shop/shopdeleteishavecode',data)
//群活码筛选  客服
export const grouplivecodecustomerfilterBoxList = (data)=>request('/pc/grouplivecode/customerfilterBoxList',data)


//客户群业绩列表
export const qyweixincutomerqyweixinrealgroupperformancelist = (data)=>request('/pc/qyweixincutomer/qyweixinrealgroupperformancelist',data)

//客户标签-已打系统标签-列表
export const tagMemberlist = (data)=>request('/pc/tagMember/list',data)
//客户标签-已打系统标签-修改
export const tagMembermodify = (data)=>request('/pc/tagMember/modify',data)
//客户标签-批量打标签 
export const tagMemberbatchSet = (data)=>request('/pc/tagMember/batchSet',data)


//验证是否安装企店助手
export const qyweixinsuiteauthinfo = (data)=>request('/pc/qyweixin/qyweixinsuiteauthinfo',data)
//步骤保存
export const configstepsave = (data)=>request('/pc/qyweixin/configstepsave',data)
//保存秘钥
export const qyweixinsecretsave = (data)=>request('/pc/qyweixin/qyweixinsecretsave',data)
//获取同步状态
export const qyweixindatasyncresult = (data)=>request('/pc/qyweixin/qyweixindatasyncresult',data)
//保存cropId
export const saveCorpId = (data)=>request('/pc/qyweixin/saveCorpId',data)

//兴趣商品页面--（今日浏览、购物车商品、购买过商品）
export const memberinterestProductPageInit = (data)=>request('/pc/member/interestProductPageInit',data)


//首页  --新店开张、交易破冰、经营进阶
// export const mallinfoNewShop = (data)=>request('/pc/mall/info',data)




//sv1.0.1
//视频号接入流程进度
export const videolivevideoliveapplyinfo = (data)=>request('/pc/videolive/videoliveapplyinfo',data)
//保存视频号ID/pc/videolive/saveVideoLiveId
export const saveVideoLiveId = (data)=>request('/pc/videolive/saveVideoLiveId',data)
//开通组件
export const videolivevideoliveapply = (data)=>request('/pc/videolive/videoliveapply',data)
//同步订单信息
export const videosyncvideoliveorderinfo = (data)=>request('/pc/video/syncvideoliveorderinfo',data)
//视频号场景接入
export const videolivevideoliveapplyscene = (data)=>request('/pc/videolive/videoliveapplyscene',data)


//获取商品橱窗列表
export const videolivevideoliveproductpagelist = (data)=>request('/pc/videolive/videoliveproductpagelist',data)
//商品橱窗弹窗的商品列表
export const videoliveproductpoplist = (data)=>request('/pc/videolive/productpoplist',data)
//弹窗添加商品
export const videoliveaddvideoliveproduct = (data)=>request('/pc/videolive/addvideoliveproduct',data)
//修改视频号类目
export const videoliveeditvideoliveproductcat = (data)=>request('/pc/videolive/editvideoliveproductcat',data)
//单个提审商品
export const videosubmitvideoliveproduct = (data)=>request('/pc/video/submitvideoliveproduct',data)
//批量提审商品
export const videovalitesubmitvideoliveproduct = (data)=>request('/pc/video/valitesubmitvideoliveproduct',data)
//单个下架
export const videoputoffvideoliveproduct = (data)=>request('/pc/video/putoffvideoliveproduct',data)
//单个上架
export const videoputonvideoliveproduct = (data)=>request('/pc/video/putonvideoliveproduct',data)
//校验批量上架商品
export const videovaliteputonvideoliveproduct =(data)=>request('/pc/video/valiteputonvideoliveproduct',data)
//单个删除
export const videolivevideoliveproductdelete = (data)=>request('/pc/videolive/videoliveproductdelete',data)
//撤销审核
export const videolivevideoliveproductrecallcheck = (data)=>request('/pc/videolive/videoliveproductrecallcheck',data)







// //查询所有类目
export const videolivevideolivewxappcatlist = (data)=>request('/pc/videolive/videolivewxappcatlist',data)
//获取营业执照
export const videolivebusinesslicense = (data)=>request('/pc/videolive/businesslicense',data)
//修改营业执照
export const videolivebusinesslicenseedit = (data)=>request('/pc/videolive/businesslicenseedit',data)
//获取类目列表
export const videolivesystemvideolivecategorypagelist = (data)=>request('/pc/videolive/systemvideolivecategorypagelist',data)
//添加类目
export const videoliveaddvideolivecategory = (data)=>request('/pc/videolive/addvideolivecategory',data)
//类目重新提审
export const videolivevideolivecategoryresubmit = (data)=>request('/pc/videolive/videolivecategoryresubmit',data)
//删除类目
export const videolivesystemvideolivecategorydelete = (data)=>request('/pc/videolive/systemvideolivecategorydelete',data)
//查看类目资料
export const videolivevideolivecategoryinfo = (data)=>request('/pc/videolive/videolivecategoryinfo',data)
//修改类目商品资质
export const videolivevideolivecategoryproductqualificationedit = (data)=>request('/pc/videolive/videolivecategoryproductqualificationedit',data)



//sv2.0.0
//好友裂变
//好友裂变活动列表
export const qyweixinqyweixinfriendfissionactivitypagelist = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivitypagelist',data)
//裂变活动详情
export const qyweixinqyweixinfriendfissionactivitydetail = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivitydetail',data)
//好友裂变可参与活动的员工
export const qyweixinqyweixinfriendfissionactivitychoseemployeelist = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivitychoseemployeelist',data)
//终止活动
export const qyweixinqyweixinfriendfissionactivityfinish = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivityfinish',data)
//删除活动
export const qyweixinqyweixinfriendfissionactivitydelete = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivitydelete',data)
//编辑好友裂变活动
export const qyweixinqyweixinfriendfissionactivityedit = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivityedit',data)
//获取任务阶梯
export const qyweixinqyweixinfriendfissionactivitytask = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivitytask',data)
//好友裂变活动客户数据
export const qyweixinqyweixinfriendfissionactivitymemberdat = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivitymemberdata',data)
//裂变记录顶部数据统计
export const qyweixinqyweixinfriendfissionrecordstatistics = (data)=>request('/pc/qyweixin/qyweixinfriendfissionrecordstatistics',data)
//裂变记录
export const qyweixinqyweixinfriendfissionrecord = (data)=>request('/pc/qyweixin/qyweixinfriendfissionrecord',data)


//优惠券
export const activityMarkdownGiftChoseCoupon = (data) => request('/pc/activitycoupon/popupList', data);
//商品分组
export const productGroupfilterBoxList = (data) => request('/pc/productGroup/filterBoxList', data);
//商品品牌 
export const productBrandfilterBoxList = (data) => request('/pc/productBrand/filterBoxList', data);
//赠品列表
export const productSpecpopList = (data) => request('/pc/productSpec/popList', data);

///pc/qyWeixinRiseFansMarketing/list涨粉营销活动列表 
export const qyWeixinRiseFansMarketingList = (data) => request('/pc/qyWeixinRiseFansMarketing/list', data);

//群裂变
//获取群裂变活动列表
export const qyweixinqyweixingroupfissionactivitypagelist = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivitypagelist',data)
//终止活动
export const qyweixinqyweixingroupfissionactivityfinish = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivityfinish',data)
//删除活动
export const qyweixinqyweixingroupfissionactivitydelete = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivitydelete',data)
//获取任务阶梯
export const qyweixinqyweixingroupfissionactivitytask = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivitytask',data)
//群裂变活动数据顶部数据统计
export const qyweixinqyweixingroupfissionrecordstatistics = (data)=>request('/pc/qyweixin/qyweixingroupfissionrecordstatistics',data)
//群裂变活动客户数据
export const qyweixinqyweixingroupfissionactivitymemberdata = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivitymemberdata',data)
//推广群聊数据
export const qyweixinqyweixingroupfissiongroupstatistics = (data)=>request('/pc/qyweixin/qyweixingroupfissiongroupstatistics',data)
//裂变记录
export const qyweixinqyweixingroupfissionrecord = (data)=>request('/pc/qyweixin/qyweixingroupfissionrecord',data)
//获取群裂变活动详情
export const qyweixinqyweixingroupfissionactivitydetail = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivitydetail',data)
//群裂变活动编辑
export const qyweixinqyweixingroupfissionactivityedit = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivityedit',data)
//获取活动可选择群
export const qyweixinqyweixingroupfissionactivitychosegrouplist = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivitychosegrouplist',data)


//获取好友裂变活动选中员工列表--排除已离职
export const qyweixinqyweixinfriendfissionactivityemployeelist = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivityemployeelist',data)
//创建群发   好友裂变
export const qyweixinqyweixinfriendfissionactivitycreatemsg = (data)=>request('/pc/qyweixin/qyweixinfriendfissionactivitycreatemsg',data)
//获取群裂变活动选中群聊群主员工列表--排除已离职
export const qyweixinqyweixingroupfissionactivityemployeelist = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivityemployeelist',data)
//创建群发   群裂变
export const qyweixinqyweixingroupfissionactivitycreatemsg = (data)=>request('/pc/qyweixin/qyweixingroupfissionactivitycreatemsg',data)





//sv2.3.0
//校验商城小程序是否使用联系我插件
export const qyweixincontactvaliteqyweixincontactplugin = (data)=>request('/pc/qyweixincontact/valiteqyweixincontactplugin',data)
//联系我列表
export const qyWeixinContactMeListRequest = (data)=>request('/pc/qyWeixinContactMe/ListRequest',data)
//联系我删除
export const qyWeixinContactMeDelRequest = (data)=>request('/pc/qyWeixinContactMe/DelRequest',data)
//联系我信息
export const qyWeixinContactMeInfoRequest = (data)=>request('/pc/qyWeixinContactMe/InfoRequest',data)
//联系我编辑
export const qyWeixinContactMeSaveRequest = (data)=>request('/pc/qyWeixinContactMe/SaveRequest',data)
//联系我适用员工列表
export const qyWeixinContactMeapplicableemployeelist = (data)=>request('/pc/qyWeixinContactMe/applicableemployeelist',data)

//联系我数据统计
export const qyWeixinContactMestatisOverView = (data)=>request('/pc/qyWeixinContactMe/statisOverView',data)
//联系我-转化数据列表
export const qyWeixinContactMedataList = (data)=>request('/pc/qyWeixinContactMe/dataList',data)
//联系我  流量数据
export const qyWeixinContactMeflowList = (data)=>request('/pc/qyWeixinContactMe/flowList',data)



//企微涨粉-列表  企微涨粉-列表(按字段PageType区分【详情页】【支付成功页】，未配置时为空)
export const qyWeixinIncreaseFanslist = (data)=>request('/pc/qyWeixinIncreaseFans/list',data)
//编辑
export const qyWeixinIncreaseFansedit = (data)=>request('/pc/qyWeixinIncreaseFans/edit',data)
//关闭
export const qyWeixinIncreaseFansclose = (data)=>request('/pc/qyWeixinIncreaseFans/close',data)

//场景接入
export const qyWeixinKfScenesConfigList = (data)=>request('/pc/qyWeixin/qyWeixinKfScenesConfigList',data)
//场景接入-保存
export const qyWeixinKfScenesConfigSave = (data)=>request('/pc/qyWeixin/qyWeixinKfScenesConfigSave',data)
//获取客服账号
export const qyWeixinKfAccountList = (data)=>request('/pc/qyWeixinKfAccount/list',data)
//智能回复分组列表
export const smartReplyGroupList = (data)=>request('/pc/smartReplyGroup/list',data)
//智能回复分组保存
export const smartReplyGroupSave = (data)=>request('/pc/smartReplyGroup/save',data)
///pc/smartReplyGroup/delete智能回复分组删除
export const smartReplyGroupDelete = (data)=>request('/pc/smartReplyGroup/delete',data)
///pc/smartReplyGroupQA/list智能回复问答列表
export const smartReplyGroupQAList = (data)=>request('pc/smartReplyGroupQA/list',data)
//批量修改/pc/smartReplyGroupQA/batchUpdateGroup
export const batchUpdateGroup = (data)=>request('/pc/smartReplyGroupQA/batchUpdateGroup',data)
//智能回复回答保存/pc/smartReplyGroupQA/save
export const saveSmartReplyGroupQA= (data)=>request('/pc/smartReplyGroupQA/save',data)
//智能回复删除/批量删除/pc/smartReplyGroupQA/batchDelete
export const batchDelete= (data)=>request('/pc/smartReplyGroupQA/batchDelete',data)
//智能回复详情/pc/smartReplyGroupQA/detail
export const smartReplyGroupQAdetail= (data)=>request('/pc/smartReplyGroupQA/detail',data)




//sv2.3.1
//获取推荐奖励
export const recommrndawrdmallrecommrndawrd = (data)=>request('/pc/recommrndawrd/mallrecommrndawrd',data)
//推荐商户列表
export const recommrndawrdrecommrndmallpagelist = (data)=>request('/pc/recommrndawrd/recommrndmallpagelist',data)

//获取商户提现账号
export const recommrndawrdmallwithdrawalaccount = (data)=>request('/pc/recommrndawrd/mallwithdrawalaccount',data)
//保存商户提现账户
export const recommrndawrdwithdrawalaccountsave = (data)=>request('/pc/recommrndawrd/withdrawalaccountsave',data)
//提现时发送短信图片验证码
export const recommrndawrdsmswithdrawalsmsimgcode = (data)=>request('/pc/recommrndawrd/sms/withdrawalsmsimgcode',data)
//提现时发送验证码
export const recommrndawrdsmswithdrawalsendsmscode = (data)=>request('/pc/recommrndawrd/sms/withdrawalsendsmscode',data)
//发起提现
export const recommrndawrdmallapplywithdrawal = (data)=>request('/pc/recommrndawrd/mallapplywithdrawal',data)
//获取提现记录
export const recommrndawrdmallwithdrawalrecord = (data)=>request('/pc/recommrndawrd/mallwithdrawalrecord',data)
//获取渠道商家
export const recommrndawrdchannelbusinessmallpagelist = (data)=>request('/pc/recommrndawrd/channelbusinessmallpagelist',data)


//免费开通付费规则
export const paymentrulereceivepaymentrule = (data)=>request('/pc/paymentrule/receivepaymentrule',data)
//申请支付
export const paymentruleapplypaymentrule = (data)=>request('/pc/paymentrule/applypaymentrule',data)

//获取支付结果
export const paymentrulebuypaymentruleResult = (data)=>request('/pc/paymentrule/buypaymentruleResult',data)


//获取协议信息
export const mallsystemAgreementInfoInit = (data)=>request('/pc/mall/systemAgreementInfoInit',data)
//手机号授权信息
export const phoneAuthSceneConfiglist = (data)=>request('/pc/phoneAuthSceneConfig/list',data)
//保存手机号授权场景
export const phoneAuthSceneConfigsave = (data)=>request('/pc/phoneAuthSceneConfig/save',data)
//我的推荐奖励海报列表
export const mallinviteposterlist = (data)=>request('/pc/mall/inviteposterlist',data)
// export const  = (data)=>request('',data)

//自动营销-初始化   这里获取短信数量
export const autoMarketingTaskConfiginit = (data) => request('/pc/autoMarketingTaskConfig/init',data);



//批量加好友-统计，各导入批次数据明细
export const qyWeixinAddFriendimportRecordstatislist = (data) => request('/pc/qyWeixinAddFriend/importRecord/statis/list',data);


//直播间分享员
//页面初始化
export const videoLiveSharerinit = (data) => request('/pc/videoLiveSharer/init',data);

//修改邀请设置
export const videoLiveSharermodifyInvitationConfig = (data) => request('/pc/videoLiveSharer/modifyInvitationConfig',data);

//分享员列表
export const videoLiveSharerlist = (data) => request('/pc/videoLiveSharer/list',data);
//分享员列表 解绑
export const videoLiveSharerunbind = (data) => request('/pc/videoLiveSharer/unbind',data);
//邀请分享员-搜索
export const videoLiveSharerinvitationSearch = (data) => request('/pc/videoLiveSharer/invitationSearch',data);
//邀请分享员-提交
export const videoLiveSharerinvitationinvitationSubmit = (data) => request('/pc/videoLiveSharer/invitationSubmit',data);
//邀请分享员-导入
export const videoLiveSharerinvitationImport = (data) => request('/pc/videoLiveSharer/invitationImport',data);
//邀请分享员-导入记录
export const videoLiveSharerimportRecordList = (data) => request('/pc/videoLiveSharer/importRecord/List',data);
//邀请分享员-带货明细
export const videoLiveSharerliveList = (data) => request('/pc/videoLiveSharer/live/list',data);
//邀请分享员-订单明细
export const videoLiveSharerliveorderlist = (data) => request('/pc/videoLiveSharer/live/order/list',data);




//校验开放平台账号是否需要认证
export const qyweixinvaliteOpenIsNeedCert = (data) => request('/pc/qyweixin/valiteOpenIsNeedCert',data);

//同步企业微信客户关系
export const qyweixincustomersyncqyweixincustomerrelation = (data) => request('/pc/qyweixincustomer/syncqyweixincustomerrelation',data);
//同步企业微信客户群
export const qyweixincustomersyncqyweixinrealgroup = (data) => request('/pc/qyweixincustomer/syncqyweixinrealgroup',data);




//获取推手视频点信息
export const shortVideomemberShortVideoPoint = (data) => request('/pc/shortVideo/memberShortVideoPoint',data);
//分配视频点数
export const shortVideodistribuMemberShortVideoPoint = (data) => request('/pc/shortVideo/distribuMemberShortVideoPoint',data);


//获取视频号设置保存
export const videoLivevideoLiveConfigSave = (data) => request('/pc/videoLive/videoLiveConfigSave',data);
//获取视频号设置
export const videoLivevideoLiveConfig = (data) => request('/pc/videoLive/videoLiveConfig',data);





//获取员工商城客户身份
export const employeeemployeeRelationMemberInfo = (data) => request('/pc/employee/employeeRelationMemberInfo',data);



